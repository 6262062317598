import { useLocation, navigate, useParams } from '@reach/router';
import { useCallback, useMemo } from 'react';

export function useCurrentPage(): [number, (page: number) => void] {
  const location = useLocation();
  const { id } = useParams() as { id: number | undefined };

  const currentPage = useMemo(() => {
    const search = new URLSearchParams(location.search);
    const current = Number(search.get('page'));
    if (Number.isNaN(current) || typeof current !== 'number') {
      return 0;
    }
    return Math.max(current - 1, 0);
  }, [location.search]);

  const setCurrentPage = useCallback(
    (page: number) => {
      // NOTE: Preventing clearing of URL search queries in SectionsEdit component
      if (id) {
        return;
      }
      if (page === 0) {
        navigate(location.pathname);
      } else {
        navigate(`?page=${page + 1}`);
      }
    },
    [location.pathname, id],
  );

  return [currentPage, setCurrentPage];
}
