import { PlanSelection } from './types';

export const PLANS: PlanSelection[] = [
  {
    name: 'Free',
    idAnnual: 'free',
    idMonthly: 'free',
    isFree: true,
    priceAnnual: 0,
    priceMonthly: 0,
    chargebeeIdAnnual: '',
    chargebeeIdMonthly: '',
  },
  {
    name: 'Freelancer',
    idAnnual: 'freelancer_annual',
    idMonthly: 'freelancer_monthly',
    isFree: false,
    priceAnnual: 500,
    priceMonthly: 50,
    chargebeeIdAnnual: 'sitesails-freelancer-year',
    chargebeeIdMonthly: 'sitesails-freelancer-month',
  },
  {
    name: 'Agency',
    idAnnual: 'agency_annual',
    idMonthly: 'agency_monthly',
    isFree: false,
    priceAnnual: 2000,
    priceMonthly: 200,
    chargebeeIdAnnual: 'sitesails-agency-year',
    chargebeeIdMonthly: 'sitesails-agency-month',
  },
];
