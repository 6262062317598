import { useState } from 'react';

export function useSeoMetadata() {
  const [editorOpen, setEditorOpen] = useState(false);

  function openEditor() {
    setEditorOpen(true);
  }

  function closeEditor() {
    setEditorOpen(false);
  }

  return {
    editorOpen,
    openEditor,
    closeEditor,
  };
}
