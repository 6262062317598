import { useRef, useState } from 'react';
import { NodeBlockField } from 'src/services/nodes';

export type BlockFieldsEditorHookProps = ReturnType<typeof useBlockFieldsEditor>;

export function useBlockFieldsEditor() {
  const [addingField, setAddingField] = useState(false);
  const [editField, setEditField] = useState<NodeBlockField | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null!);

  return {
    addingField,
    setAddingField,
    editField,
    setEditField,
    wrapperRef,
  };
}
