import { useDispatch, useSelector } from 'react-redux';
import { MAX_TABLE_ROWS } from 'src/common/const';
import {
  getUsers,
  fetchUsers,
  deleteUser as deleteUserAction,
  addUser,
  updateUser as updateUserAction,
  fetchAllUsers,
  clearUsers,
} from '../redux';
import { User } from '../types';

export function useUsers() {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);

  function removeUsers() {
    return dispatch(clearUsers());
  }

  function loadUsers(organizationId?: number, projectId?: number, page = 1, search?: string) {
    return dispatch(fetchUsers(page, MAX_TABLE_ROWS, { organizationId, projectId, search }));
  }

  function loadAllUsers(page = 1, search?: string) {
    return dispatch(fetchAllUsers(page, MAX_TABLE_ROWS, { search }));
  }

  function deleteUser(id: number) {
    return dispatch(deleteUserAction(id));
  }

  function createUser(data: User) {
    return dispatch(addUser(data));
  }

  function updateUser(data: User) {
    return dispatch(updateUserAction(data));
  }

  return {
    users,
    removeUsers,
    deleteUser,
    loadUsers,
    loadAllUsers,
    createUser,
    updateUser,
  };
}
