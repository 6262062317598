import _ from 'lodash';

import { Project } from './types';

export const transformProject = (response: any): Project => {
  const projectResponse = _.get(response, 'project', response);

  return {
    adminApiKey: projectResponse.adminApiKey,
    defaultLanguageId: projectResponse.defaultLanguageId,
    hasMembers: projectResponse.hasMembers,
    hasTranslations: projectResponse.hasTranslations,
    hasKeyValues: projectResponse.hasKeyValues,
    id: projectResponse.id,
    name: projectResponse.name,
    notificationsEmail: projectResponse.notificationsEmail,
    publicApiKey: projectResponse.publicApiKey,
    languages: projectResponse.languages,
    slug: projectResponse.slug,
    organizationId: projectResponse.organizationId,
    cacheBustWebhook: projectResponse.cacheBustWebhook,
    redeployWebhook: projectResponse.redeployWebhook,
    includeOriginalImageVariant: projectResponse.includeOriginalImageVariant,
    role: projectResponse.role,
    projectType: projectResponse.projectType,
    themeId: projectResponse.themeId,
    customHosted: projectResponse.customHosted,
    autoHosted: projectResponse.autoHosted,
    isSelfHosted: projectResponse.isSelfHosted,
    domain: projectResponse.domain,
  };
};
