import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { NavLink } from './NavLink';
import { LayoutWithNavbar } from './LayoutWithNavbar';

type EmptySectionsProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function EmptySections(_: EmptySectionsProps) {
  return (
    <LayoutWithNavbar
      endButtons={
        <NavLink className="button is-primary" to="/sections">
          <span>New Section</span>
          <span className="icon">
            <i className="fal fa-plus" />
          </span>
        </NavLink>
      }
    >
      <div className="content p-5">
        <h2 className="title is-3 is-spaced">It&apos;s empty here, why don&apos;t you create some sections?</h2>
        <h3 className="subtitle is-5 has-text-grey">
          <p>Sections are the main building blocks of SiteSails.</p>
          <p className="mt-4">
            Create your first section by clicking the button above or through the Sections link in the sidebar.
          </p>
        </h3>
      </div>
    </LayoutWithNavbar>
  );
}
