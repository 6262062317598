import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from '@reach/router';
import { Column } from 'react-table';
import Swal from 'sweetalert2';
import { useDebounce } from 'use-debounce';

import {
  useCurrentPage,
  LayoutWithNavbar,
  Table,
  TableDropdown,
  usePrevSearchURLValue,
  addParamsToLink,
} from 'src/common';
import { isLoading } from 'src/services/api';
import { getTotalPages } from 'src/services/api/redux/traits';
import { useUsers, User } from 'src/services/users';
import { RouteComponentListProps } from 'src/types';
import { showToast } from 'src/common/util/show-toast';
import { ORGANIZATION_ROLES_OPTIONS, PROJECT_ROLES_OPTIONS } from './const';

type UsersListProps = Partial<RouteComponentListProps> & {
  organizationId?: number;
  projectId?: number;
};

export function UserList({ organizationId, projectId }: UsersListProps) {
  const { users, deleteUser, loadUsers } = useUsers();
  const [currentPage] = useCurrentPage();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const prevSearchURLValue = usePrevSearchURLValue();

  async function onDeleteUser(user: User) {
    const confirm = await Swal.fire({
      title: 'Delete User',
      text: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
      showCancelButton: true,
      confirmButtonColor: '#e0245a',
      confirmButtonText: 'Delete',
      icon: 'question',
    });

    if (confirm.value) {
      const result = await deleteUser(user.id!);

      if (result.ok) {
        showToast('success', `${user.firstName} ${user.lastName} has been deleted successfully`);
      } else {
        showToast('success', 'Unable to delete user');
      }
    }
  }

  useEffect(() => {
    if (prevSearchURLValue) return;
    loadUsers(organizationId, projectId, currentPage + 1, debouncedSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const fetchUsers = useCallback(
    (page = 1) => {
      return loadUsers(organizationId, projectId, page, debouncedSearch);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch],
  );

  const getRoleName = (roleId: string) => {
    if (projectId) {
      return PROJECT_ROLES_OPTIONS.filter((r) => r.value === roleId)[0]?.label;
    }

    return ORGANIZATION_ROLES_OPTIONS.filter((r) => r.value === roleId)[0]?.label;
  };

  const tableColumns: Column<User>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: ({ row: { original } }) => (
          <div className="has-text-left">{`${original.firstName} ${original.lastName}`}</div>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => <div className="has-text-left">{value}</div>,
      },
      {
        Header: 'Role',
        accessor: 'roleName',
        Cell: ({ row: { original } }) => (
          <div className="has-text-left">
            {projectId ? getRoleName(original.projectRole!) : getRoleName(original.organizationRole!)}
          </div>
        ),
      },
      {
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <TableDropdown>
            <a className="dropdown-item" onClick={() => onDeleteUser(original)}>
              Delete
            </a>
          </TableDropdown>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, search],
  );

  const inviteLink = projectId
    ? addParamsToLink('/users/new', { currentPage: currentPage + 1 })
    : addParamsToLink(`/organizations/${organizationId}/users/new`, { currentPage: currentPage + 1 }, false);

  return (
    <LayoutWithNavbar
      breadcrumbs={[
        { label: 'SiteSails', href: '/' },
        { label: 'Users', href: '/users' },
      ]}
      onSearch={setSearch}
      searchValue={search}
      endButtons={
        <div className="navbar-item">
          <Link to={inviteLink} className="button is-primary">
            <span>Invite user</span>
            <span className="icon">
              <i className="fal fa-plus" />
            </span>
          </Link>
        </div>
      }
    >
      <Table
        data={users.items}
        loading={isLoading(users)}
        columns={tableColumns}
        onFetchData={fetchUsers}
        pageCount={getTotalPages(users)}
        paginated
      />
    </LayoutWithNavbar>
  );
}
