import clsx from 'clsx';
import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ArrayField, UseFormMethods } from 'react-hook-form';
import { Accordion, CustomFields } from 'src/common';
import { NodeBlock } from 'src/services/nodes';
import { PageNode } from 'src/services/pages';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { PageBlocksToolbar } from './PageBlocksToolbar';
import { PageBlocksHookProps } from '../hooks/use-page-blocks';
import { BlockEditorModal } from './BlockEditorModal';

type PageBlocksProps = {
  value: NodeBlock[];
  onChange: (value: NodeBlock[]) => void;
  onBlur: () => void;
  fields: Partial<ArrayField<PageNode, 'id'>>[];
  methods: UseFormMethods<PageNode>;
  activeTab: string | null;
} & PageBlocksHookProps;

export function PageBlocks({
  value: blocks,
  fields,
  methods,
  activeTab,
  onChange,
  onBlur,
  containerRef,
  ...pageBlocksProps
}: PageBlocksProps) {
  const [activeBlock, setActiveBlock] = useState<string | null>(null);
  const selectedBlock = activeBlock ? find(blocks, { apiNamePrefix: activeBlock }) || null : null;
  const { setAddingBlock, setEditBlock } = pageBlocksProps;

  useEffect(() => {
    setActiveBlock(null);
  }, [blocks]);

  return containerRef.current
    ? createPortal(
        <>
          <PageBlocksToolbar
            activeBlock={selectedBlock}
            onAddBlock={() => setAddingBlock(true)}
            onEditBlock={() => setEditBlock(selectedBlock)}
            blocks={blocks}
            onChange={onChange}
            onBlur={onBlur}
          />
          <div className="container is-fluid page-blocks-wrapper">
            {blocks.map((block) => (
              <motion.div key={block.apiNamePrefix} layout className="mb-4">
                <Accordion
                  className={clsx('page-block', activeBlock === block.apiNamePrefix && 'is-active')}
                  onActivateItem={setActiveBlock}
                  fullWidthClickable={false}
                  items={[
                    {
                      Header: block.name,
                      id: block.apiNamePrefix,
                      defaultExpanded: true,
                      subtitle: block.description,
                      content: (
                        <CustomFields
                          sectionFields={block.fields}
                          currentLanguage={activeTab}
                          languages={fields}
                          {...methods}
                        />
                      ),
                    },
                  ]}
                />
              </motion.div>
            ))}
          </div>
          <BlockEditorModal value={blocks} onChange={onChange} onBlur={onBlur} {...pageBlocksProps} />
        </>,
        containerRef.current,
      )
    : null;
}
