import { useCallback, useState } from 'react';

import { performApiCall } from 'src/services/api';
import { Category } from '../types';
import { transformCategory } from '../transformations';

export function useCategories() {
  const [categories, setCategories] = useState<Category[] | null>(null);

  const loadCategories = useCallback(async (sectionSlug: string) => {
    const res = await performApiCall({
      path: `/nodes/${sectionSlug}/categories`,
    });

    const data = res.data.data.map(transformCategory);

    setCategories(data);
  }, []);

  return {
    categories,
    loadCategories,
  };
}
