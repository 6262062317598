import React from 'react';
import Tippy from '@tippyjs/react/headless';
import clsx from 'clsx';

type DropdownProps = {
  children: React.ReactNode;
  renderTrigger: (() => React.ReactNode) | React.ReactNode;
  align?: 'left' | 'right';
  tippyPlacement?:
    | 'left'
    | 'right'
    | 'right-start'
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'bottom'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-end'
    | 'left-start'
    | 'left-end';
  'data-qa'?: string;
  up?: boolean;
};

export function Dropdown({
  children,
  renderTrigger,
  align = 'left',
  'data-qa': dataQa,
  tippyPlacement = 'right-start',
  up = false,
}: DropdownProps) {
  return (
    <Tippy
      render={(attrs) => (
        <div className={clsx(`dropdown is-${align} is-active`, up && 'is-up')}>
          <div className="dropdown-menu" tabIndex={-1} {...attrs}>
            {children}
          </div>
        </div>
      )}
      trigger="click"
      placement={tippyPlacement}
      interactive
      appendTo={document.body}
    >
      <div className="dropdown-trigger" data-qa={dataQa}>
        {typeof renderTrigger === 'function' ? renderTrigger() : renderTrigger}
      </div>
    </Tippy>
  );
}
