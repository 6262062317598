import React, { useState, useEffect, useRef, ReactNode } from 'react';

import { getFileInputValue } from 'src/common/util';
import { addMedia } from 'src/services/media';

type FileAutoUploaderProps = {
  label?: ReactNode;
  value?: any;
  isPublic: boolean;
  onChange?: (value: any) => any;
} & JSX.IntrinsicElements['input'];

type FileProps = {
  id: number;
  name: string;
  url: string;
};

export function FileAutoUploaderHook({ label, id, value, isPublic, onChange, ...props }: FileAutoUploaderProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [thumb, setThumb] = useState<FileProps | null>(null);

  const handleFileChange = async (file: File | File[] | null) => {
    if (file && !Array.isArray(file)) {
      const res = await addMedia(file, isPublic);

      if (res.ok) {
        const fileData = {
          id: res.data.id,
          url: res.data.url,
          name: res.data.name,
        };

        onChange!(fileData);
      }
    } else {
      onChange!(file);
    }
  };

  useEffect(() => {
    async function load() {
      if (value) {
        setThumb(value);
      } else {
        setThumb(null);
        if (ref.current) {
          ref.current.value = '';
        }
      }
    }
    load();
  }, [value]);

  const isLink = thumb && thumb.url.startsWith('http');
  const isPDF = thumb && thumb.name.endsWith('.pdf');

  return (
    <div className="field has-floating-label file-uploader">
      <label className="label" htmlFor={id}>
        {label}
      </label>
      <div className="control">
        <div className="file-container">
          {!thumb && (
            <label htmlFor={id} className="upload">
              <span className="icon is-small has-text-info">
                <i className="fal fa-plus" />
              </span>
              <span>Upload file</span>
            </label>
          )}
          {thumb && (
            <div className="uploaded">
              <div className="content">
                {isPDF && (
                  <span className="pdfIcon">
                    <i className="fal fa-file-pdf" />
                  </span>
                )}
                <p className="fileName">{thumb.name}</p>
              </div>
              <button type="button" className="button delete" onClick={() => handleFileChange(null)}>
                <span className="icon">
                  <i className="fal fa-trash" />
                </span>
              </button>
              <div className="overlay">
                {/* eslint-disable jsx-a11y/anchor-has-content */}
                {isLink && <a href={thumb.url} target="_blank" rel="noopener noreferrer" />}
              </div>
            </div>
          )}
        </div>
      </div>
      <input
        type="file"
        id={id}
        ref={ref}
        accept="file/*"
        {...props}
        onChange={() => handleFileChange(getFileInputValue(ref))}
      />
    </div>
  );
}
