import { combineReducers } from 'redux';

import { chainReducers, createApiReducer, INITIAL_API_STATE } from 'src/services/api';

import { TransactionActionTypes } from './actions';
import { transformProductTransaction } from '../transformations';

const transactionsReducer = chainReducers(
  INITIAL_API_STATE,
  createApiReducer(
    [
      TransactionActionTypes.CHANGE_TRANSACTIONS_START,
      TransactionActionTypes.FETCH_TRANSACTIONS_SUCCESS,
      TransactionActionTypes.CHANGE_TRANSACTIONS_FAILURE,
    ],
    (data) => ({
      _meta: data.meta,
      items: data.data.map(transformProductTransaction),
    }),
    (data) => data,
  ),
);

export default combineReducers({
  transactions: transactionsReducer,
});
