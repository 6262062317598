import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_TABLE_ROWS } from 'src/common/const';
import {
  getNodes,
  fetchNodes,
  deleteNode as deleteNodeAction,
  addNode,
  updateNode as updateNodeAction,
} from '../redux';
import { Node } from '../types';

export function useNodes(sectionSlug: string) {
  const dispatch = useDispatch();
  const nodes = useSelector(getNodes);

  const loadNodes = useCallback(
    (page = 1, sectionId: number, search?: string, sortBy?: string, sortDirection?: string) => {
      return dispatch(
        fetchNodes(page, MAX_TABLE_ROWS, {
          search,
          sectionId,
          include: 'unpublished',
          sectionSlug,
          sortBy: sortBy || 'id',
          sortDirection: sortDirection || 'desc',
        }),
      );
    },
    [dispatch, sectionSlug],
  );

  const deleteNode = useCallback(
    (id: number) => {
      return dispatch(deleteNodeAction(id, { sectionSlug }));
    },
    [dispatch, sectionSlug],
  );

  const createNode = useCallback(
    (data: Node) => {
      return dispatch(addNode(data, { sectionSlug }));
    },
    [dispatch, sectionSlug],
  );

  const updateNode = useCallback(
    (data: Node) => {
      return dispatch(updateNodeAction(data, { sectionSlug, id: data.id }));
    },
    [dispatch, sectionSlug],
  );

  return { nodes, deleteNode, loadNodes, createNode, updateNode };
}
