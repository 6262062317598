import clsx from 'clsx';
import React from 'react';

type TableBooleanProps = {
  state?: boolean;
  iconOn?: string;
  iconOff?: string;
  onClick?: () => void;
};

export function TableBoolean({ state = false, iconOn = 'check', iconOff = 'times', onClick }: TableBooleanProps) {
  const className = clsx('fal', {
    // ON
    [`fa-${iconOn}`]: state,
    'has-text-blue-400': state,

    // OFF
    [`fa-${iconOff}`]: !state,
    'has-text-ui-60': !state,
  });

  return (
    <span className={clsx('icon table-boolean', !!onClick && 'is-clickable')} onClick={onClick}>
      <i className={className} />
    </span>
  );
}
