import { format } from 'date-fns';
import { contentComparer } from 'src/common';
import { DEFAULT_API_DATE_FORMAT } from 'src/common/const';
import { PageNode } from './types';

export const transformPageForForm = (page: PageNode, defaultLanguageId: string): PageNode => ({
  ...page,
  publishDate: page.publishable?.publishedFrom ? format(page.publishable?.publishedFrom, DEFAULT_API_DATE_FORMAT) : '',
  publishTime: page.publishable?.publishedFrom ? format(page.publishable?.publishedFrom, 'HH:mm') : '',
  publishedToDate: page.publishable?.publishedTo ? format(page.publishable?.publishedTo, DEFAULT_API_DATE_FORMAT) : '',
  publishedToTime: page.publishable?.publishedTo ? format(page.publishable?.publishedTo, 'HH:mm') : '',
  alwaysActive: !page.publishable?.publishedTo,
  contents: !page.contents?.length
    ? [
        {
          languageId: defaultLanguageId,
          slug: '',
          data: {},
        },
      ]
    : page.contents.sort(contentComparer({ defaultLanguageId })),
});
