import { createCrudApiActions } from 'src/services/api';
import { Connection } from '../types';

export enum ConnectionsActionTypes {
  CHANGE_CONNECTIONS_START = 'connections/change_connections_start',
  CHANGE_CONNECTIONS_FAILURE = 'connections/change_connections_failure',
  FETCH_CONNECTIONS_SUCCESS = 'connections/fetch_connections_success',
  ADD_CONNECTION_SUCCESS = 'connections/add_connection_success',
  UPDATE_CONNECTION_SUCCESS = 'connections/update_connection_success',
  DELETE_CONNECTION_SUCCESS = 'connections/delete_connection_success',
  UPDATE_CONNECTION_VERIFICATION = 'connections/update_connection_verification',
}

export const [fetchConnections, addConnection, updateConnection, deleteConnection] = createCrudApiActions<Connection>(
  '/connections',
  ConnectionsActionTypes.CHANGE_CONNECTIONS_START,
  ConnectionsActionTypes.CHANGE_CONNECTIONS_FAILURE,
  ConnectionsActionTypes.FETCH_CONNECTIONS_SUCCESS,
  ConnectionsActionTypes.ADD_CONNECTION_SUCCESS,
  ConnectionsActionTypes.UPDATE_CONNECTION_SUCCESS,
  ConnectionsActionTypes.DELETE_CONNECTION_SUCCESS,
  (response) => response,
  { load: '/:slug', add: '/:slug', change: '/:slug/:id' },
);

export const setConnectionVerification = (id: number, verifiedAt: boolean) => ({
  type: ConnectionsActionTypes.UPDATE_CONNECTION_VERIFICATION,
  payload: { id, verifiedAt },
});
