import React, { useEffect } from 'react';
import clsx from 'clsx';
import * as yup from 'yup';

import { InputHook, isTouched, LayoutWithNavbar } from 'src/common';
import { Accordion, EditPageLayout } from 'src/common/components';
import { CurrentUserUpdateAPI } from 'src/services/users/redux/types';
import { useCurrentUser } from 'src/services/users';
import { VALIDATION_MESSAGES } from 'src/common/const';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from 'src/services/auth';
import { useProject } from 'src/services/project';
import { DevTool } from '@hookform/devtools';
import { RouteComponentProps } from '@reach/router';
import { showToast } from '../../common/util/show-toast';

const validationSchema = yup.object().shape({
  firstName: yup.string().required().label('First name'),
  lastName: yup.string().required().label('Last name'),
  password: yup.string().notRequired(),
  confirmPassword: yup
    .string()
    .label('Confirm Password')
    .when('password', (password: string, schema: any) => {
      if (password) {
        return schema.required().matches(new RegExp(password), VALIDATION_MESSAGES.passwordConfirmation());
      }
      return schema.notRequired();
    }),
});

type UserProfileProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function UserProfile(_: UserProfileProps) {
  const { currentUser, updateUser } = useCurrentUser();
  const { project } = useProject();
  const { logOut } = useAuth();
  const { handleSubmit, control, formState, reset } = useForm<CurrentUserUpdateAPI & { confirmPassword?: string }>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const save = handleSubmit(async (values) => {
    const result = await updateUser(values);

    // @ts-ignore
    if (result.ok) {
      showToast('success', 'Successfully saved');
    } else {
      showToast('error', 'There was an error processing your action');
    }
  });

  useEffect(() => {
    reset({
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
    });
  }, [project, currentUser, reset]);

  return (
    <>
      <LayoutWithNavbar
        breadcrumbs={[
          { label: 'SiteSails', href: '/' },
          { href: './', label: 'Profile' },
        ]}
        endButtons={
          <>
            <div className="navbar-item">
              <button
                type="button"
                className="button is-light has-text-link is-rounded"
                onClick={logOut}
                data-qa="logout-button"
              >
                <span className="icon">
                  <i className="fal fa-door-open" />
                </span>
              </button>
            </div>
            <div className="navbar-item">
              <button
                type="submit"
                className={clsx('button is-primary', {
                  'is-loading': formState.isSubmitting,
                })}
                data-qa="save-button"
              >
                <span className="icon">
                  <i className="far fa-check has-text-secondary-light" />
                </span>
                <span>Save</span>
              </button>
            </div>
          </>
        }
        as="form"
        onSubmit={save}
      >
        <EditPageLayout
          renderLeft={
            <Accordion
              disableShadow
              items={[
                {
                  Header: 'Personal information',
                  id: 'personal',
                  fixed: true,
                  content: (
                    <div className="columns is-gapless mb-3">
                      <div className="column is-half">
                        <Controller
                          control={control}
                          name="firstName"
                          defaultValue=""
                          as={
                            <InputHook
                              id="firstName"
                              label="First Name"
                              error={formState.errors.firstName?.message}
                              touched={isTouched(formState, 'firstName')}
                              fieldClassName="mr-2"
                            />
                          }
                        />
                      </div>
                      <div className="column is-half">
                        <Controller
                          control={control}
                          name="lastName"
                          defaultValue=""
                          as={
                            <InputHook
                              id="lastName"
                              label="Last Name"
                              error={formState.errors.lastName?.message}
                              touched={isTouched(formState, 'lastName')}
                              fieldClassName="ml-2"
                            />
                          }
                        />
                      </div>
                    </div>
                  ),
                },
                {
                  Header: 'Change password',
                  id: 'password',
                  fixed: true,
                  content: (
                    <div className="columns is-gapless mb-3">
                      <div className="column is-half">
                        <Controller
                          control={control}
                          name="password"
                          defaultValue=""
                          as={
                            <InputHook
                              id="password"
                              label="New password"
                              error={formState.errors.password?.message}
                              touched={isTouched(formState, 'password')}
                              prependIcon={<i className="fal fa-lock-alt" />}
                              fieldClassName="mr-2"
                            />
                          }
                        />
                      </div>
                      <div className="column is-half">
                        <Controller
                          control={control}
                          name="confirmPassword"
                          defaultValue=""
                          as={
                            <InputHook
                              id="confirmPassword"
                              label="Confirm password"
                              error={formState.errors.confirmPassword?.message}
                              touched={isTouched(formState, 'confirmPassword')}
                              prependIcon={<i className="fal fa-lock-alt" />}
                              type="password"
                              fieldClassName="ml-2"
                            />
                          }
                        />
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          }
        />
      </LayoutWithNavbar>
      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </>
  );
}
