import urlcat from 'urlcat';
import { performApiCall } from 'src/services/api';

import { User } from './types';
import { transformUser } from './transformations';

export const fetchMemberDetails = async (memberId: number): Promise<User> => {
  const res = await performApiCall({
    path: `/members/${memberId}`,
  });
  return transformUser(res.data);
};

export const searchMembers = async (search: string) => {
  const res = await performApiCall({
    path: `/members?search=${search}`,
  });
  return res.data.data.map((item: User) => ({ value: item.id, label: `${item.firstName} ${item.lastName}` }));
};

export const updateInvitation = async (invitationId: number, accepted: boolean) => {
  await performApiCall({
    path: `/users/me/invitations/${invitationId}`,
    method: 'POST',
    body: { accepted },
  });
};

export const sendInvitation = async (
  organizationId: number,
  email: string,
  organizationRole?: string,
  projectId?: number,
  projectRole?: string,
) => {
  const res = await performApiCall({
    path: `/users/invitations`,
    method: 'POST',
    body: { organizationId, email, organizationRole, projectId, projectRole },
  });

  return res;
};

export const fetchInvitations = async (organizationId?: number, projectId?: number) => {
  const res = await performApiCall({
    path: urlcat(`/users/invitations`, { organizationId, projectId }),
    method: 'GET',
  });

  return res.data;
};

export const deleteInvitation = async (invitationId: number) => {
  const res = await performApiCall({
    path: `/users/invitations/${invitationId}`,
    method: 'DELETE',
  });

  return res;
};
