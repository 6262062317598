import { combineReducers } from 'redux';
import { chainReducers, createApiReducer, createCrudApiReducer, INITIAL_API_STATE } from 'src/services/api';
import { AuthActionTypes } from 'src/services/auth';
import { UserActionTypes } from 'src/services/users/redux';
import { transformOrganization } from '../transformations';
import { Organization } from '../types';
import { OrganizationActionTypes } from './actions';

const organizationsReducer = createCrudApiReducer<Organization>(
  OrganizationActionTypes.CHANGE_ORGANIZATIONS_START,
  OrganizationActionTypes.CHANGE_ORGANIZATIONS_FAILURE,
  OrganizationActionTypes.FETCH_ORGANIZATIONS_SUCCESS,
  OrganizationActionTypes.ADD_ORGANIZATION_SUCCESS,
  OrganizationActionTypes.UPDATE_ORGANIZATION_SUCCESS,
  OrganizationActionTypes.DELETE_ORGANIZATION_SUCCESS,
  transformOrganization,
);

const fetchCurrentUserReducer = createApiReducer(
  [
    UserActionTypes.CHANGE_CURRENT_USER_START,
    UserActionTypes.FETCH_CURRENT_USER_SUCCESS,
    UserActionTypes.CHANGE_CURRENT_USER_FAILURE,
  ],
  (data) => ({ items: data.organizations.map(transformOrganization) }),
  (data) => data,
);

const signOut = createApiReducer(
  ['', AuthActionTypes.SIGN_OUT, ''],
  () => INITIAL_API_STATE,
  () => INITIAL_API_STATE,
);

export default combineReducers({
  organizations: chainReducers(INITIAL_API_STATE, organizationsReducer, fetchCurrentUserReducer, signOut),
});
