import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getLanguages, fetchLanguages, getAllLanguages } from '../redux';

export function useLanguages() {
  const dispatch = useDispatch();
  const languages = useSelector(getLanguages);
  const allLanguages = useSelector(getAllLanguages);

  useEffect(() => {
    dispatch(fetchLanguages());
  }, [dispatch]);

  return { languages, allLanguages };
}
