import { createCrudApiActions } from 'src/services/api';
import { Collection } from '../types';

export enum CollectionsActionTypes {
  CHANGE_COLLECTIONS_START = 'collections/change_collections_start',
  CHANGE_COLLECTIONS_FAILURE = 'collections/change_collections_failure',
  FETCH_COLLECTIONS_SUCCESS = 'collections/fetch_collections_success',
  ADD_COLLECTION_SUCCESS = 'collections/add_collection_success',
  UPDATE_COLLECTION_SUCCESS = 'collections/update_collection_success',
  DELETE_COLLECTION_SUCCESS = 'collections/delete_collection_success',
}

export const [fetchCollections, addCollection, updateCollection, deleteCollection] = createCrudApiActions<Collection>(
  '',
  CollectionsActionTypes.CHANGE_COLLECTIONS_START,
  CollectionsActionTypes.CHANGE_COLLECTIONS_FAILURE,
  CollectionsActionTypes.FETCH_COLLECTIONS_SUCCESS,
  CollectionsActionTypes.ADD_COLLECTION_SUCCESS,
  CollectionsActionTypes.UPDATE_COLLECTION_SUCCESS,
  CollectionsActionTypes.DELETE_COLLECTION_SUCCESS,
  (response) => response,
  {
    load: '/collections/:slug/:collectionSlug',
    add: '/:slug/:collectionSlug',
    change: '/nodes/:collectionSlug/:id',
  },
);
