import { Link } from '@reach/router';
import { get, truncate } from 'lodash';
import React, { ReactNode, useMemo } from 'react';
import { Column } from 'react-table';
import { NodePublishable } from 'src/services/nodes';
import { Section, SectionFieldType } from 'src/services/sections';
import urlcat from 'urlcat';
import { TableDropdown } from '../components';
import { BACK_TO_PAGE, BACK_TO_SEARCH } from '../const';
import { formatDate } from '../util';
import { formatDateTime } from '../util/format-date';
import { useProjectLink } from './use-project-link';

type UseTableColumnsCustomFieldsConfig<T extends Generic> = {
  section?: Section;
  currentPage: number;
  onDelete: (item: T) => any;
  prependColumns?: Column<T>[];
  appendColumns?: Column<T>[];
  editUrl: string;
  urlTemplate?: string;
  urlParams?: Record<string, any>;
  dataKeyName?: string;
  extraActions?: ReactNode | ((item: T) => ReactNode);
  searchTerm?: string;
};

interface Generic extends Record<string, any> {
  id?: number;
}

export function useTableColumnsCustomFields<T extends Generic>({
  section,
  currentPage,
  onDelete,
  prependColumns = [],
  appendColumns = [],
  editUrl,
  urlTemplate = '/:slug/edit/:id',
  urlParams = {},
  dataKeyName = 'data',
  extraActions = null,
  searchTerm = '',
}: UseTableColumnsCustomFieldsConfig<T>) {
  const projectLink = useProjectLink();

  function editLink(id: number) {
    const newUrl = urlcat(`${projectLink}/${editUrl}`, urlTemplate, {
      slug: section!.slug,
      id,
      ...urlParams,
      [BACK_TO_SEARCH]: searchTerm,
      [BACK_TO_PAGE]: currentPage + 1,
    });

    return newUrl;
  }

  function renderValue(value: any, type: SectionFieldType) {
    if (type === SectionFieldType.Date) {
      return formatDate(value);
    }
    if (type === SectionFieldType.DateTime) {
      return formatDateTime(value);
    }
    if (typeof value === 'string') {
      return truncate(value, { length: 50 });
    }

    if (typeof value === 'object') {
      if (value && value.label) {
        return value.label;
      }

      return 'N/A';
    }

    return value;
  }

  const tableColumns: Column<T>[] = useMemo(
    () =>
      section
        ? [
            ...(section.isPublishable
              ? [
                  {
                    Header: 'Published at',
                    accessor: 'publishable',
                    Cell: ({ value }: { value: NodePublishable }) => (
                      <div>{value ? formatDate(value.publishedFrom!) : ''}</div>
                    ),
                  },
                ]
              : []),
            ...prependColumns,
            ...get(section, 'fields', [])
              .filter((sf) => sf.isDisplayedInList)
              .map<Column<T>>((sf) => ({
                Header: sf.name,
                Cell: ({ row: { original } }: { row: { original: T } }) => (
                  <Link to={editLink(original.id!)}>
                    {renderValue(original[dataKeyName] ? original[dataKeyName][sf.apiName!] : 'N/A', sf.type)}
                  </Link>
                ),
                accessor: `${dataKeyName}.${sf.apiName!}`,
              })),
            ...appendColumns,
            {
              accessor: 'id',
              disableSortBy: true,
              Cell: ({ row: { original } }: { row: { original: T } }) => (
                <TableDropdown>
                  <Link to={editLink(original.id!)} className="dropdown-item">
                    Edit
                  </Link>
                  {typeof extraActions === 'function' ? extraActions(original) : extraActions}
                  <a className="dropdown-item" onClick={() => onDelete(original)}>
                    Delete
                  </a>
                </TableDropdown>
              ),
            },
          ]
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, section, prependColumns],
  );

  return tableColumns;
}
