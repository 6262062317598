import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from '@reach/router';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';
import { Accordion, EditPageLayout, InputHook, isTouched, LayoutWithNavbar, SelectHook } from 'src/common';
import { VALIDATION_MESSAGES } from 'src/common/const';
import { FormUserInvitation } from 'src/services/users';
import { RouteComponentListProps } from 'src/types';
import { showToast } from 'src/common/util/show-toast';
import { useOrganizations } from 'src/services/organizations';
import { useProject } from 'src/services/project';
import { sendInvitation } from 'src/services/users/api';
import { ORGANIZATION_ROLES_OPTIONS, PROJECT_ROLES_OPTIONS, ROLES } from './const';

const validationSchema = yup.object({
  email: yup.string().required(VALIDATION_MESSAGES.required('Email')).email(VALIDATION_MESSAGES.invalidEmail),
  roleId: yup.string().required(VALIDATION_MESSAGES.required('Role')),
});

type UserInviteProps = Partial<RouteComponentListProps> & {
  organizationId?: number;
  projectId?: number;
};

export function UserInvite({ organizationId, projectId }: UserInviteProps) {
  const { organizations } = useOrganizations();
  const { project } = useProject();
  const organization = organizations?.items?.find((o) => o.id === Number(organizationId));

  const { control, register, formState, handleSubmit, reset } = useForm<FormUserInvitation>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const listLink = projectId
    ? `/projects/${projectId}/users/invites`
    : `/organizations/${organizationId}/users/invites`;

  const roles = projectId ? PROJECT_ROLES_OPTIONS : ORGANIZATION_ROLES_OPTIONS.filter((r) => !r.isNonSelectable);

  useEffect(() => {
    reset({
      roleId: 'user',
    });
  }, [reset]);

  const save = handleSubmit(async (values) => {
    let result: AxiosResponse<any> | null = null;

    if (projectId) {
      result = await sendInvitation(project.organizationId, values.email, ROLES.projectUser, projectId, values.roleId);
    } else {
      result = await sendInvitation(organizationId!, values.email, values.roleId);
    }

    if (result.status === 201) {
      showToast('success', 'Invite successfully sent');
      navigate(listLink);
    } else {
      showToast('error', 'There was an error processing your action');
    }
  });

  return (
    <>
      <LayoutWithNavbar
        backHref={listLink}
        breadcrumbs={[
          { label: 'SiteSails', href: '/' },
          { href: listLink, label: 'Users' },
          { href: '#', label: 'Invite' },
        ]}
        endButtons={
          <div className="navbar-item">
            <button
              type="submit"
              className={clsx('button is-primary', {
                'is-loading': formState.isSubmitting,
              })}
              data-qa="save-button"
            >
              <span className="icon">
                <i className="far fa-check has-text-secondary-light" />
              </span>
              <span>Save</span>
            </button>
          </div>
        }
        as="form"
        onSubmit={save}
      >
        <EditPageLayout
          renderLeft={
            <Accordion
              disableShadow
              items={[
                {
                  Header: projectId
                    ? `Invite user to ${project?.name} project`
                    : `Invite user to ${organization?.name} organization`,
                  id: 'user',
                  fixed: true,
                  subtitle: `Invite will be sent to the specified email address. User can accept it
                    after signing in to SiteSails.`,
                  content: (
                    <>
                      <Controller
                        control={control}
                        name="email"
                        as={<InputHook id="email" label="Email" type="email" />}
                        error={formState.errors.email?.message}
                        touched={isTouched(formState, 'email')}
                        defaultValue=""
                      />
                      <SelectHook
                        ref={register}
                        name="roleId"
                        label="Role"
                        id="roleId"
                        options={roles}
                        error={formState.errors.roleId?.message}
                        touched={isTouched(formState, 'roleId')}
                      />
                    </>
                  ),
                },
              ]}
            />
          }
        />
      </LayoutWithNavbar>
      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </>
  );
}
