import React, { Fragment } from 'react';
import { ArrayField, Controller, UseFormMethods } from 'react-hook-form';
import { InputHook, isTouched, PublishableFields } from 'src/common';
import { PageNode } from 'src/services/pages';

type CommonDataProps = {
  methods: UseFormMethods<PageNode>;
  fields: Partial<ArrayField<PageNode, 'id'>>[];
  activeTab: string | null;
  isPublishable?: boolean;
  isSlugDisabled?: boolean;
};

export function CommonData({
  methods,
  fields,
  activeTab,
  isPublishable = true,
  isSlugDisabled = false,
}: CommonDataProps) {
  const { control, formState, register } = methods;

  return (
    <>
      {fields.map((content, index) => (
        <Fragment key={content.languageId}>
          <input type="hidden" ref={register()} name={`contents[${index}].id`} defaultValue={content.id!} />
          <input
            type="hidden"
            ref={register()}
            name={`contents[${index}].languageId`}
            defaultValue={content.languageId}
          />
          <div className={activeTab !== content.languageId ? 'is-hidden' : 'mb-4'}>
            <Controller
              name={`contents[${index}].data.title`}
              control={control}
              defaultValue={content.data?.title || ''}
              as={
                <InputHook
                  label="Title"
                  id={`title-${content.languageId}`}
                  touched={isTouched(formState, `contents[${index}].data.title`)}
                  error={(formState.errors.contents as any)?.[index]?.data?.title?.message}
                  data-qa={`page-field-${content.languageId}-title`}
                />
              }
            />
            <Controller
              name={`contents[${index}].slug`}
              control={control}
              defaultValue={content.slug || ''}
              as={
                <InputHook
                  label="Slug"
                  id={`slug-${content.languageId}`}
                  disabled={isSlugDisabled}
                  touched={isTouched(formState, `contents[${index}].slug`)}
                  error={(formState.errors.contents as any)?.[index]?.slug?.message}
                  data-qa={`page-field-${content.languageId}-slug`}
                />
              }
            />
          </div>
        </Fragment>
      ))}
      {isPublishable && <PublishableFields control={control} formState={formState} node={null} register={register} />}
    </>
  );
}
