import { find } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { SortingRule } from 'react-table';
import { useDebounce } from 'use-debounce';

import {
  ButtonCreate,
  LayoutWithNavbar,
  Table,
  useCurrentPage,
  usePrevSearchURLValue,
  useTableColumnsCustomFields,
} from 'src/common';
import { isLoading } from 'src/services/api';
import { getTotalPages } from 'src/services/api/redux/traits';
import { Collection, useCollections } from 'src/services/collections';
import { useSections } from 'src/services/sections';
import { RouteCollectionsListProps } from 'src/types';
import { SortDirection } from 'src/services/api/types';
import Swal from 'sweetalert2';
import { showToast } from 'src/common/util/show-toast';

type CollectionsListProps = RouteCollectionsListProps;

export function CollectionsList({ slug, sectionType, collectionSlug }: CollectionsListProps) {
  const { sections } = useSections();
  const section = find(sections.items, { slug });
  const collection = find(sections.items, { slug: collectionSlug });

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const prevSearchURLValue = usePrevSearchURLValue();

  const [sortDirection, setSortDirection] = useState(SortDirection.Descending);
  const [sortBy, setSortBy] = useState<string | undefined>('id');

  const { collections, loadCollections, deleteCollection } = useCollections(slug!, collectionSlug!);
  const [currentPage, setCurrentPage] = useCurrentPage();

  const updateSearch = useCallback(
    (text: string) => {
      if (!prevSearchURLValue) setCurrentPage(0);
      setSearch(text);
    },
    [setCurrentPage, prevSearchURLValue],
  );

  const handleSortedChange = useCallback((params?: SortingRule<Collection>) => {
    if (!params) {
      return;
    }

    let paramSortBy = params?.id || 'id';
    if (paramSortBy === 'createdAt') {
      paramSortBy = 'created_at';
    }

    setSortBy(paramSortBy);
    setSortDirection(params?.desc ? SortDirection.Descending : SortDirection.Ascending);
  }, []);

  const onDeleteCollection = async (deleting: any) => {
    const confirm = await Swal.fire({
      title: 'Delete',
      text: `Are you sure you want to delete?`,
      showCancelButton: true,
      confirmButtonColor: '#e0245a',
      confirmButtonText: 'Delete',
      icon: 'question',
    });

    if (confirm.value) {
      const result = await deleteCollection(deleting.id!);

      if (result.ok) {
        showToast('success', 'Entry has been deleted successfully');
      } else {
        showToast('error', 'Unable to delete');
      }
    }
  };

  const loadCollectionsWithSearch = useCallback(
    (
      page?: number,
      {
        search: _search,
        sortBy: _sortBy,
        sortDirection: _sortDirection,
      }: { search?: string; sortBy?: string; sortDirection?: SortDirection } = {},
    ) => {
      loadCollections(page, _search, _sortBy, _sortDirection);
    },
    [loadCollections],
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [collection, setCurrentPage]);

  useEffect(() => {
    if (prevSearchURLValue) return;
    loadCollectionsWithSearch(currentPage + 1, { search: debouncedSearch, sortBy, sortDirection });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, collection, currentPage, debouncedSearch, sortBy, sortDirection]);

  useEffect(() => {
    setCurrentPage(0);
  }, [collection, setCurrentPage]);

  const tableColumns = useTableColumnsCustomFields<Node>({
    section: collection,
    currentPage,
    onDelete: onDeleteCollection,
    editUrl: 'nodes',
    dataKeyName: 'data',
    urlTemplate: `/${section?.slug}/collections/${collectionSlug}/edit/:id`,
    searchTerm: search,
  });

  return !section || !collection ? (
    <LayoutWithNavbar />
  ) : (
    <LayoutWithNavbar
      breadcrumbs={[
        { label: section?.name || '', href: `/${sectionType}/${slug}` },
        {
          label: collection?.name || '',
          href: `/${sectionType}/${slug}/collections/${collectionSlug}`,
        },
      ]}
      endButtons={
        <ButtonCreate to={`/${sectionType}/${slug}/collections/${collectionSlug}/new`} currentPage={currentPage} />
      }
      onSearch={updateSearch}
      searchValue={search}
    >
      <Table
        data={collections.items}
        loading={isLoading(collections)}
        columns={tableColumns}
        onFetchData={loadCollections}
        pageCount={getTotalPages(collections)}
        paginated
        onSort={handleSortedChange}
        section={collection.name}
      />
    </LayoutWithNavbar>
  );
}
