import { useRef, useState } from 'react';
import { NodeBlock } from 'src/services/nodes';

export type PageBlocksHookProps = ReturnType<typeof usePageBlocks>;

export function usePageBlocks() {
  const [addingBlock, setAddingBlock] = useState(false);
  const [editBlock, setEditBlock] = useState<NodeBlock | null>(null);
  const containerRef = useRef<HTMLDivElement>(null!);

  return {
    addingBlock,
    setAddingBlock,
    editBlock,
    setEditBlock,
    containerRef,
  };
}
