import { combineReducers } from 'redux';

import { createApiReducer, chainReducers, INITIAL_API_STATE, createCrudApiReducer } from 'src/services/api';
import { createCrudApiReducerWithoutPaging } from 'src/services/api/redux/reducers';
import { AuthActionTypes } from 'src/services/auth/redux/actions';

import { transformUser, transformUserRole } from '../transformations';
import { User, UserRole } from '../types';
import { UserActionTypes } from './actions';

const signInReducer = createApiReducer(
  [AuthActionTypes.SIGN_IN_START, AuthActionTypes.SIGN_IN_SUCCESS, AuthActionTypes.SIGN_IN_FAILURE],
  transformUser,
  (data) => data,
);

const fetchCurrentUserReducer = createApiReducer(
  [
    UserActionTypes.CHANGE_CURRENT_USER_START,
    UserActionTypes.FETCH_CURRENT_USER_SUCCESS,
    UserActionTypes.CHANGE_CURRENT_USER_FAILURE,
  ],
  transformUser,
  (data) => data,
);

const updateCurrentUserReducer = createApiReducer(
  [
    UserActionTypes.CHANGE_CURRENT_USER_START,
    UserActionTypes.UPDATE_CURRENT_USER_SUCCESS,
    UserActionTypes.CHANGE_CURRENT_USER_FAILURE,
  ],
  transformUser,
  (data) => data,
);

const signOutReducer = createApiReducer(
  ['', AuthActionTypes.SIGN_OUT, ''],
  () => INITIAL_API_STATE,
  () => INITIAL_API_STATE,
);

const usersReducer = createCrudApiReducer<User>(
  UserActionTypes.CHANGE_USERS_START,
  UserActionTypes.CHANGE_USERS_FAILURE,
  UserActionTypes.FETCH_USERS_SUCCESS,
  UserActionTypes.ADD_ACCOUNT_USER_SUCCESS,
  UserActionTypes.UPDATE_ACCOUNT_USER_SUCCESS,
  UserActionTypes.DELETE_ACCOUNT_USER_SUCCESS,
  transformUser,
);

const membersReducer = createCrudApiReducer<User>(
  UserActionTypes.CHANGE_MEMBERS_START,
  UserActionTypes.CHANGE_MEMBERS_FAILURE,
  UserActionTypes.FETCH_MEMBERS_SUCCESS,
  UserActionTypes.ADD_MEMBER_SUCCESS,
  UserActionTypes.UPDATE_MEMBER_SUCCESS,
  UserActionTypes.DELETE_MEMBER_SUCCESS,
  transformUser,
);

const userRolesReducer = createCrudApiReducerWithoutPaging<UserRole>(
  UserActionTypes.CHANGE_USER_ROLES_START,
  UserActionTypes.CHANGE_USER_ROLES_FAILURE,
  UserActionTypes.FETCH_USER_ROLES_SUCCESS,
  UserActionTypes.ADD_USER_ROLE_SUCCESS,
  UserActionTypes.UPDATE_USER_ROLE_SUCCESS,
  UserActionTypes.DELETE_USER_ROLE_SUCCESS,
  transformUserRole,
);

const usersClearReducer = createApiReducer(
  ['', UserActionTypes.CLEAR_USERS_SUCCESS, ''],
  () => INITIAL_API_STATE,
  () => INITIAL_API_STATE,
);

export default combineReducers({
  currentUser: chainReducers(
    INITIAL_API_STATE,
    signInReducer,
    fetchCurrentUserReducer,
    updateCurrentUserReducer,
    signOutReducer,
  ),
  users: chainReducers(INITIAL_API_STATE, usersReducer, usersClearReducer),
  members: membersReducer,
  userRoles: userRolesReducer,
});
