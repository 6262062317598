import { Link, RouteComponentProps } from '@reach/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';

import {
  addParamsToLink,
  ButtonCreate,
  LayoutWithNavbar,
  Table,
  TableDropdown,
  useCurrentPage,
  usePrevSearchURLValue,
} from 'src/common';
import { isLoading } from 'src/services/api';
import { getTotalPages } from 'src/services/api/redux/traits';
import { useMembers, User } from 'src/services/users';
import Swal from 'sweetalert2';
import { useDebounce } from 'use-debounce';
import { showToast } from 'src/common/util/show-toast';

type MembersListProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function MembersList(_: MembersListProps) {
  const { members, loadMembers, deleteMember } = useMembers();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [currentPage] = useCurrentPage();
  const prevSearchURLValue = usePrevSearchURLValue();

  const onDeleteMember = useCallback(
    async (user: User) => {
      const confirm = await Swal.fire({
        title: 'Delete User',
        text: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
        showCancelButton: true,
        confirmButtonColor: '#e0245a',
        confirmButtonText: 'Delete',
        icon: 'question',
      });

      if (confirm.value) {
        const result = await deleteMember(user.id!);

        if (result.ok) {
          showToast('success', `${user.firstName} ${user.lastName} has been deleted successfully`);
        } else {
          showToast('error', 'Unable to delete user');
        }
      }
    },
    [deleteMember],
  );

  const fetchMembers = useCallback(
    (page = 1) => {
      return loadMembers(page, debouncedSearch);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch],
  );

  useEffect(() => {
    if (prevSearchURLValue) return;
    loadMembers(currentPage + 1, debouncedSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, currentPage]);

  const tableColumns: Column<User>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: ({ row: { original } }) => `${original.firstName} ${original.lastName}`,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => <div className="has-text-left">{value}</div>,
      },
      {
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => (
          <TableDropdown>
            <Link
              to={addParamsToLink(`/members/edit/${value}`, { currentPage: currentPage + 1, prevSearch: search })}
              className="dropdown-item"
            >
              View
            </Link>
            <a className="dropdown-item" onClick={() => onDeleteMember(original)}>
              Delete
            </a>
          </TableDropdown>
        ),
      },
    ],
    [currentPage, onDeleteMember, search],
  );

  return (
    <LayoutWithNavbar
      onSearch={setSearch}
      searchValue={search}
      breadcrumbs={[{ href: '/members', label: 'Members' }]}
      endButtons={<ButtonCreate to="/members/new" currentPage={currentPage} />}
    >
      <Table<User>
        data={members.items}
        loading={isLoading(members)}
        columns={tableColumns}
        onFetchData={fetchMembers}
        pageCount={getTotalPages(members)}
        paginated
      />
    </LayoutWithNavbar>
  );
}
