import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
import Swal from 'sweetalert2';
import { useDebounce } from 'use-debounce';
import { find } from 'lodash';

import { useNodes, Node, fetchNodeDetails } from 'src/services/nodes';
import {
  useCurrentPage,
  LayoutWithNavbar,
  useTableColumnsCustomFields,
  getBreadcrumbs,
  usePrevSearchURLValue,
} from 'src/common';
import { isLoading } from 'src/services/api';
import { getTotalPages } from 'src/services/api/redux/traits';
import { useSections } from 'src/services/sections';
import { ButtonCreate, Table } from 'src/common/components';
import { SortingRule } from 'react-table';
import { SortDirection } from 'src/services/api/types';
import { showToast } from 'src/common/util/show-toast';
import { duplicateNode } from 'src/services/nodes/transformations';

type NodesListProps = RouteComponentProps<{ slug: string }>;

export function NodesList({ slug, uri }: NodesListProps) {
  const { sections } = useSections();
  const section = useMemo(() => find(sections.items, { slug })!, [sections.items, slug]);
  const categorySection = useMemo(
    () => find(sections.items, { id: section?.categorySectionId }),
    [section?.categorySectionId, sections.items],
  );
  const isCategory = useMemo(() => !!uri!.match(/categories/) && !!categorySection, [categorySection, uri]);

  const { nodes, deleteNode, loadNodes, createNode } = useNodes(isCategory ? categorySection!.slug : section!.slug);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<string | undefined>('id');
  const [sortDirection, setSortDirection] = useState(SortDirection.Descending);
  const [debouncedSearch] = useDebounce(search, 500);
  const [currentPage, setCurrentPage] = useCurrentPage();
  const prevSearchURLValue = usePrevSearchURLValue();

  const updateSearch = useCallback(
    (text: string) => {
      if (!prevSearchURLValue) setCurrentPage(0);
      setSearch(text);
    },
    [setCurrentPage, prevSearchURLValue],
  );

  const handleSortedChange = useCallback((params?: SortingRule<Node>) => {
    if (!params) {
      return;
    }

    let paramSortBy = params?.id || 'id';
    if (paramSortBy === 'createdAt') {
      paramSortBy = 'created_at';
    } else if (paramSortBy === 'publishable') {
      paramSortBy = 'published_from';
    }

    setSortBy(paramSortBy);
    setSortDirection(params?.desc ? SortDirection.Descending : SortDirection.Ascending);
  }, []);

  const onDeleteNode = useCallback(
    async (node: Node) => {
      const confirm = await Swal.fire({
        title: 'Delete',
        text: `Are you sure you want to delete this entry?`,
        showCancelButton: true,
        confirmButtonColor: '#e0245a',
        confirmButtonText: 'Delete',
        icon: 'question',
      });

      if (confirm.value) {
        const result = await deleteNode(node.id!);

        if (result.ok) {
          showToast('success', 'Entry has been deleted successfully');
        } else {
          showToast('error', 'Unable to delete');
        }
      }
    },
    [deleteNode],
  );

  const loadNodesWithSearch = useCallback(
    (
      page?: number,
      {
        search: _search,
        sortBy: _sortBy,
        sortDirection: _sortDirection,
      }: {
        search?: string;
        sortBy?: string;
        sortDirection?: SortDirection;
      } = {},
    ) => {
      loadNodes(page, section.id!, _search, _sortBy, _sortDirection);
    },
    [loadNodes, section],
  );

  const onDuplicate = useCallback(
    async (nodeId: number) => {
      const sectionSlug = isCategory ? categorySection!.slug : section!.slug;
      const node = await fetchNodeDetails(sectionSlug, nodeId);
      const newNode = duplicateNode(node);
      await createNode(newNode);
      showToast('success', 'Sucessfully duplicated');
      await loadNodesWithSearch();
    },
    [categorySection, createNode, isCategory, loadNodesWithSearch, section],
  );

  const extraActions = useCallback(
    ({ id }: Node) => (
      <a onClick={() => onDuplicate(id!)} className="dropdown-item">
        Duplicate
      </a>
    ),
    [onDuplicate],
  );

  const tableColumns = useTableColumnsCustomFields<Node>({
    section: isCategory ? categorySection : section,
    currentPage,
    onDelete: onDeleteNode,
    editUrl: 'nodes',
    dataKeyName: 'data',
    urlTemplate: isCategory ? `/${section.slug}/categories/edit/:id` : undefined,
    searchTerm: search,
    extraActions,
  });

  useEffect(() => {
    if (prevSearchURLValue) return;
    loadNodesWithSearch(currentPage + 1, { sortBy, sortDirection, search: debouncedSearch });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, section, currentPage, loadNodesWithSearch, sortBy, sortDirection]);

  if (!section) {
    return <Redirect to="/" />;
  }

  return section ? (
    <LayoutWithNavbar
      breadcrumbs={getBreadcrumbs(section, isCategory ? categorySection : undefined, uri!)}
      onSearch={updateSearch}
      endButtons={
        <ButtonCreate
          to={isCategory ? `/nodes/${slug}/categories/new` : `/nodes/${slug}/new`}
          currentPage={currentPage}
        />
      }
      searchValue={search}
      data-qa="node-list-page"
    >
      <Table<Node>
        data={nodes.items}
        loading={isLoading(nodes)}
        columns={tableColumns}
        pageCount={getTotalPages(nodes)}
        paginated
        onSort={handleSortedChange}
      />
    </LayoutWithNavbar>
  ) : (
    <LayoutWithNavbar />
  );
}
