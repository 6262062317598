import { createApiAction } from 'src/services/api';

import urlcat from 'urlcat';

export enum TransactionActionTypes {
  CHANGE_TRANSACTIONS_START = 'products/change_transactions_start',
  CHANGE_TRANSACTIONS_FAILURE = 'products/change_transactions_failure',
  FETCH_TRANSACTIONS_SUCCESS = 'products/fetch_transactions_success',
}

export const fetchTransactions = (pageNumber?: number, pageSize?: number, params: Record<string, any> = {}) =>
  createApiAction(
    {
      path: urlcat('/product-transaction', '/', {
        pageNumber,
        pageSize,
        ...params,
      }),
      method: 'GET',
    },
    [
      TransactionActionTypes.CHANGE_TRANSACTIONS_START,
      TransactionActionTypes.FETCH_TRANSACTIONS_SUCCESS,
      TransactionActionTypes.CHANGE_TRANSACTIONS_FAILURE,
    ],
  );
