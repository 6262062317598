import clsx from 'clsx';
import React from 'react';

type TreeViewChildProps = {
  spacing?: number;
} & JSX.IntrinsicElements['span'];

export function TreeViewChild({ className, spacing = 1, ...props }: TreeViewChildProps) {
  return (
    <span
      className={clsx('tree-view-child', className)}
      {...props}
      style={{ marginLeft: `${0.7 * (spacing * 2 - 1)}rem` }}
    />
  );
}
