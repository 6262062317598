import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { capitalize, find } from 'lodash';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useLanguages } from 'src/services/languages';
import { SectionFieldOption } from 'src/services/sections';

import { isTouched } from '../util';
import { MultiChecks } from './form/MultiChecks';
import { SelectHook } from './form/SelectHook';
import { Modal } from './Modal';

type FormValues = { sourceLanguage: string; targetLanguages: string[] };

type TranslationModalProps = {
  visible?: boolean;
  onSubmit: (sourceLanguage: string, targetLanguages: string[]) => Promise<void>;
  onClose: () => void;
  availableLanguages: string[];
};

const validationSchema = yup.object<FormValues>({
  sourceLanguage: yup.string().label('Source Language').required(),
  targetLanguages: yup.array<string>(yup.string()).label('Target Languages').required(),
});

export function TranslationModal({ visible = false, onSubmit, onClose, availableLanguages }: TranslationModalProps) {
  const {
    languages: { items: languages },
  } = useLanguages();
  const { handleSubmit, register, watch, formState, reset } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const watchSourceLanguage = watch('sourceLanguage');

  const translate = handleSubmit(async ({ sourceLanguage, targetLanguages }) => {
    await onSubmit(sourceLanguage, targetLanguages);
    reset();
    onClose();
  });

  const sourceLanguagesOptions: SectionFieldOption[] = useMemo(
    () =>
      availableLanguages.map((lang) => ({
        value: lang,
        label: capitalize(find(languages, { id: lang })?.name),
      })),
    [availableLanguages, languages],
  );
  const targetLanguagesOptions: SectionFieldOption[] = useMemo(
    () =>
      availableLanguages
        .filter((lang) => lang !== watchSourceLanguage)
        .map((lang) => ({
          value: lang,
          label: capitalize(find(languages, { id: lang })?.name),
        })),
    [availableLanguages, languages, watchSourceLanguage],
  );

  return (
    <Modal visible={visible} onClickBackground={onClose}>
      <form onSubmit={translate}>
        <div className="modal-card-head">
          <p className="modal-card-title">Translations</p>
          <button type="button" className="delete" onClick={onClose} />
        </div>
        <div className="modal-card-body">
          <SelectHook
            ref={register}
            name="sourceLanguage"
            id="sourceLanguage"
            options={sourceLanguagesOptions}
            label="Source Language"
            touched={isTouched(formState, 'sourceLanguage')}
            error={formState.errors.sourceLanguage?.message}
          />
          <MultiChecks
            label="Target Languages"
            register={register}
            name="targetLanguages"
            id="targetLanguages"
            options={targetLanguagesOptions}
            error={formState.errors.targetLanguages?.[0]?.message}
            touched={isTouched(formState, 'targetLanguages')}
          />
        </div>
        <div className="modal-card-foot">
          <button type="reset" className="button" onClick={onClose}>
            Cancel
          </button>
          <button
            type="submit"
            className={clsx('button is-primary', {
              'is-loading': formState.isSubmitting,
            })}
            disabled={formState.isSubmitting || !formState.isValid}
          >
            Translate
          </button>
        </div>
      </form>
    </Modal>
  );
}
