import { useLocation } from '@reach/router';
import clsx from 'clsx';
import React, { Fragment, useMemo, useState } from 'react';
import { ArrayField, Control, Controller } from 'react-hook-form';
import { RICH_EDITOR_DEFAULT_TAB } from 'src/common/const';
import { FormNode } from 'src/modules/nodes/types';
import { NodeContent } from 'src/services/nodes';
import { TabDefinition } from '../../Tabs';
import Editor from './Editor';

export type LocalizableTabDefinition = TabDefinition & {
  localized?: boolean;
  languageId?: string;
};

type RichTextEditorTabsProps = {
  tabs?: LocalizableTabDefinition[];
  currentLanguage: string | null;
  fields: Partial<ArrayField<NodeContent, 'id'>>[];
  control: Control<FormNode>;
};

export function RichTextEditorTabs({ tabs = [], currentLanguage, fields, control }: RichTextEditorTabsProps) {
  const location = useLocation();

  const defaultTab = useMemo(() => {
    return new URLSearchParams(location.search).get(RICH_EDITOR_DEFAULT_TAB) || undefined;
  }, [location.search]);

  const [activeTab, setActiveTab] = useState<string | number | undefined>(() =>
    tabs.find((t) => t.id === defaultTab) ? defaultTab : 'content',
  );
  const localizedActiveTab = `${activeTab}-${currentLanguage}`;

  return (
    <div className="is-fullheight">
      <div className="tabs">
        <ul>
          {fields.map((content) => {
            const contentTabId = `content-${content.languageId}`;
            const htmlTabId = `html-${content.languageId}`;
            const isCurrentLanguage = currentLanguage === content.languageId;
            const isCurrentContentTab = localizedActiveTab === contentTabId;
            const isCurrentHtmlTab = localizedActiveTab === htmlTabId;
            return (
              <Fragment key={`contentAndHtml-${content.languageId}`}>
                <li
                  className={clsx({ 'is-active': isCurrentContentTab, 'is-hidden': !isCurrentLanguage })}
                  onClick={() => setActiveTab('content')}
                >
                  <a>Content</a>
                </li>
                <li
                  className={clsx({ 'is-active': isCurrentHtmlTab, 'is-hidden': !isCurrentLanguage })}
                  onClick={() => setActiveTab('html')}
                >
                  <a>HTML</a>
                </li>
              </Fragment>
            );
          })}
          {tabs.map((tab) => {
            const tabId = tab.localized ? `${tab.id}-${tab.languageId}` : tab.id;
            const isCurrentLanguage = tab.localized ? currentLanguage === tab.languageId : true;
            const isCurrentTab = (tab.localized ? localizedActiveTab : activeTab) === tabId;
            return (
              <li
                className={clsx({ 'is-active': isCurrentTab, 'is-hidden': !isCurrentLanguage })}
                onClick={() => setActiveTab(tab.id)}
                key={tabId}
              >
                <a>{tab.label}</a>
              </li>
            );
          })}
        </ul>
      </div>
      {fields.map((content, index) => {
        const contentTabId = `content-${content.languageId}`;
        const htmlTabId = `html-${content.languageId}`;
        const isCurrentContentTab = localizedActiveTab === contentTabId;
        const isCurrentHtmlTab = localizedActiveTab === htmlTabId;
        return (
          <Controller
            control={control}
            name={`contents[${index}].text`}
            defaultValue={content.text || ''}
            key={`contentAndHtml-${content.languageId}`}
            render={({ ref, ...props }) => (
              <Editor
                {...props}
                id={`contents-text-${index}`}
                languageId={content.languageId}
                isContentVisible={isCurrentContentTab}
                isHtmlVisible={isCurrentHtmlTab}
              />
            )}
          />
        );
      })}
      {tabs.map((tab) => {
        const tabId = tab.localized ? `${tab.id}-${tab.languageId}` : tab.id;
        const isCurrentTab = (tab.localized ? `${activeTab}-${currentLanguage}` : activeTab) === tabId;
        return (
          <div className={clsx({ 'is-hidden': !isCurrentTab }, tab.className)} key={tabId}>
            {tab.content}
          </div>
        );
      })}
    </div>
  );
}
