import { get, kebabCase } from 'lodash';
import { useEffect } from 'react';
import { Control, FormState, useWatch } from 'react-hook-form';

type UseLocalizedAutoSlugOptions = {
  control: Control<any>;
  baseField?: string;
  targetField?: string;
  prefix?: string;
  arrayField?: string;
  formState: FormState<any>;
};

export function useLocalizedAutoSlug({
  control,
  baseField = 'data.name',
  targetField = 'slug',
  prefix = '',
  arrayField = 'contents',
  formState,
}: UseLocalizedAutoSlugOptions) {
  const arrayFieldValue = useWatch({ defaultValue: [], control, name: arrayField });

  useEffect(() => {
    arrayFieldValue.forEach((langContent, index) => {
      const dirtyBase = get(formState.dirtyFields, `${arrayField}[${index}].${baseField}`);
      const dirtyTarget = get(formState.dirtyFields, `${arrayField}[${index}].${targetField}`);
      const targetValue = get(langContent, targetField);
      const generatedValue = `${prefix}${kebabCase(get(langContent, baseField))}`;

      if (!dirtyTarget && dirtyBase && targetValue !== generatedValue) {
        control.setValue(`${arrayField}[${index}].${targetField}`, generatedValue);
      }
    });
  }, [arrayFieldValue, formState, control, targetField, arrayField, prefix, baseField]);
}
