import { PagesEdit, PagesList } from './modules/pages';
import { NodesEdit, NodesList } from './modules/nodes';
import { LayoutRoute } from './types';
import { SectionsList, SectionsEdit, IntegrationsList, ProjectEdit } from './modules/settings';
import { MembersEdit, MembersList } from './modules/members';
import { ConnectionsEdit, ConnectionsList } from './modules/connections';
import { CollectionsList } from './modules/collections';
import { ProductsTransactions } from './modules/products';
import { KeyValueList, KeyValueStoresList } from './modules/key-value';
import { UserInvite, UserInviteList, UserList } from './modules/users';

export const ROUTES: LayoutRoute[] = [
  // Pages
  {
    path: 'pages',
    component: PagesList,
  },
  {
    path: 'pages/new',
    component: PagesEdit,
  },
  {
    path: 'pages/edit/:id',
    component: PagesEdit,
  },

  // Nodes
  {
    path: 'nodes/:slug',
    component: NodesList,
  },
  {
    path: 'nodes/:slug/new',
    component: NodesEdit,
  },
  {
    path: 'nodes/:slug/edit/:id',
    component: NodesEdit,
  },
  {
    path: 'nodes/:slug/transactions',
    component: ProductsTransactions,
  },

  // Categories
  {
    path: 'nodes/:slug/categories',
    component: NodesList,
  },
  {
    path: 'nodes/:slug/categories/new',
    component: NodesEdit,
  },
  {
    path: 'nodes/:slug/categories/edit/:id',
    component: NodesEdit,
  },

  // Members
  {
    path: 'members',
    component: MembersList,
  },
  {
    path: 'members/edit/:id',
    component: MembersEdit,
  },
  {
    path: 'members/new',
    component: MembersEdit,
  },

  // Connections
  {
    path: ':sectionType/:slug/connections/:connectionSlug',
    component: ConnectionsList,
  },
  {
    path: ':sectionType/:slug/connections/:connectionSlug/edit/:id',
    component: ConnectionsEdit,
  },
  {
    path: ':sectionType/:slug/connections/:connectionSlug/new',
    component: ConnectionsEdit,
  },

  // Collections
  {
    path: ':sectionType/:slug/collections/:collectionSlug',
    component: CollectionsList,
  },
  {
    path: ':sectionType/:slug/collections/:collectionSlug/edit/:id',
    component: NodesEdit,
  },
  {
    path: ':sectionType/:slug/collections/:collectionSlug/new',
    component: NodesEdit,
  },

  // Subsections
  {
    path: ':sectionType/:mainSectionSlug/subsections/:slug',
    component: NodesList,
  },
  {
    path: ':sectionType/:mainSectionSlug/subsections/:slug/new',
    component: NodesEdit,
  },
  {
    path: ':sectionType/:mainSectionSlug/subsections/:slug/edit/:id',
    component: NodesEdit,
  },

  // KeyValue Stores
  {
    path: 'key-values/:slug',
    component: KeyValueList,
  },
];

export const USER_ROUTES: LayoutRoute[] = [
  {
    icon: 'fal fa-list-ul',
    label: 'Sections',
    path: 'sections',
    component: SectionsList,
  },
  {
    path: 'sections/new',
    component: SectionsEdit,
  },
  {
    path: 'sections/edit/:id',
    component: SectionsEdit,
  },
  {
    icon: 'fal fa-line-columns',
    label: 'Key/Value Stores',
    path: 'key-values-stores',
    component: KeyValueStoresList,
    visible: (project) => project.hasKeyValues,
  },
  {
    path: 'settings',
    component: ProjectEdit,
  },
  {
    path: 'settings/integrations',
    component: IntegrationsList,
  },
  {
    path: 'users',
    component: UserList,
  },
  {
    path: 'users/new',
    component: UserInvite,
  },
  {
    path: 'users/invites',
    component: UserInviteList,
  },
  // {
  //   path: 'account/new',
  //   component: AccountEdit,
  // },
  // {
  //   path: 'account/edit/:id',
  //   component: AccountEdit,
  // },
  // {
  //   icon: 'fal fa-user-edit',
  //   path: '/profile',
  //   component: UserProfile,
  // },
];
