import { Link } from '@reach/router';
import React, { ReactNode } from 'react';
import { addParamsToLink } from '../util';

type ButtonCreateProps = {
  to?: string;
  icon?: string;
  children?: ReactNode;
  color?: string;
  currentPage?: number;
  isLinkVisible?: boolean;
};

export function ButtonCreate({
  to = 'new',
  icon = 'plus',
  children = 'Create',
  color = 'primary',
  currentPage,
  isLinkVisible = true,
}: ButtonCreateProps) {
  const link = typeof currentPage === 'undefined' ? to : addParamsToLink(to, { currentPage: currentPage + 1 });

  return (
    <div className="navbar-item">
      {isLinkVisible && (
        <Link to={link} className={`button is-${color}`} data-qa="create-button">
          <span className="icon">
            <i className={`fal fa-${icon}`} />
          </span>
          <span>{children}</span>
        </Link>
      )}
    </div>
  );
}
