import { Link } from '@reach/router';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Controller, useFieldArray, UseFormMethods } from 'react-hook-form';
import { isTouched } from 'src/common/util';
import { CollectionItemWithRSelect } from 'src/modules/nodes/types';
import { useSectionItems } from 'src/services/sections';
import { AsyncSelect } from './AsyncSelect';
import { InputHook } from './InputHook';

type CollectionsDraggableListProps = UseFormMethods<Record<string, any>> & {
  name?: string;
  createItemPath?: string;
  optionsSectionId: number;
};

export function CollectionsDraggableList({
  control,
  register,
  optionsSectionId,
  formState,
  createItemPath,
  name = 'collectionItems',
}: CollectionsDraggableListProps) {
  const loadOptions = useSectionItems();
  const { fields, append, remove, move } = useFieldArray<CollectionItemWithRSelect>({
    control,
    name,
  });
  const onDragEnd = useCallback(
    (result: DropResult) => {
      move(result.source.index, result.destination!.index!);
    },
    [move],
  );

  return (
    <div className="container is-fluid">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={name}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((item, collectionItemIndex) => (
                <Draggable
                  key={item.id}
                  draggableId={(item.id! as string | number).toString()}
                  index={collectionItemIndex}
                >
                  {(draggableProvided) => (
                    <div
                      className={clsx('columns is-vcentered has-background-white', {
                        'mb-3': collectionItemIndex === fields.length - 1,
                      })}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <input
                        type="hidden"
                        ref={register()}
                        name={`${name}[${collectionItemIndex}].id`}
                        value={item.id}
                      />
                      <div className="column is-narrow">
                        <span className="icon has-text-grey">
                          <i
                            className={clsx('fal', {
                              'fa-arrows-v': collectionItemIndex !== 0 && collectionItemIndex !== fields.length - 1,
                              'fa-long-arrow-up': collectionItemIndex === fields.length - 1,
                              'fa-long-arrow-down': collectionItemIndex === 0,
                            })}
                          />
                        </span>
                      </div>
                      <div className="column is-one-fifth">
                        <InputHook
                          label="Position"
                          id={`${name}-${collectionItemIndex}-position`}
                          value={collectionItemIndex + 1}
                          readOnly
                        />
                      </div>
                      <div className="column">
                        <Controller
                          control={control}
                          name={`${name}[${collectionItemIndex}].item`}
                          defaultValue={item.item}
                          render={({ ref: ciRef, ...ciProps }) => (
                            <AsyncSelect
                              loadOptions={loadOptions(optionsSectionId)}
                              {...ciProps}
                              label="Item"
                              id={`${name}-${collectionItemIndex}-item`}
                              touched={isTouched(formState, `${name}[${collectionItemIndex}].item`)}
                            />
                          )}
                        />
                      </div>
                      <div className="column is-narrow">
                        <button
                          type="button"
                          className="button is-white has-text-grey"
                          onClick={() => remove(collectionItemIndex)}
                        >
                          <span className="icon">
                            <i className="fal fa-trash" />
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <div className="buttons">
          <button type="button" className="button is-outlined" onClick={() => append({})}>
            <span className="icon has-text-primary">
              <i className="fal fa-plus" />
            </span>
            <span>Add Item</span>
          </button>
          {createItemPath ? (
            <Link to={createItemPath} className="button is-outlined">
              <span className="icon has-text-primary">
                <i className="fal fa-external-link" />
              </span>
              <span>Create new Item</span>
            </Link>
          ) : null}
        </div>
      </DragDropContext>
    </div>
  );
}
