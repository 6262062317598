import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { useDebounce } from 'use-debounce';
import { navigate, RouteComponentProps } from '@reach/router';
import { isLoading } from '../../services/api';
import { getTotalPages } from '../../services/api/redux/traits';
import { LayoutWithNavbar, Table, TableDropdown, useCurrentPage, usePrevSearchURLValue } from '../../common';
import { useCurrentUser, User, useUsers } from '../../services/users';
import { useAuth } from '../../services/auth';
import { showToast } from '../../common/util/show-toast';
import { useOrganizations } from '../../services/organizations';
import { SUPERADMIN_USER_ID } from './const';

type DashboardProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SuperadminUsers = (_: DashboardProps) => {
  const { logInAs, refreshUser } = useAuth();
  const { id } = useCurrentUser();
  const { loadOrganizations } = useOrganizations();
  const [currentPage] = useCurrentPage();
  const { users, removeUsers, loadAllUsers } = useUsers();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const prevSearchURLValue = usePrevSearchURLValue();

  useEffect(() => {
    if (Number(id) !== SUPERADMIN_USER_ID) {
      navigate('/dashboard');
      return;
    }

    if (prevSearchURLValue) return;
    loadAllUsers(currentPage + 1, debouncedSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, currentPage]);

  const fetchUsers = useCallback(
    (page = 1) => {
      return loadAllUsers(page, debouncedSearch);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch],
  );

  const handleImpersonate = async (userId: any) => {
    const res = await logInAs(userId);

    // @ts-ignore
    if (res.ok) {
      await refreshUser();
      await loadOrganizations();
      await removeUsers();
      navigate('/');
    } else {
      showToast('error', 'Unable to impersonate');
    }
  };

  const tableColumns: Column<User>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: ({ row: { original } }) => (
          <div className="has-text-left">{`${original.firstName} ${original.lastName}`}</div>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => <div className="has-text-left">{value}</div>,
      },
      {
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <TableDropdown>
            <a className="dropdown-item" onClick={() => handleImpersonate(original.id)}>
              Impersonate
            </a>
          </TableDropdown>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, search],
  );

  return (
    <LayoutWithNavbar breadcrumbs={[{ label: 'SiteSails', href: '/' }]} onSearch={setSearch} searchValue={search}>
      <Table
        data={users.items}
        loading={isLoading(users)}
        columns={tableColumns}
        onFetchData={fetchUsers}
        pageCount={getTotalPages(users)}
        paginated
      />
    </LayoutWithNavbar>
  );
};
