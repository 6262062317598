import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Section, SectionFieldOption, SectionType } from 'src/services/sections';
import { fetchNodes } from 'src/services/nodes/api';
import { addNodeToCollection } from 'src/services/collections/api';

import { isTouched } from '../util';
import { SelectHook } from './form/SelectHook';
import { Modal } from './Modal';
import { showToast } from '../util/show-toast';

type FormValues = { sectionSlug: string; collectionNodeSlug: string };

type CollectionAddProps = {
  visible?: boolean;
  nodeId?: number;
  nodeSection: Section;
  sections: Section[];
  onClose: () => void;
};

const validationSchema = yup.object<FormValues>({
  sectionSlug: yup.string().label('Collection Section').required(),
  collectionNodeSlug: yup.string().label('Collection').required(),
});

export function CollectionAddModal({ visible = false, onClose, nodeSection, nodeId, sections }: CollectionAddProps) {
  const { handleSubmit, register, watch, formState, reset } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const [collectionOptions, setCollectionOptions] = React.useState<SectionFieldOption[]>([]);

  const watchSectionSlug = watch('sectionSlug');

  const sectionOptions = useMemo(
    () =>
      sections
        .filter((s) => s.type === SectionType.Collections && s.parentSectionId === nodeSection.id)
        .map((s) => ({
          label: s.name,
          value: s.slug,
        })),
    [sections, nodeSection],
  );

  useEffect(() => {
    const fetchData = async () => {
      setCollectionOptions([]);

      if (!watchSectionSlug) {
        return;
      }
      const nodes = await fetchNodes(watchSectionSlug.toString());
      const co = nodes.map((n) => ({ label: String(n.data.title), value: n.slug }));
      setCollectionOptions(co);
    };

    fetchData();
  }, [watchSectionSlug]);

  const save = handleSubmit(async ({ sectionSlug, collectionNodeSlug }) => {
    const result = await addNodeToCollection(nodeSection.slug, sectionSlug, collectionNodeSlug, nodeId!);

    if (result.ok) {
      showToast('success', `Added to collection successfully`);
      reset();
      onClose();
    } else {
      showToast('error', 'Unable to add to collection');
    }
  });

  return (
    <Modal visible={visible} onClickBackground={onClose}>
      <form onSubmit={save}>
        <div className="modal-card-head">
          <p className="modal-card-title">Add to collection</p>
          <button type="button" className="delete" onClick={onClose} />
        </div>
        <div className="modal-card-body">
          <SelectHook
            ref={register}
            name="sectionSlug"
            id="sectionSlug"
            options={sectionOptions}
            label="Collection section"
            touched={isTouched(formState, 'sectionSlug')}
            error={formState.errors.sectionSlug?.message}
          />
          <SelectHook
            label="Collection"
            ref={register}
            name="collectionNodeSlug"
            id="collectionNodeSlug"
            options={collectionOptions}
            error={formState.errors.collectionNodeSlug?.message}
            touched={isTouched(formState, 'collectionNodeSlug')}
          />
        </div>
        <div className="modal-card-foot">
          <button type="reset" className="button" onClick={onClose}>
            Cancel
          </button>
          <button
            type="submit"
            className={clsx('button is-primary', {
              'is-loading': formState.isSubmitting,
            })}
            disabled={!formState.isValid}
          >
            Add
          </button>
        </div>
      </form>
    </Modal>
  );
}
