import { find } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Column } from 'react-table';
import { Link } from '@reach/router';

import { LayoutWithNavbar, useCurrentPage, currencyFormat, formatDate, Table } from 'src/common';
import { isLoading } from 'src/services/api';
import { getTotalPages } from 'src/services/api/redux/traits';
import { useTransactions } from 'src/services/products';
import { ProductTransaction } from 'src/services/products/types';
import { useSections } from 'src/services/sections';
import { RouteComponentListProps } from 'src/types';

type ProductsTransactionsProps = RouteComponentListProps;

export function ProductsTransactionsList({ slug }: ProductsTransactionsProps) {
  const { sections } = useSections();
  const { transactions, loadTransactions } = useTransactions();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [currentPage] = useCurrentPage();

  const section = find(sections.items, { slug });
  const loading = isLoading(sections);

  const loadTransactionsWithSearch = useCallback(
    (page?: number) => {
      return loadTransactions(page, section!.id!, debouncedSearch);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [section, debouncedSearch],
  );

  const tableColumns = useMemo<Column<ProductTransaction>[]>(
    () => [
      {
        Header: 'Date',
        accessor: 'purchaseDate',
        Cell: ({ value }) => <div style={{ minWidth: '100px' }}>{formatDate(value)}</div>,
      },
      {
        Header: 'Product',
        accessor: 'productName',
        Cell: ({ row: { original }, value }) => <Link to={`products/${slug}/edit/${original.productId}`}>{value}</Link>,
      },
      {
        Header: 'Customer',
        accessor: (row) => `${row.customerFirstName} ${row.customerLastName} (${row.customerEmail})`,
        id: 'customerName',
      },
      {
        Header: <div className="has-text-right">Price</div>,
        accessor: 'price',
        Cell: ({ value, row: { original } }) => (
          <div className="has-text-right">{currencyFormat(value, (original as any).currency || 'USD')}</div>
        ),
      },
      {
        Header: <div className="has-text-right">Quantity</div>,
        accessor: 'quantity',
        Cell: ({ value }) => <div className="has-text-right">{value}</div>,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value, row: { original } }) => (
          <div className="has-text-right">{currencyFormat(value, (original as any).currency || 'USD')}</div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage],
  );

  useEffect(() => {
    if (section) {
      loadTransactions(currentPage + 1, section.id!, debouncedSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, debouncedSearch, currentPage]);

  if (loading || !section) {
    return <LayoutWithNavbar />;
  }

  return (
    <LayoutWithNavbar
      breadcrumbs={[
        { href: `nodes/${slug}`, label: section.name },
        { href: `nodes/${slug}/transactions`, label: 'Transactions' },
      ]}
      onSearch={setSearch}
      searchValue={search}
    >
      <Table
        data={transactions.items}
        loading={isLoading(transactions)}
        columns={tableColumns}
        onFetchData={loadTransactionsWithSearch}
        pageCount={getTotalPages(transactions)}
        paginated
      />
    </LayoutWithNavbar>
  );
}
