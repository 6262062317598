import { performApiCall } from 'src/services/api';
import { transformProject } from './transformations';
import { Project } from './types';

export const fetchProjectDetails = async (organizationId: number, projectId: number): Promise<Project> => {
  const res = await performApiCall({
    path: `/projects/${projectId}`,
  });
  return Promise.resolve(transformProject(res.data));
};
