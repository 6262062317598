import _ from 'lodash';

import { FormUser, User, UserApiRequest, UserInvitation, UserRole } from './types';

export const transformUserForApi = (user: FormUser): UserApiRequest => ({
  id: user.id,
  userRoleId: user.roleId,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  password: user.password,
});

export const transformUserRole = (response: any): UserRole => {
  return response;
};

export const transformInvitation = (response: any): UserInvitation => {
  return response;
};

export const transformUser = (response: any): User => {
  const userResponse = _.get(response, 'user', response);
  const invitations = _.get(response, 'invitations', []);

  return {
    id: userResponse.id,
    email: userResponse.email,
    firstName: userResponse.firstName,
    lastName: userResponse.lastName,
    phoneNumber: userResponse.phoneNumber,
    languageId: userResponse.languageId,
    userRole: transformUserRole(userResponse.userRole),
    roleId: userResponse.roleId || userResponse.userRole?.id,
    roleName: userResponse.roleName || userResponse.userRole?.name,
    data: userResponse.data || userResponse.data,
    avatarUrl: userResponse.avatarUrl,
    invitations: invitations.map(transformInvitation),
    organizationRole: userResponse.organizationRole,
    projectRole: userResponse.projectRole,
  };
};
