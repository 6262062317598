import { Link } from '@reach/router';
import clsx from 'clsx';
import { capitalize, find } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { addParamsToLink, TableDropdown, TreeViewChild, useNavbarSearch } from 'src/common';
import { showToast } from 'src/common/util/show-toast';
import { PageForTable, usePages } from 'src/services/pages';
import { useProject } from 'src/services/project';
import Swal from 'sweetalert2';
import { useLoadPagesWithSearch } from './use-load-pages-with-search';

const slugRegex = /\/([a-zA-Z0-9-]+)?/g;

export function usePagesList() {
  const { search, debouncedSearch, setSearch } = useNavbarSearch();
  const { pages, loadPages, deletePage } = usePages();
  const prevSearch = debouncedSearch;

  const { project } = useProject();

  const onDeletePage = useCallback(
    async (id: number) => {
      const page = find(pages.items, { id })!;
      const confirm = await Swal.fire({
        title: 'Delete Page',
        text: `Are you sure you want to delete ${page.data.title}?`,
        showCancelButton: true,
        confirmButtonColor: '#e0245a',
        confirmButtonText: 'Delete',
        icon: 'question',
      });

      if (confirm.value) {
        const result = await deletePage(id);

        if (result.ok) {
          showToast('success', 'Page deleted successfully');
        } else {
          showToast('error', 'Unable to delete page');
        }
      }
    },
    [pages.items, deletePage],
  );

  const tableColumns = useMemo<Column<PageForTable>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'title',
        disableSortBy: true,
        Cell: ({ value, row: { original } }) =>
          original.placeholder ? (
            <>
              {original.hasParent ? <TreeViewChild spacing={original.nestingLevel} /> : null}
              <span className="icon has-text-primary mr-1">
                <i className="fas fa-file" />
              </span>
              {value}
            </>
          ) : (
            <Link to={addParamsToLink(`/pages/edit/${original.id}`, { prevSearch })}>
              {original.hasParent ? <TreeViewChild spacing={original.nestingLevel} /> : null}
              <span className="icon has-text-primary mr-1">
                <i className="fal fa-file" />
              </span>
              {value}
            </Link>
          ),
      },
      {
        Header: 'Slug',
        accessor: 'slug',
        disableSortBy: true,
      },
      {
        Header: 'Published',
        accessor: 'isPublished',
        disableSortBy: true,
        align: 'center',
        Cell: ({ value }) => (
          <div className="has-text-centered">
            <span className={clsx('icon', value ? 'has-text-primary' : 'has-text-grey-light')}>
              <i className="fal fa-check" />
            </span>
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ value, row: { original } }) => (
          <TableDropdown data-qa={`table-dropdown-${original.slug}`}>
            {original.placeholder ? (
              <Link
                className="dropdown-item"
                to={addParamsToLink(`/pages/new?parent=${original.slug}&title=${original.title}`, { prevSearch })}
              >
                Create {original.title} page
              </Link>
            ) : (
              <Link className="dropdown-item" to={addParamsToLink(`/pages/edit/${value}`, { prevSearch })}>
                Edit
              </Link>
            )}
            {!project.themeId && (
              <Link
                className="dropdown-item"
                to={addParamsToLink(`/pages/new?parent=${original.slug}/`, { prevSearch })}
              >
                Add sub page
              </Link>
            )}
            {!original.placeholder && !project.themeId ? (
              <a className="dropdown-item" onClick={() => onDeletePage(value as number)} data-qa="page-delete">
                Delete page
              </a>
            ) : null}
          </TableDropdown>
        ),
      },
    ],
    [onDeletePage, prevSearch, project.themeId],
  );

  const pagesWithChildren = useMemo(() => {
    // this type allows subRows (used in the table) to be an object with partial slugs as keys to other pages
    type RecordPageForTable = Omit<PageForTable, 'subRows'> & { subRows: Record<string, RecordPageForTable> };
    const group = pages.items.reduce((grouped, page) => {
      const matches = page.slug?.match(slugRegex) || [];
      const lastMatch = matches[matches.length - 1];
      const pageForTable: RecordPageForTable = {
        id: page.id!,
        hasParent: matches.length > 1,
        isPublished: page.publishable?.isPublished || false,
        placeholder: false,
        slug: page.slug,
        title: page.data.title as string,
        subRows: {},
        nestingLevel: matches.length - 1,
      };

      let parent: RecordPageForTable;
      matches.forEach((match, index) => {
        const records = index === 0 ? grouped : parent.subRows;

        // get entry from records
        const found = records[match];
        // if no entry is found...
        if (!found) {
          if (match === lastMatch) {
            // ... and is the last match, sets page as entry (no parent or children)
            records[match] = pageForTable;
          } else {
            // ... and is not last match, create a parent and sets to records
            parent = {
              id: null,
              hasParent: false,
              isPublished: false,
              placeholder: true,
              slug: match,
              title: capitalize(match.slice(1).replace('-', ' ')),
              subRows: {},
              nestingLevel: index,
            };
            records[match] = parent;
          }
        } else if (match === lastMatch) {
          // if an entry was found and is also the last match,
          // then it is an existing page with children, so
          // the placeholder data is updated to match the page
          found.placeholder = false;
          found.id = pageForTable.id;
          found.isPublished = pageForTable.isPublished;
          found.title = pageForTable.title;
        } else {
          // otherwise, just sets it as the parent
          parent = found;
        }
      });

      return grouped;
    }, {} as Record<string, RecordPageForTable>);

    // recursively resets main object and nested subRows that are objects back to arrays so the table can render
    function getValues(records: Record<string, RecordPageForTable>): PageForTable[] {
      return Object.values(records)
        .sort((p1, p2) => {
          if (p1.slug === '/') {
            return -1;
          }
          if (p2.slug === '/') {
            return 1;
          }
          return p1.title < p2.title ? -1 : 1;
        })
        .map((record) => ({
          ...record,
          subRows: getValues(record.subRows),
        }));
    }
    return getValues(group);
  }, [pages.items]);

  useLoadPagesWithSearch({
    search: debouncedSearch,
    loadPages,
  });

  return {
    tableColumns,
    pagesWithChildren,
    loadPages,
    debouncedSearch,
    search,
    setSearch,
  };
}
