import { Link } from '@reach/router';
import { find } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column, SortingRule } from 'react-table';
import Swal from 'sweetalert2';
import clsx from 'clsx';

import {
  ButtonCreate,
  formatDate,
  LayoutWithNavbar,
  Table,
  TableBoolean,
  useCurrentPage,
  useTableColumnsCustomFields,
} from 'src/common';
import { isLoading } from 'src/services/api';
import { getTotalPages } from 'src/services/api/redux/traits';
import { Connection, useConnections } from 'src/services/connections';
import { useSections } from 'src/services/sections';
import { RouteConnectionsListProps } from 'src/types';
import { useLanguages } from 'src/services/languages';
import { useProject } from 'src/services/project';
import { SortDirection } from 'src/services/api/types';
import { useProjectLink } from 'src/common/hooks/use-project-link';
import { showToast } from '../../common/util/show-toast';
import ConnectionToolbar from './components/ConnectionToolbar';
import placeholderImage from '../../assets/images/image-placeholder.png';

type ConnectionsListProps = RouteConnectionsListProps;

export function ConnectionsList({ slug, sectionType, connectionSlug }: ConnectionsListProps) {
  const { sections } = useSections();
  const section = find(sections.items, { slug });
  const connection = find(sections.items, { slug: connectionSlug });
  const { project } = useProject();
  const { languages } = useLanguages();
  const [selectedConnections, setSelectedConnections] = useState<number[]>([]);
  const [sortBy, setSortBy] = useState<string | undefined>('id');
  const [sortDirection, setSortDirection] = useState(SortDirection.Descending);
  const projectLink = useProjectLink();

  const { connections, loadConnections, deleteConnection, verifyConnection, translateConnections } = useConnections(
    connectionSlug!,
  );

  const [currentPage, setCurrentPage] = useCurrentPage();

  const onDeleteConnection = async (deleting: Connection) => {
    const confirm = await Swal.fire({
      title: 'Delete',
      text: `Are you sure you want to delete?`,
      showCancelButton: true,
      confirmButtonColor: '#e0245a',
      confirmButtonText: 'Delete',
      icon: 'question',
    });

    if (confirm.value) {
      const result = await deleteConnection(deleting.id!);

      if (result.ok) {
        showToast('success', 'Entry has been deleted successfully');
      } else {
        showToast('error', 'Unable to delete');
      }
    }
  };

  const onVerify = useCallback(
    async (id: number, newVerificationState: boolean) => {
      const result = await verifyConnection(id, newVerificationState);

      if (result.ok) {
        showToast('success', `${newVerificationState ? 'Verified' : 'Unverified'} successfully`);
      } else {
        showToast('error', 'Unable to verify');
      }
    },
    [verifyConnection],
  );

  const onChangeCheckbox = useCallback(
    (id: number) => {
      if (!selectedConnections.includes(id)) {
        setSelectedConnections((selected) => [...selected, id]);
      } else {
        setSelectedConnections((selected) => selected.filter((s) => id !== s));
      }
    },
    [selectedConnections],
  );

  const handleImageError = (e: any) => {
    e.preventDefault();
    e.target.src = placeholderImage;
  };

  const clearSelectedItems = useCallback(() => {
    setSelectedConnections([]);
  }, []);

  useEffect(() => {
    setCurrentPage(0);
  }, [connection, setCurrentPage]);

  useEffect(() => {
    loadConnections(currentPage + 1, undefined, sortBy, sortDirection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, connection, currentPage, sortBy, sortDirection]);

  useEffect(() => {
    setCurrentPage(0);
  }, [connection, setCurrentPage]);

  const handleSortedChange = useCallback((params?: SortingRule<Connection>) => {
    if (!params) {
      return;
    }

    let paramSortBy = params?.id || 'id';
    if (paramSortBy === 'createdAt') {
      paramSortBy = 'created_at';
    } else if (paramSortBy === 'verifiedAt') {
      paramSortBy = 'verified_at';
    }

    setSortBy(paramSortBy);
    setSortDirection(params?.desc ? SortDirection.Descending : SortDirection.Ascending);
  }, []);

  const prependColumns = useMemo<Column<Connection>[]>(
    () => [
      {
        Header: 'Item',
        accessor: 'nodeName',
        Cell: ({ row: { original }, value }) => (
          <div className="columns is-vcentered is-gapless">
            {!project.hasTranslations ? (
              <div className="column is-narrow">
                <figure className="image is-24x24 mr-3">
                  {original.nodeImageUrl ? (
                    <img
                      src={original.nodeImageUrl}
                      alt={original.nodeName || ''}
                      className="is-rounded"
                      onError={handleImageError}
                    />
                  ) : null}
                </figure>
              </div>
            ) : (
              <>
                <div className="column is-narrow">
                  <input
                    type="checkbox"
                    onChange={() => onChangeCheckbox(original.id)}
                    checked={selectedConnections.includes(original.id)}
                    className="mr-3"
                  />
                </div>
                <div className="column is-narrow">
                  <div className="tags has-addons are-small mr-3">
                    {languages.items.map((lang) => {
                      const isTranslated = (original.languages || []).indexOf(lang.id) > -1;
                      return (
                        <span className={clsx('tag', isTranslated ? 'is-primary' : 'is-light')} key={lang.id}>
                          {lang.id.toUpperCase()}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            <div className="column">
              <Link to={`${projectLink}/nodes/${slug}/connections/${connectionSlug}/edit/${original.id}`}>{value}</Link>
              <Link
                to={`${projectLink}/${sectionType}/${slug}/edit/${original.nodeId}`}
                className="ml-2"
                title="Go to item"
              >
                <span className="icon is-small">
                  <i className="fal fa-external-link fa-sm" />
                </span>
              </Link>
            </div>
          </div>
        ),
      },
      {
        Header: 'Member',
        accessor: (row) => `${row.memberFirstName} ${row.memberLastName}`,
        id: 'memberName',
        Cell: ({ row: { original } }: { row: { original: Connection } }) => (
          <div className="columns is-vcentered is-gapless">
            <div className="column is-narrow">
              <figure className="image is-24x24 mr-3">
                {original.memberAvatarUrl ? (
                  <img src={original.memberAvatarUrl} alt={original.memberFirstName || ''} className="is-rounded" />
                ) : null}
              </figure>
            </div>
            <div className="column">{`${original.memberFirstName} ${original.memberLastName}`}</div>
          </div>
        ),
      },
    ],
    [
      project.hasTranslations,
      connectionSlug,
      languages.items,
      onChangeCheckbox,
      sectionType,
      selectedConnections,
      slug,
      projectLink,
    ],
  );

  const appendColumns = useMemo<Column<Connection>[]>(
    () => [
      {
        Header: 'Verified',
        align: 'center',
        accessor: (row) => row.data.verifiedAt,
        id: 'verifiedAt',
        Cell: ({ value, row: { original } }: { value: string | Date | null; row: { original: Connection } }) => (
          <div className="has-text-centered">
            <TableBoolean state={!!value} onClick={() => onVerify(original.id, !original.data?.verifiedAt)} />
            {value ? formatDate(value) : null}
          </div>
        ),
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
      },
    ],
    [onVerify],
  );

  const extraActions = useCallback(
    ({ id, data: { verifiedAt } }: Connection) => (
      <a onClick={() => onVerify(id, !verifiedAt)} className="dropdown-item">
        {verifiedAt ? 'Unverify' : 'Verify'}
      </a>
    ),
    [onVerify],
  );

  const tableColumns = useTableColumnsCustomFields<Connection>({
    section: connection,
    currentPage,
    onDelete: onDeleteConnection,
    editUrl: sectionType!,
    urlTemplate: `:slug/connections/:connectionType/edit/:id`,
    urlParams: { connectionType: connectionSlug, slug },
    dataKeyName: 'data',
    prependColumns,
    appendColumns,
    extraActions: connection?.hasVerification ? extraActions : undefined,
  });

  return !section || !connection ? (
    <LayoutWithNavbar />
  ) : (
    <LayoutWithNavbar
      breadcrumbs={[
        { label: section?.name || '', href: `/${sectionType}/${slug}` },
        {
          label: connection?.name || '',
          href: `${projectLink}/${sectionType}/${slug}/connections/${connectionSlug}`,
        },
      ]}
      endButtons={
        <ButtonCreate to={`/${sectionType}/${slug}/connections/${connectionSlug}/new`} currentPage={currentPage} />
      }
    >
      <ConnectionToolbar
        hasTranslations={project.hasTranslations}
        selectedItems={selectedConnections}
        availableLanguages={languages.items.map((lang) => lang.id)}
        onTranslate={translateConnections}
        onDelete={deleteConnection}
        clearSelectedItems={clearSelectedItems}
      />
      <Table<Connection>
        data={connections.items}
        loading={isLoading(connections)}
        columns={tableColumns}
        onFetchData={loadConnections}
        pageCount={getTotalPages(connections)}
        paginated
        initialState={{ hiddenColumns: connection.hasVerification ? [] : ['verifiedAt'] }}
        onSort={handleSortedChange}
        section={connection.name}
      />
    </LayoutWithNavbar>
  );
}
