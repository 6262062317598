import { Method } from 'axios';

export interface ApiConfig {
  hostname?: string;
  path: string;
  method?: Method;
  headers?: any;
  formData?: any;
  body?: any;
  additionalData?: any;
}

export interface SimpleSnackBarConfig {
  serviceName: any;
}

export interface SnackBarConfigWithMessages {
  getMessage: {
    success(response: any): string;
    error(error: any): string;
  };
}

export type SnackBarConfig = SnackBarConfigWithMessages | SimpleSnackBarConfig;

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}
