import { performApiCall } from 'src/services/api';
import { transformOrganization, transformOrganizationUsage } from './transformations';
import { Organization, OrganizationUsage } from './types';

export const fetchOrganizations = async (): Promise<Organization[]> => {
  const res = await performApiCall({
    path: `/organizations`,
  });
  return Promise.resolve(res.data.map(transformOrganization));
};

export const fetchOrganizationDetails = async (organizationId: number): Promise<Organization> => {
  const res = await performApiCall({
    path: `/organizations/${organizationId}`,
  });
  return Promise.resolve(transformOrganization(res.data));
};

export const fetchOrganizationUsage = async (organizationId: number): Promise<OrganizationUsage> => {
  const res = await performApiCall({
    path: `/organizations/${organizationId}/usage`,
  });
  return Promise.resolve(transformOrganizationUsage(res.data));
};
