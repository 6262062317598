import { combineReducers } from 'redux';

import { createCrudApiReducer } from 'src/services/api';

import { NodeActionTypes } from './actions';
import { transformNode } from '../transformations';
import { Node } from '../types';

const nodesReducer = createCrudApiReducer<Node>(
  NodeActionTypes.CHANGE_NODES_START,
  NodeActionTypes.CHANGE_NODES_FAILURE,
  NodeActionTypes.FETCH_NODES_SUCCESS,
  NodeActionTypes.ADD_NODE_SUCCESS,
  NodeActionTypes.UPDATE_NODE_SUCCESS,
  NodeActionTypes.DELETE_NODE_SUCCESS,
  transformNode,
);

export default combineReducers({ nodes: nodesReducer });
