/* eslint-disable @typescript-eslint/ban-types, react/jsx-key */
import clsx from 'clsx';
import React, { Fragment, useEffect } from 'react';
import { SortingRule, TableOptions, usePagination, useSortBy, useTable } from 'react-table';
import { MAX_TABLE_ROWS } from '../const';
import { useCurrentPage } from '../hooks';
import { Pagination } from './Pagination';
import { TableHeading } from './TableHeading';
/* import { TableHeading } from './TableHeading'; */

type TableProps<T extends { id?: number | null }> = {
  data: T[];
  paginated?: boolean;
  onSort?: (params?: SortingRule<T>) => any;
  loading?: boolean;
  section?: string;
  noContainer?: boolean;
  hideTableHeader?: boolean;
  dataQa?: string;
} & TableOptions<T>;

export function Table<T extends { id?: number | null }>({
  section,
  data,
  initialState,
  paginated,
  onSort,
  noContainer = false,
  hideTableHeader = false,
  className,
  // loading = false, // TODO: add loading
  ...options
}: TableProps<T>) {
  const [currentPage, setCurrentPage] = useCurrentPage();
  const tableInstance = useTable<T>(
    {
      data,
      initialState: {
        ...(initialState || {}),
        pageSize: initialState?.pageSize ?? MAX_TABLE_ROWS,
        pageIndex: initialState?.pageIndex ?? currentPage,
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      ...options,
    },
    useSortBy,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    pageCount,
    gotoPage,
    state: { pageIndex, sortBy },
    dataQa,
  } = tableInstance;

  useEffect(() => {
    if (!section) return;
    tableInstance.state.pageIndex = 0;
  }, [section, tableInstance.state]);

  useEffect(() => {
    if (paginated) {
      setCurrentPage(pageIndex);
    }
  }, [pageIndex, setCurrentPage, paginated]);

  useEffect(() => {
    onSort?.(sortBy[0]);
  }, [sortBy, onSort]);

  return (
    <>
      <div className={clsx(!noContainer && 'table-container')}>
        <table {...getTableProps()} className={`table is-hoverable is-fullwidth ${className}`}>
          {!hideTableHeader && (
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableHeading {...column.getHeaderProps(column.getSortByToggleProps())} column={column}>
                      {column.render('Header')}
                    </TableHeading>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          <tbody {...getTableBodyProps()} data-qa={dataQa}>
            {(paginated ? page : rows).map((row) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <Fragment key={rowProps.key}>
                  <tr {...rowProps} className="is-main-row">
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                  {row.subRows.map((subRow) => {
                    prepareRow(subRow);
                    return (
                      <tr {...subRow.getRowProps()} className="is-sub-row">
                        {subRow.cells.map((cell) => (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                      </tr>
                    );
                  })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {paginated ? (
        <Pagination currentPage={currentPage} dataLength={data.length} pages={pageCount} setCurrentPage={gotoPage} />
      ) : null}
    </>
  );
}
