import { VideoApiData, VideoMediaData } from '../types';

export function createVideoMediaData(video: VideoApiData): VideoMediaData {
  return {
    id: video.data.id,
    uid: video.data.uid,
    preview: video.data.preview,
    thumbnail: video.data.thumbnail,
  };
}
