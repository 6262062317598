import { find } from 'lodash';
import { useMemo } from 'react';
import { Section } from '../types';

export function useSectionsInUse(
  sections: Section[],
  slug: string,
  uri: string,
  collectionSlug?: string,
): [Section, Section] {
  const mainSection = useMemo(() => find(sections, { slug })!, [sections, slug]);

  const subSection = useMemo(() => {
    const isCategories = uri.includes('categories');
    const isCollections = uri.includes('collections');

    if (isCategories) {
      return find(sections, { id: mainSection.categorySectionId })!;
    }
    if (isCollections) {
      return find(sections, { slug: collectionSlug })!;
    }
    return mainSection;
  }, [sections, uri, mainSection, collectionSlug]);

  return [mainSection, subSection];
}
