import React, { forwardRef } from 'react';
import { useHookCustomField } from '../../hooks';
import { FloatingLabelField, FloatingLabelFieldProps } from './FloatingLabelField';

export type MultiInputProps = FloatingLabelFieldProps & JSX.IntrinsicElements['textarea'] & { 'data-qa'?: string };

export const MultiInputHook = forwardRef<HTMLTextAreaElement, MultiInputProps>((props, ref) => {
  const { fieldProps, inputProps } = useHookCustomField(props, {
    isTextarea: true,
  });

  return (
    <FloatingLabelField {...fieldProps}>
      <textarea ref={ref} {...inputProps} data-qa={props['data-qa']} />
    </FloatingLabelField>
  );
});
