import { useEffect } from 'react';
import { useLocation } from '@reach/router';

import { BACK_TO_SEARCH } from '../const';

export const usePrevSearchOnMount = (
  onSearch: undefined | ((prevSearch: string) => void),
  setSearchVisible: (value: React.SetStateAction<boolean>) => void,
) => {
  const location = useLocation();

  useEffect(() => {
    if (!onSearch) return;

    const search = new URLSearchParams(location.search);
    const currentSearchTerm = search.get(BACK_TO_SEARCH);

    if (currentSearchTerm) {
      onSearch(currentSearchTerm);
      setSearchVisible(true);
    }
    // eslint-disable-next-line
  }, [onSearch]);
};
