import clsx from 'clsx';
import { useState, ReactNode, FormEventHandler } from 'react';
import { noop } from 'lodash';
import { FloatingLabelFieldProps } from '../components/form/FloatingLabelField';

function composedFunction(...fns: CallableFunction[]) {
  return function composed(...args: any[]) {
    fns.forEach((fn) => fn(...args));
  };
}

type CustomFieldParams<T> = {
  fieldClassName?: string;
  label?: ReactNode;
  type?: string;
  onFocus?: FormEventHandler;
  onBlur?: FormEventHandler;
  onChange?: FormEventHandler;
  prependIcon?: ReactNode;
  error?: string;
  className?: string;
  value?: any;
  name?: string;
  touched?: boolean;
  hideHelper?: boolean;
  rounded?: boolean;
  placeholder?: string;
} & T;
type CustomFieldConfig = {
  isTextarea?: boolean;
  isQuill?: boolean;
};

export function useHookCustomField<T = JSX.IntrinsicElements['input']>(
  {
    className,
    fieldClassName,
    type = 'text',
    value,
    onBlur = noop,
    onFocus = noop,
    prependIcon,
    error,
    touched,
    hideHelper,
    rounded = false,
    placeholder,
    ...rest
  }: CustomFieldParams<T>,
  { isTextarea, isQuill }: CustomFieldConfig = {},
) {
  const alwaysFloatingLabel = ['date', 'time', 'color'].includes(type);
  const [hasFocus, setHasFocus] = useState(false);

  const hasError = touched && !!error;
  const labelClassName = clsx({
    'is-active': alwaysFloatingLabel || isTextarea || isQuill || hasFocus || (value ?? false) || !!placeholder,
    'is-focused': hasFocus,
    'has-text-danger': hasError,
    'has-icons': !!prependIcon,
    'is-rounded-input': rounded,
  });
  const inputClassName = clsx(
    // isTextarea || isQuill ? 'textarea' : 'input',
    {
      textarea: isTextarea,
      input: !isTextarea && !isQuill,
      'is-danger': hasError,
      'is-active': (isTextarea || isQuill) && hasFocus,
      'is-rounded': rounded,
    },
    className,
  );
  const composedOnBlur = composedFunction(() => setHasFocus(false), onBlur);
  const composedOnFocus = composedFunction(() => setHasFocus(true), onFocus);

  const fieldProps: FloatingLabelFieldProps = {
    hideHelper,
    hasError,
    fieldClassName: clsx(
      {
        'quill-field': isQuill,
      },
      fieldClassName,
    ),
    labelClassName,
    label: rest.label,
    error: touched ? error || '' : '',
    prependIcon,
    type: type === 'hidden' ? 'hidden' : undefined,
  };

  const inputProps = {
    ...rest,
    type,
    value,
    className: inputClassName,
    placeholder,
    onBlur: composedOnBlur,
    onFocus: composedOnFocus,
  };

  return {
    fieldProps,
    inputProps,
  };
}
