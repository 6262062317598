export function transformData(data: string): any {
  try {
    const result = JSON.parse(data);
    if (Array.isArray(result)) {
      return result.map(transformData);
    }
    if (typeof result === 'object') {
      Object.keys(result).forEach((key) => {
        result[key] = transformData(result[key]);
      });
    }
    return result;
  } catch {
    return data;
  }
}
