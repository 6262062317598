import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSections,
  fetchSections,
  deleteSection as deleteSectionAction,
  addSection,
  updateSection as updateSectionAction,
} from '../redux';
import { Section } from '../types';

export function useSections() {
  const dispatch = useDispatch();
  const sections = useSelector(getSections);

  const loadSections = useCallback(
    (search?: string) => {
      return dispatch(fetchSections(search ? { search } : {}));
    },
    [dispatch],
  );

  const deleteSection = useCallback(
    (id: number) => {
      return dispatch(deleteSectionAction(id));
    },
    [dispatch],
  );

  const createSection = useCallback(
    (data: Section) => {
      return dispatch(addSection(data));
    },
    [dispatch],
  );

  const updateSection = useCallback(
    (data: Section) => {
      return dispatch(updateSectionAction(data));
    },
    [dispatch],
  );

  return {
    sections,
    deleteSection,
    loadSections,
    createSection,
    updateSection,
  };
}
