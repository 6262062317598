import clsx from 'clsx';
import React, { ReactNode, useMemo } from 'react';
import { MAX_TABLE_ROWS } from '../const';

function getPageLink(pageNumber: number, currentPage: number, setPage: (page: number) => void) {
  const isCurrent = pageNumber === currentPage;
  const pageLabel = pageNumber + 1;

  return (
    <li key={pageNumber}>
      <a
        className={clsx('pagination-link', {
          'is-current': isCurrent,
        })}
        onClick={!isCurrent ? () => setPage(pageNumber) : undefined}
      >
        {pageLabel}
      </a>
    </li>
  );
}

type PaginationProps = {
  dataLength: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  pages?: number;
};

export function Pagination({ dataLength, currentPage, setCurrentPage, pages }: PaginationProps) {
  const totalPages = pages || Math.max(Math.ceil(dataLength / MAX_TABLE_ROWS), 1);
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === totalPages - 1;

  const { hasLeftEllipsis, hasRightEllipsis, pagesLinksCenter, pagesLinksLeft, pagesLinksRight } = useMemo(() => {
    const result: {
      hasLeftEllipsis: boolean;
      hasRightEllipsis: boolean;
      pagesLinksLeft: ReactNode[];
      pagesLinksRight: ReactNode[];
      pagesLinksCenter: ReactNode[];
    } = {
      hasLeftEllipsis: false,
      hasRightEllipsis: false,
      pagesLinksLeft: [],
      pagesLinksRight: [],
      pagesLinksCenter: [],
    };
    const page = currentPage + 1;

    /*
      pagination (when there's over 21 pages):

        1 => 1  2  3  4  5  6  7  8  9 10 11 12 13 14 15 .. 81 82 83 84 85
      11 => 1  2  3  4  5  6  7  8  9 10 11 12 13 14 15 .. 81 82 83 84 85
      12 => 1  2  3  4  5 ..  8  9 10 11 12 13 14 15 16 .. 81 82 83 84 85
      34 => 1  2  3  4  5 .. 30 31 32 33 34 35 36 37 38 .. 81 82 83 84 85
      74 => 1  2  3  4  5 .. 70 71 72 73 74 75 76 77 78 .. 81 82 83 84 85
      75 => 1  2  3  4  5 .. 71 72 73 74 75 76 77 78 79 80 81 82 83 84 85
    */

    if (totalPages <= 21) {
      for (let i = 0; i <= totalPages - 1; i += 1) {
        result.pagesLinksCenter.push(getPageLink(i, currentPage, setCurrentPage));
      }
    } else if (page <= 11) {
      for (let i = 0; i < 15; i += 1) {
        result.pagesLinksLeft.push(getPageLink(i, currentPage, setCurrentPage));
      }
      for (let i = totalPages - 5; i < totalPages; i += 1) {
        result.pagesLinksRight.push(getPageLink(i, currentPage, setCurrentPage));
      }
      result.pagesLinksCenter.length = 0;
      result.hasLeftEllipsis = false;
      result.hasRightEllipsis = true;
    } else if (page >= totalPages - 10) {
      for (let i = 0; i < 5; i += 1) {
        result.pagesLinksLeft.push(getPageLink(i, currentPage, setCurrentPage));
      }
      for (let i = totalPages - 15; i < totalPages; i += 1) {
        result.pagesLinksRight.push(getPageLink(i, currentPage, setCurrentPage));
      }
      result.pagesLinksCenter.length = 0;
      result.hasLeftEllipsis = true;
      result.hasRightEllipsis = false;
    } else {
      for (let i = 0; i < 5; i += 1) {
        result.pagesLinksLeft.push(getPageLink(i, currentPage, setCurrentPage));
      }
      for (let i = page - 5; i <= page + 3; i += 1) {
        result.pagesLinksCenter.push(getPageLink(i, currentPage, setCurrentPage));
      }
      for (let i = totalPages - 5; i < totalPages; i += 1) {
        result.pagesLinksRight.push(getPageLink(i, currentPage, setCurrentPage));
      }
      result.hasLeftEllipsis = true;
      result.hasRightEllipsis = true;
    }
    return result;
  }, [currentPage, setCurrentPage, totalPages]);

  return (
    <div className="pagination is-centered is-small">
      <ul className="pagination-list">
        <li>
          <a
            className="pagination-link"
            // @ts-ignore
            disabled={isFirstPage}
            onClick={isFirstPage ? undefined : () => setCurrentPage(currentPage - 1)}
          >
            <span className="icon is-small">
              <i className="fal fa-chevron-left fa-xs" />
            </span>
          </a>
        </li>
        {pagesLinksLeft}
        {hasLeftEllipsis && (
          <li>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
        )}
        {pagesLinksCenter}
        {hasRightEllipsis && (
          <li>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
        )}
        {pagesLinksRight}
        <li>
          <a
            className="pagination-link"
            // @ts-ignore
            disabled={isLastPage}
            onClick={isLastPage ? undefined : () => setCurrentPage(currentPage + 1)}
          >
            <span className="icon is-small">
              <i className="fal fa-chevron-right fa-xs" />
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
}
