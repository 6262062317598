import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { ColumnInstance } from 'react-table';

export type TableHeadingProps<T extends Record<string, any>> = PropsWithChildren<
  {
    column: ColumnInstance<T>;
  } & JSX.IntrinsicElements['th']
>;

export function TableHeading<T extends Record<string, any>>({
  children,
  column,
  className,
  ...props
}: TableHeadingProps<T>) {
  const { align = 'left', canSort, isSortedDesc, isSorted } = column;

  return (
    <th {...props} className={clsx('table-heading', className)}>
      <div
        className={clsx('table-heading-wrapper', {
          'is-justify-content-start': align === 'left',
          'is-justify-content-center': align === 'center',
          'is-justify-content-end': align === 'right',
        })}
      >
        <span>{children}</span>
        {canSort ? (
          <span className="table-sorting">
            <i
              className={clsx(
                'fas fa-sort-up sort-asc',
                isSorted && !isSortedDesc ? 'has-text-blue' : 'has-text-grey-lighter',
              )}
            />
            <i
              className={clsx(
                'fas fa-sort-down sort-desc',
                isSorted && isSortedDesc ? 'has-text-blue' : 'has-text-grey-lighter',
              )}
            />
          </span>
        ) : null}
      </div>
    </th>
  );
}
