/* eslint-disable react/jsx-no-target-blank */
import { yupResolver } from '@hookform/resolvers/yup';
import { find, findIndex } from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Accordion,
  AsyncSelect,
  CustomFields,
  DatePicker,
  EditPageLayout,
  EditPageLoading,
  InputHook,
  isTouched,
  LayoutWithNavbar,
  usePrevPageLink,
} from 'src/common';
import { Connection, ConnectionAddApiRequest, fetchConnectionDetails, useConnections } from 'src/services/connections';
import { useSectionItems, useSections } from 'src/services/sections';
import { Content, RouteConnectionsEditProps, RSelectOption } from 'src/types';
import { navigate } from '@reach/router';
import clsx from 'clsx';
import { DevTool } from '@hookform/devtools';
import { useProject } from 'src/services/project';
import { useTranslations } from 'src/services/integrations/hooks';
import { showToast } from 'src/common/util/show-toast';
import { formatDateTime } from 'src/common/util/format-date';
import { searchMembers } from 'src/services/users/api';
import { parse } from 'date-fns';

const validationSchema = yup.object({
  data: yup
    .object()
    .notRequired()
    .nullable()
    .test('json validate', 'Custom data should be json.', () => {
      // TODO: We new smarter validation. We know this is going to be JSON formated
      return true;
    }),
});

type EditConnection = Connection & {
  member: RSelectOption;
  node: RSelectOption;
};
type ConnectionsEditProps = RouteConnectionsEditProps;

export function ConnectionsEdit({ sectionType, connectionSlug, slug, id }: ConnectionsEditProps) {
  const { sections } = useSections();
  const section = find(sections.items, { slug })!;
  const { updateConnection, verifyConnection, addConnection } = useConnections(connectionSlug!);
  const connection = find(sections.items, { slug: connectionSlug })!;
  const [editConnection, setEditConnection] = useState<EditConnection | null>(null);
  const [verifiedAt, setVerifiedAt] = useState<Date | null>(null);
  const [loadingConnection, setLoadingConnection] = useState(true);
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const formMethods = useForm<EditConnection>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { control, register, handleSubmit, formState, reset, watch } = formMethods;
  const { fields, append, remove } = useFieldArray<Content>({
    control,
    name: 'contents',
  });
  const selectedNode = watch('node');
  const { project } = useProject();
  const { translate } = useTranslations(control, connection.fields || []);
  const loadOptions = useSectionItems();

  const listLink = usePrevPageLink(`/${sectionType}/${slug}/connections/${connectionSlug}`);

  const save = handleSubmit(async (values) => {
    let result: any;
    let connectionId = Number(id);

    if (!connectionId) {
      const connectionAddData: ConnectionAddApiRequest = {
        memberId: values.member.value,
        nodeId: values.node.value,
        lang: values.contents[0].languageId,
        data: { ...values.data, ...values.contents[0].data },
      };
      result = await addConnection(connectionAddData);

      if (!result.ok) {
        showToast('error', 'There was an error processing your action');
        return;
      }

      // if we're adding a connection and there is only one language, we exit
      // if there are multiple, we update the connection with all contents
      if (values.contents.length === 1) {
        showToast('success', 'Successfully saved');
        navigate(listLink);
        return;
      }

      connectionId = Number(result.res.payload.id);
    }

    const connectionUpdateData: Connection = {
      ...values,
      contents: values.contents?.map((c) => ({
        ...c,
        id: c.id ? Number(c.id) : undefined,
        data: JSON.stringify(c.data) ?? null,
      })),
      id: connectionId,
      memberId: values.member.value,
      nodeId: values.node.value,
    };

    result = await updateConnection(connectionUpdateData);

    if (result.ok) {
      showToast('success', 'Successfully saved');

      navigate(listLink);
    } else {
      showToast('error', 'There was an error processing your action');
    }
  });

  function addLanguage(languageId: string) {
    if (fields && fields.find((e) => e.languageId === languageId)) {
      return;
    }

    append({
      id: null,
      text: '',
      description: '',
      languageId,
      title: '',
      slug: '',
    });
    setActiveTab(languageId);
  }

  function removeLanguage(languageId: string) {
    const index = findIndex(fields, { languageId });
    if (index < 0) return;

    remove(index);
    setActiveTab(fields[0].languageId!);
  }

  const onVerify = useCallback(async () => {
    const newVerificationState = !verifiedAt;
    const result = await verifyConnection(+id!, newVerificationState);
    setVerifiedAt(result.data.verifiedAt ? new Date(result.data.verifiedAt) : null);

    if (result.ok) {
      showToast('success', `${newVerificationState ? 'Verified' : 'Unverified'} successfully`);
    } else {
      showToast('error', 'Unable to verify');
    }
  }, [id, verifyConnection, verifiedAt]);

  const translationEnabled = project.hasTranslations && fields.length > 1;

  useEffect(() => {
    async function loadConnection() {
      if (id) {
        setLoadingConnection(true);
        const result = await fetchConnectionDetails(connectionSlug!, Number(id));

        if (result) {
          const resultConnection = {
            ...result,
            member: { value: result.memberId!, label: `${result.memberFirstName} ${result.memberLastName}` },
            node: { value: result.nodeId!, label: result.nodeName! },
            createdAt: parse(result.createdAt as string, 'MM/dd/yyyy HH:mm:ss', new Date()),
          };
          setLoadingConnection(false);
          setEditConnection(resultConnection);
          setVerifiedAt(result.data?.verifiedAt ? new Date(result.data.verifiedAt) : null);
          reset(resultConnection);
          setActiveTab(result.contents[0]?.languageId);
        } else {
          showToast('error', 'Connection not found');
          navigate(listLink);
        }
      } else {
        setLoadingConnection(false);
        addLanguage(project.defaultLanguageId);
      }
    }
    loadConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return !loadingConnection ? (
    <>
      <LayoutWithNavbar
        backHref={listLink}
        breadcrumbs={[
          { label: section.name || '', href: `/${sectionType}/${slug}` },
          {
            label: connection.name || '',
            href: `/${sectionType}/${slug}/connections/${connectionSlug}`,
          },
          { href: './', label: 'Edit' },
        ]}
        endButtons={
          <>
            {id && connection.hasVerification ? (
              <div className="navbar-item">
                <a className="button is-light has-text-link is-rounded" onClick={onVerify}>
                  <span className="icon">
                    <i className={`far fa-${verifiedAt ? 'ban' : 'check'}`} />
                  </span>
                </a>
              </div>
            ) : null}
            <div className="navbar-item">
              <button
                type="submit"
                className={clsx('button is-primary', {
                  'is-loading': formState.isSubmitting,
                })}
              >
                <span>Save changes</span>
                <span className="icon">
                  <i className="fal fa-cloud-upload has-text-secondary-light" />
                </span>
              </button>
            </div>
          </>
        }
        as="form"
        onSubmit={save}
        languageProps={{
          fields,
          activeTab,
          setActiveTab,
          addLanguage,
          removeLanguage,
        }}
        translationProps={
          translationEnabled
            ? {
                onTranslate: translate,
                selectedLanguages: fields.map((content) => content.languageId!),
              }
            : undefined
        }
      >
        {fields.map((content, index) => (
          <Fragment key={content.languageId}>
            <input type="hidden" ref={register()} name={`contents[${index}].id`} defaultValue={content.id!} />
            <input
              type="hidden"
              ref={register()}
              name={`contents[${index}].languageId`}
              defaultValue={content.languageId}
            />
          </Fragment>
        ))}
        <EditPageLayout
          renderLeft={
            <Accordion
              disableShadow
              items={[
                {
                  Header: 'Common',
                  id: 'common',
                  defaultExpanded: true,
                  content: (
                    <CustomFields
                      sectionFields={connection.fields}
                      {...formMethods}
                      currentLanguage={activeTab || ''}
                      languages={fields}
                    />
                  ),
                },
                {
                  Header: 'Details',
                  id: 'details',
                  defaultExpanded: true,
                  content: (
                    <>
                      <Controller
                        control={control}
                        name="member"
                        default={editConnection?.member}
                        render={({ ref, ...props }) => (
                          <AsyncSelect
                            loadOptions={searchMembers}
                            {...props}
                            label="Member"
                            id="member"
                            touched={isTouched(formState, 'member')}
                          />
                        )}
                      />
                      <div className="columns is-mobile is-vcentered mb-0">
                        <div className="column">
                          <Controller
                            control={control}
                            name="node"
                            default={editConnection?.node}
                            render={({ ref, ...props }) => (
                              <AsyncSelect
                                loadOptions={loadOptions(section.id!)}
                                {...props}
                                label="Item"
                                id="node"
                                touched={isTouched(formState, 'node')}
                              />
                            )}
                          />
                        </div>
                        <div className="column is-narrow">
                          <a
                            href={selectedNode ? `${sectionType}/${slug}/edit/${selectedNode.value}` : undefined}
                            className="button is-primary"
                            target="_blank"
                            // @ts-ignore
                            disabled={!selectedNode}
                          >
                            <span className="icon">
                              <i className="fal fa-external-link" />
                            </span>
                          </a>
                        </div>
                      </div>
                      <Controller
                        control={control}
                        name="createdAt"
                        defaultValue={editConnection?.createdAt || new Date()}
                        render={({ value, ...rest }) => (
                          <DatePicker
                            selected={value}
                            label="Date"
                            id="createdAt"
                            prependIcon={<i className="fal fa-calendar fa-lg" />}
                            error={formState.errors.createdAt?.message}
                            touched={isTouched(formState, 'createdAt')}
                            fieldClassName="mb-3"
                            portalId="createdAt"
                            showTimeInput
                            dateFormat="Pp"
                            withPortal
                            shouldCloseOnSelect={false}
                            {...rest}
                          />
                        )}
                      />
                      {verifiedAt && (
                        <InputHook
                          label="Verified at"
                          readOnly
                          value={formatDateTime(verifiedAt)}
                          hideHelper
                          prependIcon={<i className="fal fa-calendar" />}
                        />
                      )}
                    </>
                  ),
                },
              ]}
            />
          }
        />
      </LayoutWithNavbar>
      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </>
  ) : (
    <EditPageLoading />
  );
}
