import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { HELP_ANIMATION_VARIANTS } from 'src/common/const';

const defaultProps = {
  hasError: false,
};
type FieldErrorMessageProps = {
  hasError?: boolean;
  error?: string | null;
} & Partial<typeof defaultProps>;

export function FieldErrorMessage({ hasError, error }: FieldErrorMessageProps) {
  return (
    <div className="helper-wrapper">
      <AnimatePresence>
        {(hasError || !!error) && (
          <motion.div initial="hidden" animate="visible" exit="hidden" variants={HELP_ANIMATION_VARIANTS}>
            <p className="help is-danger">{error}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

FieldErrorMessage.defaultProps = defaultProps;
