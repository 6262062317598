import { AnimatePresence, motion } from 'framer-motion';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { NodeBlock } from 'src/services/nodes';
import { FADE_IN_VARIANTS } from 'src/common/const';
import arrayMove from 'array-move';
import { findIndex } from 'lodash';
import Swal from 'sweetalert2';
import { useProject } from 'src/services/project';

function MotionControl({ children }: PropsWithChildren<unknown>) {
  return (
    <motion.div variants={FADE_IN_VARIANTS} initial="hidden" animate="visible" exit="hidden" className="control">
      {children}
    </motion.div>
  );
}

type PageBlocksToolbarProps = {
  activeBlock: NodeBlock | null;
  onAddBlock: () => void;
  onEditBlock: () => void;
  blocks: NodeBlock[];
  onChange: (value: NodeBlock[]) => void;
  onBlur: () => void;
};

export function PageBlocksToolbar({
  activeBlock,
  onAddBlock,
  onEditBlock,
  blocks,
  onChange,
  onBlur,
}: PageBlocksToolbarProps) {
  const { project } = useProject();

  const isLastBlock = useMemo(() => activeBlock === blocks[blocks.length - 1], [activeBlock, blocks]);
  const isFirstBlock = useMemo(() => activeBlock === blocks[0], [activeBlock, blocks]);

  const moveUp = useCallback(() => {
    const index = findIndex(blocks, activeBlock!);
    const newValue = arrayMove(blocks, index, index - 1);
    onChange(newValue);
    onBlur();
  }, [activeBlock, blocks, onBlur, onChange]);

  const moveDown = useCallback(() => {
    const index = findIndex(blocks, activeBlock!);
    const newValue = arrayMove(blocks, index, index + 1);
    onChange(newValue);
    onBlur();
  }, [activeBlock, blocks, onBlur, onChange]);

  const onDeleteBlock = useCallback(async () => {
    const confirm = await Swal.fire({
      title: 'Delete Block',
      text: `Are you sure you want to delete ${activeBlock!.name}? All its contents will be deleted as well!`,
      showCancelButton: true,
      confirmButtonColor: '#e0245a',
      confirmButtonText: 'Delete',
      icon: 'question',
    });

    if (confirm.value) {
      onChange(blocks.filter((b) => b !== activeBlock));
      onBlur();
    }
  }, [activeBlock, blocks, onBlur, onChange]);

  return (
    <div className="page-block-toolbar container is-fluid">
      <div className="field is-grouped">
        <div className="control">
          <button
            disabled={!!project.themeId}
            type="button"
            className="button is-outlined"
            onClick={onAddBlock}
            data-qa="block-new"
          >
            <span className="icon has-text-primary">
              <i className="far fa-plus" />
            </span>
            <span>New block</span>
          </button>
        </div>
        <AnimatePresence exitBeforeEnter>
          {activeBlock && !project.themeId ? (
            <>
              <MotionControl>
                <div className="buttons has-addons">
                  <button
                    type="button"
                    className="button is-outlined"
                    disabled={isFirstBlock}
                    onClick={moveUp}
                    data-qa="block-up"
                  >
                    <span className="icon has-text-primary">
                      <i className="far fa-arrow-up" />
                    </span>
                  </button>
                  <button
                    type="button"
                    className="button is-outlined"
                    disabled={isLastBlock}
                    onClick={moveDown}
                    data-qa="block-down"
                  >
                    <span className="icon has-text-primary">
                      <i className="far fa-arrow-down" />
                    </span>
                  </button>
                </div>
              </MotionControl>
              <MotionControl>
                <button type="button" className="button is-outlined" onClick={onEditBlock} data-qa="block-edit">
                  <span className="icon has-text-primary">
                    <i className="far fa-pen" />
                  </span>
                </button>
              </MotionControl>
              <MotionControl>
                <button type="button" className="button is-outlined" onClick={onDeleteBlock} data-qa="block-delete">
                  <span className="icon has-text-primary">
                    <i className="far fa-trash" />
                  </span>
                </button>
              </MotionControl>
            </>
          ) : null}
        </AnimatePresence>
      </div>
    </div>
  );
}
