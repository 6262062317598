import { DevTool } from '@hookform/devtools';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { InputHook, isTouched, Modal } from 'src/common';
import { showToast } from 'src/common/util/show-toast';
import { KeyValueStore, useKeyValueStores } from 'src/services/key-values';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';

const validationSchema = yup.object<KeyValueStore>({
  keyName: yup.string().label('Key name').required(),
  valueName: yup.string().label('Value name').required(),
  name: yup.string().label('Name').required(),
  slug: yup.string().label('Slug').required(),
});

type StoreEditorModalProps = {
  store: KeyValueStore | null;
  onCancel: () => void;
};

export function StoreEditorModal({ onCancel, store }: StoreEditorModalProps) {
  const { addStore, updateStore } = useKeyValueStores();
  const { control, reset, handleSubmit, formState, errors, clearErrors } = useForm<KeyValueStore>({
    defaultValues: { keyName: '', valueName: '', name: '', slug: '' },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const editingItem = !!store?.id;

  const save = handleSubmit(async (values) => {
    const data = values;
    data.id = store!.id;
    const result = !editingItem ? await addStore(data) : await updateStore(data);

    if (result.ok) {
      showToast('success', `Store ${editingItem ? 'updated' : 'created'} successfully`);

      onCancel();
    } else {
      showToast('error', 'Unable to save store');
    }
  });

  useEffect(() => {
    if (store) {
      reset(store);
    }
    clearErrors();
  }, [reset, clearErrors, store]);

  return (
    <Modal visible={!!store} onClickBackground={onCancel}>
      <form onSubmit={save}>
        <div className="modal-card-head">
          <p className="modal-card-title">{editingItem ? `Edit: ${store!.name}` : 'Add store'}</p>
          <button type="button" className="delete" onClick={onCancel} />
        </div>
        <div className="modal-card-body content">
          <div className="columns is-multiline">
            <div className="column is-half">
              <Controller
                control={control}
                name="name"
                as={<InputHook />}
                label="Name"
                touched={isTouched(formState, 'name')}
                error={errors.name?.message}
              />
            </div>
            <div className="column is-half">
              <Controller
                control={control}
                name="slug"
                as={<InputHook />}
                label="Slug"
                touched={isTouched(formState, 'slug')}
                error={errors.slug?.message}
              />
            </div>
            <div className="column is-half">
              <Controller
                control={control}
                name="keyName"
                as={<InputHook />}
                label="Key name"
                touched={isTouched(formState, 'keyName')}
                error={errors.keyName?.message}
              />
            </div>
            <div className="column is-half">
              <Controller
                control={control}
                name="valueName"
                as={<InputHook />}
                label="Value name"
                touched={isTouched(formState, 'valueName')}
                error={errors.valueName?.message}
              />
            </div>
          </div>
        </div>
        <div className="modal-card-foot">
          <button type="reset" className="button" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="submit"
            className={clsx('button is-primary', {
              'is-loading': formState.isSubmitting,
            })}
            disabled={formState.isSubmitting || !formState.isValid}
          >
            Save
          </button>
        </div>
      </form>
      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </Modal>
  );
}
