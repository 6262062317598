import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { performApiCall } from 'src/services/api';

export function useSectionItems() {
  const load = useCallback(
    (sectionId: number) => async (search: string) => {
      const result: AxiosResponse<{ id: number; name: string }[]> = await performApiCall({
        path: `/sections/${sectionId}/items?search=${encodeURIComponent(search)}`,
      });
      return result.data.map((item) => ({ value: item.id, label: item.name }));
    },
    [],
  );

  return load;
}
