import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentPage } from 'src/common';
import { MAX_TABLE_ROWS } from 'src/common/const';
import { performApiCall } from 'src/services/api';
import urlcat from 'urlcat';
import {
  getConnections,
  fetchConnections,
  updateConnection as updateConnectionAction,
  deleteConnection as deleteConnectionAction,
  addConnection as addConnectionAction,
} from '../redux';
import { setConnectionVerification } from '../redux/actions';
import { Connection, ConnectionAddApiRequest } from '../types';

export function useConnections(slug: string) {
  const dispatch = useDispatch();
  const connections = useSelector(getConnections);
  const [currentPage] = useCurrentPage();

  const loadConnections = useCallback(
    (page = 1, search?: string, sortBy?: string, sortDirection?: string) => {
      return dispatch(
        fetchConnections(page, MAX_TABLE_ROWS, {
          slug,
          search,
          sortBy: sortBy || 'id',
          sortDirection: sortDirection || 'desc',
        }),
      );
    },
    [dispatch, slug],
  );

  const addConnection = useCallback(
    (data: ConnectionAddApiRequest) => {
      const apiData = { ...data, data: JSON.stringify(data.data || {}) };
      return dispatch(addConnectionAction(apiData, { slug }));
    },
    [dispatch, slug],
  );

  function updateConnection(data: Connection) {
    // TODO: remove this when api is accepting JSON directly
    const apiData = { ...data, data: JSON.stringify(data.data || {}) };
    return dispatch(updateConnectionAction(apiData, { slug }));
  }

  function deleteConnection(id: number) {
    return dispatch(deleteConnectionAction(id, { slug }));
  }

  const verifyConnection = useCallback(
    async (id: number, verificationStatus = true) => {
      try {
        const { data }: AxiosResponse = await performApiCall({
          path: urlcat('/connections', '/:slug/:id/verification', { slug, id }),
          method: verificationStatus ? 'POST' : 'DELETE',
        });

        dispatch(setConnectionVerification(data.id, data.verifiedAt));

        return {
          ok: true,
          data,
        };
      } catch (error) {
        return {
          ok: false,
          error,
        };
      }
    },
    [slug, dispatch],
  );

  const translateConnections = useCallback(
    async (sourceLanguage: string, targetLanguages: string[], connectionIds: number[]) => {
      try {
        await performApiCall({
          path: '/integrations/translations/connections',
          method: 'POST',
          body: {
            languageId: sourceLanguage,
            translationLanguageIds: targetLanguages,
            connectionIds,
          },
        });

        await loadConnections(currentPage + 1);

        return {
          ok: true,
        };
      } catch (error) {
        return {
          ok: false,
        };
      }
    },
    [currentPage, loadConnections],
  );

  return {
    connections,
    loadConnections,
    updateConnection,
    deleteConnection,
    verifyConnection,
    translateConnections,
    addConnection,
  };
}
