import React, { useEffect } from 'react';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';

import { useAuth } from 'src/services/auth';
import { isValid } from 'src/services/api';
import { verifyEmail } from 'src/services/auth/api';
import { showToast } from 'src/common/util/show-toast';

type EmailVerificationProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function EmailVerification(_: EmailVerificationProps) {
  const { auth } = useAuth();

  const location = useLocation();

  useEffect(() => {
    if (isValid(auth)) {
      navigate('/');
    }
  }, [auth]);

  const search = new URLSearchParams(location.search);
  const email = search.get('email');
  const code = search.get('code');

  useEffect(() => {
    async function verify() {
      if (!email || !code) {
        return;
      }

      const result = await verifyEmail(email!, code!);

      // @ts-ignore
      if (result) {
        showToast('success', 'Email verification complete.');
        navigate('/login');
      } else {
        showToast('error', 'There was an error verifying your email.');
      }
    }
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, code]);

  if (isValid(auth)) {
    return null;
  }

  if (!email || !code) {
    return <div className="p-5">Invalid email verification link. Please click the link in your email.</div>;
  }

  return (
    <div className="login-page">
      <div className="navbar is-transparent is-spaced">
        <div className="navbar-menu">
          <div className="navbar-end">
            <a href="#" className="navbar-item">
              Services
            </a>
            <a href="#" className="navbar-item">
              Contact
            </a>
          </div>
        </div>
      </div>
      <div className="container is-fluid">
        <div className="page-wrapper">
          <h2 className="title is-1">Email verification</h2>
          <p>Verifying your email...</p>
        </div>
      </div>
    </div>
  );
}
