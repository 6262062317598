import { NodeBlockField } from '../nodes';
import { Section, SectionField, SectionType } from './types';

export const transformSectionField = (response: any): SectionField | NodeBlockField => {
  let options = null;

  if (response.options) {
    if (typeof response.options === 'string') {
      options = JSON.parse(response.options);
    } else {
      options = response.options;
    }
  }

  return {
    id: response.id,
    name: response.name,
    type: Number(response.type),
    apiName: response.apiName,
    isRequired: response.isRequired,
    isDisplayedInList: response.isDisplayedInList,
    isLocalized: response.isLocalized,
    isTranslatable: response.isTranslatable,
    isPublic: response.isPublic,
    linkedSectionId: response.linkedSectionId,
    options,
    sectionId: response.sectionId,
    position: response.position,
    blockId: response.blockId,
  };
};

export const transformSection = (response: any): Section => ({
  id: response.id,
  name: response.name,
  type: response.type,
  slug: response.slug,
  customCSS: response.customCSS,
  connectionType: response.connectionType,
  categorySectionId: response.categorySectionId,
  parentSectionId: response.parentSectionId,
  previewUrl: response.previewUrl,

  isPublishable: response.isPublishable,
  isEvent: response.isEvent,
  isProduct: response.isProduct,
  hasStripeCheckout: response.hasStripeCheckout,
  hasTransactionConnection: response.hasTransactionConnection,
  hasSeoMetadata: response.hasSeoMetadata,
  hasVerification: response.hasVerification,
  hasCategory: response.hasCategory,

  fields: (response.fields || [])
    .map(transformSectionField)
    .sort((sf1: SectionField, sf2: SectionField) => (sf1.position! < sf2.position! ? -1 : 1)),
});

export const transformSectionFieldForApi = (sectionField: SectionField | NodeBlockField, position: number): any => {
  let options = null;

  if (sectionField.options) {
    if (typeof sectionField.options === 'string') {
      options = sectionField.options;
    } else {
      options = JSON.stringify(sectionField.options);
    }
  }

  return {
    id: sectionField.id,
    name: sectionField.name,
    type: Number(sectionField.type),
    apiName: sectionField.apiName,
    isRequired: sectionField.isRequired,
    isDisplayedInList: sectionField.isDisplayedInList,
    isLocalized: sectionField.isLocalized,
    isTranslatable: sectionField.isTranslatable,
    isPublic: sectionField.isPublic,
    linkedSectionId: sectionField.linkedSectionId,
    options,
    sectionId: sectionField.sectionId,
    position,
    blockId: (sectionField as NodeBlockField).blockId || undefined,
  };
};

export const transformSectionForApi = (section: Section): any => {
  return {
    id: section.id || undefined,
    type: Number(section.type),
    name: section.name,
    slug: section.slug,
    customCSS: section.customCSS,
    previewUrl: section.previewUrl,

    hasStripeCheckout: section.hasStripeCheckout || false,
    hasTransactionConnection: section.hasTransactionConnection || false,
    hasSeoMetadata: section.hasSeoMetadata || false,
    hasVerification: section.hasVerification || false,
    hasCategory: section.hasCategory || false,

    isPublishable: section.isPublishable || false,
    isEvent: section.isEvent || false,
    isProduct: section.isProduct || false,

    connectionType: Number(section.connectionType) || null,
    categorySectionId: Number(section.categorySectionId) || null,
    parentSectionId: Number(section.parentSectionId) || null,

    fields: (section.fields || []).map((field, index) => transformSectionFieldForApi(field, index + 1)),
  };
};

export const transformSectionForForm = (section: Section, id: string | number | null): Section => ({
  id: Number(id) || undefined,

  type: section.type,
  name: section.name,
  slug: section.slug,
  customCSS: section.customCSS,
  previewUrl: section.previewUrl,

  hasStripeCheckout: section.hasStripeCheckout,
  hasTransactionConnection: section.hasTransactionConnection,
  hasSeoMetadata: section.hasSeoMetadata,
  hasVerification: section.hasVerification,
  hasCategory: section.hasCategory,

  isPublishable: section.isPublishable,
  isEvent: section.isEvent,
  isProduct: section.isProduct,

  connectionType: section.connectionType,
  categorySectionId: section.categorySectionId,
  parentSectionId: section.parentSectionId,

  fields: (section.fields || [])
    .map(transformSectionField)
    .sort((sf1, sf2) => (sf1.position! < sf2.position! ? -1 : 1)),
});

export const sectionComparer = (s1: any, s2: any) => {
  if (s1.type === SectionType.Categories) return -1;
  if (s2.type === SectionType.Categories) return 1;

  if (s1.type === SectionType.Collections) return -1;
  if (s2.type === SectionType.Collections) return 1;

  return s1.name < s2.name ? -1 : 1;
};
