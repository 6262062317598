import React from 'react';
import { EditPageLayout } from './EditPageLayout';
import { LayoutWithNavbar } from './LayoutWithNavbar';

export function EditPageLoading() {
  return (
    <LayoutWithNavbar>
      <EditPageLayout loading />
    </LayoutWithNavbar>
  );
}
