import { cloneDeep, findIndex } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { ArrayField, Control } from 'react-hook-form';
import { NodeBlockField, NodeContent } from 'src/services/nodes';
import { SectionField, SectionFieldType } from 'src/services/sections';
import { fetchTranslations } from '../api';

function isSectionLink(sectionType: SectionFieldType) {
  return sectionType === SectionFieldType.SectionLink || sectionType === SectionFieldType.SectionMultiLink;
}

// this hook exposes the translate function that will take all of the localizable
// fields from the provided control and will translate them from the source language
// to all other languages present in the control contents

// keyvalue fields are broken out into separate fields for both name and value and
// are combined back after translation

export function useTranslations(control: Control<any>, sectionFields: (SectionField | NodeBlockField)[]) {
  const [queue, setQueue] = useState<[string, any][]>([]);

  const translate = useCallback(
    async (sourceLanguage: string, targetLanguages: string[]) => {
      const currentValues = control.getValues();
      const contents = currentValues.contents as Partial<ArrayField<NodeContent, 'id'>>[];
      const sourceContent = contents.find((c) => c.languageId === sourceLanguage)!;
      let isUsingData = false;

      const translatableFields = {
        languageId: sourceLanguage,
        translationLanguageIds: targetLanguages,
        fields: {},
      };

      const sourceSlug = sourceContent.slug;

      if (sourceContent.text) {
        // @ts-ignore
        translatableFields.fields.text = sourceContent.text;
      }

      const keyValueFields: string[] = [];
      sectionFields
        .filter((sf) => !isSectionLink(sf.type) && sf.isLocalized && sf.isTranslatable)
        .forEach((sf) => {
          let fieldName = `data_${sf.apiName}`;
          // @ts-ignore
          let fieldValueRaw = sourceContent.data ? (sourceContent.data as Record<string, any>)[sf.apiName!] : '';

          // TODO: refactor on backend, used for connections
          if (!fieldValueRaw) {
            // @ts-ignore
            const { data } = sourceContent;

            if (data) {
              isUsingData = true;
              fieldName = `data_${sf.apiName}`;
              fieldValueRaw = data ? (data as Record<string, any>)[sf.apiName!] : '';
            }
          }

          let fieldValue;

          if (typeof fieldValueRaw === 'object') {
            // fieldValue = JSON.stringify(fieldValueRaw);
            keyValueFields.push(fieldName);

            for (let i = 0; i < fieldValueRaw.length; i += 1) {
              // @ts-ignore
              translatableFields.fields[`${fieldName}||${i}||name`] = fieldValueRaw[i].name;
              // @ts-ignore
              translatableFields.fields[`${fieldName}||${i}||value`] = fieldValueRaw[i].value;
            }
          } else {
            fieldValue = (fieldValueRaw || '').toString();
            // @ts-ignore
            translatableFields.fields[fieldName] = fieldValue;
          }
        });

      const result = await fetchTranslations(translatableFields);

      Object.keys(result.translations).forEach((languageId) => {
        keyValueFields.forEach((kvf) => {
          let i = 0;
          result.translations[languageId][kvf] = [];

          while (result.translations[languageId][`${kvf}||${i}||name`]) {
            const kvTranslation = {
              name: result.translations[languageId][`${kvf}||${i}||name`].replace(/ & nbsp; /g, ' '),
              value: result.translations[languageId][`${kvf}||${i}||value`].replace(/ & nbsp; /g, ' '),
            };

            result.translations[languageId][kvf].push(kvTranslation);
            i += 1;
          }
        });
      });

      const newQueue: [string, any][] = [];
      Object.keys(result.translations).forEach((languageId) => {
        const index = findIndex(contents, { languageId });

        if (!!sourceSlug && !contents[index].slug) {
          newQueue.push([`contents[${index}].slug`, sourceSlug]);
        }

        Object.keys(result.translations[languageId]).forEach((field) => {
          if (field.indexOf('||') > -1) {
            return;
          }

          let newValue = result.translations[languageId][field] || '';
          if (typeof newValue === 'string') {
            newValue = newValue.replace(new RegExp(' [.]', 'g'), '.');
            newValue = newValue.replace(new RegExp(' [,]', 'g'), ',');
            newValue = newValue.replace(new RegExp(' [!]', 'g'), '!');
            newValue = newValue.replace(new RegExp(' [?]', 'g'), '?');
            newValue = newValue.replace(new RegExp(' [:]', 'g'), ':');
            newValue = newValue.replace(new RegExp(' [;]', 'g'), ';');
            // eslint-disable-next-line
            newValue = newValue.replace(new RegExp('  ', 'g'), ' ');
            newValue = newValue.replace(new RegExp(' [)]', 'g'), ')');
          }

          if (field.startsWith('data_')) {
            newQueue.push([`contents[${index}].data.${field.replace('data_', '')}`, newValue]);
          } else if (field.startsWith('data_')) {
            newQueue.push([`contents[${index}].data.${field.replace('data_', '')}`, newValue]);
          } else {
            newQueue.push([`contents[${index}].${field}`, newValue]);
          }
        });
      });
      sectionFields
        .filter((sf) => sf.isLocalized && !sf.isTranslatable)
        .forEach((sf) => {
          const value = cloneDeep(sourceContent.data?.[sf.apiName!]);
          targetLanguages.forEach((languageId) => {
            const index = findIndex(contents, { languageId });
            if (isUsingData) {
              newQueue.push([`contents[${index}].data.${sf.apiName!}`, value]);
            } else {
              newQueue.push([`contents[${index}].data.${sf.apiName!}`, value]);
            }
          });
        });

      setQueue(newQueue);
    },
    [control, sectionFields],
  );

  // react-hook-form doesn't support updating everything at once for some reason
  // so in this case we're using a simple queue to run one update at a time
  useEffect(() => {
    if (queue.length > 0) {
      const [[key, value], ...newQueue] = queue;
      control.setValue(key, value);
      setQueue(newQueue);
    }
  }, [control, queue]);

  return {
    translate,
  };
}
