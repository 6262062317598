import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFieldArray, UseFormMethods } from 'react-hook-form';
import { Checkbox, Fieldset, InputHook, isTouched, SelectHook, useAutoSlug } from 'src/common';
import { SectionFieldOption, SectionFieldType, useSections } from 'src/services/sections';
import { SECTION_FIELD_TYPE_OPTIONS } from 'src/services/sections/const';

type SectionFieldFormProps = {
  formMethods: UseFormMethods<any>;
  apiNamePrefix?: string;
  isEditing?: boolean;
};

export function SectionFieldForm({ formMethods, isEditing = false, apiNamePrefix = '' }: SectionFieldFormProps) {
  const { control, formState, register, watch, setValue } = formMethods;

  const {
    fields: options,
    append,
    remove,
  } = useFieldArray<SectionFieldOption>({
    control,
    name: 'options',
  });
  const appendFieldOption = useCallback(() => {
    const maxValue = options.reduce((max, option) => Math.max(max, Number(option.value) || 0), 0);
    append({ value: (maxValue + 1).toString(), label: '' });
  }, [append, options]);

  const watchType = watch('type');
  const isSectionLink = [
    SectionFieldType.SectionLink.toString(),
    SectionFieldType.SectionMultiLink.toString(),
  ].includes(watchType?.toString() || '');

  const { sections } = useSections();
  const sectionOptions = useMemo(
    () =>
      sections?.items?.map((si) => ({
        value: si.id,
        label: si.name,
      })),
    [sections?.items],
  );

  useAutoSlug({ control, formState, baseField: 'name', targetField: 'apiName', prefix: apiNamePrefix });

  useEffect(() => {
    if (isSectionLink) {
      setValue('isTranslatable', false);
    }
  }, [watchType, isSectionLink, setValue]);

  return (
    <div className="modal-card-body content">
      <div className="columns is-multiline">
        <div className="column is-full">
          <Controller
            control={control}
            name="name"
            defaultValue=""
            as={
              <InputHook
                label="Name"
                id="field-name"
                error={formState.errors.name?.message}
                touched={isTouched(formState, 'name')}
              />
            }
            data-qa="section-form-name"
          />
        </div>
        <div className="column is-half">
          <Controller
            control={control}
            name="apiName"
            defaultValue=""
            as={
              <InputHook
                label="API Name"
                id="field-apiName"
                error={formState.errors.apiName?.message}
                touched={isTouched(formState, 'apiName')}
              />
            }
            data-qa="section-form-api-name"
          />
        </div>
        <div className="column is-half">
          {isEditing ? (
            <>
              <input type="hidden" ref={register} name="type" />
              <SelectHook disabled label="Type" options={SECTION_FIELD_TYPE_OPTIONS} value={watchType} />
            </>
          ) : (
            <SelectHook
              ref={register}
              name="type"
              label="Type"
              id="field-type"
              options={SECTION_FIELD_TYPE_OPTIONS}
              error={formState.errors.type?.message}
              touched={isTouched(formState, 'type')}
              data-qa="section-form-type"
            />
          )}
        </div>
        <div className="column is-half">
          <Checkbox
            ref={register}
            name="isRequired"
            label="Required"
            type="checkbox"
            id="field-isRequired"
            error={formState.errors.isRequired?.message}
            touched={isTouched(formState, 'isRequired')}
            dataQa="section-form-required"
          />
        </div>
        <div className="column is-half">
          <Checkbox
            ref={register}
            name="isDisplayedInList"
            label="Display in list"
            type="checkbox"
            id="field-isDisplayedInList"
            error={formState.errors.isDisplayedInList?.message}
            touched={isTouched(formState, 'isDisplayedInList')}
            dataQa="section-form-display-in-list"
          />
        </div>
        <div className="column is-half">
          <Checkbox
            ref={register}
            name="isLocalized"
            label="Localized"
            type="checkbox"
            id="field-isLocalized"
            error={formState.errors.isLocalized?.message}
            touched={isTouched(formState, 'isLocalized')}
            dataQa="section-form-localized"
          />
        </div>
        <div className="column is-half">
          <Checkbox
            ref={register}
            name="isTranslatable"
            label="Translatable"
            type="checkbox"
            id="field-isTranslatable"
            error={formState.errors.isTranslatable?.message}
            touched={isTouched(formState, 'isTranslatable')}
            dataQa="section-form-translatable"
            disabled={isSectionLink}
          />
        </div>
        {watchType?.toString() === SectionFieldType.File.toString() && (
          <div className="column is-half">
            <Checkbox
              ref={register}
              name="isPublic"
              label="Public"
              type="checkbox"
              id="field-isPublic"
              error={formState.errors.isPublic?.message}
              touched={isTouched(formState, 'isPublic')}
              dataQa="section-form-localized"
              defaultChecked
            />
          </div>
        )}

        {isSectionLink ? (
          <div className="column is-full">
            <SelectHook
              ref={register}
              name="linkedSectionId"
              label="Linked Section"
              id="field-linkedSectionId"
              options={sectionOptions}
              error={formState.errors.linkedSectionId?.message}
              touched={isTouched(formState, 'linkedSectionId')}
            />
          </div>
        ) : null}

        {(watchType?.toString() === SectionFieldType.SingleOption.toString() ||
          watchType?.toString() === SectionFieldType.MultiOption.toString()) && (
          <div className="column is-full">
            <Fieldset title="Options">
              {options.map((option, index) => (
                <div className="columns" key={option.id}>
                  <div className="column">
                    <Controller
                      control={control}
                      name={`options[${index}].value`}
                      defaultValue={option.value}
                      as={
                        <InputHook
                          label="Value"
                          id={`option-${index}-value`}
                          error={formState.errors.options?.[index]?.value?.message}
                          touched={isTouched(formState, `options[${index}].value`)}
                        />
                      }
                    />
                  </div>
                  <div className="column">
                    <Controller
                      control={control}
                      name={`options[${index}].label`}
                      defaultValue={option.label}
                      as={
                        <InputHook
                          label="Label"
                          id={`option-${index}-label`}
                          error={formState.errors.options?.[index]?.label?.message}
                          touched={isTouched(formState, `options[${index}].label`)}
                        />
                      }
                    />
                  </div>
                  <div className="column is-narrow">
                    <button type="button" className="button is-white has-text-danger" onClick={() => remove(index)}>
                      <span className="icon">
                        <i className="fas fa-trash" />
                      </span>
                    </button>
                  </div>
                </div>
              ))}
              <div className="buttons">
                <button type="button" className="button is-primary is-fullwidth" onClick={appendFieldOption}>
                  <span>Add option</span>
                  <span className="icon">
                    <i className="fal fa-plus" />
                  </span>
                </button>
              </div>
            </Fieldset>
          </div>
        )}
      </div>
    </div>
  );
}
