import { DevTool } from '@hookform/devtools';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { InputHook, isTouched, Modal, MultiInputHook } from 'src/common';
import { showToast } from 'src/common/util/show-toast';
import { KeyValueItem, KeyValueStore, useKeyValueItems } from 'src/services/key-values';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';

type ItemEditorModalProps = {
  item: KeyValueItem | null;
  store: KeyValueStore;
  onCancel: () => void;
};

export function ItemEditorModal({ item, onCancel, store }: ItemEditorModalProps) {
  const { addItem, updateItem } = useKeyValueItems(store.slug);
  const validationSchema = useMemo(() => {
    return yup.object<KeyValueItem>({
      key: yup.string().label(store.keyName).required(),
      value: yup.string().label(store.valueName).required(),
    });
  }, [store.keyName, store.valueName]);
  const { control, reset, handleSubmit, formState, errors, clearErrors } = useForm<KeyValueItem>({
    defaultValues: { key: '', value: '' },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const editingItem = !!item?.id;

  const save = handleSubmit(async (values) => {
    const data = values;
    const result = !editingItem ? await addItem(data.key, data.value) : await updateItem(data.key, data.value);

    if (result.ok) {
      showToast('success', `Item ${editingItem ? 'updated' : 'created'} successfully`);

      onCancel();
    } else {
      showToast('error', 'Unable to save item');
    }
  });

  useEffect(() => {
    if (item) {
      reset(item);
    }
    clearErrors();
  }, [item, reset, clearErrors]);

  return (
    <Modal visible={!!item} onClickBackground={onCancel}>
      <form onSubmit={save}>
        <div className="modal-card-head">
          <p className="modal-card-title">{editingItem ? 'Edit item' : 'Add item'}</p>
          <button type="button" className="delete" onClick={onCancel} />
        </div>
        <div className="modal-card-body content">
          <div className="columns is-multiline">
            <div className="column is-full">
              <Controller
                control={control}
                name="key"
                as={<InputHook />}
                label={item?.id ? `${store.keyName} (Cannot be changed)` : store.keyName}
                touched={isTouched(formState, 'key')}
                error={errors.key?.message}
                readOnly={!!item?.id}
              />
            </div>
            <div className="column is-full">
              <Controller
                control={control}
                name="value"
                as={<MultiInputHook />}
                label={store.valueName}
                touched={isTouched(formState, 'value')}
                error={errors.value?.message}
              />
            </div>
          </div>
        </div>
        <div className="modal-card-foot">
          <button type="reset" className="button" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="submit"
            className={clsx('button is-primary', {
              'is-loading': formState.isSubmitting,
            })}
            disabled={formState.isSubmitting || !formState.isValid}
          >
            Save
          </button>
        </div>
      </form>
      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </Modal>
  );
}
