import { transformData } from 'src/common';

import { Connection, ConnectionContent } from './types';

export const transformContent = (response: any): ConnectionContent => ({
  id: response.id,
  languageId: response.languageId,
  data: transformData(response.data),
});

export const transformConnection = (response: any): Connection => ({
  id: response.id,
  nodeId: response.nodeId,
  nodeName: response.nodeName,
  nodeImageUrl: response.nodeImageUrl,
  memberId: response.memberId,
  memberFirstName: response.memberFirstName,
  memberLastName: response.memberLastName,
  memberAvatarUrl: response.memberAvatarUrl,
  data: response.data,
  createdAt: response.createdAt,
  languages: response.languages || [],
  contents: (response.contents || []).map(transformContent),
});
