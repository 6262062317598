import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Provider } from 'react-redux';
import { Router } from '@reach/router';
import { hotjar } from 'react-hotjar';
import { PersistGate } from 'redux-persist/integration/react';

import { isValid } from './services/api';
import { configureStore } from './redux';
import { AuthActionTypes } from './services/auth/redux';
import App from './App';
import './styles/main.scss';

if (process.env.NODE_ENV === 'production') {
  hotjar.initialize(2112714, 6);
}

const { store, persistor } = configureStore();

axios.interceptors.request.use(
  (config) => {
    const { auth, project } = store.getState();
    if (isValid(auth)) {
      config.headers.Authorization = `Bearer ${auth.token}`;
    }

    if (isValid(project?.project)) {
      config.headers['project-id'] = project.project.id;
      config.headers['organization-id'] = project.project.organizationId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// when we receive 401 error, we sign out the logged in user
axios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response !== undefined && error.response.status === 401) {
      store.dispatch({ type: AuthActionTypes.SIGN_OUT, payload: error });
    }

    return Promise.reject(error);
  },
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <App path="*" />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
