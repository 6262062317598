import clsx from 'clsx';
import React, { useMemo, useRef, useState } from 'react';
import { ImageMediaData } from 'src/common/types';
import { createImageMediaData, getFileInputValue } from 'src/common/util';
import { showToast } from 'src/common/util/show-toast';
import { addMediaImage } from 'src/services/media';

type MainImageProps = {
  value: ImageMediaData[];
  onChange: (value: ImageMediaData[]) => any;
} & JSX.IntrinsicElements['input'];

export function MainImage({ value, id, onChange, name, ...props }: MainImageProps) {
  const mainImage = useMemo(() => value?.find((image) => image.isMain), [value]);
  const ref = useRef<HTMLInputElement>(null!);
  const [isUploading, setIsUploading] = useState(false);

  const handleImageChange = async (file: File | File[] | null) => {
    if (file && !Array.isArray(file)) {
      setIsUploading(true);

      const fileRes = await addMediaImage(file);

      if (fileRes.ok) {
        const result = createImageMediaData(fileRes);
        result.isMain = true;
        onChange!([...(value || []), result]);
      } else {
        showToast('error', 'Error uploading image, try again');
      }

      setIsUploading(false);
    } else {
      onChange!([...(value || [])]);
    }
    if (ref.current) {
      ref.current.value = '';
    }
  };

  function removeCurrentImage() {
    const index = value?.indexOf(mainImage!);
    const removed = value[index];
    const newValue = (value || []).slice();
    newValue.splice(index, 1);

    if (removed.isMain && newValue.length > 0) {
      newValue[0].isMain = true;
    }

    onChange!(newValue);
  }

  return !value?.length || !mainImage ? (
    <label htmlFor={id} className={clsx('button is-outlined main-image-label', isUploading ? 'is-loading' : null)}>
      <span className={clsx('icon', !isUploading ? 'has-text-primary' : null)}>
        <i className="far fa-plus" />
      </span>
      <span>Add Image</span>
      <input
        type="file"
        id={id}
        ref={ref}
        accept="image/*"
        disabled={isUploading}
        {...props}
        onChange={() => handleImageChange(getFileInputValue(ref))}
      />
    </label>
  ) : (
    <figure className="image main-image">
      <button type="button" className="button is-white is-outlined" onClick={removeCurrentImage}>
        <span className="icon">
          <i className="fal fa-trash" />
        </span>
      </button>
      <img src={mainImage.url || ''} alt="" />
    </figure>
  );
}
