import { find } from 'lodash';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { FormNode } from 'src/modules/nodes/types';
import { CollectionItem } from 'src/services/nodes';
import { Section } from 'src/services/sections';
import { CollectionsDraggableList } from '../components';
import { LocalizableTabDefinition } from '../components/form/RichTextEditorTabs/RichTextEditorTabs';
import { addParamsToLink } from './add-params-to-link';

type GenerateSubsectionsTabsOptions = {
  subsections?: Record<string, CollectionItem[]>;
  sections: Section[];
  formMethods: UseFormMethods<FormNode>;
  currentSectionSlug: string;
  nodeId?: number;
};
export function generateSubsectionsTabs({
  subsections = {},
  sections,
  formMethods,
  currentSectionSlug,
  nodeId,
}: GenerateSubsectionsTabsOptions): LocalizableTabDefinition[] {
  return Object.keys(subsections).map((sectionSlug) => {
    const section = find(sections, { slug: sectionSlug })!;
    return {
      label: section.name,
      content: (
        <CollectionsDraggableList
          {...formMethods}
          optionsSectionId={section.id!}
          name={`subsections.${sectionSlug}`}
          createItemPath={
            nodeId
              ? addParamsToLink(`/nodes/${section.slug}/new`, {
                  returnTo: [currentSectionSlug, nodeId],
                  richEditorTab: section.slug,
                })
              : undefined
          }
        />
      ),
      id: section.slug,
    };
  });
}
