import { Link, RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { LayoutWithNavbar } from 'src/common';
import { Notifications, OrganizationCard } from 'src/common/components';
import { useOrganizations } from 'src/services/organizations';
import { isLoading, isValid } from 'src/services/api';

type DashboardProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Dashboard(_: DashboardProps) {
  const { organizations, loadOrganizations } = useOrganizations();

  // Refresh organizations every time on mount
  useEffect(() => {
    if (!isLoading(organizations)) {
      loadOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isValid(organizations)) return null;

  return (
    <LayoutWithNavbar
      breadcrumbs={[
        {
          label: 'SiteSails',
          href: '/',
        },
        {
          label: 'Organizations',
          href: './',
        },
      ]}
    >
      <div className="table-container" data-qa="dashboard">
        <Notifications />
        <div className="buttons">
          <Link to="/organizations/new" className="button is-outlined" data-qa="create-organization-button">
            <span className="icon has-text-info">
              <i className="fal fa-plus" />
            </span>
            <span>Create organization</span>
          </Link>
        </div>
        <div className="page-blocks-wrapper dashboard-card">
          {organizations.items.map((organization) => (
            <OrganizationCard key={organization.id} organization={organization} />
          ))}
        </div>
      </div>
    </LayoutWithNavbar>
  );
}
