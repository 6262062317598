import { get } from 'lodash';
import React from 'react';
import { Control, Controller, FormState } from 'react-hook-form';
import { SectionField, SectionFieldType } from 'src/services/sections';
import { ImageGallery } from '../components';
import { VideoGallery } from '../components/form/VideoGallery';
import { KeyValueEditor } from '../components/form/KeyValueEditor';
import { LocalizableTabDefinition } from '../components/form/RichTextEditorTabs/RichTextEditorTabs';
import { TextArrayEditor } from '../components/form/TextArrayEditor';

type GenerateCustomFieldsTabsOptions<T> = {
  sectionFields?: SectionField[];
  nestedName: string | null;
  control: Control;
  formState: FormState<T>;
  defaultValues?: Record<string, any>;
  localized?: false | string;
};

export function generateCustomFieldsTabs<T>({
  sectionFields = [],
  control,
  formState,
  nestedName,
  defaultValues = {},
  localized = false,
}: GenerateCustomFieldsTabsOptions<T>) {
  return sectionFields
    .map<LocalizableTabDefinition | null>((s) => {
      const name = `${nestedName}.${s.apiName}`;
      const id = localized ? s.apiName : name;
      const defaultValue = defaultValues[s.apiName!];
      const error: string | undefined = get(formState.errors, name)?.message;
      const touched: boolean = get(formState.touched, name);
      const commonProps = {
        id: s.id?.toString(),
        error,
        touched,
      };

      switch (s.type) {
        case SectionFieldType.VideoGallery:
          return {
            content: (
              <Controller
                control={control}
                name={name}
                defaultValue={defaultValue || null}
                render={({ ref, ...props }) => <VideoGallery {...props} id={s.id?.toString()} />}
              />
            ),
            id,
            label: s.name,
            localized: !!localized,
            languageId: localized || undefined,
          };
        case SectionFieldType.ImageGallery:
          return {
            content: (
              <Controller
                control={control}
                name={name}
                defaultValue={defaultValue || null}
                render={({ ref, ...props }) => <ImageGallery {...props} id={s.id?.toString()} />}
              />
            ),
            id,
            label: s.name,
            localized: !!localized,
            languageId: localized || undefined,
          };
        case SectionFieldType.KeyValue:
          return {
            content: (
              <div className="container is-fluid">
                <Controller
                  control={control}
                  name={name}
                  defaultValue={defaultValue || []}
                  render={({ ref, ...props }) => <KeyValueEditor {...commonProps} {...props} />}
                />
              </div>
            ),
            id,
            label: s.name,
            localized: !!localized,
            languageId: localized || undefined,
          };
        case SectionFieldType.TextArray:
          return {
            content: (
              <div className="container is-fluid">
                <Controller
                  control={control}
                  name={name}
                  defaultValue={defaultValue || []}
                  render={({ ref, ...props }) => <TextArrayEditor {...commonProps} {...props} />}
                />
              </div>
            ),
            id,
            label: s.name,
            localized: !!localized,
            languageId: localized || undefined,
          };
        default:
          return null;
      }
    })
    .filter(Boolean) as LocalizableTabDefinition[];
}
