import React, {
  ChangeEventHandler,
  ElementType,
  Fragment,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { Link } from '@reach/router';
import { AnimatePresence, motion, Variants } from 'framer-motion';

import { TranslationProps } from 'src/services/integrations';
import { Section } from 'src/services/sections';
import { InputHook } from './form/InputHook';

import { FADE_IN_VARIANTS } from '../const';
import { LanguageTabs, LanguageTabsProps } from './LanguageTabs';
import { Breadcrumb } from '../types';
import { TranslationModal } from './TranslationModal';
import { usePrevSearchOnMount } from '..';
import { CollectionAddModal } from './CollectionAddModal';

const searchBarVariants: Variants = {
  collapsed: {
    width: 0,
    transition: {
      duration: 0.25,
    },
  },
  expanded: {
    width: '18.75rem',
    transition: {
      duration: 0.5,
    },
  },
};

type PropsWithAs<As extends ElementType = ElementType> = PropsWithChildren<{
  as?: As;
}>;

interface LayoutWithNavbarProps<T> extends HTMLAttributes<HTMLElement>, PropsWithAs {
  breadcrumbs?: Breadcrumb[];
  backHref?: string;
  onSearch?: (value: string) => any;
  searchValue?: string;
  endButtons?: ReactNode;
  searchInputProps?: JSX.IntrinsicElements['input'];
  languageProps?: LanguageTabsProps<T>;
  translationProps?: TranslationProps;
  'data-qa'?: string;
  previewProps?: {
    mainSection: Section;
    subSection: Section;
    language: string;
    nodeId?: number;
    nodeSlug?: string;
  };
  collectionAddProps?: {
    nodeId?: number;
    nodeSection: Section;
    sections: Section[];
  };
  onCollectionAdd?: () => void;
}

export function LayoutWithNavbar<T>({
  children,
  breadcrumbs,
  backHref,
  onSearch,
  endButtons,
  searchValue,
  as,
  searchInputProps,
  languageProps,
  translationProps,
  previewProps,
  collectionAddProps,
  'data-qa': dataQa,
  ...rest
}: LayoutWithNavbarProps<T>) {
  const [searchVisible, setSearchVisible] = useState(false);
  const [translationVisible, setTranslationVisible] = useState(false);
  const [collectionAddVisible, setCollectionAddVisible] = useState(false);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const hasBreadcrumbs = breadcrumbs && breadcrumbs.length > 0;
  const hasStart = hasBreadcrumbs || backHref || onSearch;
  const hasEnd = !!endButtons || !!previewProps;

  function toggleSearch() {
    if (!searchVisible) {
      setSearchVisible(true);
    } else {
      setSearchVisible(false);
      onSearch!('');
    }
  }

  const onChangeSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    onSearch!(event.target.value);
  };

  function onSearchInputBlur() {
    if (searchVisible && !searchValue?.trim()) {
      setSearchVisible(false);
    }
  }
  usePrevSearchOnMount(onSearch, setSearchVisible);

  useEffect(() => {
    if (searchVisible && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchVisible]);

  const Component = as || Fragment;

  return (
    <>
      <Component {...rest}>
        <nav className="navbar is-spaced navbar-layout">
          <div className="navbar-menu" data-qa={dataQa}>
            {hasStart ? (
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  className="navbar-start"
                  variants={FADE_IN_VARIANTS}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  {backHref ? (
                    <div className="navbar-item">
                      <Link to={backHref} className="button is-light has-text-link is-rounded">
                        <span className="icon">
                          <i className="fal fa-arrow-left" />
                        </span>
                      </Link>
                    </div>
                  ) : null}
                  {hasBreadcrumbs ? (
                    <div className="navbar-item">
                      <div className="breadcrumb">
                        <ul>
                          {breadcrumbs!.map(({ label, href }, index) => (
                            <li
                              className={clsx({
                                'is-active': index === breadcrumbs!.length - 1,
                              })}
                              key={`${label}${href}`}
                              title={label}
                            >
                              <Link to={href}>{label}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ) : null}
                  {languageProps ? (
                    <div className="navbar-item">
                      <LanguageTabs {...languageProps} />
                    </div>
                  ) : null}
                  {translationProps ? (
                    <div className="navbar-item">
                      <button
                        type="button"
                        className="button is-outlined button-translate"
                        onClick={() => setTranslationVisible(true)}
                      >
                        <span className="icon has-text-primary mr-2">
                          <i className="far fa-language" />
                        </span>
                        <span>Translate</span>
                      </button>
                    </div>
                  ) : null}
                  {onSearch ? (
                    <div className="navbar-item search-bar">
                      {!searchVisible ? (
                        <button className="button is-light has-text-primary is-rounded" onClick={toggleSearch}>
                          <span className="icon">
                            <i className="fal fa-search" />
                          </span>
                        </button>
                      ) : (
                        <span className="icon expanded-search-icon">
                          <i className="fal fa-search" />
                        </span>
                      )}
                      <motion.div
                        initial="collapsed"
                        animate={searchVisible ? 'expanded' : 'collapsed'}
                        variants={searchBarVariants}
                        className="search-input-wrapper"
                      >
                        <InputHook
                          value={searchValue}
                          onChange={onChangeSearch}
                          hideHelper
                          rounded
                          onBlur={onSearchInputBlur}
                          {...searchInputProps}
                          ref={searchInputRef}
                        />
                        <button type="button" className="cancel-search button is-light is-rounded">
                          <span className="icon has-text-ui-60">
                            <i className="fal fa-times" onClick={toggleSearch} />
                          </span>
                        </button>
                      </motion.div>
                    </div>
                  ) : null}
                </motion.div>
              </AnimatePresence>
            ) : null}
            {hasEnd ? (
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  className="navbar-end"
                  variants={FADE_IN_VARIANTS}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  {collectionAddProps?.nodeId ? (
                    <div className="navbar-item">
                      {/* TODO: add tooltip 'No preview url defined for section' here if getPreviewUrl() == null */}
                      <a
                        className="button is-light has-text-link is-rounded"
                        onClick={() => setCollectionAddVisible(!collectionAddVisible)}
                      >
                        <span className="icon">
                          <i className="far fa-list-ol" />
                        </span>
                      </a>
                    </div>
                  ) : null}
                  {endButtons}
                </motion.div>
              </AnimatePresence>
            ) : null}
          </div>
        </nav>
        <div className="content-wrapper">{children}</div>
      </Component>
      {translationProps ? (
        <TranslationModal
          availableLanguages={translationProps.selectedLanguages}
          onSubmit={translationProps.onTranslate}
          visible={translationVisible}
          onClose={() => setTranslationVisible(false)}
        />
      ) : null}
      {collectionAddProps ? (
        <CollectionAddModal
          nodeId={collectionAddProps.nodeId}
          nodeSection={collectionAddProps.nodeSection}
          sections={collectionAddProps.sections}
          visible={collectionAddVisible}
          onClose={() => setCollectionAddVisible(false)}
        />
      ) : null}
    </>
  );
}
