import { combineReducers } from 'redux';

import { chainReducers, createApiReducer, INITIAL_API_STATE } from 'src/services/api';

import { transformTheme } from '../transformations';
import { ThemeActionTypes } from './actions';

export const themesReducer = chainReducers(
  INITIAL_API_STATE,
  createApiReducer(
    [ThemeActionTypes.CHANGE_THEME_START, ThemeActionTypes.FETCH_THEME_SUCCESS, ThemeActionTypes.CHANGE_THEME_FAILURE],
    (data) => ({ items: data.map(transformTheme) }),
    (data) => data,
  ),
);

export default combineReducers({
  themes: themesReducer,
});
