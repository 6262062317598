import _ from 'lodash';
import axios from 'axios';

import { api } from './config';
import { ApiConfig } from './types';

/**
 * Function which creates an API call for given api config
 */
export const createApiCall = (apiConfig: ApiConfig) => async () => {
  const hostname = apiConfig.hostname || api.hostname;
  const { path } = apiConfig;
  const method = _.get(apiConfig, 'method', 'GET');
  const contentType =
    _.includes(['POST', 'PUT', 'PATCH'], method) && !apiConfig.formData
      ? {
          'Content-Type': 'application/json',
        }
      : {};
  const passedHeaders = _.get(apiConfig, 'headers', {});
  const headers = {
    ...contentType,
    ...passedHeaders,
  };
  const axiosConfig = {
    method,
    headers,
    url: `${hostname}${path}`,
    timeout: 30000,
    data: apiConfig.formData || apiConfig.body,
  };

  return axios(axiosConfig);
};

/**
 * Performs an API call for given api config and returns a promise
 */
export const performApiCall = (apiConfig: ApiConfig) => {
  const apiCall = createApiCall(apiConfig);
  return apiCall();
};
