import { get, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { ArrayField, UseFormMethods } from 'react-hook-form';
import { SectionField, useSectionItems } from 'src/services/sections';
import { NodeContent } from 'src/services/nodes';
import { getInputForType, isTouched } from '../util';

type CustomFieldsProps = {
  sectionFields?: SectionField[];
  languages: Partial<ArrayField<NodeContent, 'id'>>[];
  currentLanguage: string | null;
} & UseFormMethods;

export function CustomFields({
  sectionFields = [],
  languages,
  currentLanguage,
  // react-hook-form props
  register,
  control,
  errors,
  formState,
  watch,
}: CustomFieldsProps) {
  const loadOptions = useSectionItems();

  const isMultiLanguage = languages.length > 0;
  const sortedFields = useMemo(() => sortBy(sectionFields, 'position'), [sectionFields]);
  // Non-localized fields will be disabled on non-primary languages
  const watchedValues = sortedFields.reduce<Record<string, any>>((watched, field) => {
    if (!field.isLocalized) {
      watched[field.apiName!] = watch(`data.${field.apiName!}`);
    }
    return watched;
  }, {});

  return (
    <>
      {languages.map(({ languageId, data: languageData }, langIndex) => {
        const languageSuffix = isMultiLanguage ? ` (${languageId?.toUpperCase()})` : '';

        return (
          <div key={languageId} className={languageId === currentLanguage ? '' : 'is-hidden'}>
            {sortedFields.map((sf) => {
              const name = sf.isLocalized ? `contents[${langIndex}].data.${sf.apiName}` : `data.${sf.apiName}`;
              const defaultValue = sf.isLocalized ? languageData?.[sf.apiName!] : watchedValues[sf.apiName];
              const error: string | undefined = get(errors, name)?.message;
              const touched = isTouched(formState, name);
              const commonProps = {
                id: `${sf.apiName}${sf.isLocalized ? `-${languageId}` : ''}`,
                label: sf.isLocalized ? `${sf.name}${languageSuffix}` : sf.name,
                error,
                touched,
                disabled: !sf.isLocalized && langIndex > 0,
              };

              return getInputForType(sf, {
                control,
                name,
                defaultValue,
                languageId: languageId!,
                watchedValues,
                commonProps,
                register,
                loadOptions,
              });
            })}
          </div>
        );
      })}
    </>
  );
}
