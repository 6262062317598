import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { LayoutWithNavbar } from 'src/common';
import { fetchOrganizationDetails } from 'src/services/organizations/api';
import { Organization } from 'src/services/organizations/types';
import { OrganizationCard } from 'src/common/components';

type OrganizationsProps = RouteComponentProps<{ id: string }>;

export function OrganizationDashboard({ id }: OrganizationsProps) {
  const [organization, setOrganization] = useState<Organization | null>(null);

  useEffect(() => {
    async function loadOrganization() {
      const result = await fetchOrganizationDetails(Number(id!));
      setOrganization(result);
    }
    loadOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return organization ? (
    <LayoutWithNavbar
      breadcrumbs={[
        {
          label: 'SiteSails',
          href: '/dashboard',
        },
        {
          label: 'Organizations',
          href: '/dashboard',
        },
        {
          label: organization.name || '',
          href: `organizations/${organization.id}`,
        },
      ]}
    >
      <div className="table-container">
        <div className="buttons">
          <Link
            to={`/organizations/${organization.id}/projects/new`}
            className="button is-outlined"
            data-qa="create-project-button"
          >
            <span className="icon has-text-info">
              <i className="fal fa-plus" />
            </span>
            <span>Create project</span>
          </Link>
        </div>
        <div className="page-blocks-wrapper dashboard-card">
          <OrganizationCard key={organization.id} organization={organization} />
        </div>
      </div>
    </LayoutWithNavbar>
  ) : null;
}
