import { get, kebabCase } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Control, FormState, useWatch } from 'react-hook-form';

type UseAutoSlugOptions = {
  control: Control<any>;
  formState: FormState<any>;
  baseField?: string;
  targetField?: string;
  prefix?: string;
};

export function useAutoSlug({
  control,
  formState,
  baseField = 'name',
  targetField = 'slug',
  prefix = '',
}: UseAutoSlugOptions) {
  const baseFieldValue = useWatch({ defaultValue: '', control, name: baseField });
  const generatedValue = useMemo(() => `${prefix}${kebabCase(baseFieldValue)}`, [baseFieldValue, prefix]);

  useEffect(() => {
    if (!get(formState.dirtyFields, targetField)) {
      control.setValue(targetField, generatedValue);
    }
  }, [control, formState.dirtyFields, generatedValue, targetField]);
}
