import { State } from 'src/redux';

export const getLanguages = (state: State) => {
  const allLanguages = state.languages.languages;
  const projectLanguages = state.project.project.languages;

  if (projectLanguages && projectLanguages.length > 0) {
    return { ...allLanguages, items: allLanguages.items?.filter((lang) => projectLanguages.indexOf(lang.id) > -1) };
  }

  return allLanguages;
};

export const getAllLanguages = (state: State) => state.languages.languages.items;
