import { RouteComponentProps } from '@reach/router';
import React from 'react';

import { updateInvitation, useCurrentUser } from 'src/services/users';

type NotificationsProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Notifications(_: NotificationsProps) {
  const { currentUser, refreshUser } = useCurrentUser();

  const invitations = currentUser?.invitations || [];

  const handleNotificationUpdate = async (invitationId: number, accepted: boolean) => {
    await updateInvitation(invitationId, accepted);
    await refreshUser();
  };

  if (invitations.length === 0) return null;

  return (
    <div>
      <div className="dashboard-notifications">
        {/* <div className="notification is-primary">
          Your Freelancer trial expires in 6 hours, make sure to upgrade to keep your projects!
          <button className="delete" />
        </div> */}
        {currentUser?.invitations?.map((invitation) => (
          <div key={invitation.id} className="notification notification-white">
            <div className="notification-info">
              You have been invited to <strong>{invitation.organizationName}</strong> by {invitation.senderName}
            </div>
            <div className="notification-buttons">
              <button className="pill-button accept" onClick={() => handleNotificationUpdate(invitation.id, true)}>
                Accept
              </button>
              <button className="pill-button reject" onClick={() => handleNotificationUpdate(invitation.id, false)}>
                Reject
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
