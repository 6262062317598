import urlcat from 'urlcat';

import { performApiCall } from '../api';
import { transformCollection } from './transformations';
import { Collection } from './types';

export const fetchCollectionDetails = async (
  slug: string,
  collectionSlug: string,
  collectionId: number,
): Promise<Collection> => {
  const res = await performApiCall({
    path: urlcat('/collections', '/:slug/:collectionSlug/:id', {
      slug,
      collectionSlug,
      id: collectionId,
      include: 'content,contents,images,collectionitems',
    }),
  });

  return Promise.resolve(transformCollection(res.data));
};

export const addNodeToCollection = async (
  slug: string,
  collectionSlug: string,
  collectionNodeSlug: string,
  nodeId: number,
  position = 'first',
): Promise<any> => {
  const res = await performApiCall({
    path: urlcat('/collections', '/:slug/:collectionSlug/:collectionNodeSlug/items', {
      slug,
      collectionSlug,
      collectionNodeSlug,
    }),
    method: 'POST',
    body: {
      nodeId,
      position: position === 'first' ? 0 : 1,
    },
  });

  if (!(res.status >= 200 && res.status < 300)) {
    return { ok: false };
  }
  return { ok: true };
};
