import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, isTouched } from 'src/common';
import { IntegrationsForm } from 'src/services/integrations';

type IntegrationSettingsProps = {
  slug: keyof IntegrationsForm;
  children: ReactNode;
};

export function IntegrationSettings({ slug, children }: IntegrationSettingsProps) {
  const { register, watch, formState } = useFormContext<IntegrationsForm>();
  const watchEnabled = watch(`${slug}.enabled`);

  return (
    <div className="columns is-multiline is-gapless">
      <div className="column is-full mb-4">
        <Checkbox
          ref={register}
          name={`${slug}.enabled`}
          id={`${slug}Enabled`}
          label="Enable"
          hideHelper
          touched={isTouched(formState, `${slug}.enabled`)}
        />
      </div>
      {watchEnabled ? children : null}
    </div>
  );
}
