import clsx from 'clsx';
import React, { ReactNode, useState } from 'react';

export type TabDefinition = {
  id: string | number;
  label: ReactNode;
  content: ReactNode;
  className?: string;
};

type TabsProps = {
  tabs: TabDefinition[];
  defaultTab?: string | number;
};

export function Tabs({ tabs, defaultTab }: TabsProps) {
  const [activeTab, setActiveTab] = useState(() => (tabs.find((t) => t.id === defaultTab) ? defaultTab : tabs[0].id));

  return (
    <>
      <div className="tabs">
        <ul>
          {tabs.map((tab) => (
            <li
              className={activeTab === tab.id ? 'is-active' : undefined}
              onClick={() => setActiveTab(tab.id)}
              key={tab.id}
            >
              <a>{tab.label}</a>
            </li>
          ))}
        </ul>
      </div>
      {tabs.map((tab) => (
        <div className={clsx(activeTab === tab.id ? undefined : 'is-hidden', tab.className)} key={tab.id}>
          {tab.content}
        </div>
      ))}
    </>
  );
}
