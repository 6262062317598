import { useCallback, useEffect } from 'react';

type UseLoadPagesWithSearchOptions = {
  search?: string;
  sortBy?: string;
  sortDirection?: string;
  prevSearchURLValue?: string;
  loadPages: (search: string, sortBy: string, sortDirection: string) => Promise<any>;
};

export function useLoadPagesWithSearch({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  prevSearchURLValue,
  loadPages,
}: UseLoadPagesWithSearchOptions) {
  type LoadPagesWithSearchOptions = {
    search?: string;
    sortBy?: string;
    sortDirection?: string;
  };
  const loadPagesWithSearch = useCallback(
    ({
      search: _search = search,
      sortBy: _sortBy = sortBy,
      sortDirection: _sortDirection = sortDirection,
    }: LoadPagesWithSearchOptions = {}) => {
      return loadPages(_search, _sortBy, _sortDirection);
    },
    [loadPages, search, sortBy, sortDirection],
  );

  useEffect(() => {
    if (!prevSearchURLValue) {
      loadPagesWithSearch({ search, sortBy, sortDirection });
    }
  }, [loadPagesWithSearch, prevSearchURLValue, search, sortBy, sortDirection]);

  return loadPagesWithSearch;
}
