import { transformProject } from '../project/transformations';
import { Organization, OrganizationUsage, Plan } from './types';

export const transformOrganizationPlan = (response: any): Plan => ({
  id: response.id,
  name: response.name.split(' ')[0], // remove yearly and monthly from name
  isFree: response.isFree,
  isMonthly: response.isMonthly,
  price: response.price,
});

export const transformOrganization = (response: any): Organization => ({
  id: response.id,
  name: response.name,
  plan: transformOrganizationPlan(response.plan),
  projects: (response.projects || []).map(transformProject),
  role: response.role,
  subscriptionId: response.subscriptionId,
  subscriptionBillingAddressCity: response.subscriptionBillingAddressCity,
  subscriptionBillingAddressCountry: response.subscriptionBillingAddressCountry,
  subscriptionBillingAddressFirstName: response.subscriptionBillingAddressFirstName,
  subscriptionBillingAddressLastName: response.subscriptionBillingAddressLastName,
  subscriptionBillingAddressLine1: response.subscriptionBillingAddressLine1,
  subscriptionBillingAddressState: response.subscriptionBillingAddressState,
  subscriptionBillingAddressZip: response.subscriptionBillingAddressZip,
  subscriptionCustomerCompany: response.subscriptionCustomerCompany,
  subscriptionCustomerEmail: response.subscriptionCustomerEmail,
  subscriptionCustomerFirstName: response.subscriptionCustomerFirstName,
  subscriptionCustomerLastName: response.subscriptionCustomerLastName,
  subscriptionCustomerLocale: response.subscriptionCustomerLocale,
  subscriptionCustomerPhone: response.subscriptionCustomerPhone,
  subscriptionCustomerVatNumber: response.subscriptionCustomerVatNumber,
});

export const transformOrganizationUsage = (response: any): OrganizationUsage => ({
  storageUsage: response.storageUsage,
  storageTotal: response.storageTotal,
  usersCount: response.usersCount,
  usersTotal: response.usersTotal,
  adminsCount: response.adminsCount,
  adminsTotal: response.adminsTotal,
});
