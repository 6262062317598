import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchThemes, getThemes } from '../redux';

export const useThemes = () => {
  const dispatch = useDispatch();
  const themes = useSelector(getThemes);

  useEffect(() => {
    dispatch(fetchThemes());
  }, [dispatch]);

  return { themes };
};
