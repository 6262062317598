import { createApiReducer, chainReducers, INITIAL_API_STATUS, INITIAL_API_STATE } from 'src/services/api';

import { AuthState } from './types';
import { AuthActionTypes } from './actions';

const initialState: AuthState = {
  token: null,
  _status: INITIAL_API_STATUS,
};

const auth = createApiReducer(
  [AuthActionTypes.SIGN_IN_START, AuthActionTypes.SIGN_IN_SUCCESS, AuthActionTypes.SIGN_IN_FAILURE],
  (data) => ({ token: data.token }),
  (data) => data.error.message,
);

const signOut = createApiReducer(
  ['', AuthActionTypes.SIGN_OUT, ''],
  () => INITIAL_API_STATE,
  () => INITIAL_API_STATE,
);

export default chainReducers(initialState, auth, signOut);
