import { parseISO } from 'src/common';

import { ProductTransaction } from './types';

export const transformProductTransaction = (response: any): ProductTransaction => ({
  id: response.id,
  productId: response.productId,
  productName: response.productName,
  customerEmail: response.customerEmail,
  customerFirstName: response.customerFirstName,
  customerLastName: response.customerLastName,
  purchaseDate: parseISO(response.purchaseDate),
  quantity: response.quantity,
  price: response.price,
  amount: response.amount,
});
