export const ROLES = {
  projectUser: 'projectuser',
};

export const ORGANIZATION_ROLES_OPTIONS = [
  { value: 'owner', label: 'Owner' },
  { value: 'admin', label: 'Admin' },
  { value: 'user', label: 'Content manager', isDefault: true },
  { value: 'projectuser', label: 'Project user', isNonSelectable: true },
];

export const PROJECT_ROLES_OPTIONS = [
  { value: 'admin', label: 'Admin' },
  { value: 'user', label: 'Content manager', isDefault: true },
];

export const ORGANIZATION_ADMIN_ROLES = ['owner', 'admin'];
export const PROJECT_ADMIN_ROLES = ['admin'];
