import { findIndex } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { UseFieldArrayMethods } from 'react-hook-form';
import { NodeContent } from 'src/services/nodes';

type UseNavbarLanguagesOptions = UseFieldArrayMethods<NodeContent>;

export function useNavbarLanguages({ fields, append, remove }: UseNavbarLanguagesOptions, loading: boolean) {
  const [activeTab, setActiveTab] = useState<string | null>(null);

  const addLanguage = useCallback(
    (languageId: string) => {
      if (fields && fields.find((e) => e.languageId === languageId)) {
        return;
      }

      append({
        languageId,
        slug: '',
      });
      setActiveTab(languageId);
    },
    [append, fields],
  );

  const removeLanguage = useCallback(
    (languageId: string) => {
      const index = findIndex(fields, { languageId });
      if (index < 0) return;

      remove(index);
      setActiveTab(fields[0].languageId!);
    },
    [fields, remove],
  );

  useEffect(() => {
    if (!activeTab && fields.length && !loading) {
      setActiveTab(fields[0].languageId!);
    }
  }, [fields, activeTab, loading]);

  return {
    fields,
    activeTab,
    setActiveTab,
    addLanguage,
    removeLanguage,
  };
}
