import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Dropdown } from './Dropdown';

type TableDropdownProps = {
  children: ReactNode;
  dataQa?: string;
} & JSX.IntrinsicElements['div'];

export function TableDropdown({ children, className, dataQa, ...props }: TableDropdownProps) {
  return (
    <div className={clsx('table-dropdown', className)} {...props}>
      <Dropdown
        align="right"
        renderTrigger={
          <button type="button" className="button is-white" data-qa={dataQa}>
            <span className="icon has-text-purple-light">
              <i className="fal fa-ellipsis-v" />
            </span>
          </button>
        }
      >
        <div className="dropdown-content">{children}</div>
      </Dropdown>
    </div>
  );
}
