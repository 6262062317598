import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useProject } from 'src/services/project';
import { ButtonCreate, LayoutWithNavbar, Table } from '../../common/components';
import { usePagesList } from './hooks';

type PagesListProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function PagesList(_: PagesListProps) {
  const { tableColumns, pagesWithChildren, setSearch, search } = usePagesList();

  const { project } = useProject();

  return (
    <LayoutWithNavbar
      breadcrumbs={[{ href: '/pages', label: 'Pages' }]}
      endButtons={<ButtonCreate isLinkVisible={!project.themeId}>New page</ButtonCreate>}
      onSearch={setSearch}
      searchValue={search}
      data-qa="pages-list-page"
    >
      <Table columns={tableColumns} data={pagesWithChildren} loading={false} />
    </LayoutWithNavbar>
  );
}
