import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Column, useTable } from 'react-table';
import clsx from 'clsx';

import { SectionFieldType, SectionField } from 'src/services/sections';
import { clone } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { VALIDATION_MESSAGES } from 'src/common/const';
import { Modal, TableBoolean, TableDropdown, TableHeading } from 'src/common/components';
import { createPortal } from 'react-dom';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import move from 'array-move';
import { SectionFieldForm } from './components/SectionFieldForm';

const validationSchema = yup.object({
  name: yup.string().required(VALIDATION_MESSAGES.required('Name')),
  type: yup.string().required(VALIDATION_MESSAGES.required('Type')),
  apiName: yup.string().required(VALIDATION_MESSAGES.required('API name')),
});

type SectionFieldsProps = {
  value: SectionField[];
  onChange: (value: SectionField[]) => void;
  onBlur: () => void;
  wrapper: MutableRefObject<Element>;
  canAddFields: boolean;
};

export function SectionFields({ value: fields = [], onChange, onBlur, wrapper, canAddFields }: SectionFieldsProps) {
  const [editingField, setEditingField] = useState<SectionField | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const formMethods = useForm<SectionField>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { formState, handleSubmit, reset } = formMethods;

  const hideEditor = useCallback(() => {
    reset({ options: [], apiName: '', name: '' });
    setEditorOpen(false);
  }, [reset]);

  const onEdit = useCallback(
    (field: SectionField, index: number) => {
      const cloneField = clone(field);
      cloneField.options = cloneField.options || [];
      setEditingField(cloneField);
      setEditingIndex(index);
      setEditorOpen(true);
      reset(cloneField);
    },
    [reset],
  );

  const onDelete = useCallback(
    (index: number) => {
      const newFields = fields.slice();
      newFields.splice(index, 1);
      onChange(newFields);
      onBlur();
    },
    [fields, onBlur, onChange],
  );

  const onAdd = useCallback(() => {
    setEditingField(null);
    setEditingIndex(null);
    setEditorOpen(true);
  }, []);

  const onCancel = useCallback(() => {
    setEditingField(null);
    setEditingIndex(null);
    hideEditor();
  }, [hideEditor]);

  const onSave = handleSubmit((values) => {
    const newEntry: SectionField = {
      ...(editingField || {}),
      ...values,
      type: Number(values.type),
      linkedSectionId: values.linkedSectionId ? Number(values.linkedSectionId) : undefined,
    };

    if (editingField && editingIndex !== null) {
      const newFields = fields.slice();
      newFields.splice(editingIndex, 1, newEntry);
      onChange(newFields);
    } else {
      onChange(fields.concat([newEntry]));
    }
    onBlur();
    hideEditor();
  });

  const tableColumns = useMemo<Column<SectionField>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value }) => SectionFieldType[value!],
      },
      {
        Header: 'API name',
        accessor: 'apiName',
      },
      {
        Header: <div className="has-text-centered">Required</div>,
        accessor: 'isRequired',
        Cell: ({ value }) => <TableBoolean state={value} />,
      },
      {
        Header: <div className="has-text-centered">Displayed in list</div>,
        accessor: 'isDisplayedInList',
        Cell: ({ value }) => <TableBoolean state={value} />,
      },
      {
        Header: <div className="has-text-centered">Localized</div>,
        accessor: 'isLocalized',
        Cell: ({ value }) => <TableBoolean state={value} />,
      },
      {
        Header: <div className="has-text-centered">Translatable</div>,
        accessor: 'isTranslatable',
        Cell: ({ value }) => <TableBoolean state={value} />,
      },
      {
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ row, rows }) => (
          <TableDropdown>
            <a className="dropdown-item" onClick={() => onEdit(row.original, rows.indexOf(row))}>
              Edit
            </a>
            <a className="dropdown-item" onClick={() => onDelete(rows.indexOf(row))}>
              Delete
            </a>
          </TableDropdown>
        ),
      },
    ],
    [onDelete, onEdit],
  );
  const tableInstance = useTable<SectionField>({
    columns: tableColumns,
    data: fields,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const onDragEnd = useCallback(
    (result: DropResult) => {
      onChange(move(fields, result.source.index, result.destination!.index));
    },
    [fields, onChange],
  );

  return wrapper.current
    ? createPortal(
        <div className="container is-fluid">
          <button
            type="button"
            className="button is-not-underlined is-outlined"
            onClick={onAdd}
            disabled={!canAddFields}
            data-qa="section-new-field"
          >
            <span className="icon has-text-blue">
              <i className="fal fa-plus" />
            </span>
            <span>New field</span>
          </button>
          {/* eslint-disable react/jsx-key */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="field" direction="vertical">
              {(provided) => (
                <div className="table-container" {...provided.droppableProps} ref={provided.innerRef}>
                  <table {...getTableProps()} className="table is-hoverable is-fullwidth">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <TableHeading {...column.getHeaderProps()} column={column}>
                              {column.render('Header')}
                            </TableHeading>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, rowIndex) => {
                        prepareRow(row);
                        const rowProps = row.getRowProps();
                        return (
                          <Draggable key={rowProps.key} draggableId={row.id} index={rowIndex}>
                            {(draggableProvided) => (
                              <tr
                                {...rowProps}
                                className="is-main-row"
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                              >
                                {row.cells.map((cell) => (
                                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                              </tr>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </tbody>
                  </table>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Modal visible={editorOpen} onClickBackground={onCancel}>
            <form onSubmit={onSave}>
              <div className="modal-card-head">
                <p className="modal-card-title">{editingField ? `Edit: ${editingField.name}` : 'Add field'}</p>
                <button type="button" className="delete" onClick={onCancel} />
              </div>
              <SectionFieldForm formMethods={formMethods} isEditing={!!editingField} />
              <div className="modal-card-foot">
                <button type="reset" className="button" onClick={onCancel}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className={clsx('button is-primary', {
                    'is-loading': formState.isSubmitting,
                  })}
                  disabled={formState.isSubmitting || !formState.isValid}
                  data-qa="section-fields-save"
                >
                  Save
                </button>
              </div>
            </form>
          </Modal>
        </div>,
        wrapper.current,
      )
    : null;
}
