import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCloudflareSettings,
  fetchCloudflareSettings,
  getCloudflareSettings,
  updateCloudflareSettings,
} from '../redux';
import { CloudflareSettings } from '../types';

export const useCloudflareSettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector(getCloudflareSettings);

  const loadSettings = useCallback(() => dispatch(fetchCloudflareSettings()), [dispatch]);

  const updateSettings = useCallback(
    (data: CloudflareSettings) => dispatch(updateCloudflareSettings(data)),
    [dispatch],
  );

  const deleteSettings = useCallback(() => dispatch(deleteCloudflareSettings()), [dispatch]);

  return {
    settings,
    loadSettings,
    updateSettings,
    deleteSettings,
  };
};
