import React, { ChangeEventHandler } from 'react';
import { set } from 'lodash';
import { InputHook } from './InputHook';

type TextEditorProps = {
  label?: string;
  name: string;
  value?: { value: string; position: number }[];
  onChange: (value: { value: string; position: number }[]) => void;
  onBlur: () => void;
};

export function TextArrayEditor({ name = 'text-array', label, value = [], onChange, onBlur }: TextEditorProps) {
  function append() {
    onChange(value.concat([{ value: '', position: value.length + 1 }]));
    onBlur();
  }

  function remove(index: number) {
    const newValue = value.slice();
    newValue.splice(index, 1);
    // eslint-disable-next-line no-return-assign
    newValue.map((v, i) => (v.position = i + 1));
    onChange(newValue);
    onBlur();
  }

  const onChangeInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = value.slice();
    set(newValue, event.target.name, event.target.value);
    onChange(newValue);
    onBlur();
  };

  let parsedValue = value;

  if (typeof parsedValue === 'string') {
    parsedValue = [];
  }

  return (
    <>
      {parsedValue &&
        parsedValue.map((field, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="columns" key={index}>
            <div className="column">
              <InputHook
                name={`[${index}].value`}
                value={field.value}
                label={`${label} #${index + 1}`}
                id={`${name}-${index}-value`}
                onChange={onChangeInput}
              />
            </div>
            <div className="column is-narrow">
              <button type="button" className="button is-white has-text-grey" onClick={() => remove(index)}>
                <span className="icon">
                  <i className="fal fa-trash" />
                </span>
              </button>
            </div>
          </div>
        ))}
      <div className="buttons">
        <button type="button" className="button is-outlined" onClick={append}>
          <span className="icon">
            <i className="fal fa-plus" />
          </span>
          <span>Add {label}</span>
        </button>
      </div>
    </>
  );
}
