import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  impersonate,
  getAuth,
  signIn,
  signOut,
  register as apiRegister,
  refreshUser as apiRefreshUser,
} from '../redux';

export function useAuth() {
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);

  const logIn = useCallback(
    (email: string, password: string) => {
      return dispatch(signIn(email, password));
    },
    [dispatch],
  );

  const logInAs = useCallback(
    (userId: number) => {
      return dispatch(impersonate(userId));
    },
    [dispatch],
  );

  const register = useCallback(
    (email: string, password: string, firstName: string, lastName: string) => {
      return dispatch(apiRegister(email, password, firstName, lastName));
    },
    [dispatch],
  );

  const logOut = useCallback(() => {
    return dispatch(signOut());
  }, [dispatch]);

  const refreshUser = useCallback(() => {
    return dispatch(apiRefreshUser());
  }, [dispatch]);

  return {
    auth,
    logIn,
    logOut,
    register,
    refreshUser,
    logInAs,
  };
}
