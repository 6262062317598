import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_TABLE_ROWS } from 'src/common/const';
import { performApiCall } from 'src/services/api/apiCall';
import {
  getMembers,
  fetchMembers,
  deleteMember as deleteMemberAction,
  addMember,
  updateMember as updateMemberAction,
} from '../redux';
import { User } from '../types';

export function useMembers() {
  const dispatch = useDispatch();
  const members = useSelector(getMembers);

  const loadMembers = useCallback(
    (page = 1, search?: string) => {
      return dispatch(fetchMembers(page, MAX_TABLE_ROWS, { search }));
    },
    [dispatch],
  );

  const deleteMember = useCallback(
    (id: number) => {
      return dispatch(deleteMemberAction(id));
    },
    [dispatch],
  );

  const createMember = useCallback(
    (data: User) => {
      return dispatch(addMember(data));
    },
    [dispatch],
  );

  const updateMember = useCallback(
    (data: User) => {
      return dispatch(updateMemberAction(data));
    },
    [dispatch],
  );

  const updateMemberData = useCallback((data: User) => {
    return performApiCall({
      path: `/members/${data.id}/data`,
      body: data.data,
      method: 'POST',
    });
  }, []);

  const updateMemberAvatar = useCallback(async (data: User) => {
    let res: AxiosResponse<any> | undefined;
    if (data.avatarUrl instanceof File) {
      const formData = new FormData();
      formData.append('file', data.avatarUrl);

      res = await performApiCall({
        path: `/members/${data.id}/avatar`,
        method: 'POST',
        formData,
      });
    } else if (!data.avatarUrl) {
      res = await performApiCall({
        path: `/members/${data.id}/avatar`,
        method: 'DELETE',
      });
    }

    if (!!res && !(res.status >= 200 && res.status < 300)) {
      return { ok: false };
    }
    return { ok: true };
  }, []);

  return { members, deleteMember, loadMembers, createMember, updateMember, updateMemberData, updateMemberAvatar };
}
