import { useDispatch, useSelector } from 'react-redux';
import { MAX_TABLE_ROWS } from 'src/common/const';
import { fetchTransactions, getTransactions } from '../redux';

export function useTransactions() {
  const dispatch = useDispatch();
  const transactions = useSelector(getTransactions);

  function loadTransactions(page = 1, sectionId: number, search?: string) {
    return dispatch(fetchTransactions(page, MAX_TABLE_ROWS, { sectionId, search }));
  }

  return {
    transactions,
    loadTransactions,
  };
}
