import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { uploadVideo } from 'src/common/util/cloudflare-stream';
import clsx from 'clsx';
import { VideoMediaData } from 'src/common/types';
import { useCloudflareSettings } from 'src/services/integrations/hooks';
import { isEmpty, isLoading } from 'src/services/api';
import { addMedia } from 'src/services/media';
import { createVideoMediaData } from 'src/common/util/create-video-media-data';
import { getFileInputValue } from 'src/common/util';

type VideoAutoUploaderProps = {
  label?: ReactNode;
  value?: VideoMediaData;
  onChange?: (value: VideoMediaData | null) => any;
} & Omit<JSX.IntrinsicElements['input'], 'value'>;

export function VideoAutoUploaderHook({ label, id, value, onChange, disabled, ...props }: VideoAutoUploaderProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [thumb, setThumb] = useState<VideoMediaData | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { settings: cloudflareSettings, loadSettings: loadCloudflareSettings } = useCloudflareSettings();

  useEffect(() => {
    if (isEmpty(cloudflareSettings) && !isLoading(cloudflareSettings)) {
      loadCloudflareSettings();
    }
  }, [cloudflareSettings, loadCloudflareSettings]);

  const handleVideoChange = async (file: File | File[] | null) => {
    if (file) {
      try {
        setIsUploading(true);

        const fileRes = await uploadVideo(file, cloudflareSettings.settings);

        if (fileRes.ok) {
          await addMedia(file, false);
          const res = createVideoMediaData(fileRes);
          onChange!(res);
          return res;
        }
      } finally {
        setIsUploading(false);
      }
    }

    onChange!(null);
    return null;
  };

  useEffect(() => {
    async function load() {
      if (value) {
        setThumb(value);
      } else {
        setThumb(null);
        if (ref.current) {
          ref.current.value = '';
        }
      }
    }
    load();
  }, [value]);

  return (
    <div className="field has-floating-label image-uploader">
      <label className="label" htmlFor={id}>
        {label}
      </label>
      <div className="control">
        <div className="image-container">
          <label htmlFor={id} className={clsx('button upload is-light', isUploading ? 'is-loading' : null)}>
            <span className="icon is-small has-text-info">{!isUploading ? <i className="fal fa-plus" /> : null}</span>
            <span>Upload video</span>
          </label>
          {thumb && (
            <div className="thumb thumb-custom-data">
              <img src={thumb.thumbnail} alt="" />
              <div className="overlay">
                <button
                  type="button"
                  className="button delete"
                  onClick={() => handleVideoChange(null)}
                  disabled={disabled}
                >
                  <span className="icon">
                    <i className="fal fa-times" />
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <input
        type="file"
        id={id}
        ref={ref}
        accept="*"
        disabled={isUploading}
        {...props}
        onChange={() => handleVideoChange(getFileInputValue(ref))}
      />
    </div>
  );
}
