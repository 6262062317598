import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiState } from 'src/services/api';
import { addNode, deleteNode, fetchNodes, getNodes, Node, updateNode } from 'src/services/nodes';
import { SectionType, useSections } from 'src/services/sections';
import { PageNode } from '../types';

export function usePages() {
  const dispatch = useDispatch();
  const { sections } = useSections();
  const pagesSection = useMemo(() => sections.items.find((s) => s.type === SectionType.Pages), [sections.items]);
  const pages = useSelector(getNodes) as ApiState<PageNode>;

  const loadPages = useCallback(
    (search?: string, sortBy?: string, sortDirection?: string) => {
      return dispatch(
        fetchNodes(1, 9999, {
          search,
          sectionId: pagesSection?.id,
          sectionSlug: pagesSection?.slug,
          sortBy: sortBy || 'id',
          sortDirection: sortDirection || 'desc',
          include: 'content,contents,collectionitems,subsections,blocks,unpublished',
        }),
      );
    },
    [dispatch, pagesSection?.id, pagesSection?.slug],
  );

  const createPage = useCallback(
    (data: Node) => {
      return dispatch(addNode(data, { sectionSlug: 'pages' }));
    },
    [dispatch],
  );

  const updatePage = useCallback(
    (data: Node) => {
      return dispatch(updateNode(data, { sectionSlug: 'pages', id: data.id }));
    },
    [dispatch],
  );

  const deletePage = useCallback(
    (id: number) => {
      return dispatch(deleteNode(id, { sectionSlug: 'pages' }));
    },
    [dispatch],
  );

  return {
    pages,
    loadPages,
    createPage,
    updatePage,
    deletePage,
  };
}
