import { DevTool } from '@hookform/devtools';
import { RouteComponentProps } from '@reach/router';
import clsx from 'clsx';
import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Accordion,
  EditPageLayout,
  EditPageLoading,
  LayoutWithNavbar,
  MultiInputHook,
  SeoMetadataEditor,
  SeoMetadataList,
  Tabs,
  useNavbarLanguages,
  usePrevPageLink,
  useSeoMetadata,
} from 'src/common';
import { useTranslations } from 'src/services/integrations/hooks';
import { NodeBlockField } from 'src/services/nodes';
import { useProject } from 'src/services/project';
import { SectionType, useSections } from 'src/services/sections';
import { CommonData, PageBlocks } from './components';
import { usePageEditForm, usePageBlocks } from './hooks';

type PagesEditProps = RouteComponentProps<{ id: string }>;

export function PagesEdit({ id }: PagesEditProps) {
  const listLink = usePrevPageLink('/pages');
  const { save, watchSeoMetadata, methods, contents, loadingPage, editedPage } = usePageEditForm(listLink, id);
  const { closeEditor, editorOpen, openEditor } = useSeoMetadata();
  const languageProps = useNavbarLanguages(contents, loadingPage);

  const { formState, control } = methods;

  const pageBlocksProps = usePageBlocks();
  const { containerRef } = pageBlocksProps;

  const { sections } = useSections();
  const pagesSection = sections.items.filter((s) => s.type === SectionType.Pages)[0];
  const isPublishable = pagesSection?.isPublishable;

  const { project } = useProject();
  const translationEnabled = project.hasTranslations && contents.fields.length > 1;

  const { translate } = useTranslations(control, [
    {
      apiName: 'title',
      isLocalized: true,
      isTranslatable: true,
      name: 'Title',
    } as NodeBlockField,
    ...(editedPage?.blocks?.flatMap((block) => block.fields) || []),
  ]);

  return loadingPage && !formState.isSubmitting ? (
    <EditPageLoading />
  ) : (
    <>
      <LayoutWithNavbar
        backHref={listLink}
        breadcrumbs={[
          { label: 'Pages', href: listLink },
          { label: editedPage ? `Edit ${editedPage.data.title}` : 'New page', href: './' },
        ]}
        endButtons={
          <div className="navbar-item">
            <button
              type="submit"
              className={clsx('button is-primary', {
                'is-loading': formState.isSubmitting,
              })}
              data-qa="save-button"
            >
              <span className="icon">
                <i className="far fa-check has-text-secondary-light" />
              </span>
              <span>Save</span>
            </button>
          </div>
        }
        as="form"
        onSubmit={save}
        languageProps={languageProps}
        translationProps={
          translationEnabled
            ? {
                onTranslate: translate,
                selectedLanguages: contents.fields.map((content) => content.languageId!),
              }
            : undefined
        }
      >
        <EditPageLayout
          renderLeft={
            <>
              <Accordion
                disableShadow
                items={[
                  {
                    Header: 'Common',
                    id: 'common',
                    defaultExpanded: true,
                    content: (
                      <CommonData
                        methods={methods}
                        fields={contents.fields}
                        activeTab={languageProps.activeTab}
                        isPublishable={isPublishable}
                      />
                    ),
                  },
                  {
                    Header: 'SEO Metadata',
                    id: 'seo',
                    defaultExpanded: true,
                    content: <SeoMetadataList seoMetadata={watchSeoMetadata} onClick={openEditor} />,
                  },
                ]}
              />
            </>
          }
        >
          <Tabs
            tabs={[
              {
                label: 'Blocks',
                id: 'blocks',
                className: 'is-fullheight',
                content: <div className="page-blocks" ref={containerRef} />,
              },
              {
                label: 'Custom styling',
                id: 'customCss',
                content: (
                  <div className="container is-fluid">
                    <Controller
                      control={control}
                      name="customCSS"
                      defaultValue={editedPage?.data?.customCSS || ''}
                      as={<MultiInputHook id="css" style={{ minHeight: 500 }} />}
                    />
                  </div>
                ),
              },
            ]}
          />
        </EditPageLayout>
      </LayoutWithNavbar>
      <Controller
        control={control}
        name="seoMetadata"
        defaultValue={[]}
        render={({ ref, ...props }) => <SeoMetadataEditor {...props} onCancel={closeEditor} isOpen={editorOpen} />}
      />
      <Controller
        control={control}
        name="blocks"
        defaultValue={[]}
        render={({ ref, ...props }) => (
          <PageBlocks
            fields={contents.fields}
            activeTab={languageProps.activeTab}
            methods={methods}
            {...props}
            {...pageBlocksProps}
          />
        )}
      />

      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </>
  );
}
