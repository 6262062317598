import { combineReducers } from 'redux';
import { chainReducers, createApiReducer, INITIAL_API_STATUS } from 'src/services/api';
import { CloudflareActionTypes, MailchimpActionTypes, RenteonActionTypes, StripeActionTypes } from './actions';

const INITIAL_INTEGRATION_STATE = {
  _status: INITIAL_API_STATUS,
  settings: {},
};

const fetchStripeReducer = createApiReducer(
  [
    StripeActionTypes.CHANGE_STRIPE_START,
    StripeActionTypes.FETCH_STRIPE_SUCCESS,
    StripeActionTypes.CHANGE_STRIPE_FAILURE,
  ],
  (data) => ({ settings: data || {} }),
  (data) => data,
);

const updateStripeReducer = createApiReducer(
  [
    StripeActionTypes.CHANGE_STRIPE_START,
    StripeActionTypes.UPDATE_STRIPE_SUCCESS,
    StripeActionTypes.CHANGE_STRIPE_FAILURE,
  ],
  (data) => ({ settings: data || {} }),
  (data) => data,
);

const deleteStripeReducer = createApiReducer(
  [
    StripeActionTypes.CHANGE_STRIPE_START,
    StripeActionTypes.DELETE_STRIPE_SUCCESS,
    StripeActionTypes.CHANGE_STRIPE_FAILURE,
  ],
  () => ({ settings: {} }),
  (data) => data,
);

const fetchMailchimpReducer = createApiReducer(
  [
    MailchimpActionTypes.CHANGE_MAILCHIMP_START,
    MailchimpActionTypes.FETCH_MAILCHIMP_SUCCESS,
    MailchimpActionTypes.CHANGE_MAILCHIMP_FAILURE,
  ],
  (data) => ({ settings: data || {} }),
  (data) => data,
);

const updateMailchimpReducer = createApiReducer(
  [
    MailchimpActionTypes.CHANGE_MAILCHIMP_START,
    MailchimpActionTypes.UPDATE_MAILCHIMP_SUCCESS,
    MailchimpActionTypes.CHANGE_MAILCHIMP_FAILURE,
  ],
  (data) => ({ settings: data || {} }),
  (data) => data,
);

const deleteMailchimpReducer = createApiReducer(
  [
    MailchimpActionTypes.CHANGE_MAILCHIMP_START,
    MailchimpActionTypes.DELETE_MAILCHIMP_SUCCESS,
    MailchimpActionTypes.CHANGE_MAILCHIMP_FAILURE,
  ],
  () => ({ settings: {} }),
  (data) => data,
);

const fetchCloudflareReducer = createApiReducer(
  [
    CloudflareActionTypes.CHANGE_CLOUDFLARE_START,
    CloudflareActionTypes.FETCH_CLOUDFLARE_SUCCESS,
    CloudflareActionTypes.CHANGE_CLOUDFLARE_FAILURE,
  ],
  (data) => ({ settings: data || {} }),
  (data) => data,
);

const updateCloudflareReducer = createApiReducer(
  [
    CloudflareActionTypes.CHANGE_CLOUDFLARE_START,
    CloudflareActionTypes.UPDATE_CLOUDFLARE_SUCCESS,
    CloudflareActionTypes.CHANGE_CLOUDFLARE_FAILURE,
  ],
  (data) => ({ settings: data || {} }),
  (data) => data,
);

const deleteCloudflareReducer = createApiReducer(
  [
    CloudflareActionTypes.CHANGE_CLOUDFLARE_START,
    CloudflareActionTypes.DELETE_CLOUDFLARE_SUCCESS,
    CloudflareActionTypes.CHANGE_CLOUDFLARE_FAILURE,
  ],
  () => ({ settings: {} }),
  (data) => data,
);

const fetchRenteonReducer = createApiReducer(
  [
    RenteonActionTypes.CHANGE_RENTEON_START,
    RenteonActionTypes.FETCH_RENTEON_SUCCESS,
    RenteonActionTypes.CHANGE_RENTEON_FAILURE,
  ],
  (data) => ({ settings: data || {} }),
  (data) => data,
);

const updateRenteonReducer = createApiReducer(
  [
    RenteonActionTypes.CHANGE_RENTEON_START,
    RenteonActionTypes.UPDATE_RENTEON_SUCCESS,
    RenteonActionTypes.CHANGE_RENTEON_FAILURE,
  ],
  (data) => ({ settings: data || {} }),
  (data) => data,
);

const deleteRenteonReducer = createApiReducer(
  [
    RenteonActionTypes.CHANGE_RENTEON_START,
    RenteonActionTypes.DELETE_RENTEON_SUCCESS,
    RenteonActionTypes.CHANGE_RENTEON_FAILURE,
  ],
  () => ({ settings: {} }),
  (data) => data,
);

export default combineReducers({
  stripe: chainReducers(INITIAL_INTEGRATION_STATE, fetchStripeReducer, updateStripeReducer, deleteStripeReducer),
  mailchimp: chainReducers(
    INITIAL_INTEGRATION_STATE,
    fetchMailchimpReducer,
    updateMailchimpReducer,
    deleteMailchimpReducer,
  ),
  cloudflare: chainReducers(
    INITIAL_INTEGRATION_STATE,
    fetchCloudflareReducer,
    updateCloudflareReducer,
    deleteCloudflareReducer,
  ),
  renteon: chainReducers(INITIAL_INTEGRATION_STATE, fetchRenteonReducer, updateRenteonReducer, deleteRenteonReducer),
});
