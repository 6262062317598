import React, { forwardRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import { HELP_ANIMATION_VARIANTS } from 'src/common/const';

type CheckboxProps = {
  label?: React.ReactNode;
  hideHelper?: boolean;
  touched?: boolean;
  error?: string;
  fieldClassName?: string;
  dataQa?: string;
} & JSX.IntrinsicElements['input'];

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function CheckboxHook(
  { id, label, hideHelper, touched, error, fieldClassName, dataQa, ...props },
  ref,
) {
  const hasError = touched && !!error;
  const helpClassName = clsx('help', {
    'is-danger': hasError,
  });
  const errorMessage = touched ? error || '' : '';

  return (
    <div className={clsx('field', fieldClassName)}>
      <div className="control">
        <label htmlFor={id} className="checkbox">
          <input ref={ref} type="checkbox" id={id} {...props} data-qa={dataQa} />
          &nbsp;
          {label}
        </label>
        {!hideHelper ? (
          <div className="helper-wrapper">
            <AnimatePresence>
              {hasError && (
                <motion.div initial="hidden" animate="visible" exit="hidden" variants={HELP_ANIMATION_VARIANTS}>
                  <p className={helpClassName}>{errorMessage}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ) : null}
      </div>
    </div>
  );
});
