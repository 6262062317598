export type PlanSelection = {
  idAnnual: string;
  idMonthly: string;
  isFree: boolean;
  name: string;
  priceAnnual: number;
  priceMonthly: number;
  chargebeeIdAnnual: string;
  chargebeeIdMonthly: string;
};

export enum BillingPeriod {
  Monthly = 'monthly',
  Yearly = 'yearly',
}
