import urlcat from 'urlcat';
import { BACK_TO_PAGE, BACK_TO_SEARCH, RETURN_TO, RICH_EDITOR_DEFAULT_TAB } from '../const';
import { useProjectLink } from '../hooks/use-project-link';

type UrlParams = {
  currentPage?: number;
  prevSearch?: string;
  returnTo?: [string, number | string];
  richEditorTab?: string;
};
export function addParamsToLink(url: string, params: UrlParams, useProjectPrefix = true) {
  // eslint-disable-next-line
  const projectUrl = useProjectLink();

  const fullUrl = `${useProjectPrefix ? projectUrl : ''}${url}`;

  return urlcat(fullUrl, {
    [BACK_TO_PAGE]: params.currentPage,
    [BACK_TO_SEARCH]: params.prevSearch,
    [RETURN_TO]: params.returnTo?.join(':'),
    [RICH_EDITOR_DEFAULT_TAB]: params.richEditorTab,
  });
}
