import clsx from 'clsx';
import React, { PropsWithChildren, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

type EditPageLayoutProps = PropsWithChildren<{
  renderLeft?: ReactNode;
  loading?: boolean;
  className?: string;
}>;

export function EditPageLayout({
  renderLeft = null,
  children = null,
  loading = false,
  className,
}: EditPageLayoutProps) {
  const leftColumnSize = children || loading ? 'is-one-quarter' : 'is-one-half';

  return (
    <div className={clsx('columns is-gapless edit-page-layout', className)}>
      <div className={clsx('column left-column', leftColumnSize)}>
        <div className="left-content">
          {loading ? (
            <div className="p-5">
              <Skeleton height={24} width={48} />
              <Skeleton height={24} />
              <Skeleton height={48} count={3} />
            </div>
          ) : (
            renderLeft
          )}
        </div>
      </div>
      <div className="column right-column">{loading ? <Skeleton /> : children}</div>
    </div>
  );
}
