import React, { ReactNode, MouseEventHandler } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useMotionWithBulma } from '../hooks';
import { ANIMATED_CARD_VARIANTS, FADE_IN_DIV_VARIANTS } from '../const';

type ModalProps = {
  onClickBackground?: MouseEventHandler;
  renderContent?: ({
    isModalVisible,
    currentVariant,
  }: {
    isModalVisible: boolean;
    currentVariant: string;
  }) => ReactNode;
  visible?: boolean;
  isCard?: boolean;
} & JSX.IntrinsicElements['div'];

export function Modal({
  children,
  visible = false,
  isCard = true,
  className,
  renderContent,
  onClickBackground,
  ...props
}: ModalProps) {
  const [currentVariant, isModalVisible, onAnimationComplete] = useMotionWithBulma(visible!);

  if (!isCard && !renderContent && typeof children === 'undefined') {
    throw new Error('If you are not using card you must provide children or `renderContent` prop');
  }

  return createPortal(
    <div className={clsx('modal', className, { 'is-active': isModalVisible })} {...props}>
      <motion.div
        className="modal-background"
        variants={FADE_IN_DIV_VARIANTS}
        animate={currentVariant}
        onAnimationComplete={onAnimationComplete}
        onClick={onClickBackground}
      />
      {isCard ? (
        <motion.div className="modal-card" variants={ANIMATED_CARD_VARIANTS} animate={currentVariant}>
          {children}
        </motion.div>
      ) : (
        children || renderContent!({ isModalVisible, currentVariant })
      )}
    </div>,
    document.body,
  );
}
