import { DevTool } from '@hookform/devtools';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Accordion, EditPageLayout, InputHook, LayoutWithNavbar } from 'src/common';
import * as yup from 'yup';
import { VALIDATION_MESSAGES } from 'src/common/const';
import { yupResolver } from '@hookform/resolvers/yup';
import { IntegrationsForm, useMailchimpSettings, useStripeSettings } from 'src/services/integrations';
import { showToast } from 'src/common/util/show-toast';
import clsx from 'clsx';
import { syncWithRenteon } from 'src/services/integrations/api';
import { useRenteonSettings } from 'src/services/integrations/hooks';
import { IntegrationSettings } from './IntegrationSettings';
import { useCloudflareSettings } from '../../../services/integrations/hooks/use-cloudflare-settings';

const defaultValues: IntegrationsForm = {
  stripe: {
    enabled: false,
    livePublicKey: '',
    liveSecretKey: '',
    testPublicKey: '',
    testSecretKey: '',
  },
  mailchimp: {
    enabled: false,
    apiKey: '',
  },
  cloudflare: {
    enabled: false,
    accountId: '',
    accountEmail: '',
    apiKey: '',
  },
  renteon: {
    enabled: false,
    username: '',
    password: '',
    providerCode: '',
    googleTagKey: '',
  },
};

const requiredWhenEnabled = (name: string) =>
  yup.string().when('enabled', {
    is: true,
    then: yup.string().required(VALIDATION_MESSAGES.required(name)),
    otherwise: yup.string().optional(),
  });
const validationSchema = yup.object({
  stripe: yup.object({
    enabled: yup.boolean().required(),
    livePublicKey: requiredWhenEnabled('Live Public Key'),
    liveSecretKey: requiredWhenEnabled('Live Secret Key'),
    testSecretKey: requiredWhenEnabled('Test Secret Key'),
    testPublicKey: requiredWhenEnabled('Test Public Key'),
  }),
  mailchimp: yup.object({
    enabled: yup.boolean().required(),
    apiKey: requiredWhenEnabled('API Key'),
  }),
  cloudflare: yup.object({
    enabled: yup.boolean().required(),
    accountId: requiredWhenEnabled('Account ID'),
    accountEmail: requiredWhenEnabled('Account Email'),
    apiKey: requiredWhenEnabled('API Key'),
  }),
  renteon: yup.object({
    enabled: yup.boolean().required(),
    username: requiredWhenEnabled('Username'),
    password: requiredWhenEnabled('Password'),
    providerCode: requiredWhenEnabled('Provider code'),
    googleTagKey: yup.string(),
  }),
});

export function IntegrationsList() {
  const {
    loadSettings: loadStripeSettings,
    settings: stripeSettings,
    updateSettings: updateStripeSettings,
    deleteSettings: deleteStripeSettings,
  } = useStripeSettings();
  const {
    loadSettings: loadMailchimpSettings,
    settings: mailchimpSettings,
    updateSettings: updateMailchimpSettings,
    deleteSettings: deleteMailchimpSettings,
  } = useMailchimpSettings();
  const {
    loadSettings: loadCloudflareSettings,
    settings: cloudflareSettings,
    updateSettings: updateCloudflareSettings,
    deleteSettings: deleteCloudflareSettings,
  } = useCloudflareSettings();
  const {
    loadSettings: loadRenteonSettings,
    settings: renteonSettings,
    updateSettings: updateRenteonSettings,
    deleteSettings: deleteRenteonSettings,
  } = useRenteonSettings();
  const methods = useForm<IntegrationsForm>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });
  const { handleSubmit, control, reset, formState } = methods;

  const save = handleSubmit(async (values) => {
    const actions = [];

    if (values.stripe.enabled) {
      actions.push(updateStripeSettings(values.stripe));
    } else {
      actions.push(deleteStripeSettings());
    }

    if (values.mailchimp.enabled) {
      actions.push(updateMailchimpSettings(values.mailchimp));
    } else {
      actions.push(deleteMailchimpSettings());
    }

    if (values.cloudflare.enabled) {
      actions.push(updateCloudflareSettings(values.cloudflare));
    } else {
      actions.push(deleteCloudflareSettings());
    }

    if (values.renteon.enabled) {
      actions.push(updateRenteonSettings(values.renteon));
    } else {
      actions.push(deleteRenteonSettings());
    }

    const [stripeResult, mailchimpResult, cloudflareResult, renteonResult] = await Promise.all(actions);
    const errors = [
      // @ts-ignore
      values.stripe.enabled && !stripeResult.ok ? `Stripe - ${stripeResult.errorMessage}` : null,
      // @ts-ignore
      values.mailchimp.enabled && !mailchimpResult.ok ? `MailChimp - ${mailchimpResult.errorMessage}` : null,
      // @ts-ignore
      values.cloudflare.enabled && !cloudflareResult.ok ? `CloudFlare - ${cloudflareResult.errorMessage}` : null,
      // @ts-ignore
      values.renteon.enabled && !renteonResult.ok ? `Renteon - ${renteonResult.errorMessage}` : null,
    ].filter(Boolean);

    if (errors.length) {
      showToast('error', `There was an error updating the following integrations: ${errors.join(', ')}`);
    } else {
      showToast('success', 'Successfully saved');
    }
  });

  useEffect(() => {
    loadStripeSettings();
    loadMailchimpSettings();
    loadCloudflareSettings();
    loadRenteonSettings();
  }, [loadStripeSettings, loadMailchimpSettings, loadCloudflareSettings, loadRenteonSettings]);

  useEffect(() => {
    reset({
      stripe: {
        ...stripeSettings.settings,
        enabled: !!stripeSettings.settings?.id,
      },
      mailchimp: {
        ...mailchimpSettings.settings,
        enabled: !!mailchimpSettings.settings?.id,
      },
      cloudflare: {
        ...cloudflareSettings.settings,
        enabled: !!cloudflareSettings.settings?.id,
      },
      renteon: {
        ...renteonSettings.settings,
        enabled: !!renteonSettings.settings?.id,
      },
    });
  }, [
    mailchimpSettings.settings,
    reset,
    stripeSettings.settings,
    cloudflareSettings.settings,
    renteonSettings.settings,
  ]);

  return (
    <FormProvider {...methods}>
      <LayoutWithNavbar
        breadcrumbs={[
          { label: 'SiteSails', href: '/' },
          { label: 'Integrations', href: '/integrations' },
        ]}
        as="form"
        onSubmit={save}
        endButtons={
          <div className="navbar-item">
            <button
              type="submit"
              className={clsx('button is-primary', {
                'is-loading': formState.isSubmitting,
              })}
              data-qa="save-button"
            >
              <span className="icon">
                <i className="far fa-check has-text-secondary-light" />
              </span>
              <span>Save</span>
            </button>
          </div>
        }
      >
        <EditPageLayout
          renderLeft={
            <Accordion
              disableShadow
              items={[
                {
                  fixed: !!formState.errors.stripe,
                  Header: (
                    <>
                      <span className="icon mr-2">
                        <i className="fab fa-stripe-s" />
                      </span>
                      Stripe
                      <small className="has-text-weight-normal has-text-grey ml-4">Payment processing</small>
                    </>
                  ),
                  id: 'stripe',
                  content: (
                    <IntegrationSettings slug="stripe">
                      <div className="column is-half">
                        <Controller
                          control={control}
                          name="stripe.testPublicKey"
                          defaultValue=""
                          as={
                            <InputHook
                              label="Test Public Key"
                              id="stripe.testPublicKey"
                              error={formState.errors.stripe?.testPublicKey?.message}
                              touched
                              fieldClassName="mr-2 mb-4"
                            />
                          }
                        />
                      </div>
                      <div className="column is-half">
                        <Controller
                          control={control}
                          name="stripe.testSecretKey"
                          defaultValue=""
                          as={
                            <InputHook
                              label="Test Secret Key"
                              id="stripe.testSecretKey"
                              error={formState.errors.stripe?.testSecretKey?.message}
                              touched
                              fieldClassName="ml-2 mb-4"
                            />
                          }
                        />
                      </div>
                      <div className="column is-half">
                        <Controller
                          control={control}
                          name="stripe.livePublicKey"
                          defaultValue=""
                          as={
                            <InputHook
                              label="Live Public Key"
                              id="stripe.livePublicKey"
                              error={formState.errors.stripe?.livePublicKey?.message}
                              touched
                              fieldClassName="mr-2"
                            />
                          }
                        />
                      </div>
                      <div className="column is-half">
                        <Controller
                          control={control}
                          name="stripe.liveSecretKey"
                          defaultValue=""
                          as={
                            <InputHook
                              label="Live Secret Key"
                              id="stripe.liveSecretKey"
                              error={formState.errors.stripe?.liveSecretKey?.message}
                              touched
                              fieldClassName="ml-2"
                            />
                          }
                        />
                      </div>
                    </IntegrationSettings>
                  ),
                },
                {
                  fixed: !!formState.errors.mailchimp,
                  Header: (
                    <>
                      <span className="icon mr-2">
                        <i className="fab fa-mailchimp" />
                      </span>
                      Mailchimp
                      <small className="has-text-weight-normal has-text-grey ml-4">Integrated marketing platform</small>
                    </>
                  ),
                  id: 'mailchimp',
                  content: (
                    <IntegrationSettings slug="mailchimp">
                      <div className="column is-full">
                        <Controller
                          control={control}
                          name="mailchimp.apiKey"
                          defaultValue=""
                          as={
                            <InputHook
                              label="API Key"
                              id="mailchimp.apiKey"
                              error={formState.errors.mailchimp?.apiKey?.message}
                              touched
                            />
                          }
                        />
                      </div>
                    </IntegrationSettings>
                  ),
                },
                {
                  fixed: !!formState.errors.cloudflare,
                  Header: (
                    <>
                      <span className="icon mr-2">
                        <i className="fab fa-cloudflare" />
                      </span>
                      Cloudflare
                      <small className="has-text-weight-normal has-text-grey ml-4">Video streaming</small>
                    </>
                  ),
                  id: 'cloudflare',
                  content: (
                    <IntegrationSettings slug="cloudflare">
                      <div className="column is-full">
                        <Controller
                          control={control}
                          name="cloudflare.accountId"
                          defaultValue=""
                          as={
                            <InputHook
                              label="Account ID"
                              id="cloudflare.accountId"
                              error={formState.errors.cloudflare?.accountId?.message}
                              touched
                            />
                          }
                        />
                      </div>
                      <div className="column is-full">
                        <Controller
                          control={control}
                          name="cloudflare.accountEmail"
                          defaultValue=""
                          as={
                            <InputHook
                              label="Account Email"
                              id="cloudflare.accountEmail"
                              error={formState.errors.cloudflare?.accountEmail?.message}
                              touched
                            />
                          }
                        />
                      </div>
                      <div className="column is-full">
                        <Controller
                          control={control}
                          name="cloudflare.apiKey"
                          defaultValue=""
                          as={
                            <InputHook
                              label="API Key"
                              id="cloudflare.apiKey"
                              error={formState.errors.cloudflare?.apiKey?.message}
                              touched
                            />
                          }
                        />
                      </div>
                    </IntegrationSettings>
                  ),
                },
                {
                  fixed: !!formState.errors.renteon,
                  Header: (
                    <>
                      <span className="icon mr-2">
                        <i className="far fa-ellipsis-h" />
                      </span>
                      Renteon
                    </>
                  ),
                  id: 'renteon',
                  content: (
                    <IntegrationSettings slug="renteon">
                      <div className="column is-full">
                        <Controller
                          control={control}
                          name="renteon.username"
                          defaultValue=""
                          as={
                            <InputHook
                              label="Username"
                              id="renteon.username"
                              error={formState.errors.renteon?.username?.message}
                              touched
                            />
                          }
                        />
                      </div>
                      <div className="column is-full">
                        <Controller
                          control={control}
                          name="renteon.password"
                          defaultValue=""
                          as={
                            <InputHook
                              label="Password"
                              id="renteon.password"
                              error={formState.errors.renteon?.password?.message}
                              touched
                              type="password"
                            />
                          }
                        />
                      </div>
                      <div className="column is-full">
                        <Controller
                          control={control}
                          name="renteon.providerCode"
                          defaultValue=""
                          as={
                            <InputHook
                              label="Provider code"
                              id="renteon.providerCode"
                              error={formState.errors.renteon?.providerCode?.message}
                              touched
                            />
                          }
                        />
                      </div>
                      <div className="column is-full">
                        <Controller
                          control={control}
                          name="renteon.googleTagKey"
                          defaultValue=""
                          as={
                            <InputHook
                              label="Google Tag Key"
                              id="renteon.googleTagKey"
                              error={formState.errors.renteon?.googleTagKey?.message}
                              touched
                            />
                          }
                        />
                      </div>
                      <div
                        className="column is-full is-justify-content-end"
                        style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}
                      >
                        <button
                          type="button"
                          className="button"
                          onClick={async () => {
                            try {
                              await syncWithRenteon();
                              showToast('success', 'Synced with Renteon');
                            } catch {
                              showToast('error', `Error while syncing with Renteon.`);
                            }
                          }}
                        >
                          Sync
                        </button>
                      </div>
                    </IntegrationSettings>
                  ),
                },
              ]}
            />
          }
        />
      </LayoutWithNavbar>
      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </FormProvider>
  );
}
