import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as authReducer } from 'src/services/auth';
import { reducer as usersReducer } from 'src/services/users';
import { reducer as nodesReducer } from 'src/services/nodes';
import { reducer as languagesReducer } from 'src/services/languages';
import { reducer as sectionsReducer } from 'src/services/sections';
import { reducer as integrationsReducer } from 'src/services/integrations';
import { reducer as connectionsReducer } from 'src/services/connections';
import { reducer as projectReducer } from 'src/services/project';
import { reducer as collectionsReducer } from 'src/services/collections';
import { reducer as productsReducer } from 'src/services/products';
import { reducer as keyValuesReducer } from 'src/services/key-values';
import { reducer as organizationsReducer } from 'src/services/organizations';
import { reducer as themesReducer } from 'src/services/themes';

import { State } from './types';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'users'],
};

const appReducer = combineReducers<State>({
  auth: authReducer,
  users: usersReducer,
  nodes: nodesReducer,
  languages: languagesReducer,
  sections: sectionsReducer,
  integrations: integrationsReducer,
  connections: connectionsReducer,
  project: projectReducer,
  collections: collectionsReducer,
  products: productsReducer,
  keyValues: keyValuesReducer,
  organizations: organizationsReducer,
  themes: themesReducer,
});

const persistedReducer = persistReducer(persistConfig, appReducer);

const middlewares = [thunkMiddleware];

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configureStore = () => {
  const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));

  const persistor = persistStore(store);

  return { store, persistor };
};
