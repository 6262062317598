import { createApiAction } from 'src/services/api';

export enum ThemeActionTypes {
  CHANGE_THEME_START = 'themes/change_themes_start',
  CHANGE_THEME_FAILURE = 'themes/change_themes_failure',
  FETCH_THEME_SUCCESS = 'themes/fetch_themes_success',
}

export const fetchThemes = () =>
  createApiAction(
    {
      path: `/themes`,
      method: 'GET',
    },
    [ThemeActionTypes.CHANGE_THEME_START, ThemeActionTypes.FETCH_THEME_SUCCESS, ThemeActionTypes.CHANGE_THEME_FAILURE],
  );
