import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Control, Controller, FormState, useWatch } from 'react-hook-form';
import { formatDate, isTouched, ordinalSuffix } from 'src/common/util';
import { REPEAT_EVERY_OPTIONS, WEEKDAYS_OPTIONS } from 'src/modules/events/const';
import { FormNode } from 'src/modules/nodes/types';
import { RecurringType } from 'src/services/events/types';
import { Accordion } from '../Accordion';
import { DatePicker } from './DatePicker';
import { InputHook } from './InputHook';
import { SelectHook } from './SelectHook';

type EventFieldsProps = {
  control: Control<any>;
  formState: FormState<any>;
  register: any;
  node: FormNode | null;
};

export function EventFields({ control, formState, register, node }: EventFieldsProps) {
  const watches = useWatch({
    control,
    name: [
      'recurring',
      'event.dateFrom',
      'event.dateTo',
      'event.recurringWeekDay',
      'event.recurringDay',
      'event.weekdayOccurrence',
    ],
    defaultValue: {
      recurring: '0',
      'event.dateFrom': node?.event?.dateFrom,
      'event.dateTo': node?.event?.dateTo,
      'event.recurringWeekDay': -1,
      'event.recurringDay': 0,
      'event.weekdayOccurrence': 0,
    },
  });
  const watchRecurring = Number(watches.recurring);
  const {
    'event.dateFrom': watchDateFrom,
    'event.dateTo': watchDateTo,
    'event.recurringWeekDay': watchRecurringWeekday,
    'event.recurringDay': watchRecurringDay,
    'event.weekdayOccurrence': watchWeekdayOccurrence,
  } = watches;

  const headerValue = useMemo(() => {
    return `${formatDate(watchDateFrom! as string | Date)} - ${formatDate(watchDateTo! as string | Date)}`;
  }, [watchDateFrom, watchDateTo]);

  const subhelperValue = useMemo(() => {
    const weekday = WEEKDAYS_OPTIONS.find((w) => w.value === Number(watchRecurringWeekday));
    const occurrence = REPEAT_EVERY_OPTIONS.find((o) => o.value === Number(watchWeekdayOccurrence));

    switch (watchRecurring) {
      case RecurringType.EveryWeek:
        return weekday ? `Repeat every ${weekday.label}` : '';

      case RecurringType.EveryMonthOnDate:
        return watchRecurringDay ? `Repeat every ${ordinalSuffix(watchRecurringDay)} day of month` : '';

      case RecurringType.EveryMonthOnWeekday:
        return occurrence && weekday ? `Repeat every ${occurrence.label.toLowerCase()} ${weekday.label}` : '';

      default:
        return '';
    }
  }, [watchRecurring, watchRecurringDay, watchRecurringWeekday, watchWeekdayOccurrence]);

  return (
    <Accordion
      className="events-accordion"
      disableShadow
      items={[
        {
          Header: (isExpanded) => (
            <div className="field has-floating-label is-active event-header mb-3">
              <label htmlFor="eventsHeader" className="label is-active has-icons">
                Event
              </label>
              <div className="control has-icons-left has-icons-right">
                <input
                  type="text"
                  readOnly
                  value={headerValue}
                  className={clsx(
                    'input is-clickable',
                    watchRecurring !== RecurringType.NonRecurring && 'has-subhelper',
                  )}
                  id="eventsHeader"
                />
                <span className="icon is-left">
                  <i className="fal fa-calendar fa-lg" />
                </span>
                <span className="icon is-right">
                  <i className={`fal fa-lg fa-${isExpanded ? 'minus' : 'plus'}`} />
                </span>
                {subhelperValue ? <span className="subhelper">{subhelperValue}</span> : null}
              </div>
            </div>
          ),
          id: 'event',
          content: (
            <>
              <Controller
                control={control}
                name="event.dateFrom"
                defaultValue={node?.event?.dateFrom || null}
                render={({ value, ...rest }) => (
                  <DatePicker
                    selected={value}
                    label="Date From"
                    id="event.dateFrom"
                    prependIcon={<i className="fal fa-calendar fa-lg" />}
                    error={formState.errors.event?.dateFrom?.message}
                    touched={isTouched(formState, 'event.dateFrom')}
                    fieldClassName="mb-3"
                    portalId="eventDatepicker"
                    {...rest}
                  />
                )}
              />
              <Controller
                control={control}
                name="event.dateTo"
                defaultValue={node?.event?.dateTo || null}
                render={({ value, ...rest }) => (
                  <DatePicker
                    selected={value}
                    label="Date To"
                    id="event.dateTo"
                    prependIcon={<i className="fal fa-calendar fa-lg" />}
                    error={formState.errors.event?.dateTo?.message}
                    touched={isTouched(formState, 'event.dateTo')}
                    fieldClassName="mb-3"
                    portalId="eventDatepicker"
                    {...rest}
                  />
                )}
              />
              <div className="control">
                <label htmlFor={`recurring-${RecurringType[0]}`} className="radio">
                  <input
                    type="radio"
                    name="recurring"
                    id={`recurring-${RecurringType[0]}`}
                    ref={register()}
                    value={RecurringType.NonRecurring}
                  />
                  {' Non Recurring'}
                </label>
              </div>
              <div className="control">
                <label htmlFor={`recurring-${RecurringType[1]}`} className="radio">
                  <input
                    type="radio"
                    name="recurring"
                    id={`recurring-${RecurringType[1]}`}
                    ref={register()}
                    value={RecurringType.EveryWeek}
                  />
                  {' Repeat Every Week'}
                </label>
              </div>
              <div className="control">
                <label htmlFor={`recurring-${RecurringType[2]}`} className="radio">
                  <input
                    type="radio"
                    name="recurring"
                    id={`recurring-${RecurringType[2]}`}
                    ref={register()}
                    value={RecurringType.EveryMonthOnDate}
                  />
                  {' Repeat Every Month on a date'}
                </label>
              </div>
              <div className="control mb-3">
                <label htmlFor={`recurring-${RecurringType[3]}`} className="radio">
                  <input
                    type="radio"
                    name="recurring"
                    id={`recurring-${RecurringType[3]}`}
                    ref={register()}
                    value={RecurringType.EveryMonthOnWeekday}
                  />
                  {' Repeat Every Month on a weekday'}
                </label>
              </div>

              {watchRecurring === RecurringType.EveryMonthOnWeekday ? (
                <SelectHook
                  ref={register()}
                  name="event.weekdayOccurrence"
                  label="Recurring Every"
                  error={formState.errors.event?.weekdayOccurrence?.message}
                  touched={isTouched(formState, 'event.weekdayOccurrence')}
                  options={REPEAT_EVERY_OPTIONS}
                  id="event.weekdayOccurrence"
                />
              ) : null}
              {watchRecurring === RecurringType.EveryWeek || watchRecurring === RecurringType.EveryMonthOnWeekday ? (
                <SelectHook
                  ref={register()}
                  name="event.recurringWeekDay"
                  label="Recurring Day Of The Week"
                  error={formState.errors.event?.recurringWeekDay?.message}
                  touched={isTouched(formState, 'event.recurringWeekDay')}
                  options={WEEKDAYS_OPTIONS}
                  id="event.recurringWeekDay"
                />
              ) : null}
              {watchRecurring === RecurringType.EveryMonthOnDate ? (
                <Controller
                  control={control}
                  name="event.recurringDay"
                  defaultValue={node?.event?.recurringDay || null}
                  as={
                    <InputHook
                      label="Recurring Day"
                      id="event.recurringDay"
                      type="number"
                      min="0"
                      max="31"
                      step="1"
                      error={formState.errors.event?.recurringDay?.message}
                      touched={isTouched(formState, 'event.recurringDay')}
                    />
                  }
                />
              ) : null}
            </>
          ),
        },
      ]}
    />
  );
}
