import clsx from 'clsx';
import { motion } from 'framer-motion';
import React from 'react';
import { LINK_ENTER_VARIANTS } from '../const';
import { Dropdown } from './Dropdown';
import { NavLink } from './NavLink';

type SidebarProfileButtonProps = {
  delay: number;
  profileMatch: boolean;
  fullName: string;
  logOut: () => void;
};

export default function SidebarProfileButton({
  delay = 0.05,
  profileMatch,
  fullName,
  logOut,
}: SidebarProfileButtonProps) {
  return (
    <motion.li
      layout
      variants={LINK_ENTER_VARIANTS}
      initial="hidden"
      animate="visible"
      exit="hidden"
      transition={{
        delay,
      }}
      className={clsx('profile-link', {
        'is-active': profileMatch,
      })}
    >
      <Dropdown
        align="right"
        tippyPlacement="right"
        up
        data-qa="user-menu"
        renderTrigger={
          <a className="menu-link sidebar-bottom-link">
            <figure className="image is-32x32">
              <img
                className="is-rounded"
                src={`https://ui-avatars.com/api/?name=${fullName}&background=006bd6&color=5ed5ed&size=32&format=svg`}
                alt={fullName}
              />
            </figure>
            <span className="text">{fullName}</span>
            <i className="fa fa-angle-up" />
          </a>
        }
      >
        <div className="dropdown-content">
          <NavLink to="/profile" className="dropdown-item">
            <span className="icon">
              <i className="fal fa-user" />
            </span>
            <span className="ml-2">User Profile</span>
          </NavLink>
          <a className="dropdown-item" onClick={logOut} data-qa="user-menu-logout">
            <span className="icon">
              <i className="fal fa-door-open" />
            </span>
            <span className="ml-2">Log out</span>
          </a>
        </div>
      </Dropdown>
    </motion.li>
  );
}
