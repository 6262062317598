import React, { forwardRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import { HELP_ANIMATION_VARIANTS } from '../../const';

type SelectProps = {
  label?: React.ReactNode;
  options?: { value?: string | number; label: React.ReactNode; disabled?: boolean }[];
  touched?: boolean;
  error?: string;
  fieldClassName?: string;
} & JSX.IntrinsicElements['select'];

export const SelectHook = forwardRef<HTMLSelectElement, SelectProps>(
  ({ id, label, options, multiple, touched, error, fieldClassName, ...props }, ref) => {
    const hasError = touched && !!error;
    const helpClassName = clsx('help', {
      'is-danger': hasError,
    });
    const errorMessage = touched ? error || '' : '';

    return (
      <div className={clsx('field has-floating-label', fieldClassName)}>
        <label htmlFor={id} className="label is-active">
          {label}
        </label>
        <div className="control">
          <div className={clsx('select is-fullwidth', { 'is-multiple': multiple })}>
            <select id={id} multiple={multiple} {...props} ref={ref}>
              {options?.map((option) => (
                <option value={option.value} key={option.value} disabled={option.disabled}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="helper-wrapper">
          <AnimatePresence>
            {hasError && (
              <motion.div initial="hidden" animate="visible" exit="hidden" variants={HELP_ANIMATION_VARIANTS}>
                <p className={helpClassName}>{errorMessage}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    );
  },
);
