import Quill from 'quill';

const ImageFormatAttributesList = ['alt', 'height', 'width', 'style', 'class'];

const BaseImageFormat = Quill.import('formats/image');

export class QuillCustomImageFormat extends BaseImageFormat {
  // @ts-ignore
  static formats(domNode) {
    return ImageFormatAttributesList.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        // @ts-ignore
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  // @ts-ignore
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
