import { useLocation } from '@reach/router';
import { useMemo } from 'react';
import { Section, SectionType } from 'src/services/sections';
import { BACK_TO_PAGE, BACK_TO_SEARCH, RETURN_TO } from '../const';
import { useProjectLink } from './use-project-link';
import { useReturnTo } from './use-return-to';

export function usePrevPageLink(base: string): string;
export function usePrevPageLink(mainSection: Section, subSection: Section): string;
export function usePrevPageLink(base: Section | string, subSection?: Section): string {
  const projectLink = useProjectLink();
  const location = useLocation();
  const [returnSlug, returnId] = useReturnTo();

  const listLink = useMemo(() => {
    let url = '';
    const search = new URLSearchParams(location.search || '');

    if (returnSlug && returnId) {
      search.delete(RETURN_TO);
      url = `${url}/nodes/${returnSlug}/edit/${returnId}`;
      if (search.toString().length > 0) {
        url = `${url}?${search}`;
      }
    } else {
      if (typeof base !== 'string') {
        url = `${url}/nodes/${base.slug}`;

        if (!!subSection && subSection !== base) {
          if (subSection.type === SectionType.Collections) {
            url = `${url}/collections/${subSection.slug}`;
          } else if (subSection.categorySectionId) {
            url = `${url}/categories`;
          }
        }
      } else {
        url = base;
      }

      if (location?.search) {
        const backToPageValue = Number(search.get(BACK_TO_PAGE));
        const prevSearchValue = search.get(BACK_TO_SEARCH);
        if (!Number.isNaN(backToPageValue)) {
          url = `${url}?page=${backToPageValue}`;
        }
        if (prevSearchValue) {
          url = `${url}&${BACK_TO_SEARCH}=${prevSearchValue}`;
        }
      }
    }

    return projectLink + url;
  }, [location.search, returnSlug, returnId, base, subSection, projectLink]);

  return listLink;
}
