import React, { useEffect } from 'react';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import * as yup from 'yup';
import clsx from 'clsx';

import { InputHook, isTouched } from 'src/common';
import { useAuth } from 'src/services/auth';
import { isValid } from 'src/services/api';
import { VALIDATION_MESSAGES } from 'src/common/const';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPassword } from 'src/services/auth/api';
import { showToast } from 'src/common/util/show-toast';

const validationSchema = yup.object({
  password: yup.string().required(VALIDATION_MESSAGES.required('Password')),
});

type ResetPasswordProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ResetPassword(_: ResetPasswordProps) {
  const { auth } = useAuth();

  const location = useLocation();

  const { handleSubmit, formState, control } = useForm<{
    password: string;
  }>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: { password: '' },
  });

  useEffect(() => {
    if (isValid(auth)) {
      navigate('/');
    }
  }, [auth]);

  if (isValid(auth)) {
    return null;
  }

  const search = new URLSearchParams(location.search);
  const email = search.get('email');
  const code = search.get('code');

  if (!email || !code) {
    return <div className="p-5">Invalid password reset link. Please click the link in your email.</div>;
  }

  const onSubmit = handleSubmit(async (values) => {
    const result = await resetPassword(email, code, values.password);

    // @ts-ignore
    if (result) {
      showToast('success', 'Password reset complete.');
      navigate('/login');
    } else {
      showToast('error', 'There was an error resetting your password.');
    }

    return true;
  });

  return (
    <div className="login-page">
      <div className="navbar is-transparent is-spaced">
        <div className="navbar-menu">
          <div className="navbar-end">
            <a href="#" className="navbar-item">
              Services
            </a>
            <a href="#" className="navbar-item">
              Contact
            </a>
          </div>
        </div>
      </div>
      <div className="container is-fluid">
        <div className="page-wrapper">
          <form onSubmit={onSubmit} noValidate>
            <h2 className="title is-1">Reset password</h2>
            <p>Enter your new password below:</p>
            <Controller
              control={control}
              name="password"
              defaultValue=""
              as={
                <InputHook
                  label="Password"
                  error={formState.errors.password?.message}
                  disabled={formState.isSubmitting}
                  touched={isTouched(formState, 'password')}
                  type="password"
                  prependIcon={<i className="fal fa-key" />}
                  data-qa="login-password"
                />
              }
            />
            <button
              type="submit"
              data-qa="login-button"
              className={clsx('button is-link has-text-weight-bold is-fullwidth is-submit', {
                'is-loading': formState.isSubmitting,
              })}
              disabled={formState.isSubmitting || !formState.isValid}
            >
              <span className="icon">
                <i className="fal fa-unlock" />
              </span>
              <span>Reset password</span>
            </button>

            <div className="columns is-mobile mt-2 links">
              <div className="column">
                <a href="/login">Back to Sign in</a>
              </div>
              <div className="column is-narrow">
                <a href="/registration">Create an account</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
