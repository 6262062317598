import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserRoles,
  fetchUserRoles,
  deleteUserRole as deleteUserRoleAction,
  addUserRole,
  updateUserRole as updateUserRoleAction,
} from '../redux';
import { UserRole } from '../types';

export function useUserRoles() {
  const dispatch = useDispatch();
  const userRoles = useSelector(getUserRoles);

  const loadUserRoles = useCallback(
    (search?: string) => {
      return dispatch(fetchUserRoles({ search }));
    },
    [dispatch],
  );

  const deleteUserRole = useCallback(
    (id: number) => {
      return dispatch(deleteUserRoleAction(id));
    },
    [dispatch],
  );

  const createUserRole = useCallback(
    (data: UserRole) => {
      return dispatch(addUserRole(data));
    },
    [dispatch],
  );

  const updateUserRole = useCallback(
    (data: UserRole) => {
      return dispatch(updateUserRoleAction(data));
    },
    [dispatch],
  );

  return {
    userRoles,
    deleteUserRole,
    loadUserRoles,
    createUserRole,
    updateUserRole,
  };
}
