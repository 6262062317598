import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ImageMediaData } from 'src/common/types';
import { addMediaImage } from 'src/services/media';
import { createImageMediaData, getFileInputValue } from 'src/common/util';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { motion } from 'framer-motion';
import move from 'array-move';

type ImageGalleryProps = {
  label?: ReactNode;
  inline?: boolean;
  value: ImageMediaData[];
  onChange?: (value: ImageMediaData[]) => any;
} & JSX.IntrinsicElements['input'];

export function ImageGallery({
  label,
  inline = false,
  disabled,
  value,
  onChange,
  id,
  name,
  ...props
}: ImageGalleryProps) {
  const ref = useRef<HTMLInputElement>(null!);
  const [isUploading, setIsUploading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleImageChange = async (files: File | File[] | null) => {
    if (files && Array.isArray(files)) {
      setIsUploading(true);

      const res = (
        await Promise.all(
          files.map(async (file, index) => {
            const fileRes = await addMediaImage(file);

            if (fileRes.ok) {
              const result = createImageMediaData(fileRes);
              if (!value || (value.length === 0 && index === 0)) {
                result.isMain = true;
              }
              return result;
            }
            return null;
          }),
        )
      ).filter(Boolean);

      onChange!([...(value || []), ...(res.filter(Boolean) as ImageMediaData[])]);
      setIsUploading(false);
      setIsSuccess(true);
    } else {
      onChange!([...(value || [])]);
    }
    if (ref.current) {
      ref.current.value = '';
    }
  };

  function removeCurrentImage(index: number) {
    const removed = value[index];
    const newValue = (value || []).slice();
    newValue.splice(index, 1);

    if (removed.isMain && newValue.length > 0) {
      newValue[0].isMain = true;
    }

    onChange!(newValue);
  }

  function setMain(index: number) {
    const newValue = (value || []).map((image, imgIndex) => ({
      ...image,
      isMain: imgIndex === index,
    }));
    const main = newValue.splice(index, 1);
    newValue.unshift(main[0]);
    onChange!(newValue);
  }

  function onDragEnd(result: DropResult) {
    const newValue = move(value || [], result.source.index, result.destination!.index);
    onChange!(newValue);
  }

  useEffect(() => {
    if (isSuccess) {
      const timeout = setTimeout(() => setIsSuccess(false), 3000);

      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [isSuccess]);

  return (
    <div className={clsx('field image-gallery', inline && 'is-inlined')}>
      {label ? (
        <label htmlFor={id} className="label">
          {label}
        </label>
      ) : null}
      {/* TODO: replace react-beautiful-dnd with react-dnd */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`${id}-droppable`} isDropDisabled={disabled}>
          {(provided) => (
            <div className="images-container" ref={provided.innerRef} {...provided.droppableProps}>
              {(value || []).map((image, index) => (
                <Draggable
                  key={image.id}
                  draggableId={image.id?.toString() || index.toString()}
                  index={index}
                  isDragDisabled={disabled}
                >
                  {(draggableProvided) => (
                    // @ts-ignore
                    <div
                      className="thumb"
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.dragHandleProps}
                      {...draggableProvided.draggableProps}
                    >
                      <img src={image.mediumUrl || image.smallFile?.url || image.url} alt="" />
                      {!disabled ? (
                        <div className="toolbox">
                          {image.isMain ? (
                            <span className="tag">Main</span>
                          ) : (
                            <button
                              type="button"
                              className={`
                                button is-white has-text-primary is-rounded is-small is-uppercase button-set-main
                              `}
                              onClick={() => setMain(index)}
                            >
                              Set main
                            </button>
                          )}
                          <button
                            type="button"
                            className="button is-white has-text-info is-rounded is-small button-remove"
                            onClick={() => removeCurrentImage(index)}
                          >
                            <span className="icon">
                              <i className="fal fa-minus" />
                            </span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {!disabled ? (
                <motion.label
                  layout
                  htmlFor={id}
                  className={clsx('button button-add', {
                    'is-loading': isUploading,
                    'is-success': isSuccess,
                  })}
                >
                  {!isSuccess ? (
                    <>
                      <span
                        className={clsx('icon', {
                          'has-text-info': !isUploading,
                        })}
                      >
                        <i className="fal fa-plus" />
                      </span>
                      <span>Add</span>
                    </>
                  ) : (
                    <span className="icon">
                      <i className="fal fa-check" />
                    </span>
                  )}
                </motion.label>
              ) : null}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!disabled ? (
        <input
          type="file"
          id={id}
          ref={ref}
          accept="image/*"
          multiple
          disabled={isUploading}
          {...props}
          onChange={() => handleImageChange(getFileInputValue(ref))}
        />
      ) : null}
    </div>
  );
}
