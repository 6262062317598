import { useMemo } from 'react';
import * as yup from 'yup';
import { Section, SectionField, SectionFieldType } from '../types';

const schemas = {
  imageMediaData: yup.object({
    id: yup.number().nullable(),
    url: yup.string().required(),
  }),
  videoMediaData: yup.object({
    id: yup.number().nullable(),
    uid: yup.string().required(),
    preview: yup.string().required(),
    thumbnail: yup.string().required(),
  }),
  keyValue: yup.object<{ name?: string | null; value?: string | null }>({
    name: yup.string().nullable().label('Name'),
    value: yup.string().nullable().label('Value'),
  }),
  sectionLink: yup.object<{ label: string; value: number }>({
    label: yup.string(),
    value: yup.number(),
  }),
  textArray: yup.object<{ value?: string | null }>({
    value: yup.string().nullable().label('Value'),
  }),
};

function makeFieldSchema(result: Record<string, yup.Schema<any, unknown>>, field: SectionField) {
  let fieldSchema!: any;

  if (field.type === SectionFieldType.Boolean) {
    fieldSchema = yup.bool();
  } else if ([SectionFieldType.Date, SectionFieldType.DateTime].includes(field.type)) {
    fieldSchema = yup.date();
  } else if (field.type === SectionFieldType.File) {
    fieldSchema = yup.mixed<File>();
  } else if (field.type === SectionFieldType.Image) {
    fieldSchema = schemas.imageMediaData.nullable();
  } else if (field.type === SectionFieldType.ImageGallery) {
    fieldSchema = yup.array(schemas.imageMediaData).min(field.isRequired ? 1 : 0);
  } else if (field.type === SectionFieldType.VideoGallery) {
    fieldSchema = yup.array(schemas.videoMediaData).min(field.isRequired ? 1 : 0);
  } else if (field.type === SectionFieldType.KeyValue) {
    fieldSchema = yup.array(schemas.keyValue).min(field.isRequired ? 1 : 0);
  } else if (field.type === SectionFieldType.TextArray) {
    fieldSchema = yup.array(schemas.textArray).min(field.isRequired ? 1 : 0);
  } else if (field.type === SectionFieldType.MultiOption) {
    fieldSchema = yup.array(yup.string()).min(field.isRequired ? 1 : 0);
  } else if (
    [
      SectionFieldType.MultilineText,
      SectionFieldType.Text,
      SectionFieldType.SingleOption,
      SectionFieldType.RichTextSimple,
      SectionFieldType.RichTextComplete,
    ].includes(field.type)
  ) {
    fieldSchema = yup.string().nullable();
  } else if (field.type === SectionFieldType.Number) {
    fieldSchema = yup.number().transform((value) => (Number.isNaN(value) ? undefined : value));
  } else if (field.type === SectionFieldType.SectionLink) {
    fieldSchema = schemas.sectionLink;
  } else if (field.type === SectionFieldType.SectionMultiLink) {
    fieldSchema = yup.array(schemas.sectionLink).min(field.isRequired ? 1 : 0);
  } else {
    fieldSchema = yup.object().nullable();
  }

  if (field.isRequired) {
    fieldSchema.required();
  } else {
    fieldSchema.nullable();
  }
  result[field.apiName] = fieldSchema;

  return result;
}

export function useDynamicValidationSchema(section: Pick<Section, 'fields' | 'type' | 'isEvent' | 'isProduct'>) {
  return useMemo(() => {
    const contentSchema = yup.object({
      languageId: yup.string().label('Language').required(),
      data: yup
        .object(
          (section.fields || [])
            .filter((sf) => sf.isLocalized)
            .reduce(makeFieldSchema, {
              title: yup.string().optional(),
            }),
        )
        .required(),
    });
    const dataSchema = yup.object((section.fields || []).filter((sf) => !sf.isLocalized)?.reduce(makeFieldSchema, {}));

    return yup.object({
      contents: yup.array(contentSchema).required(),
      data: dataSchema,
      // name: section.isEvent || section.isProduct ? yup.string().label('Name').required() : yup.string().optional(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section.fields]); // ignores extra fields to avoid having to memoize it
}
