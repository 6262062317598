import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { isTouched } from 'src/common/util';
import { SeoMetadata } from 'src/services/nodes';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '../Modal';
import { InputHook } from './InputHook';

const validationSchema = yup.object({
  metadata: yup.array(
    yup.object<SeoMetadata>({
      key: yup.string().label('Name').required(),
      value: yup.string().label('Value').required(),
    }),
  ),
});

type SeoMetadataEditorProps = {
  onChange: (value: SeoMetadata[]) => void;
  onBlur: () => void;
  value: SeoMetadata[];
  onCancel: () => void;
  isOpen: boolean;
};

export function SeoMetadataEditor({ value, onChange, onCancel, onBlur, isOpen }: SeoMetadataEditorProps) {
  const { control, formState, reset, handleSubmit } = useForm<{
    metadata: SeoMetadata[];
  }>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });
  const { fields, append, remove } = useFieldArray<SeoMetadata>({
    control,
    name: 'metadata',
  });

  function appendMetadata() {
    append({ key: '', value: '' });
  }

  const save = handleSubmit(async (values) => {
    onChange(values.metadata);
    onBlur();
    onCancel();
  });

  useEffect(() => {
    reset({ metadata: value || [] });
  }, [reset, value]);

  return (
    <Modal visible={isOpen} onClickBackground={onCancel}>
      <form onSubmit={save}>
        <div className="modal-card-head">
          <p className="modal-card-title">Metadata</p>
          <button type="button" className="delete" onClick={onCancel} />
        </div>
        <div className="modal-card-body content">
          {fields.map((field, index) => (
            <div className="columns" key={field.id}>
              <div className="column">
                <Controller
                  control={control}
                  name={`metadata[${index}].key`}
                  defaultValue={field.key}
                  as={
                    <InputHook
                      label="Name"
                      id={`metadata-${index}-key`}
                      error={formState.errors.metadata?.[index]?.key?.message}
                      touched={isTouched(formState, `metadata[${index}].key`)}
                    />
                  }
                />
              </div>
              <div className="column">
                <Controller
                  control={control}
                  name={`metadata[${index}].value`}
                  defaultValue={field.value}
                  as={
                    <InputHook
                      label="Value"
                      id={`metadata-${index}-value`}
                      error={formState.errors.metadata?.[index]?.value?.message}
                      touched={isTouched(formState, `metadata[${index}].value`)}
                    />
                  }
                />
              </div>
              <div className="column is-narrow">
                <button type="button" className="button is-white has-text-danger" onClick={() => remove(index)}>
                  <span className="icon">
                    <i className="fas fa-trash" />
                  </span>
                </button>
              </div>
            </div>
          ))}
          <div className="buttons">
            <button type="button" className="button is-primary is-fullwidth" onClick={appendMetadata}>
              <span>Add metadata</span>
              <span className="icon">
                <i className="fal fa-plus" />
              </span>
            </button>
          </div>
        </div>
        <div className="modal-card-foot">
          <button type="reset" className="button" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="submit"
            className={clsx('button is-primary', {
              'is-loading': formState.isSubmitting,
            })}
            disabled={formState.isSubmitting || !formState.isValid}
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
}
