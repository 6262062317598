import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import { HELP_ANIMATION_VARIANTS } from 'src/common/const';
import { SectionFieldOption } from 'src/services/sections';

type MultiChecksProps = {
  register?: any;
  options: SectionFieldOption[];
  name?: string;
  label: ReactNode;
  error?: string;
  touched?: boolean;
  id?: string;
  hideHelper?: boolean;
} & JSX.IntrinsicElements['input'];

export function MultiChecks({
  register,
  id,
  name,
  options,
  label,
  hideHelper,
  touched,
  error,
  ...rest
}: MultiChecksProps) {
  const hasError = touched && !!error;
  const helpClassName = clsx('help', {
    'is-danger': hasError,
  });
  const errorMessage = touched ? error || '' : '';

  return (
    <div className="multi-checks">
      <div className="main-label">{label}</div>
      <div className="field">
        {options.map((option) => (
          <div className="control" key={option.value}>
            <label htmlFor={`${id}-${option.value}`} className="checkbox">
              <input
                type="checkbox"
                ref={register}
                name={`${name}[]`}
                id={`${id}-${option.value}`}
                value={option.value}
                {...rest}
              />
              &nbsp;
              {option.label}
            </label>
          </div>
        ))}
        {!hideHelper ? (
          <div className="control">
            <div className="helper-wrapper">
              <AnimatePresence>
                {hasError && (
                  <motion.div initial="hidden" animate="visible" exit="hidden" variants={HELP_ANIMATION_VARIANTS}>
                    <p className={helpClassName}>{errorMessage}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
