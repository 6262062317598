import React, { useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import { navigate } from '@reach/router';
import { find, findIndex } from 'lodash';
import clsx from 'clsx';
import { fetchNodeDetails, useNodes, NodeContent, CollectionItem, NodeProduct } from 'src/services/nodes';
import {
  LayoutWithNavbar,
  CustomFields,
  SeoMetadataList,
  useSeoMetadata,
  SeoMetadataEditor,
  EditPageLayout,
  Accordion,
  SectionSpecificFields,
  EditPageLoading,
  ImageGallery,
  isTouched,
  SelectHook,
  generateCustomFieldsTabs,
  CssProvider,
  InputHook,
  parseDateToApi,
  getBreadcrumbs,
  usePrevPageLink,
  MainImage,
  CollectionsDraggableList,
  generateSubsectionsTabs,
  useReturnTo,
  EventFields,
  PublishableFields,
  contentComparer,
  useLocalizedAutoSlug,
  RichTextEditorTabs,
} from 'src/common';
import { useCategories } from 'src/services/categories';
import { SectionType, useDynamicValidationSchema, useSections, useSectionsInUse } from 'src/services/sections';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentEditProps } from 'src/types';
import { DEFAULT_API_DATE_FORMAT } from 'src/common/const';
import { format } from 'date-fns';
import { RecurringType } from 'src/services/events/types';
import { AccordionItem } from 'src/common/components/Accordion';
import { useProject } from 'src/services/project';
import { useTranslations } from 'src/services/integrations/hooks';
import { showToast } from 'src/common/util/show-toast';
import { ProductEditor } from 'src/common/components/form/ProductEditor';
import { CollectionItemWithRSelect, FormNode } from './types';

type NodesEditProps = RouteComponentEditProps;

export function NodesEdit({ id, slug, uri, collectionSlug }: NodesEditProps) {
  const [, returnId] = useReturnTo();
  const { sections } = useSections();
  const [mainSection, subSection] = useSectionsInUse(sections.items, slug!, uri!, collectionSlug);
  const { project } = useProject();
  const subsections = useMemo(
    () => sections.items.filter((s) => s.type === SectionType.Subsections && s.parentSectionId === subSection.id),
    [sections.items, subSection.id],
  );
  const [node, setNode] = useState<FormNode>(() => ({
    category: null,
    categoryId: null,
    seoMetadata: [],
    collectionItems: [],
    contents: [],
    data: {},
    event: null,
    product: {
      currency: '',
      price: 0,
      quantity: 0,
      sold: 0,
      stripeLiveId: '',
      stripeTestId: '',
      prices: [
        {
          dateFrom: null,
          dateTo: null,
          price: 0,
          stripeLiveId: '',
          stripeTestId: '',
          priceName: '',
        },
      ],
    },
    image: null,
    images: [],
    publishable: { isPublished: true, publishedFrom: new Date() },
    publishDate: format(new Date(), DEFAULT_API_DATE_FORMAT),
    publishTime: format(new Date(), 'HH:mm'),
    languageId: '',
    sectionId: subSection.id!,
    slug: '',
    alwaysActive: true,
    subsections: subsections.length
      ? subsections.reduce<Record<string, CollectionItemWithRSelect[]>>((result, section) => {
          result[section.slug] = [];
          return result;
        }, {})
      : undefined,
  }));
  const [loadingNode, setLoadingNode] = useState(true);
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const { createNode, updateNode } = useNodes(subSection.slug);
  const { categories, loadCategories } = useCategories();
  const formMethods = useForm<FormNode>({
    resolver: yupResolver(useDynamicValidationSchema(subSection)),
    mode: 'onChange',
    defaultValues: node,
  });
  const { formState, control, handleSubmit, register, reset, watch, getValues } = formMethods;

  const { fields, append, remove } = useFieldArray<NodeContent>({
    control,
    name: 'contents',
    keyName: '_id' as any, // Typings are not working as expected for this property
  });

  useLocalizedAutoSlug({ control, formState, baseField: 'data.title' });

  const watchSeoMetadata = watch('seoMetadata');
  const { closeEditor, editorOpen, openEditor } = useSeoMetadata();
  const { translate } = useTranslations(control, subSection.fields || []);

  const isEditing = !!id;
  const categoriesOptions = useMemo(
    () =>
      categories?.map((category) => ({
        value: category.id!,
        label: category.name,
      })) || [],
    [categories],
  );

  const listLink = usePrevPageLink(mainSection, subSection);

  const hasCategories = !!mainSection?.categorySectionId && !(subSection?.type === SectionType.Categories);
  const isPublishable = subSection ? subSection.isPublishable : !!mainSection?.isPublishable;

  const loading = (hasCategories && categories === null) || loadingNode;

  const addLanguage = useCallback(
    (languageId: string) => {
      if (fields && fields.find((e) => e.languageId === languageId)) {
        return;
      }

      append({
        languageId,
        slug: '',
      });
      setActiveTab(languageId);
    },
    [append, fields],
  );

  const removeLanguage = useCallback(
    (languageId: string) => {
      const index = findIndex(fields, { languageId });
      if (index < 0) return;

      remove(index);
      setActiveTab(fields[0].languageId!);
    },
    [fields, remove],
  );

  useEffect(() => {
    if (hasCategories) {
      loadCategories(mainSection.slug!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (node && !node?.category?.id && categoriesOptions.length > 0) {
      // TODO: review categories
      // @ts-ignore
      setNode({ ...node, category: find(categories, { id: categoriesOptions[0].value }) });
    }
  }, [node, categoriesOptions, categories]);

  useEffect(() => {
    async function loadNode() {
      if (id) {
        setLoadingNode(true);
        const result = await fetchNodeDetails(subSection.slug, Number(id));

        if (result) {
          const formNode = {
            ...result,
            categoryId: result.category?.id || null,
            contents:
              result.contents?.map((c) => ({ ...c, text: c.data?.text as string })).sort(contentComparer(project)) ||
              null,
            publishDate: result.publishable?.publishedFrom
              ? format(result.publishable?.publishedFrom, DEFAULT_API_DATE_FORMAT)
              : '',
            publishTime: result.publishable?.publishedFrom ? format(result.publishable?.publishedFrom, 'HH:mm') : '',
            publishedToDate: result.publishable?.publishedTo
              ? format(result.publishable?.publishedTo, DEFAULT_API_DATE_FORMAT)
              : '',
            publishedToTime: result.publishable?.publishedTo ? format(result.publishable?.publishedTo, 'HH:mm') : '',
            alwaysActive: !result.publishable?.publishedTo,
            recurring: (() => {
              if (result.event?.weekdayOccurrence != null) {
                return RecurringType.EveryMonthOnWeekday;
              }
              if (result.event?.recurringWeekDay != null) {
                return RecurringType.EveryWeek;
              }
              if (result.event?.recurringDay != null) {
                return RecurringType.EveryMonthOnDate;
              }
              return RecurringType.NonRecurring;
            })().toString(),
            collectionItems: (result.collectionItems || []).map((item) => ({
              ...item,
              item: { value: item.nodeId, label: item.nodeName },
            })),
            subsections: result.subsections
              ? Object.entries(result.subsections).reduce<Record<string, CollectionItemWithRSelect[]>>(
                  (ss, [subSlug, items]) => {
                    ss[subSlug] = items.map((item) => ({
                      ...item,
                      item: { value: item.nodeId, label: item.nodeName },
                    }));
                    return ss;
                  },
                  {},
                )
              : {},
          };

          setLoadingNode(false);
          setNode(formNode);
          reset(formNode);
          if (!formNode.contents?.length) {
            addLanguage(project.defaultLanguageId);
          } else {
            setActiveTab(formNode.contents[0].languageId);
          }
        } else {
          showToast('error', 'Node not found', 3000);
          navigate(listLink);
        }
      } else {
        setLoadingNode(false);
        addLanguage(project.defaultLanguageId);
      }
    }
    loadNode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const save = handleSubmit(async () => {
    // TODO: this is a workaround for null values on submit
    const values = getValues();
    const category = values.categoryId ? find(categories, { id: Number(values.categoryId) }) : null;

    const contents = (values.contents || []).map((c: any) => ({
      ...c,
      id: c.id ? Number(c.id) : undefined,
      data: { ...c.data, text: c.text },
    }));

    const { alwaysActive } = values;

    const data = {
      ...values,
      id: id ? parseInt(id, 10) : undefined,
      category,
      contents,
      sectionId: subSection.id,
      publishable: {
        isPublished: values.publishable?.isPublished ?? false,
        publishedFrom: values.publishDate ? parseDateToApi(values.publishDate, values.publishTime) : undefined,
        publishedTo:
          !alwaysActive && values.publishedToDate
            ? parseDateToApi(values.publishedToDate, values.publishedToTime)
            : undefined,
      },
      // @ts-ignore
      collectionItems: (values.collectionItems || []).map(({ item, id: collectionItemId }, index) => ({
        id: Number(collectionItemId) || undefined,
        position: index + 1,
        nodeId: item.value,
        nodeName: item.label,
      })),
      subsections: values.subsections
        ? Object.entries(values.subsections).reduce<Record<string, CollectionItem[]>>((result, [subSlug, items]) => {
            // @ts-ignore
            result[subSlug] = items.map(({ item, id: collectionItemId }, index) => ({
              id: Number(collectionItemId) || undefined,
              position: index + 1,
              nodeId: item.value,
              nodeName: item.label,
            }));
            return result;
          }, {})
        : undefined,
      parentSubsectionNodeId: Number(returnId) || undefined,
    };

    let result;

    // TODO: review category type
    if (isEditing) {
      // @ts-ignore
      result = await updateNode(data);
    } else {
      // @ts-ignore
      result = await createNode(data);
    }

    if (result.ok) {
      showToast('success', 'Successfully saved');
      navigate(listLink);
    } else {
      showToast('error', 'There was an error processing your action');
    }
  });

  const translationEnabled = project.hasTranslations && fields.length > 1;
  const hasCollections =
    sections.items.filter((s) => s.type === SectionType.Collections && s.parentSectionId === subSection.id).length > 0;

  if (loading) {
    return <EditPageLoading />;
  }

  return (
    <>
      <CssProvider css={mainSection.customCSS} />
      <LayoutWithNavbar
        backHref={listLink}
        breadcrumbs={getBreadcrumbs(mainSection, subSection, uri!, listLink)}
        endButtons={
          <div className="navbar-item">
            <button
              type="submit"
              className={clsx('button is-primary', {
                'is-loading': formState.isSubmitting,
              })}
              data-qa="save-button"
            >
              <span className="icon">
                <i className="far fa-check has-text-secondary-light" />
              </span>
              <span>Save</span>
            </button>
          </div>
        }
        as="form"
        onSubmit={save}
        languageProps={{
          fields,
          activeTab,
          setActiveTab,
          addLanguage,
          removeLanguage,
        }}
        translationProps={
          translationEnabled
            ? {
                onTranslate: translate,
                selectedLanguages: fields.map((content) => content.languageId!),
              }
            : undefined
        }
        previewProps={
          isEditing
            ? {
                language: activeTab || '',
                mainSection,
                subSection,
                nodeId: node?.id,
                nodeSlug: node?.slug,
              }
            : undefined
        }
        collectionAddProps={
          isEditing && hasCollections
            ? {
                nodeId: node?.id,
                nodeSection: subSection,
                sections: sections?.items,
              }
            : undefined
        }
      >
        <EditPageLayout
          renderLeft={
            <Accordion
              disableShadow
              items={
                [
                  {
                    Header: 'Main Image',
                    id: 'mainImage',
                    defaultExpanded: true,
                    content: (
                      <Controller
                        control={control}
                        name="images"
                        render={({ ref, ...props }) => <MainImage {...props} id="mainImage" />}
                      />
                    ),
                  },
                  {
                    Header: 'Common',
                    id: 'common',
                    content: (
                      <>
                        {hasCategories && (
                          <SelectHook
                            ref={register}
                            name="categoryId"
                            label="Category"
                            id="categoryId"
                            options={categoriesOptions}
                            error={formState.errors.categoryId?.message}
                            touched={isTouched(formState, 'categoryId')}
                          />
                        )}
                        {subSection.isEvent ? (
                          <EventFields control={control} formState={formState} node={node} register={register} />
                        ) : null}
                        {isPublishable && (
                          <>
                            <Controller
                              control={control}
                              name="data.author"
                              defaultValue=""
                              as={
                                <InputHook
                                  label="Author"
                                  id="author"
                                  error={formState.errors.data?.author?.message}
                                  touched={isTouched(formState, 'data.author')}
                                  fieldClassName="mb-3"
                                  prependIcon={<i className="fal fa-user" />}
                                />
                              }
                            />
                            <PublishableFields
                              control={control}
                              formState={formState}
                              register={register}
                              node={node}
                            />
                          </>
                        )}
                        {fields.map((content, index) => (
                          <div
                            className={activeTab !== content.languageId ? 'is-hidden' : 'mb-4'}
                            key={content.languageId}
                          >
                            <SectionSpecificFields
                              content={content}
                              control={control}
                              formState={formState}
                              index={index}
                              type={subSection.type}
                              node={node}
                              isMultiLanguage={fields.length > 1}
                              isEvent={subSection.isEvent}
                              isProduct={subSection.isProduct}
                            />
                          </div>
                        ))}
                        <CustomFields
                          sectionFields={subSection.fields}
                          {...formMethods}
                          currentLanguage={activeTab || ''}
                          languages={fields}
                        />
                      </>
                    ),
                    defaultExpanded: true,
                  },
                  {
                    Header: 'SEO Metadata',
                    id: 'seo',
                    content: <SeoMetadataList seoMetadata={watchSeoMetadata || []} onClick={openEditor} />,
                    defaultExpanded: true,
                  },
                ].filter(Boolean) as AccordionItem[]
              }
            />
          }
        >
          {fields.map((content, index) => (
            <Fragment key={content.languageId}>
              <input type="hidden" ref={register()} name={`contents[${index}].id`} defaultValue={content.id!} />
              <input
                type="hidden"
                ref={register()}
                name={`contents[${index}].languageId`}
                defaultValue={content.languageId}
              />
            </Fragment>
          ))}
          <RichTextEditorTabs
            fields={fields}
            currentLanguage={activeTab}
            control={control}
            tabs={[
              ...(subSection.type === SectionType.Collections
                ? [
                    {
                      label: 'Items',
                      id: 'items',
                      className: 'container is-fluid',
                      content: <CollectionsDraggableList {...formMethods} optionsSectionId={mainSection.id!} />,
                    },
                  ]
                : []),
              ...generateCustomFieldsTabs({
                nestedName: `data`,
                formState,
                control,
                sectionFields: subSection.fields?.filter((sf) => !sf.isLocalized),
              }),
              {
                label: 'Image Gallery',
                id: 'imageGallery',
                content: (
                  <Controller
                    control={control}
                    name="images"
                    render={({ ref: igRef, ...igProps }) => (
                      <ImageGallery
                        {...igProps}
                        value={watch('images', []) as any[]} // required for main image to work
                        label=""
                        id="imageGallery"
                      />
                    )}
                  />
                ),
              },
              ...(subSection.isProduct
                ? [
                    {
                      label: 'Product',
                      id: 'product',
                      content: (
                        <Controller
                          control={control}
                          name="product"
                          render={({ ref: igRef, ...igProps }) => {
                            return subSection.isProduct ? (
                              <ProductEditor {...igProps} value={watch('product') as NodeProduct} label="" />
                            ) : (
                              <></>
                            );
                          }}
                        />
                      ),
                    },
                  ]
                : []),
              ...generateSubsectionsTabs({
                subsections: node?.subsections,
                sections: sections.items,
                formMethods,
                nodeId: node?.id,
                currentSectionSlug: subSection.slug,
              }),
              ...fields
                .map((content, index) =>
                  generateCustomFieldsTabs({
                    defaultValues: content.data,
                    nestedName: `contents[${index}].data`,
                    formState,
                    control,
                    sectionFields: subSection.fields?.filter((sf) => sf.isLocalized),
                    localized: content.languageId,
                  }),
                )
                .flat(),
            ]}
          />
        </EditPageLayout>
      </LayoutWithNavbar>
      <Controller
        control={control}
        name="seoMetadata"
        defaultValue={[]}
        render={({ ref, ...props }) => <SeoMetadataEditor {...props} onCancel={closeEditor} isOpen={editorOpen} />}
      />
      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </>
  );
}
