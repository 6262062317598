import React, { ReactNode, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { motion } from 'framer-motion';
import move from 'array-move';
import { addMedia } from 'src/services/media/api';
import { createVideoMediaData } from 'src/common/util/create-video-media-data';
import { useCloudflareSettings } from 'src/services/integrations/hooks';
import { VideoMediaData } from 'src/common/types';
import { getFileInputValue } from 'src/common/util';
import { isEmpty, isLoading } from 'src/services/api';
import { uploadVideo } from 'src/common/util/cloudflare-stream';

type VideoGalleryProps = {
  label?: ReactNode;
  inline?: boolean;
  value: VideoMediaData[];
  onChange?: (value: VideoMediaData[]) => any;
} & JSX.IntrinsicElements['input'];

export function VideoGallery({
  label,
  inline = false,
  disabled,
  value,
  onChange,
  id,
  name,
  ...props
}: VideoGalleryProps) {
  const ref = useRef<HTMLInputElement>(null!);
  const [isUploading, setIsUploading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [videoPreview, setVideoPreview] = useState<VideoMediaData | null>(null);
  const { settings: cloudflareSettings, loadSettings: loadCloudflareSettings } = useCloudflareSettings();

  useEffect(() => {
    if (isEmpty(cloudflareSettings) && !isLoading(cloudflareSettings)) {
      loadCloudflareSettings();
    }
  }, [cloudflareSettings, loadCloudflareSettings]);

  const handleVideoChange = async (files: File | File[] | null) => {
    if (files && Array.isArray(files)) {
      setIsUploading(true);

      const res = (
        await Promise.all(
          files.map(async (file) => {
            const fileRes = await uploadVideo(file, cloudflareSettings.settings);

            if (fileRes.ok) {
              await addMedia(file, false);
              return createVideoMediaData(fileRes);
            }

            return null;
          }),
        )
      ).filter(Boolean);

      onChange!([...(value || []), ...(res.filter(Boolean) as VideoMediaData[])]);
      setIsUploading(false);
      setIsSuccess(true);
    } else {
      onChange!([...(value || [])]);
    }
    if (ref.current) {
      ref.current.value = '';
    }
  };

  const handleVideoPreviewChange = (video: VideoMediaData) => {
    setVideoPreview(video);
  };

  function removeCurrentImage(index: number) {
    const newValue = (value || []).slice();
    newValue.splice(index, 1);

    onChange!(newValue);
  }

  function onDragEnd(result: DropResult) {
    const newValue = move(value || [], result.source.index, result.destination!.index);
    onChange!(newValue);
  }

  useEffect(() => {
    if (isSuccess) {
      const timeout = setTimeout(() => setIsSuccess(false), 3000);

      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [isSuccess]);

  return (
    <div className={clsx('field image-gallery', inline && 'is-inlined')}>
      {label ? (
        <label htmlFor={id} className="label">
          {label}
        </label>
      ) : null}
      {/* TODO: replace react-beautiful-dnd with react-dnd */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`${id}-droppable`} isDropDisabled={disabled}>
          {(provided) => (
            <div className="images-container" ref={provided.innerRef} {...provided.droppableProps}>
              {(value || []).map((video, index) => (
                <Draggable
                  key={video.id}
                  draggableId={video.id?.toString() || index.toString()}
                  index={index}
                  isDragDisabled={disabled}
                >
                  {(draggableProvided) => (
                    // @ts-ignore
                    <div
                      className="thumb"
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.dragHandleProps}
                      {...draggableProvided.draggableProps}
                    >
                      <img src={video.thumbnail} alt="" />
                      {!disabled ? (
                        <div className="toolbox">
                          <button
                            type="button"
                            className="button is-white has-text-info is-rounded is-small button-remove"
                            onClick={() => removeCurrentImage(index)}
                          >
                            <span className="icon">
                              <i className="fal fa-minus" />
                            </span>
                          </button>
                          {video.id !== videoPreview?.id ? (
                            <button
                              type="button"
                              className="button is-white has-text-info is-small"
                              onClick={() => handleVideoPreviewChange(video)}
                            >
                              <span className="tag">Preview</span>
                            </button>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {!disabled ? (
                <motion.label
                  layout
                  htmlFor={id}
                  className={clsx('button button-add', {
                    'is-loading': isUploading,
                    'is-success': isSuccess,
                  })}
                >
                  {!isSuccess ? (
                    <>
                      <span
                        className={clsx('icon', {
                          'has-text-info': !isUploading,
                        })}
                      >
                        <i className="fal fa-plus" />
                      </span>
                      <span>Add</span>
                    </>
                  ) : (
                    <span className="icon">
                      <i className="fal fa-check" />
                    </span>
                  )}
                </motion.label>
              ) : null}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!disabled ? (
        <input
          type="file"
          id={id}
          ref={ref}
          onChange={() => handleVideoChange(getFileInputValue(ref))}
          accept="*"
          multiple
          disabled={isUploading}
          {...props}
        />
      ) : null}
      {videoPreview ? (
        <iframe
          title="test"
          src={`https://iframe.videodelivery.net/${videoPreview.uid}`}
          style={{ border: 'none' }}
          height="720"
          width="1280"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowFullScreen={true!}
        />
      ) : null}
    </div>
  );
}
