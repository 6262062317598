import React from 'react';
import Async, { Props } from 'react-select/async';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { HELP_ANIMATION_VARIANTS } from 'src/common/const';

type AsyncSelectProps = {
  label?: React.ReactNode;
  touched?: boolean;
  error?: string;
  fieldClassName?: string;
  id?: string;
  hideHelper?: boolean;
  disabled?: boolean;
} & Props<any, false>;

export function AsyncSelect({
  id,
  label,
  touched,
  error,
  fieldClassName,
  hideHelper,
  className,
  disabled,
  ...props
}: AsyncSelectProps) {
  const hasError = touched && !!error;
  const helpClassName = clsx('help', {
    'is-danger': hasError,
  });
  const errorMessage = touched ? error || '' : '';

  return (
    <div className={clsx('field has-floating-label', fieldClassName)}>
      <label htmlFor={id} className="label is-active">
        {label}
      </label>
      <div className="control">
        <div className={clsx('select is-fullwidth')}>
          <Async
            // key is set to defaultValue because the defaultValue is initially undefined and
            // after it's set, control won't reload and show it unless we do it manually
            // by changing the key
            // eslint-disable-next-line
            key={props.defaultValue}
            menuPlacement="auto"
            components={{
              IndicatorSeparator: null,
              DropdownIndicator: null,
            }}
            className={clsx('rselect-container', className)}
            classNamePrefix="rselect"
            defaultOptions
            menuPortalTarget={document.body}
            isDisabled={disabled}
            {...props}
          />
        </div>
      </div>
      {!hideHelper ? (
        <div className="helper-wrapper">
          <AnimatePresence>
            {hasError && (
              <motion.div initial="hidden" animate="visible" exit="hidden" variants={HELP_ANIMATION_VARIANTS}>
                <p className={helpClassName}>{errorMessage}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : null}
    </div>
  );
}
