import { capitalize } from 'lodash';
import React from 'react';
import { SeoMetadata } from 'src/services/nodes';
import { InputHook } from './InputHook';

type SeoMetadataListProps = {
  seoMetadata: SeoMetadata[] | null;
  touched?: boolean;
  onClick: () => void;
} & JSX.IntrinsicElements['div'];

export function SeoMetadataList({ seoMetadata, touched, onClick, ...props }: SeoMetadataListProps) {
  return (
    <div {...props} className="mb-4">
      <button type="button" className="button is-outlined mb-4" onClick={onClick}>
        <span className="icon has-text-primary">
          <i className="fal fa-pencil" />
        </span>
        <span>Edit Metadata</span>
      </button>
      {seoMetadata?.map((md) => (
        <InputHook
          key={md.key}
          style={{ textOverflow: 'ellipsis' }}
          label={capitalize(md.key)}
          id={`seo-metadata-${md.key}`}
          touched={touched}
          value={md.value}
          readOnly
        />
      ))}
    </div>
  );
}
