import { Link, navigate, RouteComponentProps } from '@reach/router';
import clsx from 'clsx';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { LayoutWithNavbar } from 'src/common';
import { formatFileSize } from 'src/common/util';
import { showToast } from 'src/common/util/show-toast';
import { getChargebeeHostedPage, updateSubscriptionData, getSubscriptionData } from 'src/services/billing/api';
import { Subscription } from 'src/services/billing/types';
import { fetchOrganizationDetails, fetchOrganizationUsage } from 'src/services/organizations/api';
import { Organization, OrganizationUsage } from 'src/services/organizations/types';
import { useCurrentUser } from 'src/services/users';
import { PLANS } from './const';
import { BillingPeriod } from './types';

type BillingAndPlansProps = RouteComponentProps<{
  organizationId: number;
}>;

const PLAN_SPECIFICATIONS: Record<string, any> = {
  free: {
    description:
      'Try out our platform and see how it fits in your stack. This plan can be enough for small and simple projects.',
    features: [
      { icon: 'fal fa-box', text: 'Main features included' },
      { icon: 'fal fa-server', text: 'Unlimited traffic' },
      { icon: 'fal fa-coin', text: 'Always free' },
    ],
  },
  freelancer: {
    description:
      // eslint-disable-next-line
      'A single admin with unlimited projects and content managers. Includes a few extra features. This plan is designed to fit the needs of freelancers.',
    features: [
      { icon: 'fal fa-box', text: 'Unlimited projects' },
      { icon: 'fal fa-server', text: 'Key Value store' },
      { icon: 'fal fa-coin', text: 'Member management' },
    ],
  },
  agency: {
    description:
      // eslint-disable-next-line
      'Unlimited projects, admins and content managers. Includes some more complex features. This plan is meant for companies and agencies which have more than one developer.',
    features: [
      { icon: 'fal fa-box', text: 'All features included' },
      { icon: 'fal fa-server', text: 'Language translations' },
      { icon: 'fal fa-coin', text: 'Third party integrations' },
    ],
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function BillingAndPlans({ organizationId }: BillingAndPlansProps) {
  const { currentUser } = useCurrentUser();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [usage, setUsage] = useState<OrganizationUsage | null>(null);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>(BillingPeriod.Monthly);
  const [plansVisible, setPlansVisible] = useState<boolean>(true);

  useEffect(() => {
    async function loadOrganization() {
      if (organizationId) {
        const result = await fetchOrganizationDetails(organizationId);

        if (result) {
          setOrganization(result);

          const [subscriptionDataResult, usageResult] = await Promise.all([
            getSubscriptionData(organizationId),
            fetchOrganizationUsage(organizationId),
          ]);

          setUsage(usageResult);
          setSubscription(subscriptionDataResult);
        } else {
          showToast('error', 'Organization not found', 3000);
          navigate('/dashboard');
        }
      }
    }
    loadOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  useEffect(() => {
    const el = document.createElement('script');
    el.onload = () => {
      // @ts-ignore
      window.Chargebee.init({
        site: process.env.REACT_APP_CHARGEBEE_SITE,
      });
      // @ts-ignore
      window.Chargebee.registerAgain();
    };
    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
    document.body.appendChild(el);
  }, []);

  const handleChargebeeCreateSubscription = async (planId: string) => {
    // @ts-ignore
    const chargebeeInstance = window.Chargebee.getInstance();

    chargebeeInstance.openCheckout({
      hostedPage: async () => {
        return getChargebeeHostedPage(organization!.id!, currentUser.email, planId);
      },
      success: async (hostedPageId: any) => {
        await updateSubscriptionData(organization!.id!, hostedPageId);
      },
    });
  };

  const handleChargebeeShowPortal = async (sectionType: string | undefined) => {
    // @ts-ignore
    const chargebeeInstance = window.Chargebee.getInstance();

    const portal = chargebeeInstance.createChargebeePortal();
    portal.open({}, { sectionType, params: { subscriptionId: organization?.subscriptionId } });
  };

  const handleChangeBillingPeriod: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value as BillingPeriod;

    setBillingPeriod(value);
  };

  const renderInfoCard = (title: string, content: string, actionText: string, action: () => void) => (
    <div className="column is-one-third" key={title}>
      <div className="card info-card">
        <div className="card-content">
          <p className="subtitle is-6 mb-5">{title}</p>
          <p className="title is-1 mb-3">{content}</p>
          <button type="button" className="button is-ghost is-small" onClick={action}>
            {actionText}
          </button>
        </div>
      </div>
    </div>
  );

  const renderCostCard = () => {
    const title = organization!.plan.isMonthly ? 'Monthly Cost' : 'Annual Cost';
    const actionText = organization!.plan.isMonthly ? 'Switch to annual payment' : 'Switch to monthly payment';
    const price = `$${subscription?.price.toFixed(2)}`;

    return renderInfoCard(title, price, actionText, () => handleChargebeeShowPortal('edit_subscription'));
  };

  const renderPaymentCard = () => {
    return renderInfoCard(
      'Next Payment',
      subscription?.nextBillingAt.toLocaleDateString('en-US', { day: 'numeric', month: 'short' }) || '',
      'View payment history',
      () => handleChargebeeShowPortal('portal_billing_history'),
    );
  };

  const renderUsageStat = (
    title: string,
    description: string,
    current: number,
    max: number,
    formatter: any | undefined = undefined,
  ) => (
    <div className="column is-one-third stat-column" key={title}>
      <p className="title is-6 mb-1">{title}</p>
      <p className="has-text-grey is-size-7 mb-2">{description}</p>
      <progress className="progress is-small is-info mb-1" value={current} max={max} />
      <div className="columns is-gapless has-text-grey is-size-7">
        <div className="column">{formatter ? formatter(current) : current}</div>
        <div className="column has-text-right">{formatter ? formatter(max) : max}</div>
      </div>
    </div>
  );

  const renderStorageUsage = () => {
    const percentage = ((usage?.storageUsage || 0) / (usage?.storageTotal || 1)) * 100;
    return renderUsageStat(
      'Storage',
      `Your storage is ${percentage.toFixed(0)}% full`,
      usage?.storageUsage || 0,
      usage?.storageTotal || 0,
      formatFileSize,
    );
  };

  const renderUsersUsage = () => {
    const description =
      (usage?.usersTotal || 0) - (usage?.usersCount || 0) > 0
        ? `You can invite ${(usage?.usersTotal || 0) - (usage?.usersCount || 0)} more content managers`
        : 'You have reached the limit for invited content managers.';

    return renderUsageStat('Content Managers', description, usage?.usersCount || 0, usage?.usersTotal || 0);
  };

  const renderAdminsUsage = () => {
    const description =
      (usage?.adminsTotal || 0) - (usage?.adminsCount || 0) > 0
        ? `You can invite ${(usage?.adminsTotal || 0) - (usage?.adminsCount || 0)} more admins`
        : 'You have reached the limit for invited admins.';

    return renderUsageStat('Admins', description, usage?.adminsCount || 0, usage?.adminsTotal || 0);
  };

  if (!organization || !subscription) return null;

  return (
    <LayoutWithNavbar breadcrumbs={[{ label: 'Billing & Plans', href: './' }]}>
      <div className="table-container billing-and-plans">
        <div className="columns">
          {renderCostCard()}
          {renderPaymentCard()}

          <div className="column is-one-third">
            <div className="card info-card">
              <div className="card-content">
                <p className="subtitle is-6 mb-2">Actions</p>
                <div>
                  <Link type="button" className="button is-ghost is-small" to={`/organizations/${organization?.id}`}>
                    Manage projects
                  </Link>
                </div>
                <div>
                  <button
                    type="button"
                    className="button is-ghost is-small"
                    onClick={() => handleChargebeeShowPortal('portal_payment_methods')}
                  >
                    Configure payments
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="button is-ghost is-small"
                    onClick={() => setPlansVisible(!plansVisible)}
                  >
                    {plansVisible ? 'Hide plans' : 'Compare plans'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {plansVisible && (
          <>
            <div className="columns is-vcentered">
              <div className="column">
                <h2 className="title is-3"> </h2>
              </div>
              <div className="column is-narrow">
                <div className="control">
                  <label className="radio">
                    <input
                      type="radio"
                      name="period"
                      value="monthly"
                      checked={billingPeriod === BillingPeriod.Monthly}
                      onChange={handleChangeBillingPeriod}
                    />{' '}
                    Billed Monthly
                  </label>{' '}
                  <label className="radio">
                    <input
                      type="radio"
                      name="period"
                      value="yearly"
                      checked={billingPeriod === BillingPeriod.Yearly}
                      onChange={handleChangeBillingPeriod}
                    />{' '}
                    Billed Yearly
                  </label>
                </div>
              </div>
            </div>
            <div className="columns">
              {PLANS.map((plan) => (
                <div className="column is-one-third" key={plan.idAnnual}>
                  <div className="card plan-card">
                    <div className="card-content">
                      <div className="media mb-2">
                        <div className="media-content">
                          <p className={clsx('title is-7 plan-name mb-1', plan.isFree && 'has-text-info')}>
                            {plan.name}
                          </p>
                          <h3 className="title is-4">
                            {`$${billingPeriod === BillingPeriod.Monthly ? plan.priceMonthly : plan.priceAnnual}`}
                            <small>/{billingPeriod === BillingPeriod.Monthly ? 'month' : 'year'}</small>
                          </h3>
                        </div>
                        <div className="media-right">
                          {plan.idAnnual === organization.plan.id || plan.idMonthly === organization.plan.id ? (
                            <p className="has-text-grey is-size-7 mb-4">Your plan</p>
                          ) : (
                            <button
                              type="button"
                              className="button is-primary is-small"
                              onClick={() =>
                                organization?.plan?.isFree
                                  ? handleChargebeeCreateSubscription(plan.chargebeeIdMonthly)
                                  : handleChargebeeShowPortal('edit_subscription')
                              }
                            >
                              <span className="icon">
                                <i className="fal fa-chevron-circle-up" />
                              </span>
                              <span>Switch</span>
                            </button>
                          )}
                        </div>
                      </div>
                      <p className="has-text-grey is-size-7 mb-4" style={{ minHeight: '75px' }}>
                        {PLAN_SPECIFICATIONS[plan?.name?.toLowerCase()]?.description}
                      </p>
                      <ul className="features">
                        {PLAN_SPECIFICATIONS[plan.name.toLowerCase()].features.map((feature: any) => (
                          <li key={feature.icon}>
                            <span className="icon">
                              <i className={feature.icon} />
                            </span>
                            <span>{feature.text}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <div className="card usage-card">
          <div className="card-content">
            <div className="media mb-2">
              <div className="media-content">
                <p className="title is-3">Usage</p>
              </div>
              <div className="media-right">
                <button type="button" className="button is-outlined">
                  <span className="icon has-text-info">
                    <i className="far fa-chart-pie" />
                  </span>
                  <span>Usage Report</span>
                </button>
              </div>
            </div>
            <p className="has-text-grey mb-4">Track and manage your current and future resource usage.</p>

            <div className="columns">
              {renderStorageUsage()}
              {renderAdminsUsage()}
              {renderUsersUsage()}
            </div>
          </div>
        </div>
      </div>
    </LayoutWithNavbar>
  );
}
