import * as tus from 'tus-js-client';
import { addCloudflareVideo } from 'src/services/media/api';
import { CloudflareSettings } from 'src/services/integrations';

export const uploadVideo = (file: any, cloudflareSettings: CloudflareSettings): any => {
  return new Promise((resolve, reject) => {
    let streamMediaId: string;

    // NOTE: Create a new tus upload
    const options = {
      endpoint: `https://api.cloudflare.com/client/v4/accounts/${cloudflareSettings.accountId}/stream`,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        name: file.name,
        requireSignedURLs: 'true',
      },
      headers: {
        'X-Auth-Key': cloudflareSettings.apiKey!,
        'X-Auth-Email': cloudflareSettings.accountEmail!,
      },
      onError: (error: any) => {
        // TODO: handle exception
        reject(error);
      },
      onAfterResponse: async (req: any, res: any) => {
        streamMediaId = res.getHeader('stream-media-id');
      },
      onSuccess: async () => {
        const res = await addCloudflareVideo(streamMediaId);
        resolve(res);
      },
    };
    const upload: any = new tus.Upload(file, options);
    upload.start();
  });
};
