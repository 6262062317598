import { CURRENCIES_MAP } from '../const';

export function currencyFormat(value: number, currency: string) {
  try {
    const formatter = new Intl.NumberFormat('en-US', {
      currency: currency in CURRENCIES_MAP ? CURRENCIES_MAP[currency] : currency,
      style: 'currency',
    });

    return formatter.format(value);
  } catch (error) {
    // Return default formatted as USD if it fails the first time while the user is
    // still inputting their currency. Eg.:
    // currency === 'b' // user types "b" for "brl", formats as USD in the meantime
    const formatter = new Intl.NumberFormat('en-US', {
      currency: 'usd',
      style: 'currency',
    });

    return formatter.format(value);
  }
}
