import { combineReducers } from 'redux';

import { AuthActionTypes } from 'src/services/auth/redux/actions';
import {
  chainReducers,
  createApiReducer,
  createCrudApiReducerWithoutPaging,
  INITIAL_API_STATE,
} from 'src/services/api/redux/reducers';
import { SectionActionTypes } from './actions';
import { transformSection } from '../transformations';
import { Section } from '../types';

const sectionsReducer = createCrudApiReducerWithoutPaging<Section>(
  SectionActionTypes.CHANGE_SECTIONS_START,
  SectionActionTypes.CHANGE_SECTIONS_FAILURE,
  SectionActionTypes.FETCH_SECTIONS_SUCCESS,
  SectionActionTypes.ADD_SECTION_SUCCESS,
  SectionActionTypes.UPDATE_SECTION_SUCCESS,
  SectionActionTypes.DELETE_SECTION_SUCCESS,
  transformSection,
);

const signOut = createApiReducer(
  ['', AuthActionTypes.SIGN_OUT, ''],
  () => INITIAL_API_STATE,
  () => INITIAL_API_STATE,
);

export default combineReducers({ sections: chainReducers(INITIAL_API_STATE, sectionsReducer, signOut) });
