import { RouteComponentProps, useMatch } from '@reach/router';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useAuth } from 'src/services/auth';
import { useCurrentUser } from 'src/services/users';
import { LINK_ENTER_VARIANTS } from '../const';
import { NavLink } from './NavLink';
import SidebarProfileButton from './SidebarProfileButton';
import { SUPERADMIN_USER_ID } from '../../modules/impersonate/const';

type UserSidebarProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function UserSidebar(_: UserSidebarProps) {
  const { id, fullName } = useCurrentUser();
  const profileMatch = useMatch('/profile');
  const { logOut } = useAuth();

  return (
    <>
      <ul className="sidebar-menu">
        <AnimatePresence>
          <motion.li
            layout
            variants={LINK_ENTER_VARIANTS}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ delay: 0.05 }}
          >
            <NavLink to="/dashboard" className="menu-link">
              <span className="icon">
                <i className="fal fa-border-all" />
              </span>
              <span className="ml-2">Dashboard</span>
            </NavLink>
          </motion.li>
          {Number(id) === SUPERADMIN_USER_ID ? (
            <motion.li
              layout
              variants={LINK_ENTER_VARIANTS}
              initial="hidden"
              animate="visible"
              exit="hidden"
              key="projects"
              transition={{ delay: 0.05 }}
            >
              <NavLink to="/admin/users" className="menu-link">
                <span className="icon">
                  <i className="fal fa-users" />
                </span>
                <span className="ml-2">Users</span>
              </NavLink>
            </motion.li>
          ) : null}
        </AnimatePresence>
      </ul>
      <ul className="sidebar-menu">
        <AnimatePresence>
          <SidebarProfileButton delay={0.1} fullName={fullName} logOut={logOut} profileMatch={!!profileMatch} />
        </AnimatePresence>
      </ul>
    </>
  );
}
