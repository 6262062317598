import React, { FormEventHandler } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { FADE_IN_VARIANTS } from 'src/common/const';
import { FieldErrorMessage } from './FieldErrorMessage';

export type FloatingLabelFieldProps = {
  fieldClassName?: string;
  labelClassName?: string;
  label?: React.ReactNode;
  id?: string;
  error?: string;
  children?: React.ReactNode;
  prependIcon?: React.ReactNode;
  touched?: boolean;
  hideHelper?: boolean;
  hasError?: boolean;
  type?: string;
  onFocus?: FormEventHandler;
  onBlur?: FormEventHandler;
  onChange?: FormEventHandler;
  className?: string;
  value?: any;
  name?: string;
  rounded?: boolean;
};

export function FloatingLabelField({
  fieldClassName,
  label,
  id,
  labelClassName,
  children,
  hasError = false,
  error,
  prependIcon,
  hideHelper = false,
  type,
}: FloatingLabelFieldProps) {
  if (type === 'hidden') {
    return <>{children}</>;
  }
  return (
    <div className={clsx('field has-floating-label', fieldClassName)}>
      {label ? (
        <motion.label
          variants={FADE_IN_VARIANTS}
          animate={hasError ? 'hidden' : 'visible'}
          htmlFor={id}
          className={clsx('label', labelClassName)}
        >
          {label}
        </motion.label>
      ) : null}
      {!hideHelper ? <FieldErrorMessage hasError={hasError} error={error} /> : null}
      <div className={clsx('control', { 'has-icons-left': !!prependIcon })}>
        {children}
        {prependIcon ? <span className="icon is-left">{prependIcon}</span> : null}
      </div>
    </div>
  );
}
