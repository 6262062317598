import urlcat from 'urlcat';

import { performApiCall } from '../api';
import { transformConnection } from './transformations';
import { Connection } from './types';

export const fetchConnectionDetails = async (connectionSlug: string, connectionId: number): Promise<Connection> => {
  const res = await performApiCall({
    path: urlcat('/connections', '/:slug/:id', {
      slug: connectionSlug,
      id: connectionId,
    }),
  });

  return transformConnection(res.data);
};
