export const WEBHOOK_TEST_PAYLOAD_CACHEBUST = {
  sectionId: 123,
  sectionSlug: 'section-slug',
  nodeId: 234,
  nodeSlug: 'node-slug',
};

export enum ProjectFieldType {
  STANDARD_PROJECT = 1,
  THEMED_PROJECT = 2,
}

export enum SelfHostedType {
  INITIAL = '',
  CUSTOM = 'custom',
  AUTO_GENERATED = 'auto',
}

export const PROJECT_TYPE_OPTIONS = [
  { value: ProjectFieldType.STANDARD_PROJECT, label: 'Create standard project' },
  { value: ProjectFieldType.THEMED_PROJECT, label: 'Create themed project' },
];

export const DOMAIN_REGEX =
  /^(((?!-))(xn--)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30})\.[a-z]{2,}$/;

export const SUBDOMAIN_REGEX = /[a-zA-Z0-9-_]+$/;
