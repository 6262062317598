import { createApiAction } from 'src/services/api';

export enum StripeActionTypes {
  CHANGE_STRIPE_START = 'integrations/change_stripe_start',
  CHANGE_STRIPE_FAILURE = 'integrations/change_stripe_failure',
  FETCH_STRIPE_SUCCESS = 'integrations/fetch_stripe_success',
  UPDATE_STRIPE_SUCCESS = 'integrations/update_stripe_success',
  DELETE_STRIPE_SUCCESS = 'integrations/delete_stripe_success',
}

export enum MailchimpActionTypes {
  CHANGE_MAILCHIMP_START = 'integrations/change_mailchimp_start',
  CHANGE_MAILCHIMP_FAILURE = 'integrations/change_mailchimp_failure',
  FETCH_MAILCHIMP_SUCCESS = 'integrations/fetch_mailchimp_success',
  UPDATE_MAILCHIMP_SUCCESS = 'integrations/update_mailchimp_success',
  DELETE_MAILCHIMP_SUCCESS = 'integrations/delete_mailchimp_success',
}

export enum CloudflareActionTypes {
  CHANGE_CLOUDFLARE_START = 'integrations/change_cloudflare_start',
  CHANGE_CLOUDFLARE_FAILURE = 'integrations/change_cloudflare_failure',
  FETCH_CLOUDFLARE_SUCCESS = 'integrations/fetch_cloudflare_success',
  UPDATE_CLOUDFLARE_SUCCESS = 'integrations/update_cloudflare_success',
  DELETE_CLOUDFLARE_SUCCESS = 'integrations/delete_cloudflare_success',
}

export enum RenteonActionTypes {
  CHANGE_RENTEON_START = 'integrations/change_renteon_start',
  CHANGE_RENTEON_FAILURE = 'integrations/change_renteon_failure',
  FETCH_RENTEON_SUCCESS = 'integrations/fetch_renteon_success',
  UPDATE_RENTEON_SUCCESS = 'integrations/update_renteon_success',
  DELETE_RENTEON_SUCCESS = 'integrations/delete_renteon_success',
}

export const fetchStripeSettings = () =>
  createApiAction({ path: '/integrations/stripe/settings', method: 'GET' }, [
    StripeActionTypes.CHANGE_STRIPE_START,
    StripeActionTypes.FETCH_STRIPE_SUCCESS,
    StripeActionTypes.CHANGE_STRIPE_FAILURE,
  ]);

export const updateStripeSettings = (data: any) =>
  createApiAction(
    {
      path: '/integrations/stripe/settings',
      method: 'POST',
      body: data,
    },
    [
      StripeActionTypes.CHANGE_STRIPE_START,
      StripeActionTypes.UPDATE_STRIPE_SUCCESS,
      StripeActionTypes.CHANGE_STRIPE_FAILURE,
    ],
  );

export const deleteStripeSettings = () =>
  createApiAction(
    {
      path: '/integrations/stripe/settings',
      method: 'DELETE',
    },
    [
      StripeActionTypes.CHANGE_STRIPE_START,
      StripeActionTypes.DELETE_STRIPE_SUCCESS,
      StripeActionTypes.CHANGE_STRIPE_FAILURE,
    ],
  );

export const fetchMailchimpSettings = () =>
  createApiAction({ path: '/integrations/mailchimp/settings', method: 'GET' }, [
    MailchimpActionTypes.CHANGE_MAILCHIMP_START,
    MailchimpActionTypes.FETCH_MAILCHIMP_SUCCESS,
    MailchimpActionTypes.CHANGE_MAILCHIMP_FAILURE,
  ]);

export const updateMailchimpSettings = (data: any) =>
  createApiAction(
    {
      path: '/integrations/mailchimp/settings',
      method: 'POST',
      body: data,
    },
    [
      MailchimpActionTypes.CHANGE_MAILCHIMP_START,
      MailchimpActionTypes.UPDATE_MAILCHIMP_SUCCESS,
      MailchimpActionTypes.CHANGE_MAILCHIMP_FAILURE,
    ],
  );

export const deleteMailchimpSettings = () =>
  createApiAction(
    {
      path: '/integrations/mailchimp/settings',
      method: 'DELETE',
    },
    [
      MailchimpActionTypes.CHANGE_MAILCHIMP_START,
      MailchimpActionTypes.DELETE_MAILCHIMP_SUCCESS,
      MailchimpActionTypes.CHANGE_MAILCHIMP_FAILURE,
    ],
  );

export const fetchCloudflareSettings = () =>
  createApiAction({ path: '/integrations/cloudflare/settings', method: 'GET' }, [
    CloudflareActionTypes.CHANGE_CLOUDFLARE_START,
    CloudflareActionTypes.FETCH_CLOUDFLARE_SUCCESS,
    CloudflareActionTypes.CHANGE_CLOUDFLARE_FAILURE,
  ]);

export const updateCloudflareSettings = (data: any) =>
  createApiAction({ path: '/integrations/cloudflare/settings', method: 'POST', body: data }, [
    CloudflareActionTypes.CHANGE_CLOUDFLARE_START,
    CloudflareActionTypes.UPDATE_CLOUDFLARE_SUCCESS,
    CloudflareActionTypes.CHANGE_CLOUDFLARE_FAILURE,
  ]);

export const deleteCloudflareSettings = () =>
  createApiAction(
    {
      path: '/integrations/cloudflare/settings',
      method: 'DELETE',
    },
    [
      CloudflareActionTypes.CHANGE_CLOUDFLARE_START,
      CloudflareActionTypes.DELETE_CLOUDFLARE_SUCCESS,
      CloudflareActionTypes.CHANGE_CLOUDFLARE_FAILURE,
    ],
  );

export const fetchRenteonSettings = () =>
  createApiAction({ path: '/integrations/renteon/settings', method: 'GET' }, [
    RenteonActionTypes.CHANGE_RENTEON_START,
    RenteonActionTypes.FETCH_RENTEON_SUCCESS,
    RenteonActionTypes.CHANGE_RENTEON_FAILURE,
  ]);

export const updateRenteonSettings = (data: any) =>
  createApiAction({ path: '/integrations/renteon/settings', method: 'POST', body: data }, [
    RenteonActionTypes.CHANGE_RENTEON_START,
    RenteonActionTypes.UPDATE_RENTEON_SUCCESS,
    RenteonActionTypes.CHANGE_RENTEON_FAILURE,
  ]);

export const deleteRenteonSettings = () =>
  createApiAction(
    {
      path: '/integrations/renteon/settings',
      method: 'DELETE',
    },
    [
      RenteonActionTypes.CHANGE_RENTEON_START,
      RenteonActionTypes.DELETE_RENTEON_SUCCESS,
      RenteonActionTypes.CHANGE_RENTEON_FAILURE,
    ],
  );
