import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { SectionField, SectionFieldType, useSectionItems } from 'src/services/sections';
import { TextArrayEditor } from 'src/common/components/form/TextArrayEditor';
import { VideoAutoUploaderHook } from 'src/common/components/form/VideoAutoUploaderHook';
import {
  AsyncSelect,
  Checkbox,
  ColorPicker,
  DatePicker,
  FileAutoUploaderHook,
  ImageAutoUploaderHook,
  ImageGallery,
  InputHook,
  MultiChecks,
  MultiInputHook,
  QuillHook,
  SelectHook,
} from 'src/common/components';
import SectionMultiLink from 'src/common/components/form/SectionMultiLink';
import { parseISO } from 'src/common/util/parse-iso';

type GetInputForTypeOptions = Pick<UseFormMethods<Record<string, any>>, 'control' | 'register'> & {
  name: string;
  commonProps: {
    disabled: boolean;
    id?: string;
    label: string;
    error?: string;
    touched?: boolean;
  };
  watchedValues: Record<string, any>;
  languageId: string;
  defaultValue?: any;
  loadOptions: ReturnType<typeof useSectionItems>;
  richText?: boolean;
};
export function getInputForType(
  sf: SectionField,
  {
    control,
    name,
    commonProps,
    languageId,
    defaultValue,
    watchedValues,
    register,
    loadOptions,
  }: GetInputForTypeOptions,
) {
  const disabledProps = {
    value: watchedValues[sf.apiName],
    disabled: true,
    label: commonProps.label,
    key: name,
  };

  switch (sf.type) {
    case SectionFieldType.Text:
      return commonProps.disabled ? (
        <InputHook {...disabledProps} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || ''}
          as={<InputHook {...commonProps} />}
          key={name}
          data-qa={`node-field${sf.isLocalized ? `-${languageId}` : ''}-${sf.apiName}`}
        />
      );

    case SectionFieldType.TextArray:
      return commonProps.disabled ? (
        <InputHook {...disabledProps} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || []}
          render={({ ref, ...props }) => (
            <TextArrayEditor {...props} id={sf.id?.toString() || sf.apiName} {...commonProps} />
          )}
          key={name}
          data-qa={`node-field${sf.isLocalized ? `-${languageId}` : ''}-${sf.apiName}`}
        />
      );

    case SectionFieldType.Number:
      return commonProps.disabled ? (
        <InputHook {...disabledProps} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || ''}
          as={<InputHook type="number" {...commonProps} />}
          key={name}
          data-qa={`node-field${sf.isLocalized ? `-${languageId}` : ''}-${sf.apiName}`}
        />
      );

    case SectionFieldType.Date:
      return commonProps.disabled ? (
        <InputHook {...disabledProps} prependIcon={<i className="fal fa-calendar fa-lg" />} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || null}
          render={({ value, ...rest }) => (
            <DatePicker
              selected={typeof value === 'string' ? parseISO(value) : value}
              {...commonProps}
              prependIcon={<i className="fal fa-calendar fa-lg" />}
              withPortal
              portalId={commonProps.id}
              {...rest}
            />
          )}
          key={name}
        />
      );

    case SectionFieldType.DateTime:
      return commonProps.disabled ? (
        <InputHook {...disabledProps} prependIcon={<i className="fal fa-calendar fa-lg" />} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || null}
          render={({ value, ...rest }) => (
            <DatePicker
              showTimeSelect
              selected={value}
              {...commonProps}
              prependIcon={<i className="fal fa-calendar fa-lg" />}
              dateFormat="Pp"
              withPortal
              portalId={commonProps.id}
              {...rest}
            />
          )}
          key={name}
        />
      );

    case SectionFieldType.File:
      return commonProps.disabled ? (
        <InputHook {...disabledProps} value={disabledProps.value?.name || ''} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || null}
          render={({ ref, ...props }) => (
            <FileAutoUploaderHook label={sf.name} id={sf.id?.toString()} isPublic={sf.isPublic} {...props} />
          )}
          key={name}
        />
      );

    case SectionFieldType.Video:
      return commonProps.disabled ? (
        <VideoAutoUploaderHook {...disabledProps} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || null}
          render={({ ref, ...props }) => (
            <VideoAutoUploaderHook label={sf.name} id={sf.id?.toString() || sf.apiName} {...props} />
          )}
          key={name}
        />
      );

    case SectionFieldType.Image:
      return commonProps.disabled ? (
        <ImageAutoUploaderHook {...disabledProps} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || null}
          render={({ ref, ...props }) => (
            <ImageAutoUploaderHook label={sf.name} id={sf.id?.toString() || sf.apiName} {...props} />
          )}
          key={name}
        />
      );

    case SectionFieldType.ImageGallery:
      return commonProps.disabled ? (
        <ImageGallery {...disabledProps} inline />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || null}
          render={({ ref, ...props }) => (
            <ImageGallery {...props} id={sf.id?.toString() || sf.apiName} label={commonProps.label} inline />
          )}
          key={name}
        />
      );

    case SectionFieldType.Boolean:
      return commonProps.disabled ? (
        <Checkbox
          {...disabledProps}
          value={undefined}
          checked={disabledProps.value === 'true' || disabledProps.value === true}
        />
      ) : (
        <Checkbox ref={register()} defaultChecked={defaultValue} {...commonProps} name={name} key={name} />
      );

    case SectionFieldType.MultilineText:
      return commonProps.disabled ? (
        <MultiInputHook {...disabledProps} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || ''}
          as={<MultiInputHook {...commonProps} />}
          key={name}
          data-qa={`node-field${sf.isLocalized ? `-${languageId}` : ''}-${sf.apiName}`}
        />
      );

    case SectionFieldType.MultiOption:
      return commonProps.disabled ? (
        <MultiChecks options={sf.options || []} {...disabledProps} />
      ) : (
        <MultiChecks
          register={register()}
          name={name}
          // defaultValue={defaultValue || []}
          options={sf.options || []}
          {...commonProps}
          key={name}
        />
      );

    case SectionFieldType.SingleOption:
      // eslint-disable-next-line no-case-declarations
      let options = [];

      if (typeof sf.options === 'string') {
        try {
          options = JSON.parse(sf.options);
        } catch (ex) {
          console.error(ex);
        }
      } else if (Array.isArray(sf.options)) {
        options = sf.options;
      }

      return commonProps.disabled ? (
        <SelectHook options={options} {...disabledProps} />
      ) : (
        <SelectHook
          ref={register()}
          name={name}
          defaultValue={defaultValue || ''}
          options={options}
          {...commonProps}
          key={name}
        />
      );

    case SectionFieldType.SectionLink:
      return commonProps.disabled ? (
        <AsyncSelect loadOptions={loadOptions(sf.linkedSectionId!)} {...disabledProps} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || undefined}
          render={({ ref, ...props }) => (
            <AsyncSelect loadOptions={loadOptions(sf.linkedSectionId!)} {...commonProps} {...props} />
          )}
          key={name}
        />
      );

    case SectionFieldType.SectionMultiLink:
      return commonProps.disabled ? (
        <SectionMultiLink loadOptions={loadOptions(sf.linkedSectionId!)} {...disabledProps} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || undefined}
          render={({ ref, ...props }) => (
            <SectionMultiLink loadOptions={loadOptions(sf.linkedSectionId!)} {...commonProps} {...props} />
          )}
          key={name}
        />
      );

    case SectionFieldType.RichTextSimple:
    case SectionFieldType.RichTextComplete:
      return commonProps.disabled ? (
        <QuillHook {...disabledProps} complete={sf.type === SectionFieldType.RichTextComplete} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || ''}
          render={({ ref, ...props }) => (
            <QuillHook
              {...props}
              {...commonProps}
              complete={sf.type === SectionFieldType.RichTextComplete}
              id={`node-field${sf.isLocalized ? `-${languageId}` : ''}-${sf.apiName}`}
            />
          )}
          key={name}
        />
      );

    case SectionFieldType.ColorPicker:
      return commonProps.disabled ? (
        <ColorPicker {...disabledProps} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || ''}
          render={({ ref, ...props }) => <ColorPicker {...props} {...commonProps} />}
          key={name}
          data-qa={`node-field${sf.isLocalized ? `-${languageId}` : ''}-${sf.apiName}`}
        />
      );

    default:
      return null;
  }
}
