import { performApiCall } from 'src/services/api';

import { Node } from './types';
import { transformNode } from './transformations';

export const fetchNodeDetails = async (sectionSlug: string, nodeId: number): Promise<Node> => {
  const res = await performApiCall({
    path: `/nodes/${sectionSlug}/${nodeId}?include=content,contents,images,collectionitems,subsections,blocks`,
  });
  return Promise.resolve(transformNode(res.data));
};

export const fetchNodes = async (sectionSlug: string, pageNumber = 1, pageSize = 999): Promise<Node[]> => {
  const res = await performApiCall({
    path: `/nodes/${sectionSlug}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  });
  return Promise.resolve(res.data.data.map(transformNode));
};
