/* eslint-disable max-classes-per-file */
import React, { useMemo } from 'react';

import { useHookCustomField } from 'src/common/hooks';

import { FloatingLabelField, FloatingLabelFieldProps } from '../FloatingLabelField';
import { Editor } from './Editor';

export type QuillProps = FloatingLabelFieldProps & {
  imageUploadParameters?: Record<string, any>;
  complete?: boolean;
  hasField?: boolean;
};

export function QuillHook({ complete = true, hasField = true, ...props }: QuillProps) {
  const { inputProps, fieldProps } = useHookCustomField<any>(props as any, { isQuill: true });

  const { value, id, onChange } = inputProps;

  const editor = useMemo(
    () => <Editor value={value} id={id} onChange={onChange} complete={complete} />,
    [complete, id, onChange, value],
  );

  return hasField ? <FloatingLabelField {...fieldProps}>{editor}</FloatingLabelField> : editor;
}
