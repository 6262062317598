import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRenteonSettings, fetchRenteonSettings, getRenteonSettings, updateRenteonSettings } from '../redux';

export function useRenteonSettings() {
  const dispatch = useDispatch();
  const settings = useSelector(getRenteonSettings);

  const loadSettings = useCallback(() => dispatch(fetchRenteonSettings()), [dispatch]);

  const updateSettings = useCallback((data: any) => dispatch(updateRenteonSettings(data)), [dispatch]);

  const deleteSettings = useCallback(() => dispatch(deleteRenteonSettings()), [dispatch]);

  return {
    settings,
    loadSettings,
    updateSettings,
    deleteSettings,
  };
}
