import { performApiCall } from 'src/services/api';

export const sendPasswordResetLink = async (email: string): Promise<boolean> => {
  try {
    await performApiCall({
      path: `/auth/forgot-password`,
      method: 'POST',
      body: { email },
    });

    return true;
  } catch {
    return false;
  }
};

export const resetPassword = async (email: string, code: string, password: string): Promise<boolean> => {
  try {
    await performApiCall({
      path: `/auth/reset-password`,
      method: 'POST',
      body: { email, code, password },
    });

    return true;
  } catch {
    return false;
  }
};
export const verifyEmail = async (email: string, activationCode: string): Promise<boolean> => {
  try {
    await performApiCall({
      path: `/auth/activation`,
      method: 'POST',
      body: { email, activationCode },
    });

    return true;
  } catch {
    return false;
  }
};
