import { Link, Router } from '@reach/router';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { Section } from 'src/services/sections';
import { Project } from 'src/services/project';
import Logo from '../../assets/images/logo.svg';
import { FADE_IN_VARIANTS } from '../const';
import { PanelSidebar } from './PanelSidebar';
import OrganizationSidebar from './OrganizationSidebar';
import UserSidebar from './UserSidebar';

type SidebarProps = {
  isLoginPage?: boolean;
  sections: Section[];
  project: Project;
};

export function Sidebar({ isLoginPage, ...props }: SidebarProps) {
  return (
    <div data-qa="sidebar" className={clsx('column is-narrow sidebar-container', isLoginPage && 'is-wide')}>
      <div className="sidebar-logo-box">
        <Link to="/dashboard" className="logo">
          <img src={Logo} alt="Site Sails" />
        </Link>
      </div>
      <Router component="nav" className="sidebar">
        {!isLoginPage ? (
          <>
            <OrganizationSidebar path="/organizations/:id/*" />
            <UserSidebar path="/dashboard" />
            <UserSidebar path="/admin/users" />
            <PanelSidebar {...props} path="/*" />
          </>
        ) : null}
      </Router>
      <AnimatePresence>
        {isLoginPage ? (
          <motion.footer
            className="copyrights"
            variants={FADE_IN_VARIANTS}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            &copy; INIT, All rights reserved
          </motion.footer>
        ) : null}
      </AnimatePresence>
    </div>
  );
}
