import { createCrudApiActions } from 'src/services/api';

import { Node } from '../types';
import { transformNodeForApi } from '../transformations';

export enum NodeActionTypes {
  CHANGE_NODES_START = 'nodes/change_nodes_start',
  CHANGE_NODES_FAILURE = 'nodes/change_nodes_failure',
  FETCH_NODES_SUCCESS = 'nodes/fetch_nodes_success',
  ADD_NODE_SUCCESS = 'nodes/add_node_success',
  UPDATE_NODE_SUCCESS = 'nodes/update_node_success',
  DELETE_NODE_SUCCESS = 'nodes/delete_node_success',
}

export const [fetchNodes, addNode, updateNode, deleteNode] = createCrudApiActions<Node>(
  '/nodes',
  NodeActionTypes.CHANGE_NODES_START,
  NodeActionTypes.CHANGE_NODES_FAILURE,
  NodeActionTypes.FETCH_NODES_SUCCESS,
  NodeActionTypes.ADD_NODE_SUCCESS,
  NodeActionTypes.UPDATE_NODE_SUCCESS,
  NodeActionTypes.DELETE_NODE_SUCCESS,
  transformNodeForApi,
  {
    add: '/:sectionSlug',
    change: '/:sectionSlug/:id',
    load: '/:sectionSlug',
  },
);
