import { createApiAction } from 'src/services/api';
import { SuccessAction } from 'src/services/api/redux/types';

export enum AuthActionTypes {
  SIGN_IN_START = 'auth/sign_in_start',
  SIGN_IN_SUCCESS = 'auth/sign_in_success',
  SIGN_IN_FAILURE = 'auth/sign_in_failure',
  SIGN_OUT = 'auth/SIGN_OUT',
  REGISTRATION_START = 'auth/registration_start',
  REGISTRATION_FAILURE = 'auth/registration_failure',
}

const signOutAction = (): SuccessAction => ({
  type: AuthActionTypes.SIGN_OUT,
  payload: null,
});

export const signIn = (email: string, password: string) =>
  createApiAction(
    {
      path: '/auth/authenticate',
      method: 'POST',
      body: { username: email, password },
    },
    [AuthActionTypes.SIGN_IN_START, AuthActionTypes.SIGN_IN_SUCCESS, AuthActionTypes.SIGN_IN_FAILURE],
  );

export const impersonate = (userId: number | null) =>
  createApiAction(
    {
      path: `/admin/users/${userId}/impersonate`,
      method: 'POST',
    },
    [AuthActionTypes.SIGN_IN_START, AuthActionTypes.SIGN_IN_SUCCESS, AuthActionTypes.SIGN_IN_FAILURE],
  );

export const refreshUser = () =>
  createApiAction(
    {
      path: '/users/me',
      method: 'GET',
    },
    [AuthActionTypes.SIGN_IN_START, AuthActionTypes.SIGN_IN_SUCCESS, AuthActionTypes.SIGN_IN_FAILURE],
  );

export function signOut() {
  return (dispatch: any) => {
    dispatch(signOutAction());
  };
}

export const register = (email: string, password: string, firstName: string, lastName: string) =>
  createApiAction(
    {
      path: '/auth/register',
      method: 'POST',
      body: {
        email,
        firstName,
        lastName,
        password,
      },
    },
    [AuthActionTypes.REGISTRATION_START, AuthActionTypes.SIGN_IN_SUCCESS, AuthActionTypes.REGISTRATION_FAILURE],
  );
