import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addKeyValueStore,
  deleteKeyValueStore,
  fetchKeyValueStores,
  getKeyValuesStores,
  updateKeyValueStore,
} from '../redux';
import { KeyValueStore } from '../types';

export function useKeyValueStores() {
  const dispatch = useDispatch();
  const keyValueStores = useSelector(getKeyValuesStores);

  const loadStores = useCallback(
    (page = 1, search?: string) => {
      return dispatch(fetchKeyValueStores(page, 999, { search }));
    },
    [dispatch],
  );

  const addStore = useCallback(
    (store: KeyValueStore) => {
      return dispatch(addKeyValueStore(store));
    },
    [dispatch],
  );

  const updateStore = useCallback(
    (store: KeyValueStore) => {
      return dispatch(updateKeyValueStore(store));
    },
    [dispatch],
  );

  const deleteStore = useCallback(
    (id: number) => {
      return dispatch(deleteKeyValueStore(id));
    },
    [dispatch],
  );

  return {
    keyValueStores,
    loadStores,
    addStore,
    updateStore,
    deleteStore,
  };
}
