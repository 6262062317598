import { performApiCall } from 'src/services/api';
import urlcat from 'urlcat';

export const addMedia = async (file: any, isPublic: boolean): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await performApiCall({
    path: urlcat(`/media`, '/', {
      isPublic,
    }),
    method: 'POST',
    formData,
  });
  if (!(res.status >= 200 && res.status < 300)) {
    return { ok: false };
  }
  return { ok: true, data: res.data };
};

export const addMediaImage = async (file: any): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await performApiCall({
    path: `/media/images`,
    method: 'POST',
    formData,
  });
  if (!(res.status >= 200 && res.status < 300)) {
    return { ok: false };
  }
  return { ok: true, data: res.data };
};

export const addCloudflareVideo = async (streamMediaId: string): Promise<any> => {
  const res = await performApiCall({
    path: `/integrations/cloudflare/videos?streamMediaId=${streamMediaId}`,
    method: 'POST',
  });
  if (!(res.status >= 200 && res.status < 300)) {
    return { ok: false };
  }
  return { ok: true, data: res.data };
};
