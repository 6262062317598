import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser, CurrentUserState, CurrentUserUpdateAPI } from '../redux';
import { fetchCurrentUser, updateCurrentUser } from '../redux/actions';

export function useCurrentUser() {
  const dispatch = useDispatch();
  const currentUser: CurrentUserState = useSelector(getCurrentUser);
  const fullName = `${currentUser.firstName} ${currentUser.lastName}`;
  const id = `${currentUser.id}`;

  const updateUser = useCallback(
    (user: CurrentUserUpdateAPI) => {
      return dispatch(updateCurrentUser(user));
    },
    [dispatch],
  );

  const refreshUser = useCallback(() => {
    return dispatch(fetchCurrentUser());
  }, [dispatch]);

  return { id, currentUser, fullName, updateUser, refreshUser };
}
