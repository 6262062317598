import React, { ChangeEventHandler } from 'react';
import { set } from 'lodash';
import { NodeProduct } from 'src/services/nodes';
import { InputHook } from 'src/common/components/form/InputHook';
import { DatePicker } from 'src/common/components/form/DatePicker';

type ProductEditorProps = {
  label?: string;
  value: NodeProduct;
  onChange: (value: NodeProduct) => void;
};

export function ProductEditor({ label, value, onChange }: ProductEditorProps) {
  function appendPrice() {
    value.prices.push({
      dateFrom: null,
      dateTo: null,
      price: 0,
      priceName: '',
      stripeLiveId: '',
      stripeTestId: '',
    });
    onChange(value);
  }

  function removePrice(index: number) {
    const newValue = value.prices.slice();
    newValue.splice(index, 1);
    value.prices = newValue;
    onChange(value);
  }

  const onChangeInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    set(value, event.target.name, event.target.value);
    onChange(value);
  };

  const handlePriceDateChange = (fieldName: 'dateFrom' | 'dateTo', priceIndex: number) => (dateValue: any) => {
    set(value, `prices[${priceIndex}]${fieldName}`, dateValue);
    onChange(value);
  };

  return (
    <div className="product-editor">
      <InputHook
        name="currency"
        value={value.currency}
        label="Currency"
        id="product-currency"
        onChange={onChangeInput}
      />
      <InputHook
        name="quantity"
        value={value.quantity}
        label="Quantity"
        id="product-quantity"
        onChange={onChangeInput}
      />
      <InputHook name="sold" value={value.sold} label="Sold" id="product-sold" onChange={onChangeInput} />
      <h5 className="product-editor-default-price">Price 1</h5>

      {value.prices.map((price: any, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            {index !== 0 ? (
              <div className="product-editor-button-box column">
                <h5>Price {index + 1}</h5>
                <button type="button" className="button is-white has-text-danger" onClick={() => removePrice(index)}>
                  <span className="icon">
                    <i className="fas fa-trash" />
                  </span>
                </button>
              </div>
            ) : null}
            <InputHook
              name={`prices[${index}].price`}
              value={price.price}
              label="Price"
              id={`${price}-${index}-price`}
              onChange={onChangeInput}
            />
            <DatePicker
              label="Date From"
              selected={price.dateFrom}
              onChange={handlePriceDateChange('dateFrom', index)}
              className="product-editor-datepicker"
            />
            <DatePicker
              label="Date To"
              selected={price.dateTo}
              onChange={handlePriceDateChange('dateTo', index)}
              className="product-editor-datepicker"
            />
            <InputHook
              name={`prices[${index}].priceName`}
              value={price.priceName}
              label="Price Name"
              id={`${price}-${index}-priceName`}
              onChange={onChangeInput}
            />
            <div className="product-editor-input-box">
              <InputHook
                name={`prices[${index}].stripeLiveId`}
                value={price.stripeLiveId}
                label="Stripe Live ID"
                id={`${price}-${index}-stripeLiveId`}
                onChange={onChangeInput}
              />
              <InputHook
                name={`prices[${index}].stripeTestId`}
                value={price.stripeTestId}
                label="Stripe Test ID"
                id={`${price}-${index}-stripeTestId`}
                onChange={onChangeInput}
              />
            </div>
          </div>
        );
      })}
      <div className="buttons">
        <button type="button" className="button is-primary is-fullwidth" onClick={appendPrice}>
          <span>Add {label}</span>
          <span className="icon">
            <i className="fal fa-plus" />
          </span>
        </button>
      </div>
    </div>
  );
}
