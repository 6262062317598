import clsx from 'clsx';
import { motion, Variants } from 'framer-motion';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

const variants: Variants = {
  hidden: {
    height: 0,
    transition: {
      duration: 0.175,
      ease: 'easeIn',
    },
  },
  visible: {
    height: 'auto',
    transition: {
      ease: 'easeOut',
      duration: 0.15,
    },
  },
};

type ExpandedItems = {
  [id: string]: boolean;
};

export type AccordionItem = {
  Header: ReactNode | ((expanded: boolean) => ReactNode);
  content: ReactNode;
  id: string;
  fixed?: boolean;
  defaultExpanded?: boolean;
  subtitle?: ReactNode;
};

type AccordionProps = {
  items: AccordionItem[];
  disableShadow?: boolean;
  className?: string;
  onActivateItem?: (item: string | null) => any;
  fullWidthClickable?: boolean;
};

export function Accordion({
  items,
  disableShadow = false,
  className = '',
  onActivateItem = () => {},
  fullWidthClickable = true,
}: AccordionProps) {
  const accordionRef = useRef<HTMLDivElement>(null!);

  const setDefaultExpendedItems = (currentExpanded: ExpandedItems = {}): ExpandedItems => {
    return items.reduce(
      (expandedItems, item) => {
        const itemExpanded = item.defaultExpanded ?? false;
        expandedItems[item.id] = currentExpanded[item.id] ?? itemExpanded;
        return expandedItems;
      },
      { ...currentExpanded },
    );
  };

  const [expanded, setExpanded] = useState(setDefaultExpendedItems);

  useEffect(() => {
    setExpanded((prevExpanded) => setDefaultExpendedItems(prevExpanded));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const toggle = useCallback((id: string) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  }, []);

  return (
    <div className={clsx('card accordion', className)} ref={accordionRef}>
      {items.map((item) => {
        const isExpanded = item.fixed || expanded[item.id];
        return (
          <div key={item.id} onClick={() => onActivateItem(item.id)} data-qa={`accordion-item-${item.id}`}>
            <div
              className={clsx('card-header', {
                'is-shadowless': disableShadow || !isExpanded,
                'is-clickable': fullWidthClickable,
              })}
              onClick={fullWidthClickable ? () => toggle(item.id) : undefined}
              title={fullWidthClickable && !item.fixed ? `Click to ${isExpanded ? 'collapse' : 'expand'}` : undefined}
            >
              {typeof item.Header === 'function' ? (
                item.Header(isExpanded)
              ) : (
                <>
                  <p className="card-header-title">{item.Header}</p>
                  <div
                    className={clsx('card-header-icon', !fullWidthClickable && 'is-clickable')}
                    onClick={!fullWidthClickable ? () => toggle(item.id) : undefined}
                    title={
                      fullWidthClickable && !item.fixed ? undefined : `Click to ${isExpanded ? 'collapse' : 'expand'}`
                    }
                  >
                    <span className="icon">
                      <i
                        className={clsx('fal has-text-ui-60', {
                          'fa-minus': isExpanded,
                          'fa-plus': !isExpanded,
                        })}
                      />
                    </span>
                  </div>
                </>
              )}
            </div>
            <motion.div
              className="accordion-content"
              variants={variants}
              initial={item.defaultExpanded || item.fixed ? 'visible' : 'hidden'}
              animate={isExpanded ? 'visible' : 'hidden'}
            >
              <div className="card-content">
                {item.subtitle ? <p className="mb-4 ml-2 is-size-7 has-text-grey">{item.subtitle}</p> : null}
                {item.content}
              </div>
            </motion.div>
          </div>
        );
      })}
    </div>
  );
}
