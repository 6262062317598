import { performApiCall } from '../api';
import { transformNode } from '../nodes/transformations';
import { PageNode } from './types';

export const fetchPageDetails = async (pageId: number): Promise<PageNode> => {
  const res = await performApiCall({
    path: `/nodes/pages/${pageId}?include=content,contents,images,collectionitems,subsections,blocks`,
  });
  return Promise.resolve(transformNode(res.data) as PageNode);
};
