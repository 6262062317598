import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectStateProject, getProject, updateProjectAction, selectProjectAction } from '../redux';
import { createProjectAction, deleteProjectAction, loadProjectAction } from '../redux/actions';
import { Project } from '../types';

export function useProject() {
  const dispatch = useDispatch();
  const project: ProjectStateProject = useSelector(getProject) || {};

  const addProject = useCallback(
    (data: Project) => {
      return dispatch(createProjectAction(data));
    },
    [dispatch],
  );

  const loadProject = useCallback(
    (projectId: number) => {
      return dispatch(loadProjectAction(projectId));
    },
    [dispatch],
  );

  const updateProject = useCallback(
    (data: Project) => {
      return dispatch(updateProjectAction(data));
    },
    [dispatch],
  );

  const deleteProject = useCallback(
    (projectId: number) => {
      return dispatch(deleteProjectAction(projectId));
    },
    [dispatch],
  );

  const selectProject = useCallback(
    (data: Project) => {
      return dispatch(selectProjectAction(data));
    },
    [dispatch],
  );

  return { project, addProject, updateProject, selectProject, loadProject, deleteProject };
}
