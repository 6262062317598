import React, { Fragment, useCallback, useEffect } from 'react';
import { AsyncSelect } from './AsyncSelect';
import { FieldErrorMessage } from './FieldErrorMessage';

type Value = { value: number; label: string } | null;

type SectionMultiLinkProps = {
  name?: string;
  label?: string;
  id?: string;
  error?: string;
  touched?: boolean;
  loadOptions: (search: string) => Promise<Value[]>;
  fieldClassName?: string;
  hideHelper?: boolean;
  onChange?: (value: Value[]) => void;
  onBlur?: () => void;
  value: Value[];
  disabled?: boolean;
};

export default function SectionMultiLink({
  loadOptions,
  name,
  label,
  value,
  onChange,
  onBlur,
  error,
  touched,
  disabled,
  ...props
}: SectionMultiLinkProps) {
  if (!Array.isArray(value)) {
    // eslint-disable-next-line no-param-reassign
    value = [value];
  }

  const handleAppend = useCallback(() => {
    onChange?.([...value, null]);
    onBlur?.();
  }, [onChange, value, onBlur]);

  function handleChange(index: number) {
    return (option: Value) => {
      const newValues = value.slice();
      newValues.splice(index, 1, option);
      onChange?.(newValues);
      onBlur?.();
    };
  }

  function handleRemove(index: number) {
    const newValues = value.slice();
    newValues.splice(index, 1);
    onChange?.(newValues);
    onBlur?.();
  }

  useEffect(() => {
    if (!value?.length) {
      handleAppend();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {value.map((field, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="columns mb-0" key={index}>
          <div className="column">
            <AsyncSelect
              defaultValue={field}
              loadOptions={loadOptions}
              onChange={handleChange(index)}
              label={`${label} #${index + 1}`}
              hideHelper
              disabled={disabled}
              {...props}
            />
          </div>
          <div className="column is-narrow">
            <button
              type="button"
              className="button is-white has-text-grey"
              onClick={() => handleRemove(index)}
              disabled={disabled}
            >
              <span className="icon">
                <i className="fal fa-trash" />
              </span>
            </button>
          </div>
        </div>
      ))}
      <FieldErrorMessage hasError={touched && !!error} error={error} />
      <button type="button" className="button is-outlined mb-5" onClick={handleAppend} disabled={disabled}>
        <span className="icon has-text-primary">
          <i className="fal fa-plus" />
        </span>
        <span>{`Add ${label}`}</span>
      </button>
    </>
  );
}
