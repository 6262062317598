import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useToggle } from 'react-use';
import { TranslationModal } from 'src/common';
import { showToast } from 'src/common/util/show-toast';
import Swal from 'sweetalert2';

type ConnectionToolbarProps = {
  hasTranslations: boolean;
  selectedItems: number[];
  availableLanguages: string[];
  clearSelectedItems: () => void;
  onTranslate: (sourceLanguage: string, targetLanguages: string[], selectedItems: number[]) => Promise<{ ok: boolean }>;
  onDelete?: (id: number) => Promise<any>;
  onCopy?: () => void; // unused for now
};

export default function ConnectionToolbar({
  hasTranslations,
  selectedItems,
  availableLanguages,
  clearSelectedItems,
  onTranslate,
  onDelete,
  onCopy, // unused for now
}: ConnectionToolbarProps) {
  const [translating, toggleTranslating] = useToggle(false);
  const [deleting, toggleDeleting] = useToggle(false);

  const translate = useCallback(
    async (sourceLanguage: string, targetLanguages: string[]) => {
      const { ok } = await onTranslate(sourceLanguage, targetLanguages, selectedItems);

      if (ok) {
        showToast('success', 'Entries translated successfully!');
      } else {
        showToast('error', 'Error translating entries');
      }
    },
    [onTranslate, selectedItems],
  );

  const deleteMultiple = useCallback(async () => {
    toggleDeleting();
    const confirm = await Swal.fire({
      title: 'Delete',
      text: `Are you sure you want to delete all the selected entries?`,
      showCancelButton: true,
      confirmButtonColor: '#e0245a',
      confirmButtonText: 'Delete',
      icon: 'question',
    });

    if (confirm.value) {
      const result = await Promise.all<{ ok: boolean }>(selectedItems.map((id) => onDelete!(id)));
      const allDeleted = result.every(({ ok }) => ok);
      const someDeleted = result.some(({ ok }) => ok);

      if (allDeleted) {
        showToast('success', 'All selected entries were deleted');
      } else if (someDeleted) {
        showToast('success', 'Some of the selected entries were deleted');
      } else {
        showToast('error', 'Unable to delete');
      }
      clearSelectedItems();
    }
    toggleDeleting();
  }, [onDelete, selectedItems, toggleDeleting, clearSelectedItems]);

  const buttonsDisabled = selectedItems.length < 1;

  return (
    <>
      <div className="table-container">
        <div className="buttons">
          {hasTranslations ? (
            <button type="button" className="button is-outlined" disabled={buttonsDisabled} onClick={toggleTranslating}>
              <span className="icon has-text-primary">
                <i className="far fa-language" />
              </span>
              <span>Translate selected</span>
            </button>
          ) : null}
          {onCopy ? ( // unused for now
            <button type="button" className="button is-outlined" disabled={buttonsDisabled}>
              <span className="icon has-text-primary">
                <i className="far fa-copy" />
              </span>
            </button>
          ) : null}
          {onDelete ? (
            <button
              type="button"
              className={clsx('button is-outlined', deleting && 'is-loading')}
              disabled={buttonsDisabled}
              onClick={deleteMultiple}
            >
              <span className={clsx('icon', !deleting && 'has-text-primary')}>
                <i className="far fa-trash" />
              </span>
            </button>
          ) : null}
        </div>
      </div>
      <TranslationModal
        availableLanguages={availableLanguages}
        visible={translating}
        onClose={toggleTranslating}
        onSubmit={translate}
      />
    </>
  );
}
