import { Match, RouteComponentProps } from '@reach/router';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useAuth } from 'src/services/auth';
import { useOrganizations } from 'src/services/organizations';
import { useCurrentUser } from 'src/services/users';
import { ORGANIZATION_ADMIN_ROLES } from 'src/modules/users/const';
import { LINK_ENTER_VARIANTS, SUBMENU_ENTER_VARIANTS, SUBMENU_LINK_ENTER_VARIANTS } from '../const';
import { NavLink } from './NavLink';
import SidebarProfileButton from './SidebarProfileButton';

type OrganizationSidebarProps = RouteComponentProps<{ id: string }>;

export default function OrganizationSidebar({ id }: OrganizationSidebarProps) {
  const { organizations } = useOrganizations();
  const { fullName } = useCurrentUser();
  const { logOut } = useAuth();

  const selectedOrganization = id ? organizations.items.find((org) => org.id === +id) : undefined;

  return selectedOrganization ? (
    <>
      <ul className="sidebar-menu">
        <AnimatePresence>
          <motion.li
            layout
            variants={LINK_ENTER_VARIANTS}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ delay: 0 }}
            key="logo"
          >
            <span className="menu-link sidebar-top-link">
              <figure className="image is-32x32">
                <img
                  className="is-rounded"
                  src={`https://ui-avatars.com/api/?name=${selectedOrganization?.name}&background=006bd6&color=5ed5ed&size=32&format=svg`}
                  alt={selectedOrganization?.name || ''}
                />
              </figure>
              <span className="text">
                {selectedOrganization?.name}
                <div style={{ marginTop: '-5px' }}>
                  <small style={{ fontSize: '11px', fontWeight: 300, color: '#008DDD', marginTop: '-10px' }}>
                    {selectedOrganization?.plan?.name}
                  </small>
                </div>
              </span>
            </span>
          </motion.li>
          <motion.li
            layout
            variants={LINK_ENTER_VARIANTS}
            initial="hidden"
            animate="visible"
            exit="hidden"
            key="projects"
            transition={{ delay: 0.05 }}
          >
            <NavLink to={`/organizations/${id}`} exact className="menu-link">
              <span className="icon">
                <i className="fal fa-folder" />
              </span>
              <span className="ml-2">Projects</span>
            </NavLink>
          </motion.li>
          <Match path={`/organizations/${id}/users/*`}>
            {(props) =>
              ORGANIZATION_ADMIN_ROLES.includes(selectedOrganization.role) ? (
                <motion.li
                  layout
                  variants={LINK_ENTER_VARIANTS}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  transition={{ delay: 0.1 }}
                  key="users"
                  className={clsx({ 'is-active': !!props.match })}
                >
                  <NavLink to={`/organizations/${id}/users`} className="menu-link" exact>
                    <span className="icon">
                      <i className="fal fa-users" />
                    </span>
                    <span className="ml-2">Users</span>
                  </NavLink>
                  <motion.ul
                    layout
                    className="submenu"
                    variants={SUBMENU_ENTER_VARIANTS}
                    animate={props.match ? 'visible' : 'hidden'}
                  >
                    <motion.li
                      variants={SUBMENU_LINK_ENTER_VARIANTS}
                      animate={props.match ? 'visible' : 'hidden'}
                      transition={{ delay: 0 }}
                    >
                      <NavLink to={`/organizations/${id}/users/invites`} exact>
                        <span className="text ml-2">Invites</span>
                      </NavLink>
                    </motion.li>
                  </motion.ul>
                </motion.li>
              ) : null
            }
          </Match>
          <Match path={`/organizations/${id}/settings`} key="settings">
            {() => (
              <motion.li key="settings">
                <NavLink to={`/organizations/${id}/edit`} className="menu-link" exact>
                  <span className="icon">
                    <i className="fal fa-cog" />
                  </span>
                  <span className="ml-2">Settings</span>
                </NavLink>
              </motion.li>
            )}
          </Match>
          <motion.li key="billing">
            <NavLink to={`/organizations/${id}/billing-and-plans`} className="menu-link">
              <span className="icon">
                <i className="fal fa-coin" />
              </span>
              <span className="ml-2">Billing &amp; Plans</span>
            </NavLink>
          </motion.li>
        </AnimatePresence>
      </ul>
      <ul className="sidebar-menu">
        <SidebarProfileButton delay={0.05} fullName={fullName} logOut={logOut} profileMatch={false} />
      </ul>
    </>
  ) : null;
}
