import { useLocation } from '@reach/router';
import { BACK_TO_SEARCH } from '../const';

export const usePrevSearchURLValue = () => {
  const location = useLocation();

  const searchQuery = new URLSearchParams(location.search);
  const prevSearchTerm = searchQuery.get(BACK_TO_SEARCH);

  return prevSearchTerm;
};
