import clsx from 'clsx';
import React, { ChangeEventHandler, FocusEventHandler, FormEventHandler, useEffect, useState } from 'react';
import { MultiInputHook } from '../MultiInputHook';
import { QuillHook } from '../Quill';

type EditorProps = {
  value: string;
  onChange: ChangeEventHandler | FormEventHandler | ((value: string) => void);
  onBlur: () => void;
  name: string;
  isContentVisible?: boolean;
  isHtmlVisible?: boolean;
  error?: string;
  touched?: boolean;
  id?: string;
  languageId?: string;
};

export default function Editor({
  value,
  onChange,
  onBlur,
  isContentVisible,
  isHtmlVisible,
  id,
  languageId,
  ...rest
}: EditorProps) {
  const [htmlValue, setHtmlValue] = useState(value);

  const onBlurTextarea: FocusEventHandler<HTMLTextAreaElement> = (event) => {
    (onChange as (value: string) => void)(event.target.value);
    onBlur();
  };

  const onChangeTextarea: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setHtmlValue(event.target.value);
  };

  useEffect(() => {
    setHtmlValue(value);
  }, [value]);

  return (
    <>
      <div className={clsx(`is-fullheight rich-edit-content-${languageId}`, !isContentVisible && 'is-hidden')}>
        <QuillHook
          id={id ? `${id}-quill` : undefined}
          value={value}
          onChange={onChange as FormEventHandler}
          onBlur={onBlur}
          hasField={false}
          {...rest}
        />
      </div>
      <div className={clsx(`is-fullheight rich-edit-html-${languageId}`, !isHtmlVisible && 'is-hidden')}>
        <MultiInputHook
          className="editor-html"
          id={id ? `${id}-textarea` : undefined}
          value={htmlValue || ''}
          onBlur={onBlurTextarea}
          onChange={onChangeTextarea}
        />
      </div>
    </>
  );
}
