import _ from 'lodash';

export const isEmpty = (data: any): boolean => {
  return _.get(data, '_status.isEmpty', true);
};

export const isLoading = (data: any): boolean => {
  return _.get(data, '_status.isLoading', false);
};

export const isValid = (data: any): boolean => {
  return _.get(data, '_status.isValid', false);
};

export const isError = (data: any): boolean => {
  return _.get(data, '_status.isError', false);
};

export const getError = (data: any): any => {
  return _.get(data, '_status.error', null);
};

export const getErrorMessage = (data: any): string | null => {
  return _.get(data, '_status.errorMessage', null);
};

export const getTotalPages = (data: any): number | undefined => {
  return _.get(data, '_meta.totalPages', undefined);
};
