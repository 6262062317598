import { useLocation } from '@reach/router';
import { useMemo } from 'react';
import { RETURN_TO } from '../const';

export function useReturnTo() {
  const location = useLocation();

  const returnTo = useMemo(() => {
    const search = new URLSearchParams(location.search);
    if (search.has(RETURN_TO)) {
      return search.get(RETURN_TO)!.split(':');
    }
    return [null, null];
  }, [location.search]);

  return returnTo;
}
