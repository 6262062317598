import { navigate } from '@reach/router';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { parseDateToApi } from 'src/common';
import { showToast } from 'src/common/util/show-toast';
import { useProject } from 'src/services/project';
import { NodeContent } from 'src/services/nodes';
import { fetchPageDetails, PageNode, transformPageForForm, usePages } from 'src/services/pages';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = yup.object({
  blocks: yup.array().defined(),
  contents: yup
    .array(
      yup.object({
        slug: yup.string().label('Slug').required().matches(/^\//, 'Slug has to begin with a "/" (forward slash)'),
        data: yup
          .object({
            title: yup.string().label('Title').required(),
          })
          .defined(),
      }),
    )
    .defined(),
});

export function usePageEditForm(listLink: string, editId?: string) {
  const { project } = useProject();
  const { updatePage, createPage } = usePages();
  const [loadingPage, setLoadingPage] = useState(true);
  const [editedPage, setEditedPage] = useState<PageNode | null>(null);
  const initialValues = useMemo(() => {
    const urlSearch = new URLSearchParams(window.location.search);
    const slug = urlSearch.get('parent');
    const title = urlSearch.get('title');
    return { slug, title };
  }, []);
  const methods = useForm<PageNode>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      contents: [
        {
          languageId: project.defaultLanguageId,
          slug: initialValues.slug || '',
          data: {
            title: initialValues.title || '',
          },
        },
      ],
    },
  });
  const { handleSubmit, watch, reset, control } = methods;
  const contentsMethods = useFieldArray<NodeContent>({ control, name: 'contents', keyName: '_id' as any });

  const save = handleSubmit(async (values) => {
    const contents = (values.contents || []).map((c: any) => ({
      ...c,
      id: c.id ? Number(c.id) : undefined,
      data: { ...c.data, text: c.text },
    }));

    const blocks = (values.blocks || []).map((b) => ({
      ...b,
      id: b.id ? Number(b.id) : undefined,
      fields: (b.fields || []).map((f) => ({
        ...f,
        blockId: b.id ? Number(b.id) : undefined,
      })),
    }));

    const { alwaysActive } = values;

    const data = {
      ...values,
      id: editedPage ? parseInt(editedPage.id as any, 10) : undefined,
      contents,
      publishable: {
        isPublished: values.publishable?.isPublished ?? false,
        publishedFrom: values.publishDate ? parseDateToApi(values.publishDate, values.publishTime) : undefined,
        publishedTo:
          !alwaysActive && values.publishedToDate
            ? parseDateToApi(values.publishedToDate, values.publishedToTime)
            : undefined,
      },
      blocks,
    };

    let result;

    if (editedPage) {
      // @ts-ignore
      result = await updatePage(data);
    } else {
      // @ts-ignore
      result = await createPage(data);
    }

    if (result.ok) {
      showToast('success', 'Successfully saved');
      navigate(listLink);
    } else {
      showToast('error', 'There was an error processing your action');
    }
  });

  const watchSeoMetadata = watch('seoMetadata', []) || [];

  useEffect(() => {
    async function load() {
      if (editId) {
        setLoadingPage(true);
        const page = await fetchPageDetails(+editId);

        if (page) {
          setEditedPage(page);
          const formPage = transformPageForForm(page, project.defaultLanguageId);
          reset(formPage);
        }
      }
      setLoadingPage(false);
    }
    load();
  }, [project.defaultLanguageId, editId, reset]);

  return {
    methods,
    contents: contentsMethods,
    save,
    watchSeoMetadata,
    loadingPage,
    editedPage,
  };
}
