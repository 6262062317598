/* eslint-disable max-classes-per-file */
import BlotFormatter, { DeleteAction, ImageSpec, ResizeAction } from 'quill-blot-formatter';
import React, { useEffect, useMemo, useRef } from 'react';
import { useQuill } from 'react-quilljs';
import ImageUploader from 'quill-image-uploader';
import QuillTableUI from 'quill-table-ui';
import { addMediaImage } from 'src/services/media';
import { QuillCustomAlignAction } from './QuillCustomAlignAction';
import { QuillCustomImageFormat } from './QuillCustomImageFormat';

class CustomImageSpec extends ImageSpec {
  getActions() {
    return [QuillCustomAlignAction, DeleteAction, ResizeAction];
  }
}

type EditorProps = {
  complete?: boolean;
  id?: string;
  value?: string;
  onChange: (value: string) => any;
};

export function Editor({ complete = true, id, value, onChange }: EditorProps) {
  const initialized = useRef(false);
  const modules = useMemo(
    () => ({
      toolbar: complete
        ? [
            ['bold', 'italic', 'underline', 'link'],
            [{ header: [1, 2, 3, 4, false] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['table'],
            ['image', 'video'],
            ['clean'],
          ]
        : [['bold', 'italic', 'underline', 'link'], ['clean']],
      imageUploader: {
        upload: async (file: File) => {
          const res = await addMediaImage(file);

          if (res.ok && !!res.data?.url) {
            return res.data.url;
          }
          throw new Error('error uploading image');
        },
      },
      blotFormatter: {
        specs: [CustomImageSpec],
        overlay: {
          style: {
            border: '2px solid red',
          },
        },
      },
      table: complete,
      tableUI: complete,
      uploader: false,
    }),
    [complete],
  );

  const { quillRef, quill, Quill } = useQuill({ modules, theme: 'snow' });

  // Initial value and update when editing HTML
  useEffect(() => {
    if (quill && !initialized.current) {
      const editorValue = quill.root.innerHTML;
      if (value !== editorValue) {
        initialized.current = true;
        // Clear the editor
        quill.deleteText(0, quill.getLength());
        // "Paste" updated value
        quill.clipboard.dangerouslyPasteHTML(value || '');
      }
    }
  }, [quill, value]);

  // onChange
  useEffect(() => {
    if (quill) {
      const updateValue = () => {
        onChange?.(quill.root.innerHTML);
      };
      quill.on('text-change', updateValue);

      return () => {
        quill.off('text-change', updateValue);
      };
    }
    return undefined;
  }, [onChange, quill]);

  if (Quill && !quill && !(window as any).__quillRegistered) {
    (window as any).__quillRegistered = true;
    Quill.register('modules/imageUploader', ImageUploader);
    Quill.register('modules/blotFormatter', BlotFormatter);
    Quill.register(QuillCustomImageFormat, true);
    Quill.register('modules/tableUI', QuillTableUI);
  }

  return (
    <div id={id} className="quill">
      <div ref={quillRef} />
    </div>
  );
}
