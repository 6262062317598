import React from 'react';
import { Control, Controller, FormState } from 'react-hook-form';
import { isTouched } from 'src/common/util';
import { FormNode } from 'src/modules/nodes/types';
import { NodeContent } from 'src/services/nodes';
import { SectionType } from 'src/services/sections';
import { InputHook } from './InputHook';

type SectionSpecificFieldsProps = {
  type: SectionType;
  index: number;
  content?: Partial<NodeContent>;
  control: Control<any>;
  name?: string;
  formState: FormState<any>;
  node: FormNode | null;
  isMultiLanguage: boolean;
  isEvent?: boolean;
  isProduct?: boolean;
};

export function SectionSpecificFields({
  type,
  index,
  content,
  control,
  name = 'contents',
  formState,
  node,
  isMultiLanguage,
  isEvent = false,
  isProduct = false,
}: SectionSpecificFieldsProps) {
  if (
    !isProduct &&
    !isEvent &&
    [SectionType.Articles, SectionType.Pages, SectionType.Collections, SectionType.Categories].indexOf(type) > -1
  ) {
    const languageSuffix = isMultiLanguage ? ` ${content?.languageId?.toUpperCase()}` : '';

    return (
      // <CommonContent
      //   index={index}
      //   content={content || {}}
      //   control={control}
      //   name={name}
      //   formState={formState}
      //   isMultiLanguage={isMultiLanguage}
      //   // TODO: remove after refactor
      //   onlySlug={type === SectionType.Categories}
      // />
      <Controller
        control={control}
        name={`${name}[${index}].slug`}
        defaultValue={content?.slug || ''}
        as={<InputHook id={`${name}-slug-${index}`} label={`Slug${languageSuffix}`} />}
        error={formState.errors[name]?.[index]?.slug?.message}
        touched={isTouched(formState, `${name}[${index}].slug`)}
        data-qa={`node-field-${content?.languageId}-slug`}
      />
    );
  }
  if (isEvent || isProduct) {
    return (
      <>
        {/* <Controller
          control={control}
          name="name"
          defaultValue={node?.name || ''}
          as={
            <InputHook
              label="Name"
              id="name"
              error={formState.errors.name?.message}
              touched={isTouched(formState, 'name')}
            />
          }
        /> */}
        <Controller
          control={control}
          name="slug"
          defaultValue={node?.slug || ''}
          as={
            <InputHook
              label="Slug"
              id="slug"
              error={formState.errors.slug?.message}
              touched={isTouched(formState, 'slug')}
            />
          }
        />
      </>
    );
  }
  return null;
}
