import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStripeSettings, fetchStripeSettings, getStripeSettings, updateStripeSettings } from '../redux';
import { StripeSettings } from '../types';

export function useStripeSettings() {
  const dispatch = useDispatch();
  const settings = useSelector(getStripeSettings);

  const loadSettings = useCallback(() => dispatch(fetchStripeSettings()), [dispatch]);

  const updateSettings = useCallback((data: StripeSettings) => dispatch(updateStripeSettings(data)), [dispatch]);

  const deleteSettings = useCallback(() => dispatch(deleteStripeSettings()), [dispatch]);

  return {
    settings,
    loadSettings,
    updateSettings,
    deleteSettings,
  };
}
