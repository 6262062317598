import { performApiCall } from '../api';
import { TranslationRequest } from './types';

export const fetchTranslations = async (data: TranslationRequest): Promise<any> => {
  const res = await performApiCall({
    path: '/integrations/translations',
    method: 'POST',
    body: data,
  });

  return Promise.resolve(res.data);
};

export const syncWithRenteon = async (): Promise<any> => {
  const res = await performApiCall({
    path: `/integrations/renteon/settings/sync`,
    method: 'POST',
  });
  return res.data;
};
