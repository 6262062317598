import { combineReducers } from 'redux';

import { createApiReducer, chainReducers, INITIAL_API_STATUS } from 'src/services/api';
import { AuthActionTypes } from 'src/services/auth/redux/actions';

import { ProjectActionTypes } from './actions';
import { transformProject } from '../transformations';

const initialState = { _status: INITIAL_API_STATUS, project: null };

const updateProject = createApiReducer([
  ProjectActionTypes.CHANGE_PROJECT_START,
  ProjectActionTypes.UPDATE_PROJECT,
  ProjectActionTypes.CHANGE_PROJECT_FAILED,
]);

// const signInReducer = createApiReducer(
//   [AuthActionTypes.SIGN_IN_START, AuthActionTypes.SIGN_IN_SUCCESS, AuthActionTypes.SIGN_IN_FAILURE],
//   transformProject,
//   (data) => data,
// );

const signOutReducer = createApiReducer(
  ['', AuthActionTypes.SIGN_OUT, ''],
  () => initialState,
  () => initialState,
);

const selectProjectReducer = createApiReducer(
  ['', ProjectActionTypes.SELECT_PROJECT, ''],
  transformProject,
  () => initialState,
);

// const deleteProjectReducer = createApiReducer(
//   [
//     ProjectActionTypes.CHANGE_PROJECT_START,
//     ProjectActionTypes.DELETE_PROJECT,
//     ProjectActionTypes.CHANGE_PROJECT_FAILED,
//   ],
//   (data, state) => ({
//     items: state.items.filter((i: any) => i.id !== data.id),
//   }),
//   (data) => data,
// );

export default combineReducers({
  project: chainReducers(initialState, signOutReducer, updateProject, selectProjectReducer),
});
