import { Match, RouteComponentProps, useMatch } from '@reach/router';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { find } from 'lodash';
import React, { useMemo } from 'react';
import { USER_ROUTES } from 'src/const';
import { useAuth } from 'src/services/auth';
import { Project } from 'src/services/project';
import { useKeyValueStores } from 'src/services/key-values';
import { Section, sectionComparer, SectionRouteLink, SectionType } from 'src/services/sections';
import { SECTION_TYPE_ICONS, SECTION_TYPES } from 'src/services/sections/const';
import { useCurrentUser } from 'src/services/users';
import { ListPathProps } from 'src/types';
import { useOrganizations } from 'src/services/organizations';
import { PROJECT_ADMIN_ROLES } from 'src/modules/users/const';
import { LINK_ENTER_VARIANTS, SUBMENU_ENTER_VARIANTS, SUBMENU_LINK_ENTER_VARIANTS } from '../const';
import { NavLink } from './NavLink';
import SidebarProfileButton from './SidebarProfileButton';
import { useProjectLink } from '../hooks/use-project-link';

type PanelSidebarProps = {
  sections: Section[];
  project: Project;
} & RouteComponentProps;

export function PanelSidebar({ sections, project }: PanelSidebarProps) {
  const hasPagesSection = !!sections.find((s) => s.type === SectionType.Pages);
  const commonSections = useMemo(
    () =>
      sections.filter(
        (s) =>
          s.type !== SectionType.Connections &&
          s.type !== SectionType.Categories &&
          s.type !== SectionType.Collections &&
          s.type !== SectionType.Subsections &&
          s.type !== SectionType.Pages,
      ),
    [sections],
  );
  const connectionSections = useMemo(() => sections.filter((s) => s.type === SectionType.Connections), [sections]);
  const collectionSections = useMemo(() => sections.filter((s) => s.type === SectionType.Collections), [sections]);
  const subsectionSections = useMemo(() => sections.filter((s) => s.type === SectionType.Subsections), [sections]);
  const visibleUserRoutes = useMemo(
    () => USER_ROUTES.filter((route) => !!route.label && (!route.visible || route.visible(project))),
    [project],
  );
  const profileMatch = useMatch('/profile');
  const { fullName } = useCurrentUser();
  const { logOut } = useAuth();
  const { keyValueStores } = useKeyValueStores();
  const { organizations } = useOrganizations();
  const projectLink = useProjectLink();

  const selectedOrganization = organizations.items.find((org) => org.id === project.organizationId);
  const selectedProject = selectedOrganization?.projects.find((proj) => proj.id === project.id);

  /* eslint-disable react/prop-types */

  return (
    <>
      <ul className="sidebar-menu">
        <AnimatePresence>
          <motion.li
            layout
            variants={LINK_ENTER_VARIANTS}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ delay: 0 }}
            key="organization-li"
          >
            <span className="menu-link sidebar-top-link">
              <figure className="image is-32x32">
                <img
                  className="is-rounded"
                  src={`https://ui-avatars.com/api/?name=${selectedOrganization?.name}&background=006bd6&color=5ed5ed&size=32&format=svg`}
                  alt={selectedOrganization?.name || ''}
                />
              </figure>
              <span className="text">
                {project?.name}
                <div style={{ marginTop: '-5px' }}>
                  <small style={{ fontSize: '11px', fontWeight: 300, color: '#008DDD', marginTop: '-10px' }}>
                    {selectedOrganization?.name}
                  </small>
                </div>
              </span>
            </span>
          </motion.li>

          {hasPagesSection ? (
            <Match path={`${projectLink}/pages/*`} key="pages">
              {(props) => (
                <motion.li
                  layout
                  variants={LINK_ENTER_VARIANTS}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  transition={{ delay: 0 }}
                  className={clsx({ 'is-active': !!props.match })}
                  key="pages-li"
                >
                  <NavLink className="menu-link" to={`${projectLink}/pages`} data-qa="sidebar-section-pages">
                    <span className="icon">
                      <i className="fal fa-file" />
                    </span>
                    <span className="ml-2">Pages</span>
                  </NavLink>
                </motion.li>
              )}
            </Match>
          ) : null}
          {commonSections.map((section, index) => {
            const type = find(SECTION_TYPES, { value: section.type })!;
            const icon = SECTION_TYPE_ICONS[section.type];
            const connections = connectionSections
              .filter((s) => s.type === SectionType.Connections && s.parentSectionId === section.id)
              .sort(sectionComparer);
            const collections = collectionSections
              .filter((s) => s.type === SectionType.Collections && s.parentSectionId === section.id)
              .sort(sectionComparer);
            const subsections = subsectionSections
              .filter((s) => s.type === SectionType.Subsections && s.parentSectionId === section.id)
              .sort(sectionComparer);
            const categories = sections.filter((s) => s.categorySectionId === section.id);
            const hasSubmenu =
              categories.length > 0 ||
              connections.length > 0 ||
              collections.length > 0 ||
              subsections.length > 0 ||
              section.isProduct;
            const submenuItems = hasSubmenu
              ? ([
                  ...(categories.length > 0
                    ? [
                        {
                          link: (sectionSlug: string) => `${projectLink}/nodes/${sectionSlug}/categories`,
                          label: 'Categories',
                        },
                      ]
                    : []),
                  ...subsections.map(
                    (item) =>
                      ({
                        link: (sectionSlug) => `${projectLink}/nodes/${sectionSlug}/subsections/${item.slug}`,
                        label: item.name,
                      } as SectionRouteLink),
                  ),
                  ...collections.map(
                    (item) =>
                      ({
                        link: (sectionSlug) => `${projectLink}/nodes/${sectionSlug}/collections/${item.slug}`,
                        label: item.name,
                      } as SectionRouteLink),
                  ),
                  ...connections.map(
                    (item) =>
                      ({
                        link: (sectionSlug) => `${projectLink}/nodes/${sectionSlug}/connections/${item.slug}`,
                        label: item.name,
                      } as SectionRouteLink),
                  ),
                  section.isProduct
                    ? ({
                        link: (slug) => `${projectLink}/nodes/${slug}/transactions`,
                        label: 'Transactions',
                      } as SectionRouteLink)
                    : null,
                ].filter(Boolean) as SectionRouteLink[])
              : [];

            return (
              <Match<ListPathProps> path={`${projectLink}/nodes/${section.slug}/*`} key={section.slug}>
                {(props) => (
                  <motion.li
                    layout
                    variants={LINK_ENTER_VARIANTS}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    transition={{ delay: 0.05 * (index + Number(hasPagesSection)) }}
                    className={clsx({ 'is-active': hasSubmenu && !!props.match })}
                  >
                    <NavLink
                      className="menu-link"
                      to={`${projectLink}/nodes/${section.slug}`}
                      data-qa={`sidebar-section-${section.slug}`}
                    >
                      <span className="icon">
                        <i className={icon} />
                      </span>
                      <span className="ml-2">{section.name}</span>
                    </NavLink>
                    {hasSubmenu ? (
                      <motion.ul
                        layout
                        className="submenu"
                        variants={SUBMENU_ENTER_VARIANTS}
                        animate={props.match ? 'visible' : 'hidden'}
                      >
                        {submenuItems.map((item, sIndex) => {
                          const link = item.link(section.slug);

                          return (
                            <motion.li
                              key={link}
                              variants={SUBMENU_LINK_ENTER_VARIANTS}
                              animate={props.match ? 'visible' : 'hidden'}
                              transition={{ delay: 0.05 * sIndex }}
                            >
                              <NavLink to={link} exact>
                                <span className="ml-2">
                                  {typeof item.label === 'string' ? item.label : item.label(type)}
                                </span>
                              </NavLink>
                            </motion.li>
                          );
                        })}
                      </motion.ul>
                    ) : null}
                  </motion.li>
                )}
              </Match>
            );
          })}
          {keyValueStores.items.map((kvs) => (
            <motion.li
              layout
              variants={LINK_ENTER_VARIANTS}
              initial="hidden"
              animate="visible"
              exit="hidden"
              key={kvs.id}
              transition={{ delay: 0.05 * (commonSections.length + Number(hasPagesSection)) }}
            >
              <NavLink className="menu-link" to={`${projectLink}/key-values/${kvs.slug}`}>
                <span className="icon">
                  <i className="fal fa-line-columns" />
                </span>
                <span className="ml-2">{kvs.name}</span>
              </NavLink>
            </motion.li>
          ))}
          {project.hasMembers ? (
            <motion.li
              layout
              variants={LINK_ENTER_VARIANTS}
              initial="hidden"
              animate="visible"
              exit="hidden"
              key="members"
              transition={{
                delay: 0.05 * (commonSections.length + keyValueStores.items.length + Number(hasPagesSection)),
              }}
            >
              <NavLink className="menu-link" to={`${projectLink}/members`}>
                <span className="icon">
                  <i className="fal fa-users" />
                </span>
                <span className="ml-2">Members</span>
              </NavLink>
            </motion.li>
          ) : null}
        </AnimatePresence>
      </ul>
      <ul className="sidebar-menu">
        <AnimatePresence>
          {!project?.themeId &&
            visibleUserRoutes.map((route, index) => (
              <motion.li
                layout
                variants={LINK_ENTER_VARIANTS}
                initial="hidden"
                animate="visible"
                exit="hidden"
                key={route.path}
                transition={{
                  delay:
                    0.05 *
                    (index +
                      commonSections.length +
                      keyValueStores.items.length +
                      Number(project.hasMembers) +
                      Number(hasPagesSection)),
                }}
              >
                <NavLink
                  to={`${projectLink}/${route.path}`}
                  className="menu-link"
                  data-qa={`sidebar-section-${route.path}`}
                >
                  {route.icon && (
                    <span className="icon">
                      <i className={route.icon} />
                    </span>
                  )}
                  <span className="ml-2">{route.label}</span>
                </NavLink>
              </motion.li>
            ))}

          <Match path={`${projectLink}/settings/*`} key="settings">
            {(props) => (
              <motion.li
                layout
                variants={LINK_ENTER_VARIANTS}
                initial="hidden"
                animate="visible"
                exit="hidden"
                transition={{ delay: 0.1 }}
                key="settings"
                className={clsx({ 'is-active': !!props.match })}
              >
                <NavLink to={`${projectLink}/settings`} className="menu-link" exact>
                  <span className="icon">
                    <i className="fal fa-cog" />
                  </span>
                  <span className="ml-2">Settings</span>
                </NavLink>
                <motion.ul
                  layout
                  className="submenu"
                  variants={SUBMENU_ENTER_VARIANTS}
                  animate={props.match ? 'visible' : 'hidden'}
                >
                  <motion.li
                    variants={SUBMENU_LINK_ENTER_VARIANTS}
                    animate={props.match ? 'visible' : 'hidden'}
                    transition={{ delay: 0 }}
                  >
                    <NavLink to={`${projectLink}/settings/integrations`} exact>
                      <span className="ml-2">Integrations</span>
                    </NavLink>
                  </motion.li>
                </motion.ul>
              </motion.li>
            )}
          </Match>

          <Match path={`${projectLink}/users/*`} key="users">
            {(props) =>
              selectedProject !== undefined &&
              PROJECT_ADMIN_ROLES.includes(selectedProject.role) && (
                <motion.li
                  layout
                  variants={LINK_ENTER_VARIANTS}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  transition={{ delay: 0.1 }}
                  key="users"
                  className={clsx({ 'is-active': !!props.match })}
                >
                  <NavLink to={`${projectLink}/users`} className="menu-link" exact>
                    <span className="icon">
                      <i className="fal fa-users" />
                    </span>
                    <span className="ml-2">Users</span>
                  </NavLink>
                  <motion.ul
                    layout
                    className="submenu"
                    variants={SUBMENU_ENTER_VARIANTS}
                    animate={props.match ? 'visible' : 'hidden'}
                  >
                    <motion.li
                      variants={SUBMENU_LINK_ENTER_VARIANTS}
                      animate={props.match ? 'visible' : 'hidden'}
                      transition={{ delay: 0 }}
                    >
                      <NavLink to={`${projectLink}/users/invites`} exact>
                        <span className="ml-2">Invites</span>
                      </NavLink>
                    </motion.li>
                  </motion.ul>
                </motion.li>
              )
            }
          </Match>

          <SidebarProfileButton
            delay={
              0.05 *
              (visibleUserRoutes.length +
                commonSections.length +
                keyValueStores.items.length +
                Number(project.hasMembers) +
                Number(hasPagesSection))
            }
            fullName={fullName}
            logOut={logOut}
            profileMatch={!!profileMatch}
          />
        </AnimatePresence>
      </ul>
    </>
  );
}
