import { combineReducers } from 'redux';
import { chainReducers, createApiReducer, createCrudApiReducer, INITIAL_API_STATE } from 'src/services/api';
import { Connection } from '../types';
import { ConnectionsActionTypes } from './actions';

const connectionsReducer = createCrudApiReducer<Connection>(
  ConnectionsActionTypes.CHANGE_CONNECTIONS_START,
  ConnectionsActionTypes.CHANGE_CONNECTIONS_FAILURE,
  ConnectionsActionTypes.FETCH_CONNECTIONS_SUCCESS,
  ConnectionsActionTypes.ADD_CONNECTION_SUCCESS,
  ConnectionsActionTypes.UPDATE_CONNECTION_SUCCESS,
  ConnectionsActionTypes.DELETE_CONNECTION_SUCCESS,
  (response: any) => ({ ...response }),
);

const verificationReducer = createApiReducer(
  [
    ConnectionsActionTypes.CHANGE_CONNECTIONS_START,
    ConnectionsActionTypes.UPDATE_CONNECTION_VERIFICATION,
    ConnectionsActionTypes.CHANGE_CONNECTIONS_FAILURE,
  ],
  (data, state) => {
    return {
      ...state,
      items: state.items.map((i: any) =>
        i.id === data.id ? { ...i, data: { ...i.data, verifiedAt: data.verifiedAt } } : { ...i },
      ),
    };
  },
  (data) => data,
);

export default combineReducers({
  connections: chainReducers(INITIAL_API_STATE, connectionsReducer, verificationReducer),
});
