import { useState, useLayoutEffect } from 'react';

export function useMotionWithBulma(isActive: boolean): [string, boolean, () => void] {
  const [finishedAnimation, setFinishedAnimation] = useState(true);
  const currentVariant = isActive ? 'visible' : 'hidden';
  const isVisible = isActive || !finishedAnimation;

  function onAnimationComplete() {
    if (!isActive) {
      setFinishedAnimation(true);
    }
  }

  useLayoutEffect(() => {
    if (isActive && finishedAnimation) {
      setFinishedAnimation(false);
    }
  }, [isActive, finishedAnimation]);

  return [currentVariant, isVisible, onAnimationComplete];
}
