import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { usePrevSearchURLValue } from './use-prev-search-url-value';

export function useNavbarSearch() {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const prevSearchURLValue = usePrevSearchURLValue();

  return {
    setSearch,
    search,
    debouncedSearch,
    prevSearchURLValue,
  };
}
