import Sketch, { SketchProps } from '@uiw/react-color-sketch';
import React, { ReactNode, useCallback } from 'react';
import { NonUndefined } from 'react-hook-form';

type ColorPickerProps = {
  label?: ReactNode;
  value: string;
  onChange?: (value: string) => any;
  onBlur?: () => void;
  id?: string;
  disabled?: boolean;
};

export function ColorPicker({ label, value, onChange, onBlur, id, disabled = false }: ColorPickerProps) {
  const onPickColor = useCallback<NonUndefined<SketchProps['onChange']>>(
    (newShade) => {
      onChange?.(newShade.hex.substring(1));
      onBlur?.();
    },
    [onBlur, onChange],
  );

  return (
    <div className="field has-floating-label color-picker">
      {label ? (
        <label htmlFor={id} className="label">
          {label}
        </label>
      ) : null}
      <Sketch
        color={value || ''}
        onChange={onPickColor}
        id={id}
        presetColors={false}
        prefixCls="color-picker-component"
        style={{ boxShadow: 'none', width: '100%' }}
        editableDisable={!disabled}
      />
    </div>
  );
}
