import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, Controller, FormState, useWatch } from 'react-hook-form';
import { formatDate, isTouched } from 'src/common/util';
import { FormNode } from 'src/modules/nodes/types';
import { Accordion } from '../Accordion';
import { Checkbox } from './Checkbox';
import { InputHook } from './InputHook';

type PublishableFieldsProps = {
  control: Control<any>;
  formState: FormState<any>;
  node: FormNode | null;
  register: any;
};

export function PublishableFields({ control, formState, node, register }: PublishableFieldsProps) {
  const [queue, setQueue] = useState<string[]>([]);

  const watches = useWatch({
    control,
    name: [
      'publishable.isPublished',
      'publishDate',
      'publishTime',
      'publishedToDate',
      'publishedToTime',
      'alwaysActive',
    ],
  });
  const {
    'publishable.isPublished': arrayPublished,
    publishDate,
    publishTime,
    publishedToDate,
    publishedToTime,
    alwaysActive: arrayAlwaysActive,
  } = watches;
  const isPublished = Array.isArray(arrayPublished) ? arrayPublished[0] || false : arrayPublished || false;
  const alwaysActive = Array.isArray(arrayAlwaysActive) ? arrayAlwaysActive[0] || false : arrayAlwaysActive || false;

  const headerValue = useMemo(() => {
    const base = publishDate ? `${formatDate(`${publishDate}T${publishTime}`)} ${publishTime}` : '';

    if (!alwaysActive && base) {
      if (publishedToDate) {
        return `${base} ‣ ${formatDate(`${publishedToDate}T${publishedToTime}`)} ${publishedToTime}`;
      }
      return base;
    }
    return base;
  }, [alwaysActive, publishDate, publishTime, publishedToDate, publishedToTime]);

  const clearPublishedToDate = useCallback(() => {
    setQueue(['publishedToDate', 'publishedToTime']);
  }, []);

  // react-hook-form requires setValue to be called once per update
  useEffect(() => {
    if (queue.length > 0) {
      const newQueue = queue.slice();
      const first = newQueue.shift()!;

      control.setValue(first, '');

      setQueue(newQueue);
    }
  }, [queue, control]);

  return (
    <Accordion
      className="events-accordion"
      disableShadow
      items={[
        {
          Header: (isExpanded) => (
            <div className="field has-floating-label is-active event-header mb-3">
              <label htmlFor="publishableHeader" className="label is-active has-icons">
                Publishable
              </label>
              <div className="control has-icons-right has-icons-left">
                <input
                  type="text"
                  readOnly
                  value={headerValue || 'Unpublished'}
                  className="input is-clickable has-subhelper"
                  id="publishableHeader"
                />
                <span className="icon is-left">
                  <i className={`fal fa-lg fa-${isPublished ? 'file-check' : 'file-edit'}`} />
                </span>
                <span className="icon is-right">
                  <i className={`fal fa-lg fa-${isExpanded ? 'minus' : 'plus'}`} />
                </span>
                <span className={`subhelper has-text-${isPublished ? 'success' : 'info'}`}>
                  {isPublished ? 'Published' : 'Draft'}
                </span>
              </div>
            </div>
          ),
          id: 'publishable',
          content: (
            <>
              <div className="columns is-multiline is-gapless mb-0">
                <div className="column is-half">
                  <Controller
                    control={control}
                    name="publishDate"
                    defaultValue={node?.publishDate || ''}
                    as={
                      <InputHook
                        id="publishDate"
                        label="Publish date"
                        type="date"
                        error={formState.errors.publishDate?.message}
                        touched={isTouched(formState, 'publishDate')}
                        fieldClassName="mr-2 mb-3"
                      />
                    }
                  />
                </div>
                <div className="column is-half">
                  <Controller
                    control={control}
                    name="publishTime"
                    defaultValue={node?.publishTime || ''}
                    as={
                      <InputHook
                        id="publishTime"
                        label="Publish time"
                        type="time"
                        error={formState.errors.publishTime?.message}
                        touched={isTouched(formState, 'publishTime')}
                        fieldClassName="ml-2 mb-3"
                      />
                    }
                  />
                </div>
              </div>
              {!alwaysActive ? (
                <div className="columns is-gapless is-vcentered">
                  <div className="column is-half">
                    <Controller
                      control={control}
                      name="publishedToDate"
                      defaultValue={node?.publishedToDate || ''}
                      as={
                        <InputHook
                          id="publishedToDate"
                          label="Publish to date"
                          type="date"
                          error={formState.errors.publishedToDate?.message}
                          touched={isTouched(formState, 'publishedToDate')}
                          fieldClassName="mr-2 mb-3"
                        />
                      }
                    />
                  </div>
                  <div className="column">
                    <Controller
                      control={control}
                      name="publishedToTime"
                      defaultValue={node?.publishedToTime || ''}
                      as={
                        <InputHook
                          id="publishedToTime"
                          label="Publish time"
                          type="time"
                          error={formState.errors.publishedToTime?.message}
                          touched={isTouched(formState, 'publishedToTime')}
                          fieldClassName="ml-2 mb-3"
                        />
                      }
                    />
                  </div>
                  <div className="column is-narrow">
                    <button
                      className="button is-white mb-3 ml-2"
                      type="button"
                      title="Clear published to date and time"
                      onClick={clearPublishedToDate}
                    >
                      <span className="icon has-text-grey">
                        <i className="fal fa-times" />
                      </span>
                    </button>
                  </div>
                </div>
              ) : null}
              <div className="columns is-gapless">
                <div className="column is-half">
                  <Checkbox
                    ref={register}
                    name="publishable.isPublished"
                    label="Published"
                    id="publishable.isPublished"
                    touched={isTouched(formState, 'publishable.isPublished')}
                    hideHelper
                    defaultChecked={node?.publishable?.isPublished || false}
                  />
                </div>
                <div className="column is-half">
                  <Checkbox
                    ref={register}
                    name="alwaysActive"
                    label="Always active"
                    id="alwaysActive"
                    error={formState.errors.alwaysActive?.message}
                    touched={isTouched(formState, 'alwaysActive')}
                    hideHelper
                    defaultChecked={node?.alwaysActive || false}
                    fieldClassName="ml-2"
                  />
                </div>
              </div>
            </>
          ),
        },
      ]}
    />
  );
}
