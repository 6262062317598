export const WEEKDAYS_OPTIONS: { value: number; label: string }[] = [
  { value: 0, label: 'Monday' },
  { value: 1, label: 'Tuesday' },
  { value: 2, label: 'Wednesday' },
  { value: 3, label: 'Thursday' },
  { value: 4, label: 'Friday' },
  { value: 5, label: 'Saturday' },
  { value: 6, label: 'Sunday' },
];

export const REPEAT_EVERY_OPTIONS: { value: number; label: string }[] = [
  { value: 0, label: 'First' },
  { value: 1, label: 'Second' },
  { value: 2, label: 'Third' },
  { value: 3, label: 'Fourth' },
];
