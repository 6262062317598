import React, { forwardRef, ReactNode } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { InputHook } from './InputHook';

type DatePickerProps = {
  label?: ReactNode;
  error?: string;
  touched?: boolean;
  prependIcon?: ReactNode;
  fieldClassName?: string;
} & ReactDatePickerProps;

export const DatePicker = forwardRef<ReactDatePicker, DatePickerProps>(function CustomDatePicker(
  { label, error, touched, prependIcon, fieldClassName, ...rest },
  ref,
) {
  return (
    <ReactDatePicker
      ref={ref}
      customInput={
        <InputHook
          label={label}
          id={rest.id}
          error={error}
          touched={touched}
          readOnly
          prependIcon={prependIcon}
          fieldClassName={fieldClassName}
        />
      }
      {...rest}
    />
  );
});
