import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { LayoutWithNavbar, Table, TableDropdown, useCurrentPage, usePrevSearchURLValue } from 'src/common';
import { showToast } from 'src/common/util/show-toast';
import { isLoading } from 'src/services/api';
import { getTotalPages } from 'src/services/api/redux/traits';
import { KeyValueStore, useKeyValueStores } from 'src/services/key-values';
import { RouteComponentListProps } from 'src/types';
import Swal from 'sweetalert2';
import { useDebounce } from 'use-debounce';
import { StoreEditorModal } from './components';

type KeyValueStoresListProps = RouteComponentListProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function KeyValueStoresList(_: KeyValueStoresListProps) {
  const { keyValueStores, loadStores, deleteStore } = useKeyValueStores();
  const [currentPage, setCurrentPage] = useCurrentPage();
  const prevSearchURLValue = usePrevSearchURLValue();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [editStore, setEditStore] = useState<KeyValueStore | null>(null);

  const onDeleteStore = useCallback(
    async (store: KeyValueStore) => {
      const confirm = await Swal.fire({
        title: 'Delete',
        text: `Are you sure you want to delete this store?`,
        showCancelButton: true,
        confirmButtonColor: '#e0245a',
        confirmButtonText: 'Delete',
        icon: 'question',
      });

      if (confirm.value) {
        const result = await deleteStore(store.id!);

        if (result.ok) {
          showToast('success', 'Store has been deleted successfully');
          setEditStore(null);
        } else {
          showToast('error', 'Unable to delete');
        }
      }
    },
    [deleteStore],
  );

  const tableColumns = useMemo<Column<KeyValueStore>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: 'Slug',
        accessor: 'slug',
        disableSortBy: true,
      },
      {
        Header: 'Key name',
        accessor: 'keyName',
        disableSortBy: true,
      },
      {
        Header: 'Value name',
        accessor: 'valueName',
        disableSortBy: true,
      },

      {
        accessor: 'id',
        Cell: ({ row: { original } }) => (
          <TableDropdown>
            <a className="dropdown-item" onClick={() => setEditStore(original)}>
              Edit
            </a>
            <a className="dropdown-item" onClick={() => onDeleteStore(original)}>
              Delete
            </a>
          </TableDropdown>
        ),
      },
    ],
    [onDeleteStore],
  );

  const updateSearch = useCallback(
    (text: string) => {
      if (!prevSearchURLValue) setCurrentPage(0);
      setSearch(text);
    },
    [setCurrentPage, prevSearchURLValue],
  );

  useEffect(() => {
    loadStores(currentPage + 1, debouncedSearch);
  }, [currentPage, debouncedSearch, loadStores]);

  return (
    <>
      <LayoutWithNavbar
        breadcrumbs={[{ href: '/key-values/stores', label: 'Key/Value Stores' }]}
        onSearch={updateSearch}
        searchValue={search}
        endButtons={
          <div className="navbar-item">
            <button
              className="button is-primary"
              onClick={() => setEditStore({ keyName: '', valueName: '', name: '', slug: '' })}
              data-qa="create-button"
            >
              <span className="icon">
                <i className="fal fa-plus" />
              </span>
              <span>Create</span>
            </button>
          </div>
        }
      >
        <Table
          data={keyValueStores.items}
          loading={isLoading(keyValueStores)}
          columns={tableColumns}
          pageCount={getTotalPages(keyValueStores)}
          paginated
        />
      </LayoutWithNavbar>
      <StoreEditorModal store={editStore} onCancel={() => setEditStore(null)} />
    </>
  );
}
