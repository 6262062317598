import React, { ReactNode } from 'react';

type FieldsetProps = {
  title: ReactNode;
  children: ReactNode;
  className?: string;
};

export function Fieldset({ className = '', title, children }: FieldsetProps) {
  return (
    <fieldset className={className}>
      <legend>{title}</legend>
      {children}
    </fieldset>
  );
}
