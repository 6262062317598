export function ordinalSuffix(number: number | string) {
  const stringNumber = number.toString();
  const lastNumber = stringNumber.substr(-1, 1);
  let suffix = 'th';

  if (Number(number) < 4 || Number(number) > 20) {
    switch (lastNumber) {
      case '1':
        suffix = 'st';
        break;

      case '2':
        suffix = 'nd';
        break;

      case '3':
        suffix = 'rd';
        break;

      default:
        suffix = 'th';
    }
  }

  return `${number}${suffix}`;
}
