import React from 'react';
import { Link, LinkProps } from '@reach/router';
import clsx from 'clsx';

type NavLinkProps = {
  className?: string;
  exact?: boolean;
} & React.PropsWithoutRef<LinkProps<any>>;

export function NavLink({ exact = false, className = '', ...props }: NavLinkProps) {
  return (
    <Link
      {...props}
      getProps={({ isPartiallyCurrent, href, isCurrent }) => {
        return {
          className: clsx(className, {
            'is-active': exact ? isCurrent : isCurrent || (isPartiallyCurrent && href !== '/'),
          }),
        };
      }}
    />
  );
}
