import React, { useState, useEffect, useRef, ReactNode } from 'react';
import clsx from 'clsx';
import { getFileInputValue } from 'src/common/util';
import { showToast } from 'src/common/util/show-toast';

function getThumb(file: File): Promise<string | null> {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();

      reader.addEventListener('loadend', () => {
        resolve(reader.result as string);
      });
      reader.addEventListener('error', () => {
        reject(reader.error);
      });
      reader.readAsDataURL(file);
    } else {
      resolve(null);
    }
  });
}

type ImageUploaderProps = {
  label?: ReactNode;
  value?: File;
  onChange?: (value: File | File[] | null) => any;
  fieldClassName?: string;
} & JSX.IntrinsicElements['input'];

export function ImageUploaderHook({ label, id, value, onChange, fieldClassName, ...props }: ImageUploaderProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [thumb, setThumb] = useState<string | null>(null);

  useEffect(() => {
    async function load() {
      if (value) {
        if (typeof value === 'string') {
          setThumb(value);
        } else {
          try {
            const result = await getThumb(value);
            setThumb(result);
          } catch (error) {
            console.error(error);
            showToast('error', `Error while reading selected file. (${error.message})`);
            setThumb(null);
          }
        }
      } else {
        setThumb(null);
        if (ref.current) {
          ref.current.value = '';
        }
      }
    }
    load();
  }, [value]);

  const isLink = thumb && thumb.startsWith('http');

  return (
    <div className={clsx('field has-floating-label image-uploader', fieldClassName)}>
      {label ? (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      ) : null}
      <div className="control">
        <div className="image-container">
          <label htmlFor={id} className="button upload is-light">
            <span className="icon is-small has-text-info">
              <i className="fal fa-plus" />
            </span>
            <span>Upload image</span>
          </label>
          {thumb && (
            <div className="thumb">
              <img src={thumb} alt="" />
              <div className="overlay">
                {/* eslint-disable jsx-a11y/anchor-has-content */}
                {isLink && <a href={thumb} target="_blank" rel="noopener noreferrer" />}
                <button type="button" className="button delete" onClick={() => onChange!(null)}>
                  <span className="icon">
                    <i className="fal fa-times" />
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <input
        type="file"
        id={id}
        ref={ref}
        accept="image/*"
        {...props}
        onChange={() => onChange!(getFileInputValue(ref))}
      />
    </div>
  );
}
