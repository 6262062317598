import { Language } from './types';

export const transformLanguage = (response: any): Language => ({
  id: response.id,
  name: response.name,
});

export const transformLanguageForApi = (language: Language): any => {
  return language;
};
