import { createCrudApiActions } from 'src/services/api';

import { Language } from '../types';
import { transformLanguageForApi } from '../transformations';

export enum LanguageActionTypes {
  CHANGE_LANGUAGES_START = 'languages/change_languages_start',
  CHANGE_LANGUAGES_FAILURE = 'languages/change_languages_failure',
  FETCH_LANGUAGES_SUCCESS = 'languages/fetch_languages_success',
  ADD_LANGUAGE_SUCCESS = 'languages/add_language_success',
  UPDATE_LANGUAGE_SUCCESS = 'languages/update_language_success',
  DELETE_LANGUAGE_SUCCESS = 'languages/delete_language_success',
}

export const [fetchLanguages, addLanguage, updateLanguage, deleteLanguage] = createCrudApiActions<Language>(
  '/languages',
  LanguageActionTypes.CHANGE_LANGUAGES_START,
  LanguageActionTypes.CHANGE_LANGUAGES_FAILURE,
  LanguageActionTypes.FETCH_LANGUAGES_SUCCESS,
  LanguageActionTypes.ADD_LANGUAGE_SUCCESS,
  LanguageActionTypes.UPDATE_LANGUAGE_SUCCESS,
  LanguageActionTypes.DELETE_LANGUAGE_SUCCESS,
  transformLanguageForApi,
);
