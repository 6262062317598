import React, { useEffect } from 'react';
import { Router, navigate, useMatch, RouteComponentProps } from '@reach/router';
import { ROUTES, USER_ROUTES } from 'src/const';
import { useAuth } from 'src/services/auth';
import { isEmpty, isLoading, isValid } from 'src/services/api';
import { useSections } from 'src/services/sections';
import { useProject } from 'src/services/project';
import { EmptySections } from './EmptySections';

type PanelProps = RouteComponentProps<{ projectId: string }>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Panel(props: PanelProps) {
  const { auth } = useAuth();
  const isLoginPage = useMatch('/login');
  const isSectionsPage = useMatch('/sections/*');
  const isAuthenticated = isValid(auth);
  const allRoutes = ROUTES.concat(USER_ROUTES);
  const { sections, loadSections } = useSections();
  const { project, loadProject } = useProject();
  const { projectId } = props;

  useEffect(() => {
    async function checkProject() {
      if (project?.id !== Number(projectId) && !isLoading(project)) {
        await loadProject(Number(projectId));
      }
    }
    checkProject();
  }, [project, projectId, loadProject]);

  useEffect(() => {
    if (!isAuthenticated && !isLoginPage) {
      navigate('/login');
    }
  }, [isAuthenticated, isLoginPage]);

  useEffect(() => {
    if (isValid(project) && !isLoading(project) && !isEmpty(project) && !isLoading(sections)) {
      loadSections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSections, project]);

  if (isAuthenticated && (isValid(sections) || isSectionsPage)) {
    return (
      <>
        <Router>
          <EmptySections path="/" />
          {allRoutes.map((route) => (
            <route.component key={route.path} path={route.path} />
          ))}
        </Router>
      </>
    );
  }
  return null;
}
