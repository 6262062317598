import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from '@reach/router';
import clsx from 'clsx';
import { capitalize, find } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Accordion,
  EditPageLayout,
  EditPageLoading,
  ImageUploaderHook,
  InputHook,
  isTouched,
  LayoutWithNavbar,
  SelectHook,
  usePrevPageLink,
} from 'src/common';
import { VALIDATION_MESSAGES } from 'src/common/const';
import { isLoading } from 'src/services/api';
import { useLanguages } from 'src/services/languages';
import { fetchMemberDetails, useMembers, User, useUserRoles } from 'src/services/users';
import { RouteComponentEditProps, RSelectOption } from 'src/types';
import * as yup from 'yup';
import { showToast } from '../../common/util/show-toast';

const validationSchema = yup.object({
  firstName: yup.string().required(VALIDATION_MESSAGES.required('First name')),
  lastName: yup.string(),
  // roleId: yup.number().required(VALIDATION_MESSAGES.required('Role')),
});

type MembersEditProps = RouteComponentEditProps;

export function MembersEdit({ id }: MembersEditProps) {
  const [user, setUser] = useState<User | null>(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const { createMember, updateMember, updateMemberData, updateMemberAvatar } = useMembers();
  const { languages } = useLanguages();
  const { loadUserRoles, userRoles } = useUserRoles();
  const { control, formState, reset, handleSubmit, register } = useForm<User>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const listLink = usePrevPageLink('/members');

  const rolesOptions: RSelectOption[] = useMemo(
    () =>
      userRoles.items.map((role) => ({
        value: role.id!,
        label: role.name,
      })),
    [userRoles.items],
  );

  const save = handleSubmit(async (values) => {
    const data = {
      ...values,
      id: user?.id,
      roleName: find(rolesOptions, { value: values.roleId })?.label,
    };

    let result;

    if (data.id) {
      result = await updateMember(data);
      await Promise.all([updateMemberData(data), updateMemberAvatar(data)]);
    } else {
      result = await createMember(data);
      const memberId = result?.res?.payload?.id;
      if (memberId) {
        data.id = memberId;
        await updateMemberAvatar(data);
      }
    }

    if (result.ok) {
      showToast('success', 'Successfully saved');
      navigate(listLink);
    } else {
      showToast('error', 'There was an error processing your action');
    }
  });

  const languageOptions = useMemo(
    () =>
      languages.items.map((lang) => ({
        label: capitalize(lang.name),
        value: lang.id,
      })),
    [languages.items],
  );

  const loading = !formState.isSubmitting && (loadingUser || isLoading(userRoles));

  useEffect(() => {
    loadUserRoles('');
  }, [loadUserRoles]);

  useEffect(() => {
    async function loadMember() {
      if (id && !loading && !user) {
        setLoadingUser(true);
        const editUser = await fetchMemberDetails(Number(id));

        if (editUser) {
          setUser(editUser);
          setLoadingUser(false);
          reset(editUser);
        } else {
          navigate(listLink);
          // TODO delete old swal // or want to keep? // Swal.fire('Error', 'Member not found', 'error');
          showToast('error', 'Member not found');
        }
      }
    }
    loadMember();
  }, [id, loading, reset, rolesOptions, listLink, user]);

  if (loading) {
    return <EditPageLoading />;
  }

  return (
    <>
      <LayoutWithNavbar
        backHref={listLink}
        breadcrumbs={[
          { href: listLink, label: 'Members' },
          {
            href: './',
            label: `${user?.firstName || ''} ${user?.lastName || ''}`,
          },
        ]}
        endButtons={
          <div className="navbar-item">
            <button
              type="submit"
              className={clsx('button is-primary', {
                'is-loading': formState.isSubmitting,
              })}
              data-qa="save-button"
            >
              <span className="icon">
                <i className="far fa-check has-text-secondary-light" />
              </span>
              <span>Save</span>
            </button>
          </div>
        }
        as="form"
        onSubmit={save}
      >
        <EditPageLayout
          renderLeft={
            <Accordion
              disableShadow
              items={[
                {
                  Header: 'Member',
                  id: 'member',
                  fixed: true,
                  content: (
                    <>
                      <Controller
                        control={control}
                        name="firstName"
                        as={<InputHook id="firstName" label="First Name" />}
                        error={formState.errors.firstName?.message}
                        touched={isTouched(formState, 'firstName')}
                        defaultValue=""
                      />
                      <Controller
                        control={control}
                        name="lastName"
                        as={<InputHook id="lastName" label="Last Name" />}
                        error={formState.errors.lastName?.message}
                        touched={isTouched(formState, 'lastName')}
                        defaultValue=""
                      />
                      <Controller
                        control={control}
                        name="email"
                        as={<InputHook id="email" label="Email" type="email" />}
                        error={formState.errors.email?.message}
                        touched={isTouched(formState, 'email')}
                        defaultValue=""
                      />
                      <Controller
                        control={control}
                        name="avatarUrl"
                        render={({ ref, ...props }) => <ImageUploaderHook id="avatarUrl" label="Avatar" {...props} />}
                        error={formState.errors.avatarUrl?.message}
                        touched={isTouched(formState, 'avatarUrl')}
                        defaultValue=""
                      />
                    </>
                  ),
                },
                {
                  Header: 'Data',
                  id: 'data',
                  defaultExpanded: true,
                  content: (
                    <>
                      <SelectHook
                        ref={register}
                        name="data.language"
                        id="language"
                        label="Language"
                        options={languageOptions}
                      />
                    </>
                  ),
                },
              ]}
            />
          }
        />
      </LayoutWithNavbar>
      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </>
  );
}
