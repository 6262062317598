import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizations } from '../redux';
import { fetchOrganizations, addOrganizationAction, updateOrganizationAction } from '../redux/actions';
import { Organization } from '../types';

export function useOrganizations() {
  const dispatch = useDispatch();
  const organizations = useSelector(getOrganizations);

  const loadOrganizations = useCallback(
    (search = '') => {
      return dispatch(fetchOrganizations(1, 9999, { search }));
    },
    [dispatch],
  );

  const addOrganization = useCallback(
    (data: Organization) => {
      return dispatch(addOrganizationAction(data));
    },
    [dispatch],
  );

  const updateOrganization = useCallback(
    (data: Organization) => {
      return dispatch(updateOrganizationAction(data));
    },
    [dispatch],
  );

  return {
    organizations,
    loadOrganizations,
    addOrganization,
    updateOrganization,
  };
}
