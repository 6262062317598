import { combineReducers } from 'redux';

import { createCrudApiReducer } from 'src/services/api';

import { LanguageActionTypes } from './actions';
import { transformLanguage } from '../transformations';
import { Language } from '../types';

const languagesReducer = createCrudApiReducer<Language>(
  LanguageActionTypes.CHANGE_LANGUAGES_START,
  LanguageActionTypes.CHANGE_LANGUAGES_FAILURE,
  LanguageActionTypes.FETCH_LANGUAGES_SUCCESS,
  LanguageActionTypes.ADD_LANGUAGE_SUCCESS,
  LanguageActionTypes.UPDATE_LANGUAGE_SUCCESS,
  LanguageActionTypes.DELETE_LANGUAGE_SUCCESS,
  transformLanguage,
);

export default combineReducers({ languages: languagesReducer });
