import { Link, navigate } from '@reach/router';
import React, { useCallback } from 'react';
import { useKeyValueStores } from 'src/services/key-values';

import { Organization } from 'src/services/organizations/types';
import { Project, useProject } from 'src/services/project';
import { useSections } from 'src/services/sections';
import { TableDropdown } from './TableDropdown';

type OrganizationCardProps = {
  organization: Organization;
};

export function OrganizationCard({ organization }: OrganizationCardProps) {
  const { selectProject } = useProject();
  const { loadSections } = useSections();
  const { loadStores } = useKeyValueStores();

  const handleSelectProject = useCallback(
    async (project: Project) => {
      selectProject(project);
      const promises = [loadSections()];
      if (project.hasKeyValues) {
        promises.push(loadStores());
      }
      await Promise.all(promises);
      navigate(`/projects/${project.id}/`);
    },
    [selectProject, loadSections, loadStores],
  );

  return (
    <div className="card page-block" key={organization.id}>
      <div className="card-header is-shadowless">
        <p className="card-header-title">
          <Link to={`/organizations/${organization.id}`}>{`${organization.name} `}</Link>
          <span className="card-tag">{organization.plan.name}</span>
          {organization.role === 'owner' ? <span className="card-tag">Owner</span> : null}
        </p>
        <div className="card-header-icon">
          <TableDropdown>
            <Link to={`/organizations/${organization.id}/edit`} className="dropdown-item">
              Edit
            </Link>
          </TableDropdown>
        </div>
        {/* <button className="button button-with-icon">
      <span className="icon">
        <i className="far fa-chevron-circle-up" />
      </span>
      <p>upgrade</p>
    </button> */}
      </div>
      {/* <p className="card-info-details">Lorem ipsum dolor sit amet</p> */}
      {/* <div className="card-content">
    <Table
      columns={tableColumns}
      data={organization.projects}
      noContainer
      className="dashboard-card-table"
    />
  </div> */}
      <div className="my-results">
        {organization.projects.map((project) => (
          <div className="card-status-bar" key={project.id}>
            <div className="card-status-bar-left">
              {/* <div className="card-status-bar-icon" /> */}
              <a data-qa="dashboard-project-link" onClick={() => handleSelectProject(project)}>
                {project.name}
              </a>
            </div>
            <div className="card-status-bar-right">
              <p className="card-status-bar-info">&info</p>
              <p className="card-status-bar-info">&detail</p>
              <p className="card-status-bar-info">&something</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
