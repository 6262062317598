import React, { useState, useEffect, useCallback, useRef } from 'react';
import { RouteComponentProps, navigate, useLocation } from '@reach/router';
import * as yup from 'yup';
import clsx from 'clsx';

import { fetchSectionDetails, Section, useSections, transformSectionForForm, SectionType } from 'src/services/sections';
import {
  LayoutWithNavbar,
  InputHook,
  Checkbox,
  isTouched,
  usePrevPageLink,
  EditPageLoading,
  EditPageLayout,
  Accordion,
  Tabs,
  MultiInputHook,
  SelectHook,
} from 'src/common';
import { CONNECTION_TYPE_OPTIONS, VALIDATION_MESSAGES } from 'src/common/const';
import { transformSectionForApi } from 'src/services/sections/transformations';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';
import { showToast } from 'src/common/util/show-toast';
import { SectionFields } from './SectionFields';

const validationSchema = yup.object({
  name: yup.string().required(VALIDATION_MESSAGES.required('Name')),
  slug: yup.string().required(VALIDATION_MESSAGES.required('Slug')),
});

type SectionsEditProps = RouteComponentProps<{ id: string }>;

export function SectionsEdit({ id }: SectionsEditProps) {
  const location = useLocation();
  const [section, setSection] = useState<Section | null>(null);
  const [loadingSection, setLoadingSection] = useState(true);
  const sectionFieldsWrapperRef = useRef<HTMLDivElement>(null!);
  const { createSection, updateSection, sections } = useSections();

  const listLink = usePrevPageLink('/sections');

  const { control, register, formState, handleSubmit, reset, watch } = useForm<Section>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const isEditing = !!id;
  const canAddFields = section?.type !== 8 || Number(watch('connectionType')) === 1;

  const getSectionTypeDescription = useCallback(
    (mainSectionId: number | undefined, type: SectionType) => {
      if (!mainSectionId) {
        return 'Main section';
      }

      const mainSection = sections.items.find((e) => e.id === mainSectionId);
      return `${SectionType[type]} for ${mainSection?.name}`;
    },
    [sections?.items],
  );

  useEffect(() => {
    async function loadSection() {
      if (id) {
        setLoadingSection(true);
        const result = await fetchSectionDetails(Number(id));
        const mainSectionId =
          (result.type === SectionType.Categories ? result.categorySectionId : null) || result.parentSectionId;

        if (result) {
          setLoadingSection(false);
          const transformedSection = transformSectionForForm(result, id);
          transformedSection.typeDescription = getSectionTypeDescription(mainSectionId, result.type);
          setSection(transformedSection);
          reset(transformedSection);
        } else {
          showToast('error', 'Section not found', 3000);
          navigate(listLink);
        }
      } else {
        const searchParams = new URLSearchParams(location.search);
        const sectionType = searchParams.get('type');
        const parentSectionId = searchParams.get('mainSection');
        const type = sectionType ? Number(sectionType) : SectionType.Articles;
        const transformedSection = transformSectionForForm(
          {
            name: '',
            slug: '',
            type,
            categorySectionId: Number(sectionType) === SectionType.Categories ? Number(parentSectionId) : undefined,
            parentSectionId:
              Number(sectionType) === SectionType.Collections || Number(sectionType) === SectionType.Connections
                ? Number(parentSectionId)
                : undefined,
            isPublishable: true,
            isEvent: false,
            isProduct: false,

            hasStripeCheckout: false,
            hasTransactionConnection: false,
            hasSeoMetadata: false,
            hasVerification: false,
            hasCategory: type === SectionType.Articles,
          },
          null,
        );
        const mainSectionId = Number(parentSectionId) || undefined;
        transformedSection.typeDescription = getSectionTypeDescription(
          mainSectionId,
          Number(sectionType) || SectionType.Articles,
        );
        setSection(transformedSection);
        reset(transformedSection);
        setLoadingSection(false);
      }
    }
    loadSection();
  }, [getSectionTypeDescription, id, listLink, reset, location]);

  const save = handleSubmit(async (values) => {
    const data = transformSectionForApi({ ...section, ...values });
    data.id = section?.id;

    let result;

    if (data.id) {
      result = await updateSection(data);
    } else {
      result = await createSection(data);
    }

    if (result.ok) {
      showToast('success', 'Successfully saved');
      navigate(listLink);
    } else {
      showToast('error', 'There was an error processing your action');
    }
  });

  if (loadingSection) {
    return <EditPageLoading />;
  }

  return (
    <>
      <LayoutWithNavbar
        backHref={listLink}
        breadcrumbs={[
          { href: listLink, label: 'Sections' },
          {
            href: './',
            label: isEditing ? section?.name || '' : 'New section',
          },
        ]}
        endButtons={
          <div className="navbar-item">
            <button
              type="submit"
              className={clsx('button is-primary', {
                'is-loading': formState.isSubmitting,
              })}
              data-qa="save-button"
            >
              <span className="icon">
                <i className="far fa-check has-text-secondary-light" />
              </span>
              <span>Save</span>
            </button>
          </div>
        }
        as="form"
        onSubmit={save}
        className="sections-edit-page"
      >
        <input type="hidden" name="type" ref={register} />
        <input type="hidden" name="categorySectionId" ref={register} />
        <input type="hidden" name="parentSectionId" ref={register} />
        <EditPageLayout
          renderLeft={
            <Accordion
              disableShadow
              items={[
                {
                  Header: 'General',
                  id: 'general',
                  defaultExpanded: true,
                  content: (
                    <>
                      {section?.type === 8 ? (
                        <Controller
                          control={control}
                          name="connectionType"
                          defaultValue="1"
                          as={
                            <SelectHook
                              label="Type"
                              id="connectionType"
                              options={CONNECTION_TYPE_OPTIONS}
                              error={formState.errors.name?.message}
                              touched={isTouched(formState, 'connectionType')}
                            />
                          }
                        />
                      ) : (
                        <Controller
                          control={control}
                          name="typeDescription"
                          defaultValue=""
                          as={<InputHook label="Type" id="typeDescription" readOnly />}
                        />
                      )}
                      <Controller
                        control={control}
                        name="name"
                        defaultValue=""
                        as={
                          <InputHook
                            label="Name"
                            id="name"
                            error={formState.errors.name?.message}
                            touched={isTouched(formState, 'name')}
                          />
                        }
                        data-qa="section-name"
                      />
                      <Controller
                        control={control}
                        name="slug"
                        defaultValue=""
                        as={
                          <InputHook
                            label="Slug"
                            id="slug"
                            error={formState.errors.slug?.message}
                            touched={isTouched(formState, 'slug')}
                          />
                        }
                        data-qa="section-slug"
                      />
                      <Controller
                        control={control}
                        name="previewUrl"
                        defaultValue=""
                        as={
                          <MultiInputHook
                            label="Preview URL"
                            id="previewUrl"
                            error={formState.errors.previewUrl?.message}
                            touched={isTouched(formState, 'previewUrl')}
                          />
                        }
                        data-qa="section-preview-url"
                      />
                      {/* TODO: icons */}
                    </>
                  ),
                },
                {
                  Header: 'Features',
                  id: 'features',
                  defaultExpanded: true,
                  content: (
                    <>
                      <Checkbox
                        ref={register}
                        name="hasCategory"
                        id="hasCategory"
                        label="Category"
                        error={formState.errors.hasCategory?.message}
                        touched={isTouched(formState, 'hasCategory')}
                        disabled={!!id || SectionType.Articles === Number(section?.type)}
                        dataQa="section-category"
                      />
                      <Checkbox
                        ref={register}
                        name="isProduct"
                        id="isProduct"
                        label="Product"
                        error={formState.errors.isProduct?.message}
                        touched={isTouched(formState, 'isProduct')}
                        dataQa="section-product"
                      />
                      <Checkbox
                        ref={register}
                        name="hasStripeCheckout"
                        id="hasStripeCheckout"
                        label="Stripe Checkout"
                        error={formState.errors.hasStripeCheckout?.message}
                        touched={isTouched(formState, 'hasStripeCheckout')}
                        dataQa="section-stripe-checkout"
                      />
                      <Checkbox
                        ref={register}
                        name="hasTransactionConnection"
                        id="hasTransactionConnection"
                        label="Transaction"
                        error={formState.errors.hasTransactionConnection?.message}
                        touched={isTouched(formState, 'hasTransactionConnection')}
                        dataQa="section-transaction-connection"
                      />
                      <Checkbox
                        ref={register}
                        name="isEvent"
                        id="isEvent"
                        label="Event"
                        error={formState.errors.isEvent?.message}
                        touched={isTouched(formState, 'isEvent')}
                        dataQa="section-event"
                      />
                      <Checkbox
                        ref={register}
                        name="isPublishable"
                        id="isPublishable"
                        label="Publishable"
                        error={formState.errors.isPublishable?.message}
                        touched={isTouched(formState, 'isPublishable')}
                        dataQa="section-publishable"
                      />
                      <Checkbox
                        ref={register}
                        name="hasVerification"
                        id="hasVerification"
                        label="Verification"
                        error={formState.errors.hasVerification?.message}
                        touched={isTouched(formState, 'hasVerification')}
                        dataQa="section-verification"
                      />
                      <Checkbox
                        ref={register}
                        name="hasSeoMetadata"
                        id="hasSeoMetadata"
                        label="SEO Metadata"
                        error={formState.errors.hasSeoMetadata?.message}
                        touched={isTouched(formState, 'hasSeoMetadata')}
                        dataQa="section-seo-metadata"
                      />
                    </>
                  ),
                },
              ]}
            />
          }
        >
          <Tabs
            tabs={[
              {
                label: 'Fields',
                id: 'fields',
                content: <div id="section-fields-wrapper" ref={sectionFieldsWrapperRef} />,
              },
              {
                label: 'Custom styling',
                id: 'customCss',
                content: (
                  <div className="container is-fluid">
                    <Controller
                      control={control}
                      name="customCSS"
                      defaultValue={section?.customCSS || ''}
                      as={<MultiInputHook id="css" style={{ minHeight: 500 }} />}
                    />
                  </div>
                ),
              },
            ]}
          />
        </EditPageLayout>
      </LayoutWithNavbar>
      <Controller
        control={control}
        name="fields"
        defaultValue={[]}
        render={({ ref, ...props }) => (
          <SectionFields {...props} canAddFields={canAddFields} wrapper={sectionFieldsWrapperRef} />
        )}
      />
      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </>
  );
}
