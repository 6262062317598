import { ImageApiData, ImageMediaData } from '../types';

export function createImageMediaData(image: ImageApiData): ImageMediaData {
  return {
    id: image.data.id,
    fileId: image.data.fileId,
    name: image.data.name,
    isMain: image.data.isMain,
    url: image.data.url,
    smallUrl: image.data.smallUrl,
    mediumUrl: image.data.mediumUrl,
    originalFileId: image.data.originalFileId,
    smallFileId: image.data.smallFileId,
    mediumFileId: image.data.mediumFileId,
  };
}
