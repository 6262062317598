import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import * as yup from 'yup';
import { navigate, RouteComponentProps } from '@reach/router';

import { InputHook, isTouched, LayoutWithNavbar } from 'src/common';
import { Accordion, EditPageLayout } from 'src/common/components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';
import { showToast } from 'src/common/util/show-toast';
import { fetchOrganizationDetails } from 'src/services/organizations/api';
import { Organization } from 'src/services/organizations/types';
import { useOrganizations } from 'src/services/organizations';

const validationSchema = yup.object().shape({
  name: yup.string().required().label('Organization Name'),
});

type OrganizationsProps = RouteComponentProps<{
  id: number;
}>;

export function OrganizationEdit({ id }: OrganizationsProps) {
  const [organization, setOrganization] = useState<Organization | null>(null);
  const { addOrganization, updateOrganization } = useOrganizations();
  const { handleSubmit, control, formState, reset } = useForm<Organization>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const save = handleSubmit(async (values) => {
    const data = {
      ...organization,
      ...values,
    };

    let result;

    if (id) {
      result = await updateOrganization(data);
    } else {
      result = await addOrganization(data);
    }

    // @ts-ignore
    if (result.ok) {
      showToast('success', 'Successfully saved');
      navigate(`/organizations/${result.res.payload.id}`);
    } else {
      showToast('error', 'There was an error processing your action');
    }
  });

  useEffect(() => {
    async function loadOrganization() {
      if (id) {
        const result = await fetchOrganizationDetails(id);

        if (result) {
          setOrganization(result);
          reset({
            ...result,
          });
        } else {
          showToast('error', 'Organization not found', 3000);
          navigate('/dashboard');
        }
      } else {
        reset({});
      }
    }
    loadOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <LayoutWithNavbar
        breadcrumbs={[
          { label: 'SiteSails', href: '/dashboard' },
          { label: 'Organizations', href: '/dashboard' },
          { label: organization?.name || 'New', href: `/organizations/${organization?.id}` },
        ]}
        endButtons={
          <>
            <div className="navbar-item">
              <button
                type="submit"
                className={clsx('button is-primary', {
                  'is-loading': formState.isSubmitting,
                })}
                data-qa="save-button"
              >
                <span className="icon">
                  <i className="far fa-check has-text-secondary-light" />
                </span>
                <span>Save</span>
              </button>
            </div>
          </>
        }
        as="form"
        onSubmit={save}
      >
        <EditPageLayout
          renderLeft={
            <Accordion
              disableShadow
              items={[
                {
                  Header: 'Organization',
                  defaultExpanded: true,
                  id: 'organization',
                  content: (
                    <div className="columns is-multiline is-gapless mb-3">
                      <div className="column is-full">
                        <Controller
                          control={control}
                          name="name"
                          defaultValue=""
                          as={
                            <InputHook
                              id="name"
                              label="Name"
                              error={formState.errors.name?.message}
                              touched={isTouched(formState, 'name')}
                              fieldClassName="mb-3"
                              data-qa="name-field"
                            />
                          }
                        />
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          }
        />
      </LayoutWithNavbar>
      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </>
  );
}
