import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from '@reach/router';
import clsx from 'clsx';
import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Checkbox, InputHook, isTouched, LayoutWithNavbar } from 'src/common';
import { VALIDATION_MESSAGES } from 'src/common/const';
import { UserRole, useUserRoles } from 'src/services/users';
import { RouteComponentEditProps } from 'src/types';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { showToast } from 'src/common/util/show-toast';

const validationSchema = yup.object({
  name: yup.string().required(VALIDATION_MESSAGES.required('First name')),
  isAdministrator: yup.boolean(),
});

type RolesEditProps = RouteComponentEditProps;

export function RolesEdit({ id }: RolesEditProps) {
  const [role, setRole] = useState<UserRole | null>(null);
  const [loadingRole, setLoadingRole] = useState(true);
  const { loadUserRoles, userRoles, createUserRole, deleteUserRole, updateUserRole } = useUserRoles();
  const { control, register, formState, reset, handleSubmit } = useForm<UserRole>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const listLink = '/members/roles';
  const isEditing = !!id;

  const onDeleteRole = async () => {
    const confirm = await Swal.fire({
      title: 'Delete',
      text: `Are you sure you want to delete ${role!.name}?`,
      showCancelButton: true,
      confirmButtonColor: '#e0245a',
      confirmButtonText: 'Delete',
      icon: 'question',
    });

    if (confirm.value) {
      const result = await deleteUserRole(role!.id!);

      if (result.ok) {
        showToast('success', `${role!.name} has been deleted successfully`);
        navigate(listLink);
      } else {
        showToast('success', 'Unable to delete role');
      }
    }
  };

  const save = handleSubmit(async (values) => {
    const data = {
      ...values,
      id: role?.id,
    };

    let result;

    if (data.id) {
      result = await updateUserRole(data);
    } else {
      result = await createUserRole(data);
    }

    if (result.ok) {
      showToast('success', 'Successfully saved');
      navigate(listLink);
    } else {
      showToast('error', 'There was an error processing your action');
    }
  });

  useEffect(() => {
    async function load() {
      setLoadingRole(true);

      if (id) {
        await loadUserRoles('');
      }

      setLoadingRole(false);
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let foundRole: UserRole | undefined;
    if (id) {
      foundRole = find(userRoles.items, { id: +id });
    }

    if (!foundRole) {
      setRole(null);
      reset({
        name: '',
        isAdministrator: false,
      });
    } else {
      setRole(foundRole);
      reset(foundRole || undefined);
    }
  }, [userRoles.items, id, reset]);

  if (loadingRole) {
    return <LayoutWithNavbar />;
  }

  return (
    <>
      <LayoutWithNavbar
        backHref={listLink}
        breadcrumbs={[
          { href: listLink, label: 'Members' },
          { href: listLink, label: 'Roles' },
          { href: './', label: isEditing ? role?.name || '' : 'New Role' },
        ]}
        endButtons={
          <>
            <div className="navbar-item">
              <button type="button" className="button is-light" onClick={onDeleteRole}>
                <span>Delete</span>
                <span className="icon">
                  <i className="fal fa-trash" />
                </span>
              </button>
            </div>
            <div className="navbar-item">
              <button
                type="submit"
                className={clsx('button is-primary', {
                  'is-loading': formState.isSubmitting,
                })}
              >
                <span>Save changes</span>
                <span className="icon">
                  <i className="fal fa-cloud-upload has-text-secondary-light" />
                </span>
              </button>
            </div>
          </>
        }
        as="form"
        onSubmit={save}
      >
        <h2 className="title is-3">
          {isEditing ? (
            <>
              {'Edit: '}
              <span className="has-text-weight-normal">{role?.name}</span>
            </>
          ) : (
            'New Role'
          )}
        </h2>
        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="card-content">
                <div className="columns is-multiline">
                  <div className="column is-full">
                    <Controller
                      control={control}
                      name="name"
                      as={<InputHook id="firstName" label="Name" />}
                      error={formState.errors.name?.message}
                      touched={isTouched(formState, 'name')}
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-one-quarter">
            <Checkbox
              ref={register}
              name="isAdministrator"
              label="Is Administrator"
              error={formState.errors.isAdministrator?.message}
              touched={isTouched(formState, 'isAdministrator')}
            />
          </div>
        </div>
      </LayoutWithNavbar>
      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </>
  );
}
