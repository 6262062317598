import React, { ChangeEventHandler } from 'react';
import { set } from 'lodash';
import { InputHook } from './InputHook';

type KeyValueEditorProps = {
  label?: string;
  name: string;
  value?: { name: string; value: string }[];
  onChange: (value: { name: string; value: string }[]) => void;
  onBlur: () => void;
};

export function KeyValueEditor({ name = 'keyvalue', label, value = [], onChange, onBlur }: KeyValueEditorProps) {
  function append() {
    onChange(value.concat([{ name: '', value: '' }]));
    onBlur();
  }

  function remove(index: number) {
    const newValue = value.slice();
    newValue.splice(index, 1);
    onChange(newValue);
    onBlur();
  }

  const onChangeInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = value.slice();
    set(newValue, event.target.name, event.target.value);
    onChange(newValue);
    onBlur();
  };

  let parsedValue = value;

  if (typeof parsedValue === 'string') {
    parsedValue = [];
  }

  return (
    <>
      {label ? <h5 className="title is-5">{label}</h5> : null}
      {parsedValue &&
        parsedValue.map((field, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="columns" key={index}>
            <div className="column">
              <InputHook
                name={`[${index}].name`}
                value={field.name}
                label="Name"
                id={`${name}-${index}-name`}
                onChange={onChangeInput}
              />
            </div>
            <div className="column">
              <InputHook
                name={`[${index}].value`}
                value={field.value}
                label="Value"
                id={`${name}-${index}-value`}
                onChange={onChangeInput}
              />
            </div>
            <div className="column is-narrow">
              <button type="button" className="button is-white has-text-danger" onClick={() => remove(index)}>
                <span className="icon">
                  <i className="fas fa-trash" />
                </span>
              </button>
            </div>
          </div>
        ))}
      <div className="buttons">
        <button type="button" className="button is-primary is-fullwidth" onClick={append}>
          <span>Add {label}</span>
          <span className="icon">
            <i className="fal fa-plus" />
          </span>
        </button>
      </div>
    </>
  );
}
