import React, { useEffect, useMemo, useState } from 'react';
import { Link } from '@reach/router';
import { Column } from 'react-table';
import Swal from 'sweetalert2';

import { LayoutWithNavbar, Table, TableDropdown, addParamsToLink } from 'src/common';
import { fetchInvitations, UserInvitation, deleteInvitation } from 'src/services/users';
import { RouteComponentListProps } from 'src/types';
import { showToast } from 'src/common/util/show-toast';
import { ORGANIZATION_ROLES_OPTIONS, PROJECT_ROLES_OPTIONS, ROLES } from './const';

type UserInviteListProps = Partial<RouteComponentListProps> & {
  organizationId?: number;
  projectId?: number;
};

export function UserInviteList({ organizationId, projectId }: UserInviteListProps) {
  const [invites, setInvites] = useState<UserInvitation[] | null>(null);

  useEffect(() => {
    async function loadInvites() {
      const data = await fetchInvitations(organizationId, projectId);
      setInvites(data);
    }
    loadInvites();
  }, [organizationId, projectId]);

  const getRoleName = (invite: UserInvitation) => {
    const projectRoleName = PROJECT_ROLES_OPTIONS.filter((r) => r.value === invite.projectRole)[0]?.label;

    if (projectId) {
      return projectRoleName;
    }

    const roleName = ORGANIZATION_ROLES_OPTIONS.filter((r) => r.value === invite.organizationRole)[0]?.label;

    if (invite.organizationRole === ROLES.projectUser) {
      return `${projectRoleName} on ${invite.projectName} project`;
    }

    return roleName;
  };

  async function onDeleteInvite(id: number) {
    const confirm = await Swal.fire({
      title: 'Delete Invite',
      text: `Are you sure you want to delete the invite?`,
      showCancelButton: true,
      confirmButtonColor: '#e0245a',
      confirmButtonText: 'Delete',
      icon: 'question',
    });

    if (confirm.value) {
      const result = await deleteInvitation(id);

      if (result.status === 200) {
        const data = await fetchInvitations(organizationId, projectId);
        setInvites(data);
        showToast('success', `Invite has been deleted successfully`);
      } else {
        showToast('success', 'Unable to delete user');
      }
    }
  }

  const tableColumns: Column<UserInvitation>[] = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => <div className="has-text-left">{value}</div>,
      },
      {
        Header: 'Role',
        accessor: 'organizationRole',
        Cell: ({ row: { original } }) => <div className="has-text-left">{getRoleName(original)}</div>,
      },
      {
        Header: 'Invited at',
        accessor: 'sentAt',
        Cell: ({ value }) => <div className="has-text-left">{new Date(value).toLocaleDateString()}</div>,
      },
      {
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ value }) => (
          <TableDropdown>
            <a className="dropdown-item" onClick={() => onDeleteInvite(value)}>
              Delete
            </a>
          </TableDropdown>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const inviteLink = projectId
    ? addParamsToLink('/users/new', {})
    : addParamsToLink(`/organizations/${organizationId}/users/new`, {}, false);

  const usersLink = projectId
    ? addParamsToLink('/users', {})
    : addParamsToLink(`/organizations/${organizationId}/users`, {}, false);

  return (
    <LayoutWithNavbar
      breadcrumbs={[
        { label: 'SiteSails', href: '/' },
        { label: 'Users', href: usersLink },
        { label: 'Invites', href: '/users/invites' },
      ]}
      endButtons={
        <div className="navbar-item">
          <Link to={inviteLink} className="button is-primary">
            <span>Invite user</span>
            <span className="icon">
              <i className="fal fa-plus" />
            </span>
          </Link>
        </div>
      }
    >
      {invites ? <Table data={invites} loading={false} columns={tableColumns} /> : null}
    </LayoutWithNavbar>
  );
}
