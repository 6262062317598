import { findIndex } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { InputHook, isTouched, Modal, useAutoSlug } from 'src/common';
import { NodeBlock } from 'src/services/nodes';
import { yupResolver } from '@hookform/resolvers/yup';
import { PageBlocksHookProps } from '../hooks/use-page-blocks';
import BlockFieldsEditor from './BlockFieldsEditor';
import { useBlockFieldsEditor } from '../hooks';

const validationSchema = yup.object({
  apiNamePrefix: yup.string().label('API Name Prefix').required(),
  description: yup.string().label('Description').defined(),
  name: yup.string().label('Name').required(),
  fields: yup.array().label('Fields').min(1),
});

type BlockEditorModalProps = {
  value: NodeBlock[];
  onChange: (value: NodeBlock[]) => void;
  onBlur: () => void;
} & Omit<PageBlocksHookProps, 'containerRef'>;

export function BlockEditorModal({
  value,
  onChange,
  onBlur,
  editBlock,
  addingBlock,
  setEditBlock,
  setAddingBlock,
}: BlockEditorModalProps) {
  const blockFieldsProps = useBlockFieldsEditor();
  const { wrapperRef } = blockFieldsProps;
  const { control, reset, handleSubmit, formState, watch } = useForm<NodeBlock>({
    defaultValues: {
      apiNamePrefix: '',
      description: '',
      name: '',
      fields: [],
    },
    resolver: yupResolver(validationSchema),
  });
  const currentBlock = watch();

  const onCancel = useCallback(() => {
    setEditBlock(null);
    setAddingBlock(false);
    reset({
      apiNamePrefix: '',
      description: '',
      name: '',
      fields: [],
    });
  }, [setEditBlock, setAddingBlock, reset]);

  const save = handleSubmit(async (values) => {
    if (editBlock) {
      const data: NodeBlock = {
        ...editBlock,
        ...values,
      };
      const index = findIndex(value, { id: +editBlock.id! });
      const newValue = value.slice();
      newValue.splice(index, 1, data);
      onChange(newValue);
    } else {
      const data: NodeBlock = {
        ...values,
        id: null,
        position: value.length,
      };
      onChange(value.concat(data));
    }
    onBlur();
    onCancel();
  });

  useAutoSlug({ control, formState, baseField: 'name', targetField: 'apiNamePrefix' });

  useEffect(() => {
    if (editBlock) {
      reset(editBlock);
    } else {
      reset({
        apiNamePrefix: '',
        description: '',
        name: '',
        fields: [],
      });
    }
  }, [reset, editBlock]);

  const modalVisible = !!editBlock || addingBlock;

  return (
    <>
      <Modal visible={modalVisible} onClickBackground={onCancel}>
        <form onSubmit={save}>
          <div className="modal-card-head">
            <p className="modal-card-title">{editBlock ? `Edit: ${editBlock.name}` : 'New block'}</p>
            <button type="button" className="delete" onClick={onCancel} />
          </div>
          <div className="modal-card-body">
            <Controller
              control={control}
              name="name"
              defaultValue=""
              as={
                <InputHook
                  label="Name"
                  id="block-name"
                  error={formState.errors.name?.message}
                  touched={isTouched(formState, 'name')}
                />
              }
            />
            <Controller
              control={control}
              name="description"
              defaultValue=""
              as={
                <InputHook
                  label="Description"
                  id="block-description"
                  error={formState.errors.description?.message}
                  touched={isTouched(formState, 'description')}
                />
              }
            />
            <Controller
              control={control}
              name="apiNamePrefix"
              defaultValue=""
              as={
                <InputHook
                  label="API Name Prefix"
                  id="block-apiNamePrefix"
                  error={formState.errors.apiNamePrefix?.message}
                  touched={isTouched(formState, 'apiNamePrefix')}
                />
              }
            />
          </div>
          <div className="modal-card-body" ref={wrapperRef} />
          <div className="modal-card-foot">
            <button type="reset" className="button" onClick={onCancel}>
              Cancel
            </button>
            <button type="submit" className="button is-primary" data-qa="block-save">
              <span className="icon">
                <i className="far fa-check" />
              </span>
              <span>{editBlock ? 'Save changes' : 'Create block'}</span>
            </button>
          </div>
        </form>
      </Modal>
      <Controller
        control={control}
        name="fields"
        defaultValue={[]}
        render={({ ref, ...props }) => <BlockFieldsEditor {...props} {...blockFieldsProps} block={currentBlock} />}
      />
    </>
  );
}
