import Noty from 'noty';

export const showToast = (type: 'success' | 'error', text: string, timeout = 1500) => {
  new Noty({
    theme: 'bootstrap-v4',
    type,
    text,
    timeout,
  }).show();
};
