import { createApiAction, createCrudApiActions, createCrudApiActionsWithoutPaging } from 'src/services/api';
import { SuccessAction } from 'src/services/api/redux/types';

import urlcat from 'urlcat';
import { transformUser, transformUserForApi, transformUserRole } from '../transformations';
import { User, UserRole } from '../types';
import { CurrentUserUpdateAPI } from './types';

export enum UserActionTypes {
  CHANGE_CURRENT_USER_START = 'users/fetch_current_user_start',
  CHANGE_CURRENT_USER_FAILURE = 'users/fetch_current_user_failure',
  FETCH_CURRENT_USER_SUCCESS = 'users/fetch_current_user_success',
  UPDATE_CURRENT_USER_SUCCESS = 'users/update_current_user_success',

  CHANGE_USERS_START = 'users/change_users_start',
  CHANGE_USERS_FAILURE = 'users/change_users_failure',
  FETCH_USERS_SUCCESS = 'users/fetch_users_success',
  CLEAR_USERS_SUCCESS = 'users/clear_users_success',
  ADD_ACCOUNT_USER_SUCCESS = 'users/add_account_user_success',
  UPDATE_ACCOUNT_USER_SUCCESS = 'users/update_account_user_success',
  DELETE_ACCOUNT_USER_SUCCESS = 'users/delete_account_user_success',

  CHANGE_MEMBERS_START = 'users/change_members_start',
  CHANGE_MEMBERS_FAILURE = 'users/change_members_failure',
  FETCH_MEMBERS_SUCCESS = 'users/fetch_members_success',
  ADD_MEMBER_SUCCESS = 'users/add_member_success',
  UPDATE_MEMBER_SUCCESS = 'users/update_member_success',
  DELETE_MEMBER_SUCCESS = 'users/delete_member_success',

  CHANGE_USER_ROLES_START = 'users/change_user_roles_start',
  CHANGE_USER_ROLES_FAILURE = 'users/change_user_roles_failure',
  FETCH_USER_ROLES_SUCCESS = 'users/fetch_user_roles_success',
  ADD_USER_ROLE_SUCCESS = 'users/add_user_role_success',
  UPDATE_USER_ROLE_SUCCESS = 'users/update_user_role_success',
  DELETE_USER_ROLE_SUCCESS = 'users/delete_user_role_success',
}

export const clearUsers = (): SuccessAction => ({
  type: UserActionTypes.CLEAR_USERS_SUCCESS,
  payload: null,
});

export const fetchCurrentUser = () =>
  createApiAction(
    {
      path: '/users/me',
      method: 'GET',
    },
    [
      UserActionTypes.CHANGE_CURRENT_USER_START,
      UserActionTypes.FETCH_CURRENT_USER_SUCCESS,
      UserActionTypes.CHANGE_CURRENT_USER_FAILURE,
    ],
  );

export const fetchAllUsers = (pageNumber?: number, pageSize?: number, params: Record<string, any> = {}) =>
  createApiAction(
    {
      path: urlcat('/admin/users', '/', {
        pageNumber,
        pageSize,
        ...params,
      }),
      method: 'GET',
    },
    [UserActionTypes.CHANGE_USERS_START, UserActionTypes.FETCH_USERS_SUCCESS, UserActionTypes.CHANGE_USERS_FAILURE],
  );

export const updateCurrentUser = (data: CurrentUserUpdateAPI) =>
  createApiAction(
    {
      path: '/users/me',
      method: 'PATCH',
      body: data,
    },
    [
      UserActionTypes.CHANGE_CURRENT_USER_START,
      UserActionTypes.UPDATE_CURRENT_USER_SUCCESS,
      UserActionTypes.CHANGE_CURRENT_USER_FAILURE,
    ],
  );

export const [fetchUsers, addUser, updateUser, deleteUser] = createCrudApiActions<User>(
  '/users',
  UserActionTypes.CHANGE_USERS_START,
  UserActionTypes.CHANGE_USERS_FAILURE,
  UserActionTypes.FETCH_USERS_SUCCESS,
  UserActionTypes.ADD_ACCOUNT_USER_SUCCESS,
  UserActionTypes.UPDATE_ACCOUNT_USER_SUCCESS,
  UserActionTypes.DELETE_ACCOUNT_USER_SUCCESS,
  transformUserForApi,
);

export const [fetchMembers, addMember, updateMember, deleteMember] = createCrudApiActions<User>(
  '/members',
  UserActionTypes.CHANGE_MEMBERS_START,
  UserActionTypes.CHANGE_MEMBERS_FAILURE,
  UserActionTypes.FETCH_MEMBERS_SUCCESS,
  UserActionTypes.ADD_MEMBER_SUCCESS,
  UserActionTypes.UPDATE_MEMBER_SUCCESS,
  UserActionTypes.DELETE_MEMBER_SUCCESS,
  transformUser,
);

export const [fetchUserRoles, addUserRole, updateUserRole, deleteUserRole] =
  createCrudApiActionsWithoutPaging<UserRole>(
    '/user-roles',
    UserActionTypes.CHANGE_USER_ROLES_START,
    UserActionTypes.CHANGE_USER_ROLES_FAILURE,
    UserActionTypes.FETCH_USER_ROLES_SUCCESS,
    UserActionTypes.ADD_USER_ROLE_SUCCESS,
    UserActionTypes.UPDATE_USER_ROLE_SUCCESS,
    UserActionTypes.DELETE_USER_ROLE_SUCCESS,
    transformUserRole,
  );
