import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { EditPageLoading } from 'src/common';
import Swal from 'sweetalert2';
import { ProjectForm } from './components';
import { ProjectFieldType } from './const';
import { useProjectForm } from './hooks';

type ProjectCreateProps = RouteComponentProps<{
  organizationId: number;
}>;

export function ProjectCreate({ organizationId }: ProjectCreateProps) {
  const { allLanguages, onDelete, organization, save, themes } = useProjectForm(organizationId);
  const [projectType, setProjectType] = useState<ProjectFieldType | null>(null);

  useEffect(() => {
    const callModal = async () => {
      const confirm = await Swal.fire({
        title: 'Choose project type',
        showCancelButton: true,
        confirmButtonText: 'Standard Project',
        allowOutsideClick: false,
        text: 'Please select the type of project you want',
        cancelButtonText: 'Themed Project',
        icon: 'info',
      });
      if (!confirm.value) {
        setProjectType(ProjectFieldType.THEMED_PROJECT);
      } else {
        setProjectType(ProjectFieldType.STANDARD_PROJECT);
      }
    };

    callModal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loaded = !!organization && !!projectType;

  return loaded ? (
    <ProjectForm
      organization={organization}
      themes={themes}
      allLanguages={allLanguages}
      onDelete={onDelete}
      onSubmit={save}
      newProjectType={projectType}
    />
  ) : (
    <EditPageLoading />
  );
}
