import { createCrudApiActions } from 'src/services/api';
import { Organization } from '../types';

export enum OrganizationActionTypes {
  CHANGE_ORGANIZATIONS_START = 'organizations/change_organizations_start',
  CHANGE_ORGANIZATIONS_FAILURE = 'organizations/change_organizations_failure',
  FETCH_ORGANIZATIONS_SUCCESS = 'organizations/fetch_organizations_success',
  ADD_ORGANIZATION_SUCCESS = 'organizations/add_organization_success',
  UPDATE_ORGANIZATION_SUCCESS = 'organizations/update_organization_success',
  DELETE_ORGANIZATION_SUCCESS = 'organizations/delete_organization_success',
}

export const [fetchOrganizations, addOrganizationAction, updateOrganizationAction, deleteOrganizationAction] =
  createCrudApiActions<Organization>(
    '/organizations',
    OrganizationActionTypes.CHANGE_ORGANIZATIONS_START,
    OrganizationActionTypes.CHANGE_ORGANIZATIONS_FAILURE,
    OrganizationActionTypes.FETCH_ORGANIZATIONS_SUCCESS,
    OrganizationActionTypes.ADD_ORGANIZATION_SUCCESS,
    OrganizationActionTypes.UPDATE_ORGANIZATION_SUCCESS,
    OrganizationActionTypes.DELETE_ORGANIZATION_SUCCESS,
    (data) => data,
  );
