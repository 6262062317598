import { find, findIndex } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { InputHook, Modal } from 'src/common';
import { SectionFieldForm } from 'src/modules/settings';
import { NodeBlock, NodeBlockField } from 'src/services/nodes';
import { SECTION_FIELD_TYPE_OPTIONS } from 'src/services/sections/const';
import { VALIDATION_MESSAGES } from 'src/common/const';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { SectionFieldType } from 'src/services/sections';
import { BlockFieldsEditorHookProps } from '../hooks/use-block-fields-editor';

const validationSchema = yup.object({
  name: yup.string().required(VALIDATION_MESSAGES.required('Name')),
  type: yup.string().required(VALIDATION_MESSAGES.required('Type')),
  apiName: yup.string().required(VALIDATION_MESSAGES.required('API name')),
});

type BlockFieldsEditorProps = {
  value: NodeBlockField[];
  onChange: (value: NodeBlockField[]) => void;
  onBlur: () => void;
  block?: NodeBlock;
} & BlockFieldsEditorHookProps;

export default function BlockFieldsEditor({
  wrapperRef: wrapper,
  setAddingField,
  value,
  setEditField,
  addingField,
  editField,
  onChange,
  onBlur,
  block,
}: BlockFieldsEditorProps) {
  const formMethods = useForm<NodeBlockField>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      apiName: `${block!.apiNamePrefix}-`,
      name: '',
      blockId: block!.id || undefined,
      options: [],
      type: SectionFieldType.Text,
    },
  });
  const { handleSubmit, reset, register } = formMethods;

  const onCancel = useCallback(() => {
    setAddingField(false);
    setEditField(null);
  }, [setAddingField, setEditField]);

  const onDeleteField = useCallback(
    async (field: NodeBlockField) => {
      const confirm = await Swal.fire({
        title: 'Delete Element',
        text: `Are you sure you want to delete ${field.name}?`,
        showCancelButton: true,
        confirmButtonColor: '#e0245a',
        confirmButtonText: 'Delete',
        icon: 'question',
      });

      if (confirm.value) {
        onChange(value.filter((v) => v !== field));
        onBlur();
      }
    },
    [onBlur, onChange, value],
  );

  const save = handleSubmit(async (values) => {
    if (editField) {
      const data: NodeBlockField = {
        ...editField,
        ...values,
        type: Number(values.type),
        blockId: editField.blockId ? +editField.blockId : undefined,
      };
      const index = findIndex(value, editField);
      const newValue = value.slice();
      newValue.splice(index, 1, data);
      onChange(newValue);
    } else {
      const data: NodeBlockField = {
        ...values,
        type: Number(values.type),
        blockId: block ? +block.id! : undefined,
      };
      onChange([...value, data]);
    }
    onBlur();
    onCancel();
  });

  useEffect(() => {
    if (editField) {
      reset({
        ...editField,
        options: editField.options || [],
      });
    } else {
      reset({
        apiName: '',
        name: '',
        blockId: block!.id || undefined,
        options: [],
        type: SectionFieldType.Text,
      });
    }
  }, [reset, editField, block]);

  return wrapper.current
    ? createPortal(
        <>
          <div className="columns is-vcentered">
            <div className="column is-narrow">
              <p className="subtitle has-text-weight-normal">Elements</p>
            </div>
            <div className="column is-narrow">
              <button
                type="button"
                className="button is-outlined"
                onClick={() => setAddingField(true)}
                data-qa="block-field-new"
              >
                <span className="icon has-text-primary">
                  <i className="far fa-plus" />
                </span>
                <span>New element</span>
              </button>
            </div>
          </div>
          {value.map((field) => (
            <div className="columns is-gapless is-vcentered" key={field.id || field.apiName}>
              <div className="column mr-2">
                <InputHook value={field.name} readOnly label="Name" />
              </div>
              <div className="column mr-2">
                <InputHook value={field.apiName} readOnly label="API Name" />
              </div>
              <div className="column mr-2">
                <InputHook
                  value={find(SECTION_FIELD_TYPE_OPTIONS, { value: +field.type })?.label}
                  readOnly
                  label="Type"
                />
              </div>
              <div className="column is-narrow mr-1">
                <button type="button" className="button is-white" onClick={() => setEditField(field)}>
                  <span className="icon has-text-grey">
                    <i className="fal fa-pen" />
                  </span>
                </button>
              </div>
              <div className="column is-narrow">
                <button
                  type="button"
                  className="button is-white"
                  onClick={() => onDeleteField(field)}
                  data-qa={`block-field-delete-${field.apiName}`}
                >
                  <span className="icon has-text-grey-light">
                    <i className="fal fa-trash" />
                  </span>
                </button>
              </div>
            </div>
          ))}
          <Modal visible={!!editField || addingField} onClickBackground={onCancel}>
            <form onSubmit={save}>
              <input type="hidden" name="blockId" defaultValue={block!.id || ''} ref={register} />
              <div className="modal-card-head">
                <p className="modal-card-title">{editField ? `Edit: ${editField.name}` : 'New element'}</p>
                <button type="button" className="delete" onClick={onCancel} />
              </div>
              <SectionFieldForm
                formMethods={formMethods}
                apiNamePrefix={`${block!.apiNamePrefix}-`}
                isEditing={!!editField}
              />
              <div className="modal-card-foot">
                <button type="reset" className="button" onClick={onCancel}>
                  Cancel
                </button>
                <button type="submit" className="button is-primary" data-qa="block-field-save">
                  <span className="icon">
                    <i className="far fa-check" />
                  </span>
                  <span>{editField ? 'Save changes' : 'Create element'}</span>
                </button>
              </div>
            </form>
          </Modal>
        </>,
        wrapper.current,
      )
    : null;
}
