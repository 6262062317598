import { find } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { LayoutWithNavbar, Table, TableDropdown, useCurrentPage, usePrevSearchURLValue } from 'src/common';
import { showToast } from 'src/common/util/show-toast';
import { isLoading } from 'src/services/api';
import { getTotalPages } from 'src/services/api/redux/traits';
import { KeyValueItem, useKeyValueItems, useKeyValueStores } from 'src/services/key-values';
import { RouteComponentListProps } from 'src/types';
import Swal from 'sweetalert2';
import { useDebounce } from 'use-debounce';
import { ItemEditorModal } from './components';

type KeyValueListProps = RouteComponentListProps;

export function KeyValueList({ slug }: KeyValueListProps) {
  const { keyValueStores } = useKeyValueStores();
  const store = useMemo(() => find(keyValueStores.items, { slug }), [keyValueStores, slug]);
  const { keyValueItems, loadItems, deleteItem } = useKeyValueItems(slug!);
  const [currentPage, setCurrentPage] = useCurrentPage();
  const prevSearchURLValue = usePrevSearchURLValue();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [editItem, setEditItem] = useState<KeyValueItem | null>(null);

  const onDeleteItem = useCallback(
    async (item: KeyValueItem) => {
      const confirm = await Swal.fire({
        title: 'Delete',
        text: `Are you sure you want to delete this entry?`,
        showCancelButton: true,
        confirmButtonColor: '#e0245a',
        confirmButtonText: 'Delete',
        icon: 'question',
      });

      if (confirm.value) {
        const result = await deleteItem(item.key);

        if (result.ok) {
          showToast('success', 'Entry has been deleted successfully');
          setEditItem(null);
        } else {
          showToast('error', 'Unable to delete');
        }
      }
    },
    [deleteItem],
  );

  const tableColumns = useMemo<Column<KeyValueItem>[]>(
    () =>
      store
        ? [
            {
              Header: store?.keyName || '',
              accessor: 'key',
              disableSortBy: true,
            },
            {
              Header: store?.valueName || '',
              accessor: 'value',
              disableSortBy: true,
            },

            {
              accessor: 'key',
              id: 'actions',
              disableSortBy: true,
              Cell: ({ row: { original } }) => (
                <TableDropdown>
                  <a className="dropdown-item" onClick={() => setEditItem(original)}>
                    Edit
                  </a>
                  <a className="dropdown-item" onClick={() => onDeleteItem(original)}>
                    Delete
                  </a>
                </TableDropdown>
              ),
            },
          ]
        : [],
    [onDeleteItem, store],
  );

  const updateSearch = useCallback(
    (text: string) => {
      if (!prevSearchURLValue) setCurrentPage(0);
      setSearch(text);
    },
    [setCurrentPage, prevSearchURLValue],
  );

  useEffect(() => {
    if (store && !editItem) {
      loadItems(currentPage + 1, debouncedSearch);
    }
  }, [currentPage, debouncedSearch, editItem, loadItems, store]);

  return !store ? (
    <LayoutWithNavbar />
  ) : (
    <>
      <LayoutWithNavbar
        breadcrumbs={[{ href: `key-values/${slug}`, label: store.name }]}
        onSearch={updateSearch}
        searchValue={search}
        endButtons={
          <div className="navbar-item">
            <button
              className="button is-primary"
              onClick={() => setEditItem({ key: '', value: '' })}
              data-qa="create-button"
            >
              <span className="icon">
                <i className="fal fa-plus" />
              </span>
              <span>Add</span>
            </button>
          </div>
        }
      >
        <Table
          data={keyValueItems.items}
          loading={isLoading(keyValueItems)}
          columns={tableColumns}
          pageCount={getTotalPages(keyValueItems)}
          paginated
        />
      </LayoutWithNavbar>
      <ItemEditorModal item={editItem} onCancel={() => setEditItem(null)} store={store} />
    </>
  );
}
