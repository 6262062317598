import { parse } from 'date-fns';
import { parseISO } from './parse-iso';

export function formatDate(input: string | Date, inputFormat?: string) {
  try {
    if (typeof input === 'string') {
      const date = inputFormat ? parse(input, inputFormat, new Date()) : parseISO(input);

      return date.toLocaleDateString();
    }

    return input.toLocaleDateString();
  } catch {
    return '';
  }
}

export function formatDateTime(input: string | Date, inputFormat?: string) {
  try {
    if (typeof input === 'string') {
      const date = inputFormat ? parse(input, inputFormat, new Date()) : parseISO(input);

      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }

    return `${input.toLocaleDateString()} ${input.toLocaleTimeString()}`;
  } catch {
    return '';
  }
}
