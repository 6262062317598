import { createApiAction } from 'src/services/api';
import { Project } from '../types';

export enum ProjectActionTypes {
  CHANGE_PROJECT_START = 'project/change_start',
  CHANGE_PROJECT_FAILED = 'project/change_failed',
  CREATE_PROJECT = 'project/create',
  UPDATE_PROJECT = 'project/update',
  SELECT_PROJECT = 'project/select',
  DELETE_PROJECT = 'project/update',
}

export const selectProjectAction = (data: Project) => ({
  type: ProjectActionTypes.SELECT_PROJECT,
  payload: data,
});

export const loadProjectAction = (projectId: number) =>
  createApiAction(
    {
      path: `/projects/${projectId}`,
      method: 'GET',
    },
    [
      ProjectActionTypes.CHANGE_PROJECT_START,
      ProjectActionTypes.SELECT_PROJECT,
      ProjectActionTypes.CHANGE_PROJECT_FAILED,
    ],
  );

export const createProjectAction = (data: Project) =>
  createApiAction(
    {
      path: `/organizations/${data.organizationId}/projects`,
      method: 'POST',
      body: data,
    },
    [
      ProjectActionTypes.CHANGE_PROJECT_START,
      ProjectActionTypes.CREATE_PROJECT,
      ProjectActionTypes.CHANGE_PROJECT_FAILED,
    ],
  );

export const updateProjectAction = (data: Project) =>
  createApiAction(
    {
      path: `/organizations/${data.organizationId}/projects/${data.id}`,
      method: 'PUT',
      body: data,
    },
    [
      ProjectActionTypes.CHANGE_PROJECT_START,
      ProjectActionTypes.UPDATE_PROJECT,
      ProjectActionTypes.CHANGE_PROJECT_FAILED,
    ],
  );

export const deleteProjectAction = (projectId: number) =>
  createApiAction(
    {
      path: `/projects/${projectId}`,
      method: 'DELETE',
    },
    [
      ProjectActionTypes.CHANGE_PROJECT_START,
      ProjectActionTypes.DELETE_PROJECT,
      ProjectActionTypes.CHANGE_PROJECT_FAILED,
    ],
  );
