import { v4 as uuidv4 } from 'uuid';
import { Project } from 'src/services/project';
import { ProjectFieldType, SelfHostedType } from '../const';
import { ProjectFormValues } from '../types';

export function getProjectValues(
  project?: Project,
  newProjectType = ProjectFieldType.STANDARD_PROJECT,
): ProjectFormValues {
  if (project) {
    if (project.isSelfHosted || project.projectType === ProjectFieldType.STANDARD_PROJECT) {
      return {
        ...project,
        languages: project!.languages?.map((l) => ({ languageId: l })) || [],
        isSelfHosted: true,
        selfHostingType: SelfHostedType.INITIAL,
      };
    }

    if (project.domain && project.domain.includes('sitesails.io')) {
      return {
        ...project,
        languages: project.languages?.map((l) => ({ languageId: l })) || [],
        autoHosted: project.domain,
        selfHostingType: SelfHostedType.AUTO_GENERATED,
        includeOriginalImageVariant: true,
      };
    }
    return {
      ...project,
      languages: project!.languages?.map((l) => ({ languageId: l })) || [],
      customHosted: project.domain,
      selfHostingType: SelfHostedType.CUSTOM,
      includeOriginalImageVariant: true,
    };
  }
  const isSelfHosted = newProjectType === ProjectFieldType.STANDARD_PROJECT;
  return {
    languages: [{ languageId: 'en' }],
    adminApiKey: uuidv4(),
    publicApiKey: uuidv4(),
    defaultLanguageId: 'en',
    projectType: newProjectType,
    isSelfHosted,
    selfHostingType: isSelfHosted ? SelfHostedType.INITIAL : SelfHostedType.AUTO_GENERATED,
    includeOriginalImageVariant: newProjectType === ProjectFieldType.THEMED_PROJECT,
    createEmptyProject: false,
  } as any;
}
