import { Action, BlotFormatter, Aligner, Toolbar, DefaultToolbar } from 'quill-blot-formatter';

import { QuillCustomAligner } from './QuillCustomAligner';

export class QuillCustomAlignAction extends Action {
  toolbar: Toolbar;

  aligner: Aligner;

  constructor(formatter: BlotFormatter) {
    super(formatter);
    this.aligner = new QuillCustomAligner(formatter.options.align);
    this.toolbar = new DefaultToolbar();
  }

  onCreate() {
    const toolbar = this.toolbar.create(this.formatter, this.aligner);
    this.formatter.overlay.appendChild(toolbar);
  }

  onDestroy() {
    const toolbar = this.toolbar.getElement();
    if (!toolbar) {
      return;
    }

    this.formatter.overlay.removeChild(toolbar);
    this.toolbar.destroy();
  }
}
