import React from 'react';
import Swal from 'sweetalert2';
import { capitalize, find } from 'lodash';

import { Language, useLanguages } from 'src/services/languages';
import { Dropdown } from 'src/common';
import clsx from 'clsx';

export interface LanguageTabsProps<T extends Record<string, any>> {
  fields: T[];
  activeTab: string | null;
  setActiveTab: (id: string) => void;
  addLanguage: (id: string) => void;
  removeLanguage: (id: string) => void;
}

export function LanguageTabs<T extends Record<string, any>>({
  fields,
  activeTab,
  setActiveTab,
  addLanguage,
  removeLanguage,
}: LanguageTabsProps<T>) {
  const {
    languages: { items: languages },
  } = useLanguages();
  const availableLanguages = languages.filter((language) => !find(fields, { languageId: language.id }));
  const showAbbreviations = fields.length > 4;

  async function onDeleteLanguage(language: Language) {
    const confirm = await Swal.fire({
      title: 'Remove language',
      text: `Are you sure you want to remove ${language.name}?`,
      showCancelButton: true,
      confirmButtonColor: '#e0245a',
      confirmButtonText: 'Remove',
      icon: 'question',
    });

    if (confirm.value) {
      removeLanguage(language.id);
    }
  }

  return (
    <div className="tags content-tabs-wrapper">
      {fields.map(({ languageId }) => {
        const language = find(languages, { id: languageId });

        return languageId ? (
          <span
            key={languageId}
            className={clsx(
              'tag is-clickable is-medium',
              languageId === activeTab ? 'is-primary is-active-language' : 'has-text-grey',
            )}
            onClick={() => setActiveTab(languageId)}
            data-qa={`language-tab-${languageId}`}
          >
            {showAbbreviations ? languageId.toUpperCase() : capitalize(language?.name)}
            {/* <i className="fal fa-times close-icon" onClick={() => onDeleteLanguage(language!)} /> */}
            <button className="delete is-small" type="button" onClick={() => onDeleteLanguage(language!)} />
          </span>
        ) : null;
      })}
      {availableLanguages.length > 0 && (
        <Dropdown
          align="right"
          renderTrigger={
            <span className="tag is-medium is-clickable has-text-primary">
              <i className="fal fa-plus" />
            </span>
          }
          data-qa="language-add-button"
        >
          <div className="dropdown-content">
            {availableLanguages.map((language) => (
              <a
                className="dropdown-item"
                key={language.id}
                onClick={() => addLanguage(language.id)}
                data-qa={`language-dropdown-option-${language.id}`}
              >
                {capitalize(language.name)}
              </a>
            ))}
          </div>
        </Dropdown>
      )}
    </div>
  );
}
