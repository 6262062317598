import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_TABLE_ROWS } from 'src/common/const';
import {
  addKeyValueItem,
  fetchKeyValueItems,
  deleteKeyValueItem,
  getKeyValuesItems,
  updateKeyValueItem,
} from '../redux';

export function useKeyValueItems(slug: string) {
  const dispatch = useDispatch();
  const keyValueItems = useSelector(getKeyValuesItems);

  const loadItems = useCallback(
    (page = 1, search?: string) => {
      return dispatch(fetchKeyValueItems(page, MAX_TABLE_ROWS, { slug, search }));
    },
    [dispatch, slug],
  );

  const addItem = useCallback(
    (key: string, value: string) => {
      return dispatch(addKeyValueItem({ value }, { slug, key }));
    },
    [dispatch, slug],
  );

  const updateItem = useCallback(
    (key: string, value: string) => {
      return dispatch(updateKeyValueItem({ value }, { slug, key }));
    },
    [dispatch, slug],
  );

  const deleteItem = useCallback(
    (key: string) => {
      return dispatch(deleteKeyValueItem(null, { slug, key }));
    },
    [dispatch, slug],
  );

  return {
    keyValueItems,
    loadItems,
    addItem,
    updateItem,
    deleteItem,
  };
}
