import React, { useEffect, useState } from 'react';
import { useMatch, Router, navigate, RouteComponentProps, Redirect } from '@reach/router';
import { ForgotPassword, Login, ResetPassword, Registration, EmailVerification } from './modules/login';
import { Panel, Sidebar } from './common';
import { SectionType, useSections } from './services/sections';
import { isLoading, isValid } from './services/api';
import { useAuth } from './services/auth';
import { useProject } from './services/project';
import { Dashboard, OrganizationDashboard } from './modules/dashboard';
import { useOrganizations } from './services/organizations';
import { UserProfile, ProjectEdit, ProjectCreate } from './modules/settings';
import { OrganizationEdit } from './modules/dashboard/OrganizationEdit';
import { useProjectLink } from './common/hooks/use-project-link';
import { UserInvite, UserInviteList, UserList } from './modules/users';
import { BillingAndPlans } from './modules/billing';
import { SuperadminUsers } from './modules/impersonate/SuperadminUsers';

type AppProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function App(_: AppProps) {
  const { auth, refreshUser } = useAuth();
  const isForgotPasswordPage = useMatch('/forgot-password');
  const isResetPasswordPage = useMatch('/reset-password');
  const isRegistrationPage = useMatch('/registration');
  const isEmailVerificationPage = useMatch('/email-verification');
  const isLoginPage =
    useMatch('/login') || isForgotPasswordPage || isRegistrationPage || isResetPasswordPage || isEmailVerificationPage;

  const { sections } = useSections();
  const { organizations, loadOrganizations } = useOrganizations();
  const { project } = useProject();
  const [initialized, setInitialized] = useState(false);
  const [refreshedUser, setRefreshedUser] = useState(false);
  const [initializedToken, setInitializedToken] = useState<string | null>(null);
  const hasPagesSection = !!sections.items.find((s) => s.type === SectionType.Pages);
  const projectLink = useProjectLink();

  useEffect(() => {
    if (!isValid(auth) && !isLoginPage) {
      navigate('/login');
    }
  }, [auth, isLoginPage]);

  useEffect(() => {
    if (isValid(auth) && (!initialized || initializedToken !== auth.token)) {
      setInitialized(true);
      setInitializedToken(auth.token);
      if (!refreshedUser) {
        refreshUser();
        setRefreshedUser(true);
      }
    }
  }, [auth, initialized, initializedToken, refreshUser, refreshedUser]);

  useEffect(() => {
    if (!isValid(organizations) && !isLoading(organizations) && isValid(auth)) {
      loadOrganizations();
    }
  }, [auth, loadOrganizations, organizations]);

  return (
    <div className="app">
      <div className="columns is-gapless">
        <Sidebar isLoginPage={!!isLoginPage} sections={sections.items} project={project} />
        <div className="column">
          <Router>
            {isValid(auth) ? (
              <>
                <Dashboard path="/dashboard" />
                <SuperadminUsers path="/admin/users" />
                <OrganizationEdit path="/organizations/new" />
                <OrganizationEdit path="/organizations/:id/edit" />
                <OrganizationDashboard path="/organizations/:id" />
                <ProjectCreate path="/organizations/:organizationId/projects/new" />
                <ProjectEdit path="/organizations/:organizationId/projects/:id/edit" />
                <UserProfile path="/profile" />
                <UserList path="/organizations/:organizationId/users" />
                <UserInviteList path="/organizations/:organizationId/users/invites" />
                <UserInvite path="/organizations/:organizationId/users/new" />
                <BillingAndPlans path="/organizations/:organizationId/billing-and-plans" />
                <Panel path="/projects/:projectId/*" />
              </>
            ) : null}
            <Login path="/login" />
            <ForgotPassword path="/forgot-password" />
            <ResetPassword path="/reset-password" />
            <Registration path="/registration" />
            <EmailVerification path="/email-verification" />
            {hasPagesSection ? <Redirect from={projectLink} to={`${projectLink}/pages`} noThrow /> : null}
            {!hasPagesSection && sections.items.length > 0 ? (
              <Redirect from={projectLink} to={`${projectLink}/nodes/${sections.items[0].slug}`} noThrow />
            ) : null}
            {isValid(auth) ? <Redirect from="/" to="/dashboard" noThrow /> : <Redirect from="/" to="/login" noThrow />}
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;
