import { performApiCall } from 'src/services/api';
import { transformSubscription } from './transformations';
import { Subscription } from './types';

export const getChargebeeHostedPage = async (
  organizationId: number,
  email: string,
  planId: string,
): Promise<string> => {
  const res = await performApiCall({
    path: `/integrations/chargebee/hosted-page`,
    method: 'POST',
    headers: { 'organization-id': organizationId },
    body: { customerEmail: email, subscriptionPlanId: planId },
  });

  return Promise.resolve(res.data);
};

export const updateSubscriptionData = async (organizationId: number, hostedPageId: string) => {
  const res = await performApiCall({
    path: `/integrations/chargebee/update-subscription`,
    method: 'POST',
    headers: { 'organization-id': organizationId },
    body: { hostedPageId },
  });

  return Promise.resolve(res.data);
};

export const getSubscriptionData = async (organizationId: number): Promise<Subscription> => {
  const res = await performApiCall({
    path: `/integrations/chargebee/subscriptions/${organizationId}`,
    method: 'GET',
    headers: { 'organization-id': organizationId },
  });

  return Promise.resolve(transformSubscription(res.data));
};
