import React, { useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import * as yup from 'yup';
import clsx from 'clsx';

import { InputHook, isTouched } from 'src/common';
import { useAuth } from 'src/services/auth';
import { isValid } from 'src/services/api';
import { VALIDATION_MESSAGES } from 'src/common/const';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { showToast } from 'src/common/util/show-toast';

const validationSchema = yup.object({
  email: yup.string().required(VALIDATION_MESSAGES.required('Email')).email(VALIDATION_MESSAGES.invalidEmail),
  password: yup.string().required(VALIDATION_MESSAGES.required('Password')),
});

type LoginProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Login(_: LoginProps) {
  const { auth, logIn } = useAuth();
  const { handleSubmit, formState, control } = useForm<{
    email: string;
    password: string;
    remember: boolean;
  }>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: { email: '', password: '' },
  });

  const onSubmit = handleSubmit(async (values) => {
    const res = await logIn(values.email, values.password);

    // @ts-ignore
    if (res.ok) {
      navigate('/dashboard');
    } else {
      showToast('error', 'Username or password incorrect');
    }
  });

  useEffect(() => {
    if (isValid(auth)) {
      navigate('/dashboard');
    }
  }, [auth]);

  if (isValid(auth)) {
    return null;
  }

  return (
    <div className="login-page">
      <div className="navbar is-transparent is-spaced">
        <div className="navbar-menu">
          <div className="navbar-end">
            <a href="#" className="navbar-item">
              Services
            </a>
            <a href="#" className="navbar-item">
              Contact
            </a>
          </div>
        </div>
      </div>
      <div className="container is-fluid">
        <div className="page-wrapper">
          <form onSubmit={onSubmit} noValidate>
            <h2 className="title is-1">Sign in</h2>
            <p>Please fill in the information below:</p>
            <Controller
              control={control}
              name="email"
              defaultValue=""
              as={
                <InputHook
                  label="Email"
                  error={formState.errors.email?.message}
                  disabled={formState.isSubmitting}
                  touched={isTouched(formState, 'email')}
                  type="email"
                  prependIcon={<i className="fal fa-envelope-open" />}
                  data-qa="login-email"
                />
              }
            />
            <Controller
              control={control}
              name="password"
              defaultValue=""
              as={
                <InputHook
                  label="Password"
                  error={formState.errors.password?.message}
                  disabled={formState.isSubmitting}
                  touched={isTouched(formState, 'password')}
                  type="password"
                  prependIcon={<i className="fal fa-key" />}
                  data-qa="login-password"
                />
              }
            />
            <button
              type="submit"
              data-qa="login-button"
              className={clsx('button is-link has-text-weight-bold is-fullwidth is-submit', {
                'is-loading': formState.isSubmitting,
              })}
              disabled={formState.isSubmitting || !formState.isValid}
            >
              <span className="icon">
                <i className="fal fa-unlock" />
              </span>
              <span>Sign in</span>
            </button>

            <div className="columns is-mobile mt-2 links">
              <div className="column">
                <a href="/forgot-password">Forgot your password?</a>
              </div>
              <div className="column is-narrow">
                <a data-qa="registration-link" href="/registration">
                  Create an account
                </a>
              </div>
            </div>

            {/* <hr className="mt-0" />

            <p className="mt-6 sign-in-alt">or sign in with:</p>
            <div className="columns is-mobile mt-2 mb-3 sign-in-alt-btns">
              <div className="column">
                <a href="#" className="button is-outlined">
                  <span className="icon has-text-primary is-medium">
                    <i className="fab fa-github fa-lg" />
                  </span>
                  <span>Github</span>
                </a>
              </div>
              <div className="column has-text-centered">
                <a href="#" className="button is-outlined">
                  <span className="icon has-text-primary is-medium">
                    <i className="fab fa-gitlab fa-lg" />
                  </span>
                  <span>GitLab</span>
                </a>
              </div>
              <div className="column has-text-right">
                <a href="#" className="button is-outlined">
                  <span className="icon has-text-primary is-medium">
                    <i className="fab fa-bitbucket fa-lg" />
                  </span>
                  <span>BitBucket</span>
                </a>
              </div>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
}
