import { createCrudApiActionsWithoutPaging } from 'src/services/api';

import { Section } from '../types';
import { transformSectionForApi } from '../transformations';

export enum SectionActionTypes {
  CHANGE_SECTIONS_START = 'sections/change_sections_start',
  CHANGE_SECTIONS_FAILURE = 'sections/change_sections_failure',
  FETCH_SECTIONS_SUCCESS = 'sections/fetch_sections_success',
  ADD_SECTION_SUCCESS = 'sections/add_section_success',
  UPDATE_SECTION_SUCCESS = 'sections/update_section_success',
  DELETE_SECTION_SUCCESS = 'sections/delete_section_success',
}

export const [fetchSections, addSection, updateSection, deleteSection] = createCrudApiActionsWithoutPaging<Section>(
  '/sections',
  SectionActionTypes.CHANGE_SECTIONS_START,
  SectionActionTypes.CHANGE_SECTIONS_FAILURE,
  SectionActionTypes.FETCH_SECTIONS_SUCCESS,
  SectionActionTypes.ADD_SECTION_SUCCESS,
  SectionActionTypes.UPDATE_SECTION_SUCCESS,
  SectionActionTypes.DELETE_SECTION_SUCCESS,
  transformSectionForApi,
);
