import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Project } from 'src/services/project';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, EditPageLayout, InputHook, isTouched, LayoutWithNavbar, SelectHook } from 'src/common';
import { Organization } from 'src/services/organizations/types';
import { showToast } from 'src/common/util/show-toast';
import { capitalize, find } from 'lodash';
import { Language } from 'src/services/languages';
import { Accordion, AccordionItem } from 'src/common/components/Accordion';
import clsx from 'clsx';
import { DevTool } from '@hookform/devtools';
import { Theme } from 'src/services/themes';
import {
  DOMAIN_REGEX,
  ProjectFieldType,
  PROJECT_TYPE_OPTIONS,
  SelfHostedType,
  SUBDOMAIN_REGEX,
  WEBHOOK_TEST_PAYLOAD_CACHEBUST,
} from '../const';
import { ProjectFormValues } from '../types';
import { getProjectValues } from '../util';

const validationSchema = yup.object().shape({
  name: yup.string().required().label('Project Name'),
  themeId: yup
    .number()
    .when('projectType', {
      is: ProjectFieldType.THEMED_PROJECT,
      then: yup
        .number()
        .transform((value) => value || undefined)
        .required(),
      otherwise: yup
        .number()
        .transform((value) => value || null)
        .nullable(),
    })
    .label('Themes'),
  publicApiKey: yup.string().required().label('Public Key'),
  adminApiKey: yup.string().required().label('Admin Key'),
  languages: yup
    .array(
      yup.object({
        languageId: yup.string().optional(),
      }),
    )
    .nullable()
    .label('Languages'),
  customHosted: yup
    .string()
    .label('Custom domain')
    .when('selfHostingType', {
      is: SelfHostedType.CUSTOM,
      then: yup
        .string()
        .test(
          'customHosted',
          // eslint-disable-next-line no-template-curly-in-string
          '${label} must be a valid domain name',
          (value) => !value || value?.match(DOMAIN_REGEX) !== null,
        )
        .required(),
    }),
  autoHosted: yup
    .string()
    .label('Subdomain')
    .when('selfHostingType', {
      is: SelfHostedType.AUTO_GENERATED,
      then: yup
        .string()
        .test(
          'autoHosted',
          // eslint-disable-next-line no-template-curly-in-string
          '${label} must be a valid subdomain',
          (value) => !value || value?.match(SUBDOMAIN_REGEX) !== null,
        )
        .required(),
    }),
  notificationsEmail: yup.string().nullable().optional().email().label('Notifications email'),
  createEmptyProject: yup.bool().label('Create empty project').optional(),
});

type ProjectFormProps = {
  organization: Organization;
  themes: Theme[];
  project?: Project;
  allLanguages: Language[];
  newProjectType?: ProjectFieldType;
  onDelete: () => void;
  onSubmit: (values: ProjectFormValues) => any;
};

export function ProjectForm({
  organization,
  themes,
  project,
  allLanguages,
  onDelete,
  onSubmit,
  newProjectType,
}: ProjectFormProps) {
  const [newLanguage, setNewLanguage] = useState<string>('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues = useMemo(() => getProjectValues(project, newProjectType), []);
  const { handleSubmit, control, formState, register, watch, getValues, setValue } = useForm<ProjectFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues,
  });
  const { fields, remove, append } = useFieldArray<{ languageId: string }>({
    control,
    name: 'languages',
  });
  const watchLanguages = watch('languages', [] as { languageId: string }[]);
  const watchCacheBustWebhook = watch('cacheBustWebhook');
  const watchRedeployWebhook = watch('redeployWebhook');
  const watchOption = watch('projectType');
  const watchTheme = watch('themeId');
  const watchSelfHosted = watch('isSelfHosted');
  const watchSelfHostingType = watch('selfHostingType');
  const watchDomain = watch('domain', '');

  const isThemedProject = watchOption === 2;
  const showSuffix = isThemedProject && !watchSelfHosted && !watchDomain.includes('.sitesails.io');

  const onTestWebhook = useCallback(async (url: string | null, payload?: any) => {
    if (!url) return;

    try {
      const result = await fetch(url, {
        method: 'POST',
        body: payload ? JSON.stringify(payload) : undefined,
      });

      if (result.ok) {
        showToast('success', 'Webhook call successful');
      } else {
        showToast('error', `Webhook responded with ${result.status}`);
      }
    } catch {
      showToast('error', `Error calling webhook - no response.`);
    }
  }, []);

  const newLanguagesOptions = useMemo<{ value: string; label: string }[]>(() => {
    return [{ value: '', label: 'Select' }].concat(
      allLanguages
        .filter((lang) => !find(fields, { languageId: lang.id }))
        .map((lang) => ({ value: lang.id, label: capitalize(lang.name) })),
    );
  }, [fields, allLanguages]);

  const themeOptions = useMemo<{ value: number; label: string }[]>(() => {
    const initial = [{ value: -1, label: 'Select theme' }];

    const convertedTheme = themes.map((t) => ({ value: t.id, label: t.name }));

    return [...initial, ...convertedTheme];
  }, [themes]);

  const onAddLanguage = useCallback(() => {
    append({ languageId: newLanguage });
    setNewLanguage('');
  }, [append, newLanguage]);

  useEffect(() => {
    const account = getValues();

    if (account && !find(watchLanguages, { languageId: account.defaultLanguageId })) {
      if (watchLanguages.length > 0) {
        setValue('defaultLanguageId', watchLanguages[0].languageId);
      }
    }
  }, [getValues, setValue, watchLanguages]);

  useEffect(() => {
    if (isThemedProject) {
      setValue('hasTranslations', true);
    }
  }, [isThemedProject, setValue]);

  const projectItems = (
    [
      {
        Header: 'Project options',
        defaultExpanded: true,
        id: 'option',
        content: (
          <div className="columns is-multiline is-gapless">
            <div className="column is-full">
              <Controller
                control={control}
                name="projectType"
                as={
                  <SelectHook
                    label="Project options"
                    options={PROJECT_TYPE_OPTIONS}
                    id="projectType"
                    name="projectType"
                    touched={isTouched(formState, 'projectType')}
                  />
                }
              />
            </div>
          </div>
        ),
      },
      {
        Header: 'Project',
        defaultExpanded: true,
        id: 'project',
        content: (
          <div className="columns is-multiline is-gapless">
            <div className="column is-full">
              <Controller
                control={control}
                name="name"
                defaultValue=""
                as={
                  <InputHook
                    id="name"
                    label="Name"
                    error={formState.errors.name?.message}
                    touched={isTouched(formState, 'name')}
                    fieldClassName="mb-3"
                    data-qa="name-field"
                  />
                }
              />
            </div>
          </div>
        ),
      },
      {
        Header: 'Additional Features',
        defaultExpanded: true,
        id: 'additionalFeatures',
        content: (
          <>
            <div className="columns is-multiline is-gapless">
              <div className="column is-full mt-3">
                <Checkbox
                  ref={register}
                  name="hasMembers"
                  disabled={organization?.plan.id === 'free'}
                  label="Members"
                />
              </div>
              <div className="column is-full">
                <Checkbox
                  ref={register}
                  name="hasTranslations"
                  disabled={
                    organization?.plan.id === 'free' ||
                    organization?.plan.id === 'freelancer_monthly' ||
                    organization?.plan.id === 'freelancer_yearly' ||
                    isThemedProject
                  }
                  label="Translations"
                />
              </div>
            </div>
          </>
        ),
      },
      {
        Header: 'Themes',
        defaultExpanded: true,
        id: 'themes',
        content: (
          <>
            <Controller
              control={control}
              name="themeId"
              defaultValue=""
              as={
                <SelectHook
                  label="Theme options"
                  options={themeOptions}
                  id="themeId"
                  name="themeId"
                  value={watchTheme}
                  ref={register}
                  touched={isTouched(formState, 'themeId')}
                  error={formState.errors.themeId?.message}
                />
              }
            />
            {!isThemedProject ? (
              <>
                <div className="columns is-multiline is-gapless">
                  <div className="column is-full mt-4 ml-2">
                    <Checkbox id="isSelfHosted" name="isSelfHosted" ref={register} label="Self Hosted" />
                  </div>
                </div>
                {!watchSelfHosted && (
                  <>
                    <div className="columns is-vcentered">
                      <div className="column is-3 ml-2">
                        <label className="radio">
                          <input type="radio" value="auto" name="selfHostingType" ref={register} />
                          &nbsp; Auto generated
                        </label>
                      </div>
                      <div className={showSuffix ? 'column is-6' : 'column is-9'}>
                        <Controller
                          control={control}
                          value="autoHosted"
                          defaultValue=""
                          name="autoHosted"
                          as={
                            <InputHook
                              disabled={
                                watchSelfHostingType === SelfHostedType.CUSTOM ||
                                watchSelfHostingType === SelfHostedType.INITIAL
                              }
                              touched={isTouched(formState, 'autoHosted')}
                              id="autoHosted"
                            />
                          }
                        />
                      </div>
                      {showSuffix && <div className="column is-3 mt-4 pl-0">.sitesails.io</div>}
                    </div>

                    <div className="columns is-vcentered">
                      <div className="column is-3 ml-2">
                        <label className="radio">
                          <input type="radio" value="custom" name="selfHostingType" ref={register} />
                          &nbsp; Custom
                        </label>
                      </div>
                      <div className="column is-9">
                        <Controller
                          control={control}
                          name="customHosted"
                          value="customHosted"
                          defaultValue=""
                          as={
                            <InputHook
                              disabled={
                                watchSelfHostingType === SelfHostedType.AUTO_GENERATED ||
                                watchSelfHostingType === SelfHostedType.INITIAL
                              }
                              touched={isTouched(formState, 'customHosted')}
                              id="customHosted"
                            />
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : null}
          </>
        ),
      },
      !project
        ? {
            Header: 'Data',
            defaultExpanded: true,
            id: 'data',
            content: (
              <div className="columns is-multiline is-gapless">
                <div className="column is-full mt-3">
                  <Checkbox ref={register} name="createEmptyProject" label="Create empty project" />
                </div>
              </div>
            ),
          }
        : null,
      isThemedProject
        ? {
            Header: 'Domain',
            defaultExpanded: true,
            id: 'domain',
            content: (
              <>
                <div className="columns is-vcentered">
                  <div className="column is-3 ml-2">
                    <label className="radio">
                      <input type="radio" value="custom" name="selfHostingType" ref={register} />
                      &nbsp; Custom
                    </label>
                  </div>
                  <div className="column is-9">
                    <Controller
                      control={control}
                      name="customHosted"
                      value="customHosted"
                      defaultValue=""
                      as={
                        <InputHook
                          disabled={
                            watchSelfHostingType === SelfHostedType.AUTO_GENERATED ||
                            watchSelfHostingType === SelfHostedType.INITIAL
                          }
                          touched={isTouched(formState, 'customHosted')}
                          id="customHosted"
                          label="Custom domain"
                          error={formState.errors.customHosted?.message}
                        />
                      }
                    />
                  </div>
                </div>

                <div className="columns is-vcentered">
                  <div className="column is-3 ml-2">
                    <label className="radio">
                      <input type="radio" value="auto" name="selfHostingType" ref={register} />
                      &nbsp; Auto generated
                    </label>
                  </div>
                  <div className={showSuffix ? 'column is-6' : 'column is-9'}>
                    <Controller
                      control={control}
                      value="autoHosted"
                      defaultValue=""
                      name="autoHosted"
                      as={
                        <InputHook
                          disabled={
                            watchSelfHostingType === SelfHostedType.CUSTOM ||
                            watchSelfHostingType === SelfHostedType.INITIAL
                          }
                          touched={isTouched(formState, 'autoHosted')}
                          id="autoHosted"
                          label="Subdomain"
                          error={formState.errors.autoHosted?.message}
                        />
                      }
                    />
                  </div>
                  {showSuffix && <div className="column is-3 mt-4 pl-0">.sitesails.io</div>}
                </div>
              </>
            ),
          }
        : null,
      !isThemedProject
        ? {
            Header: 'API Keys',
            defaultExpanded: true,
            id: 'apiKeys',
            content: (
              <>
                <Controller
                  control={control}
                  name="publicApiKey"
                  defaultValue=""
                  as={
                    <InputHook
                      id="publicApiKey"
                      label="Public Key"
                      error={formState.errors.publicApiKey?.message}
                      touched={isTouched(formState, 'publicApiKey')}
                    />
                  }
                />
                <Controller
                  control={control}
                  name="adminApiKey"
                  defaultValue=""
                  as={
                    <InputHook
                      id="adminApiKey"
                      label="Admin Key"
                      error={formState.errors.adminApiKey?.message}
                      touched={isTouched(formState, 'adminApiKey')}
                    />
                  }
                />
              </>
            ),
          }
        : null,
      {
        Header: 'Webhooks',
        defaultExpanded: true,
        id: 'webhooks',
        content: (
          <>
            <div className="columns is-vcentered">
              <div className="column">
                <Controller
                  control={control}
                  name="cacheBustWebhook"
                  defaultValue=""
                  as={
                    <InputHook
                      id="cacheBustWebhook"
                      label="Cache bust webhook URL"
                      error={formState.errors.cacheBustWebhook?.message}
                      touched={isTouched(formState, 'cacheBustWebhook')}
                    />
                  }
                />
              </div>
              <div className="column is-narrow">
                <button
                  type="button"
                  className="button is-outlined"
                  onClick={() => onTestWebhook(watchCacheBustWebhook, WEBHOOK_TEST_PAYLOAD_CACHEBUST)}
                >
                  <span className="icon has-text-primary">
                    <i className="fal fa-play" />
                  </span>
                  <span>Test</span>
                </button>
              </div>
            </div>
            <div className="columns is-vcentered">
              <div className="column">
                <Controller
                  control={control}
                  name="redeployWebhook"
                  defaultValue=""
                  as={
                    <InputHook
                      id="redeployWebhook"
                      label="Redeploy webhook URL"
                      error={formState.errors.redeployWebhook?.message}
                      touched={isTouched(formState, 'redeployWebhook')}
                    />
                  }
                />{' '}
              </div>
              <div className="column is-narrow">
                <button
                  type="button"
                  className="button is-outlined"
                  onClick={() => onTestWebhook(watchRedeployWebhook)}
                >
                  <span className="icon has-text-primary">
                    <i className="fal fa-play" />
                  </span>
                  <span>Test</span>
                </button>
              </div>
            </div>
          </>
        ),
      },
      !isThemedProject || !!project
        ? {
            Header: 'Languages',
            defaultExpanded: true,
            id: 'languages',
            content: (
              <>
                {fields.map((lang, index) => (
                  <div className="columns is-vcentered" key={lang.languageId}>
                    <div className="column">
                      <input
                        type="hidden"
                        value={lang.languageId}
                        ref={register()}
                        name={`languages[${index}].languageId`}
                      />
                      <InputHook
                        value={capitalize(find(allLanguages, { id: lang.languageId })?.name || '')}
                        readOnly
                        label={`Language #${index + 1}`}
                      />
                    </div>
                    <div className="column is-narrow">
                      <div className="control">
                        <label htmlFor={`defaultLanguage-${lang.languageId}`} className="radio">
                          <input
                            type="radio"
                            name="defaultLanguageId"
                            value={lang.languageId}
                            ref={register()}
                            id={`defaultLanguage-${lang.languageId}`}
                          />
                          &nbsp; Default
                        </label>
                      </div>
                    </div>
                    <div className="column is-narrow">
                      <button type="button" className="button is-white" onClick={() => remove(index)}>
                        <span className="icon has-text-grey">
                          <i className="fal fa-trash" />
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
                {newLanguagesOptions.length > 1 ? (
                  <div className="columns is-vcentered">
                    <div className="column">
                      <SelectHook
                        value={newLanguage}
                        onChange={(event) => setNewLanguage(event.target.value)}
                        label="Add new language"
                        options={newLanguagesOptions}
                        data-qa="language-select"
                      />
                    </div>
                    <div className="column is-narrow">
                      <button
                        type="button"
                        className="button is-outlined"
                        onClick={onAddLanguage}
                        disabled={!newLanguage}
                        data-qa="add-language"
                      >
                        <span className="icon has-text-primary">
                          <i className="fal fa-plus" />
                        </span>
                        <span>Add Language</span>
                      </button>
                    </div>
                  </div>
                ) : null}
              </>
            ),
          }
        : null,
      {
        Header: 'Notifications',
        defaultExpanded: true,
        id: 'notifications',
        content: (
          <Controller
            control={control}
            name="notificationsEmail"
            defaultValue=""
            as={
              <InputHook
                id="notificationsEmail"
                label="Notifications Email"
                error={formState.errors.notificationsEmail?.message}
                touched={isTouched(formState, 'notificationsEmail')}
              />
            }
          />
        ),
      },
    ] as (AccordionItem | null)[]
  ).filter(Boolean) as AccordionItem[];

  const filteredProjectItems =
    watchOption?.toString() === ProjectFieldType.STANDARD_PROJECT.toString()
      ? projectItems.filter((i) => i.id !== 'themes')
      : projectItems.filter((i) => i.id !== 'additionalFeatures' && i.id !== 'webhooks');

  return organization ? (
    <>
      <LayoutWithNavbar
        breadcrumbs={[
          { label: 'SiteSails', href: '/dashboard' },
          { label: 'Projects', href: project ? `/projects/${project.id}` : `/organizations/${organization.id}` },
          { label: project?.name || 'New Project', href: '#' },
        ]}
        endButtons={
          <>
            {!!project && (
              <div className="navbar-item">
                <a
                  className={clsx('button is-danger', {
                    'is-loading': formState.isSubmitting,
                  })}
                  data-qa="delete-button"
                  onClick={onDelete}
                >
                  <span className="icon">
                    <i className="far fa-trash" />
                  </span>
                  <span>Delete</span>
                </a>
              </div>
            )}
            <div className="navbar-item">
              <button
                type="submit"
                className={clsx('button is-primary', {
                  'is-loading': formState.isSubmitting,
                })}
                data-qa="save-button"
              >
                <span className="icon">
                  <i className="far fa-check has-text-secondary-light" />
                </span>
                <span>Save</span>
              </button>
            </div>
          </>
        }
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {isThemedProject ? (
          <>
            <Controller control={control} name="projectType" as={<input type="hidden" />} />
            <Controller control={control} name="publicApiKey" as={<input type="hidden" />} />
            <Controller control={control} name="adminApiKey" as={<input type="hidden" />} />
          </>
        ) : null}
        <EditPageLayout renderLeft={<Accordion disableShadow items={filteredProjectItems} />} />
      </LayoutWithNavbar>
      {process.env.REACT_APP_SHOW_DEVTOOLS === 'true' ? <DevTool control={control} /> : null}
    </>
  ) : null;
}
