import { Section } from 'src/services/sections';
import { Breadcrumb } from '../types';

export function getBreadcrumbs(mainSection: Section, subSection: Section | undefined, uri: string, listLink?: string) {
  const breadcrumbs: Breadcrumb[] = [{ href: listLink || `/nodes/${mainSection.slug}`, label: mainSection.name }];

  if (!!subSection && subSection !== mainSection) {
    breadcrumbs.push({
      label: subSection.name.replace(new RegExp(`${mainSection.name} ?`), ''),
      href: listLink || `/nodes/${mainSection.slug}/${subSection.slug}`,
    });
  }

  if (uri.match(/edit|new/)) {
    const isEditing = uri.includes('edit');
    breadcrumbs.push({ href: './', label: isEditing ? 'Edit entry' : 'New entry' });
  }

  return breadcrumbs;
}
