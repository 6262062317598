export interface Section {
  id?: number;
  name: string;
  type: SectionType;
  slug: string;
  customCSS?: string;
  fields?: SectionField[];
  connectionType?: SectionConnectionTypes;
  categorySectionId?: number;
  parentSectionId?: number;
  previewUrl?: string;

  isPublishable: boolean;
  isEvent: boolean;
  isProduct: boolean;

  hasStripeCheckout: boolean;
  hasTransactionConnection: boolean;
  hasSeoMetadata: boolean;
  hasVerification: boolean;
  hasCategory: boolean;

  typeDescription?: string;
}

export interface SectionField {
  id?: number;
  sectionId?: number;

  type: SectionFieldType;
  name: string;
  apiName: string;

  isRequired: boolean;
  isDisplayedInList: boolean;
  isLocalized: boolean;
  isTranslatable: boolean;
  isPublic: boolean;

  options?: SectionFieldOption[];
  linkedSectionId?: number;

  position?: number;
}

export interface SectionFieldOption {
  value: string;
  label: string;
}

export enum SectionType {
  Articles = 1,
  Subsections = 2,
  Pages = 3,
  Connections = 8,
  Categories = 9,
  Collections = 10,
}

export enum SectionFieldType {
  Text = 1,
  Number = 2,
  Boolean = 3,
  Date = 4,
  SingleOption = 5,
  MultiOption = 6,
  Image = 7,
  File = 8,
  SectionLink = 9,
  ImageGallery = 10,
  MultilineText = 11,
  DateTime = 12,
  SectionMultiLink = 13,
  KeyValue = 14,
  RichTextSimple = 15,
  RichTextComplete = 16,
  TextArray = 17,
  VideoGallery = 18,
  Video = 19,
  ColorPicker = 20,
  InmanaLink = 21,
}

export interface SectionRouteLink {
  link: (slug: string) => string;
  label: string | ((type: SectionTypeOption) => string);
}

export interface SectionTypeOption {
  value: SectionType;
  label: string;
  hasCategories: boolean;
  slug: string;
  subRoutes?: SectionRouteLink[];
}

export enum SectionConnectionTypes {
  Custom = 1,
  Comment = 2,
  Review = 3,
  Like = 4,
}
