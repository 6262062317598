import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

type CssProviderProps = { css: string | undefined };

export function CssProvider({ css }: CssProviderProps) {
  const [parsedCss, setParsedCss] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (css) {
      setParsedCss(css.replace(/sitesails-editor/g, 'ql-editor'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [css]);

  if (!parsedCss) {
    return null;
  }

  return (
    <Helmet>
      <style>{parsedCss}</style>
    </Helmet>
  );
}
