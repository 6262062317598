import { combineReducers } from 'redux';
import { createCrudApiReducer } from 'src/services/api';
import { Collection } from '../types';
import { CollectionsActionTypes } from './actions';

const collectionsReducer = createCrudApiReducer<Collection>(
  CollectionsActionTypes.CHANGE_COLLECTIONS_START,
  CollectionsActionTypes.CHANGE_COLLECTIONS_FAILURE,
  CollectionsActionTypes.FETCH_COLLECTIONS_SUCCESS,
  CollectionsActionTypes.ADD_COLLECTION_SUCCESS,
  CollectionsActionTypes.UPDATE_COLLECTION_SUCCESS,
  CollectionsActionTypes.DELETE_COLLECTION_SUCCESS,
  (response: any) => ({ ...response }),
);

export default combineReducers({ collections: collectionsReducer });
