import { createCrudApiActions } from 'src/services/api';

import { KeyValueItem, KeyValueStore } from '../types';
import { transformKeyValueItemForApi, transformKeyValueStoreForApi } from '../transformations';

export enum KeyValueStoresActionTypes {
  CHANGE_KEYVALUESTORES_START = 'keyvaluestores/change_keyvaluestores_start',
  CHANGE_KEYVALUESTORES_FAILURE = 'keyvaluestores/change_keyvaluestores_failure',
  FETCH_KEYVALUESTORES_SUCCESS = 'keyvaluestores/fetch_keyvaluestores_success',
  ADD_KEYVALUESTORE_SUCCESS = 'keyvaluestores/add_keyvaluestore_success',
  UPDATE_KEYVALUESTORE_SUCCESS = 'keyvaluestores/update_keyvaluestore_success',
  DELETE_KEYVALUESTORE_SUCCESS = 'keyvaluestores/delete_keyvaluestore_success',
}

export enum KeyValueItemsActionTypes {
  CHANGE_KEYVALUEITEMS_START = 'keyvaluestores/change_keyvalueitems_start',
  CHANGE_KEYVALUEITEMS_FAILURE = 'keyvaluestores/change_keyvalueitems_failure',
  FETCH_KEYVALUEITEMS_SUCCESS = 'keyvaluestores/fetch_keyvalueitems_success',
  ADD_KEYVALUEITEM_SUCCESS = 'keyvaluestores/add_keyvalueitem_success',
  UPDATE_KEYVALUEITEM_SUCCESS = 'keyvaluestores/update_keyvalueitem_success',
  DELETE_KEYVALUEITEM_SUCCESS = 'keyvaluestores/delete_keyvalueitem_success',
}

export const [fetchKeyValueStores, addKeyValueStore, updateKeyValueStore, deleteKeyValueStore] =
  createCrudApiActions<KeyValueStore>(
    '/keyvalues',
    KeyValueStoresActionTypes.CHANGE_KEYVALUESTORES_START,
    KeyValueStoresActionTypes.CHANGE_KEYVALUESTORES_FAILURE,
    KeyValueStoresActionTypes.FETCH_KEYVALUESTORES_SUCCESS,
    KeyValueStoresActionTypes.ADD_KEYVALUESTORE_SUCCESS,
    KeyValueStoresActionTypes.UPDATE_KEYVALUESTORE_SUCCESS,
    KeyValueStoresActionTypes.DELETE_KEYVALUESTORE_SUCCESS,
    transformKeyValueStoreForApi,
    {
      change: '/:id',
    },
  );

export const [fetchKeyValueItems, addKeyValueItem, updateKeyValueItem, deleteKeyValueItem] =
  createCrudApiActions<KeyValueItem>(
    '/keyvalues',
    KeyValueItemsActionTypes.CHANGE_KEYVALUEITEMS_START,
    KeyValueItemsActionTypes.CHANGE_KEYVALUEITEMS_FAILURE,
    KeyValueItemsActionTypes.FETCH_KEYVALUEITEMS_SUCCESS,
    KeyValueItemsActionTypes.ADD_KEYVALUEITEM_SUCCESS,
    KeyValueItemsActionTypes.UPDATE_KEYVALUEITEM_SUCCESS,
    KeyValueItemsActionTypes.DELETE_KEYVALUEITEM_SUCCESS,
    transformKeyValueItemForApi,
    {
      load: '/:slug',
      add: '/:slug/:key',
      change: '/:slug/:key',
    },
  );
