import React, { useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import * as yup from 'yup';
import clsx from 'clsx';

import { InputHook, isTouched } from 'src/common';
import { useAuth } from 'src/services/auth';
import { isValid } from 'src/services/api';
import { VALIDATION_MESSAGES } from 'src/common/const';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { showToast } from 'src/common/util/show-toast';

const validationSchema = yup.object({
  email: yup.string().required(VALIDATION_MESSAGES.required('Email')).email(VALIDATION_MESSAGES.invalidEmail),
  firstName: yup.string().required(VALIDATION_MESSAGES.required('First name')),
  lastName: yup.string().required(VALIDATION_MESSAGES.required('Last name')),
  password: yup
    .string()
    .required(VALIDATION_MESSAGES.required('Password'))
    .min(6, 'Too short, please enter at least 6 chars.'),
});

type RegistrationProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Registration(_: RegistrationProps) {
  const { auth, register } = useAuth();
  const { handleSubmit, formState, control } = useForm<{
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: { email: '', password: '', firstName: '', lastName: '' },
  });

  const onSubmit = handleSubmit(async (values) => {
    const res = await register(values.email, values.password, values.firstName, values.lastName);

    // @ts-ignore
    if (res.ok) {
      showToast('success', 'Registration complete. Welcome!');
      navigate('/login');
      navigate('/');
      // @ts-ignore
    } else if (res.status === 409) {
      showToast('error', 'This email is already used for another account.');
    } else {
      showToast('error', 'There was an error while registering. Please try again in a few minutes.');
    }
  });

  useEffect(() => {
    if (isValid(auth)) {
      navigate('/');
    }
  }, [auth]);

  if (isValid(auth)) {
    return null;
  }

  return (
    <div className="login-page">
      <div className="navbar is-transparent is-spaced">
        <div className="navbar-menu">
          <div className="navbar-end">
            <a href="#" className="navbar-item">
              Services
            </a>
            <a href="#" className="navbar-item">
              Contact
            </a>
          </div>
        </div>
      </div>
      <div className="container is-fluid">
        <div className="page-wrapper">
          <form onSubmit={onSubmit} noValidate>
            <h2 className="title is-1">Create an account</h2>
            <p>Please fill in the information below and we&apos;ll create an account for you.</p>
            <Controller
              control={control}
              name="email"
              defaultValue=""
              as={
                <InputHook
                  label="Email"
                  error={formState.errors.email?.message}
                  disabled={formState.isSubmitting}
                  touched={isTouched(formState, 'email')}
                  type="email"
                  prependIcon={<i className="fal fa-envelope-open" />}
                  data-qa="login-email"
                />
              }
            />
            <Controller
              control={control}
              name="firstName"
              defaultValue=""
              as={
                <InputHook
                  label="First name"
                  error={formState.errors.firstName?.message}
                  disabled={formState.isSubmitting}
                  touched={isTouched(formState, 'firstName')}
                  type="firstName"
                  prependIcon={<i className="fal fa-user" />}
                  data-qa="login-firstName"
                />
              }
            />
            <Controller
              control={control}
              name="lastName"
              defaultValue=""
              as={
                <InputHook
                  label="Last name"
                  error={formState.errors.lastName?.message}
                  disabled={formState.isSubmitting}
                  touched={isTouched(formState, 'lastName')}
                  type="lastName"
                  prependIcon={<i className="fal fa-user" />}
                  data-qa="login-lastName"
                />
              }
            />
            <Controller
              control={control}
              name="password"
              defaultValue=""
              as={
                <InputHook
                  label="Password"
                  error={formState.errors.password?.message}
                  disabled={formState.isSubmitting}
                  touched={isTouched(formState, 'password')}
                  type="password"
                  prependIcon={<i className="fal fa-key" />}
                  data-qa="login-password"
                />
              }
            />
            <button
              type="submit"
              data-qa="registration-button"
              className={clsx('button is-link has-text-weight-bold is-fullwidth is-submit', {
                'is-loading': formState.isSubmitting,
              })}
              disabled={formState.isSubmitting || !formState.isValid}
            >
              <span className="icon">
                <i className="fal fa-user-plus" />
              </span>
              <span>Create account</span>
            </button>

            <div className="columns is-mobile mt-2 links">
              <div className="column">
                <a href="/login">Back to Sign in</a>
              </div>
              <div className="column is-narrow">
                <a href="/forgot-password">Forgot your password?</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
