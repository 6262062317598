import { Action, combineReducers } from 'redux';

import { chainReducers, createApiReducer, createCrudApiReducer, INITIAL_API_STATE } from 'src/services/api';

import { AuthActionTypes } from 'src/services/auth';
import { ProjectActionTypes } from 'src/services/project/redux/actions';
import { KeyValueStoresActionTypes, KeyValueItemsActionTypes } from './actions';
import { transformKeyValueItem, transformKeyValueStore } from '../transformations';
import { KeyValueStore, KeyValueItem } from '../types';
import { KeyValuesState } from './types';

const keyValuesStoresReducer = createCrudApiReducer<KeyValueStore>(
  KeyValueStoresActionTypes.CHANGE_KEYVALUESTORES_START,
  KeyValueStoresActionTypes.CHANGE_KEYVALUESTORES_FAILURE,
  KeyValueStoresActionTypes.FETCH_KEYVALUESTORES_SUCCESS,
  KeyValueStoresActionTypes.ADD_KEYVALUESTORE_SUCCESS,
  KeyValueStoresActionTypes.UPDATE_KEYVALUESTORE_SUCCESS,
  KeyValueStoresActionTypes.DELETE_KEYVALUESTORE_SUCCESS,
  transformKeyValueStore,
);

const keyValuesItemsReducer = createCrudApiReducer<KeyValueItem>(
  KeyValueItemsActionTypes.CHANGE_KEYVALUEITEMS_START,
  KeyValueItemsActionTypes.CHANGE_KEYVALUEITEMS_FAILURE,
  KeyValueItemsActionTypes.FETCH_KEYVALUEITEMS_SUCCESS,
  KeyValueItemsActionTypes.ADD_KEYVALUEITEM_SUCCESS,
  KeyValueItemsActionTypes.UPDATE_KEYVALUEITEM_SUCCESS,
  KeyValueItemsActionTypes.DELETE_KEYVALUEITEM_SUCCESS,
  transformKeyValueItem,
  'key',
);

const resetReducer = (state: KeyValuesState['items'] | KeyValuesState['stores'], action: Action) => {
  if (action.type === ProjectActionTypes.SELECT_PROJECT) {
    return INITIAL_API_STATE;
  }
  return state;
};

const signOut = createApiReducer(
  ['', AuthActionTypes.SIGN_OUT, ''],
  () => INITIAL_API_STATE,
  () => INITIAL_API_STATE,
);

export default combineReducers({
  stores: chainReducers(INITIAL_API_STATE, keyValuesStoresReducer, resetReducer, signOut),
  items: chainReducers(INITIAL_API_STATE, keyValuesItemsReducer, resetReducer, signOut),
});
