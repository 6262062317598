import { Variants } from 'framer-motion';
import * as yup from 'yup';

export const DEFAULT_API_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export const DEFAULT_API_DATE_FORMAT = 'yyyy-MM-dd';

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy HH:mm';

export const MAX_TABLE_ROWS = 15;

export const HELP_ANIMATION_VARIANTS: Variants = {
  hidden: { opacity: 0, x: 10 },
  visible: { opacity: 1, x: 0, transition: { delay: 0.2 } },
};

export const ANIMATED_CARD_VARIANTS: Variants = {
  hidden: {
    opacity: 0,
    scale: 0.6,
    transition: {
      ease: 'easeIn',
      duration: 0.15,
    },
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
};

export const FADE_IN_DIV_VARIANTS: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.175,
      ease: 'easeIn',
    },
  },
  visible: {
    opacity: 0.75,
    transition: {
      ease: 'easeOut',
      duration: 0.15,
    },
  },
};

export const FADE_IN_VARIANTS: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.175,
      ease: 'easeIn',
    },
  },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 0.15,
    },
  },
};

export const VALIDATION_MESSAGES = {
  required: (name: string) => `${name} cannot be blank`,
  invalidEmail: () => 'Email address is invalid',
  passwordConfirmation: () => 'Passwords do not match',
  min: (name: string, min: number) => `${name} must be at least ${min}`,
};

export const BACK_TO_PAGE = 'prevPage';
export const BACK_TO_SEARCH = 'prevSearch';
export const RETURN_TO = 'returnTo';
export const RICH_EDITOR_DEFAULT_TAB = 'richEditorTab';

export const CONNECTION_TYPE_OPTIONS = [
  { value: 1, label: 'Custom' },
  { value: 2, label: 'Comments' },
  { value: 3, label: 'Reviews' },
  { value: 4, label: 'Likes' },
];

export const CURRENCIES_MAP: Record<string, string> = {
  'US Dollar': 'USD',
};

export const CONTENTS_SCHEMA = yup
  .array(
    yup.object({
      id: yup
        .number()
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
      languageId: yup.string().required(VALIDATION_MESSAGES.required('Language')),
      title: yup.string().required(VALIDATION_MESSAGES.required('Title')),
      description: yup.string().nullable().notRequired(),
      content: yup.string().notRequired(),
    }),
  )
  .required(VALIDATION_MESSAGES.required('Contents'));

export const LINK_ENTER_VARIANTS: Variants = {
  hidden: {
    x: '25%',
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
};
export const SUBMENU_ENTER_VARIANTS: Variants = {
  hidden: {
    opacity: 0,
    height: 0,
  },
  visible: {
    opacity: 1,
    height: 'auto',
  },
};
export const SUBMENU_LINK_ENTER_VARIANTS: Variants = {
  hidden: {
    x: '100%',
  },
  visible: {
    x: 0,
  },
};
