import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteMailchimpSettings,
  fetchMailchimpSettings,
  getMailchimpSettings,
  updateMailchimpSettings,
} from '../redux';

export function useMailchimpSettings() {
  const dispatch = useDispatch();
  const settings = useSelector(getMailchimpSettings);

  const loadSettings = useCallback(() => dispatch(fetchMailchimpSettings()), [dispatch]);

  const updateSettings = useCallback((data: any) => dispatch(updateMailchimpSettings(data)), [dispatch]);

  const deleteSettings = useCallback(() => dispatch(deleteMailchimpSettings()), [dispatch]);

  return {
    settings,
    loadSettings,
    updateSettings,
    deleteSettings,
  };
}
