import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import { Column } from 'react-table';
import Swal from 'sweetalert2';
import { useDebounce } from 'use-debounce';

import {
  useCurrentPage,
  LayoutWithNavbar,
  Table,
  TableDropdown,
  ButtonCreate,
  usePrevSearchURLValue,
  addParamsToLink,
  TreeViewChild,
} from 'src/common';
import { isLoading } from 'src/services/api';
import { useSections, Section, SectionType, sectionComparer } from 'src/services/sections';
import { showToast } from 'src/common/util/show-toast';
import urlcat from 'urlcat';
import { BACK_TO_PAGE } from 'src/common/const';
import { useProjectLink } from 'src/common/hooks/use-project-link';

type SectionsListProps = RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SectionsList(_: SectionsListProps) {
  const { sections, deleteSection, loadSections } = useSections();
  const [search] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [currentPage] = useCurrentPage();
  const prevSearchURLValue = usePrevSearchURLValue();
  const projectLink = useProjectLink();

  const onDeleteSection = useCallback(
    async (section: any) => {
      const confirm = await Swal.fire({
        title: 'Delete Section',
        text: `Are you sure you want to delete ${section.name}?`,
        showCancelButton: true,
        confirmButtonColor: '#e0245a',
        confirmButtonText: 'Delete',
        icon: 'question',
      });

      if (confirm.value) {
        const result = await deleteSection(section.id!);

        if (result.ok) {
          showToast('success', `Section has been deleted successfully`);
        } else {
          showToast('error', 'Unable to delete section');
        }
      }
    },
    [deleteSection],
  );

  useEffect(() => {
    if (prevSearchURLValue) return;
    loadSections(debouncedSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const getSectionName = (type: SectionType) => {
    switch (type) {
      case SectionType.Categories:
      case SectionType.Collections:
      case SectionType.Connections:
        return SectionType[type];
      default:
        return 'Main Section';
    }
  };

  const getSubSectionIcon = (type: SectionType) => {
    let icon = null;
    switch (type) {
      case SectionType.Categories:
        icon = <i className="fal fa-tag" />;
        break;
      case SectionType.Collections:
        icon = <i className="fal fa-list-ol" />;
        break;
      case SectionType.Connections:
        icon = <i className="fal fa-user-tag" />;
        break;
      case SectionType.Subsections:
        icon = <i className="fal fa-chevron-square-right" />;
        break;
      default:
        icon = null;
    }

    if (icon) {
      return <span className="icon has-text-primary mr-1">{icon}</span>;
    }
    return null;
  };

  const tableColumns: Column<Section>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row: { original }, value }) => (
          <Link
            to={addParamsToLink(`/sections/edit/${original.id}`, { currentPage: currentPage + 1, prevSearch: search })}
            data-qa={`section-link-${value}`}
          >
            {[
              SectionType.Categories,
              SectionType.Collections,
              SectionType.Connections,
              SectionType.Subsections,
            ].includes(original.type) ? (
              <TreeViewChild />
            ) : null}
            {getSubSectionIcon(original.type)}
            {value}
          </Link>
        ),
      },
      {
        Header: 'Slug',
        accessor: 'slug',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value }) => getSectionName(value),
      },
      {
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => (
          <TableDropdown dataQa={`table-menu-dropdown-${original.name}`}>
            {getSectionName(original.type) === 'Main Section' ? (
              <>
                {!original.categorySectionId ? (
                  <Link
                    to={urlcat(`${projectLink}/sections/new`, {
                      mainSection: value,
                      type: SectionType.Categories,
                      [BACK_TO_PAGE]: currentPage + 1,
                    })}
                    className="dropdown-item"
                  >
                    Add Categories
                  </Link>
                ) : null}
                <Link
                  to={urlcat(`${projectLink}/sections/new`, {
                    mainSection: value,
                    type: SectionType.Collections,
                    [BACK_TO_PAGE]: currentPage + 1,
                  })}
                  className="dropdown-item"
                  data-qa="dropdown-add-collections"
                >
                  Add Collections
                </Link>
                <Link
                  to={urlcat(`${projectLink}/sections/new`, {
                    mainSection: value,
                    type: SectionType.Connections,
                    [BACK_TO_PAGE]: currentPage + 1,
                  })}
                  className="dropdown-item"
                  data-qa="dropdown-add-connections"
                >
                  Add Connections
                </Link>
              </>
            ) : null}
            <Link
              to={addParamsToLink(`/sections/edit/${value}`, { currentPage: currentPage + 1, prevSearch: search })}
              className="dropdown-item"
            >
              Edit
            </Link>
            <a className="dropdown-item" onClick={() => onDeleteSection(original)} data-qa="dropdown-delete">
              Delete
            </a>
          </TableDropdown>
        ),
      },
    ],
    [currentPage, onDeleteSection, search, projectLink],
  );

  const sectionsData = useMemo(
    () =>
      sections.items
        .filter(
          (section) =>
            ![
              SectionType.Categories,
              SectionType.Collections,
              SectionType.Connections,
              SectionType.Subsections,
            ].includes(section.type),
        )
        .map((section) => ({
          ...section,
          subRows: sections.items
            .filter((subSection) => [subSection.categorySectionId, subSection.parentSectionId].includes(section.id))
            .sort(sectionComparer),
        })),
    [sections.items],
  );

  const onFetchData = useCallback(() => loadSections(), [loadSections]);

  return (
    <LayoutWithNavbar
      breadcrumbs={[
        { label: 'SiteSails', href: '/' },
        { label: 'Sections', href: `./` },
      ]}
      // TODO: searching also reflects on sidebar menu (redux state is overwritten)
      // onSearch={setSearch}
      // searchValue={search}
      endButtons={<ButtonCreate />}
    >
      <Table
        data={sectionsData}
        loading={isLoading(sections)}
        columns={tableColumns}
        onFetchData={onFetchData}
        dataQa="sections-table"
      />
    </LayoutWithNavbar>
  );
}
