import React, { forwardRef } from 'react';
import { useHookCustomField } from '../../hooks/use-hook-custom-field';
import { FloatingLabelField, FloatingLabelFieldProps } from './FloatingLabelField';

export type InputProps = FloatingLabelFieldProps & JSX.IntrinsicElements['input'];

export const InputHook = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  const { fieldProps, inputProps } = useHookCustomField<JSX.IntrinsicElements['input']>(props);

  return (
    <FloatingLabelField {...fieldProps}>
      <input ref={ref} {...inputProps} />
    </FloatingLabelField>
  );
});
