import { Aligner, Alignment, AlignOptions } from 'quill-blot-formatter';

const LEFT_ALIGN = 'left';
const CENTER_ALIGN = 'center';
const RIGHT_ALIGN = 'right';

export class QuillCustomAligner implements Aligner {
  alignments: any;

  alignAttribute: string;

  applyStyle: boolean;

  constructor(options: AlignOptions) {
    this.applyStyle = options.aligner.applyStyle;
    this.alignAttribute = options.attribute;
    this.alignments = {
      [LEFT_ALIGN]: {
        name: LEFT_ALIGN,
        icon: options.icons.left,
        apply: (el: HTMLElement) => {
          this.setAlignment(el, LEFT_ALIGN);
          // this.setStyle(el, 'inline', 'left', '0 1em 1em 0');
          this.setClass(el, 'ss__image-align-left');
        },
      },
      [CENTER_ALIGN]: {
        name: CENTER_ALIGN,
        icon: options.icons.center,
        apply: (el: HTMLElement) => {
          this.setAlignment(el, CENTER_ALIGN);
          // this.setStyle(el, 'block', null, 'auto');
          this.setClass(el, 'ss__image-align-center');
        },
      },
      [RIGHT_ALIGN]: {
        name: RIGHT_ALIGN,
        icon: options.icons.right,
        apply: (el: HTMLElement) => {
          this.setAlignment(el, RIGHT_ALIGN);
          // this.setStyle(el, 'inline', 'right', '0 0 1em 1em');
          this.setClass(el, 'ss__image-align-right');
        },
      },
    };
  }

  getAlignments(): Alignment[] {
    return Object.keys(this.alignments).map((k) => this.alignments[k]);
  }

  clear(el: HTMLElement): void {
    el.removeAttribute(this.alignAttribute);
    this.setStyle(el, null, null, null);
    this.setClass(el, null);
  }

  isAligned(el: HTMLElement, alignment: Alignment): boolean {
    return el.getAttribute(this.alignAttribute) === alignment.name;
  }

  setAlignment(el: HTMLElement, value: string) {
    el.setAttribute(this.alignAttribute, value);
  }

  setStyle(el: HTMLElement, display: string | null, float: string | null, margin: string | null) {
    if (this.applyStyle) {
      el.style.setProperty('display', display);
      el.style.setProperty('float', float);
      el.style.setProperty('margin', margin);
    }
  }

  setClass(el: HTMLElement, className: string | null) {
    this.setStyle(el, null, null, null);
    el.classList.remove('ss__image-align-left');
    el.classList.remove('ss__image-align-center');
    el.classList.remove('ss__image-align-right');

    if (className) {
      el.classList.add(className);
    }
  }
}
