import { navigate } from '@reach/router';
import { useState, useCallback, useEffect } from 'react';
import { showToast } from 'src/common/util/show-toast';
import { useLanguages } from 'src/services/languages';
import { fetchOrganizationDetails } from 'src/services/organizations/api';
import { Organization } from 'src/services/organizations/types';
import { Project, useProject } from 'src/services/project';
import { fetchProjectDetails } from 'src/services/project/api';
import { useThemes } from 'src/services/themes';
import Swal from 'sweetalert2';
import { ProjectFieldType, SelfHostedType } from '../const';
import { ProjectFormValues } from '../types';

export function useProjectForm(organizationId?: number, projectId?: number) {
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [project, setProject] = useState<Project | null>(null);
  const { addProject, updateProject, deleteProject } = useProject();
  const { allLanguages } = useLanguages();
  const { themes } = useThemes();

  const onDelete = useCallback(async () => {
    const confirm = await Swal.fire({
      title: 'Delete',
      text: `Are you sure you want to delete this project?`,
      showCancelButton: true,
      confirmButtonColor: '#e0245a',
      confirmButtonText: 'Delete',
      icon: 'question',
    });

    if (confirm.value) {
      const result = await deleteProject(projectId!);

      // @ts-ignore
      if (result.ok) {
        showToast('success', 'Project has been deleted successfully');
        navigate('/dashboard');
      } else {
        showToast('error', 'Unable to delete');
      }
    }
  }, [deleteProject, projectId]);

  const save = async (values: ProjectFormValues) => {
    const isThemedProject = values.projectType === 2;

    function autoHosted() {
      if (
        values.selfHostingType === SelfHostedType.CUSTOM ||
        values.selfHostingType === SelfHostedType.INITIAL ||
        values.projectType === ProjectFieldType.STANDARD_PROJECT
      ) {
        return '';
      }
      if (values.autoHosted.includes('sitesails.io')) {
        return values.autoHosted;
      }
      return `${values.autoHosted}.sitesails.io`;
    }

    const languages =
      isThemedProject && !projectId ? [{ languageId: 'en' }, { languageId: 'hr' }] : values.languages || [];
    const data = {
      ...project,
      ...values,
      organizationId: (organizationId || project?.organizationId)!,
      languages: languages.map((lang) => lang.languageId),
      domain: autoHosted() || values.customHosted,
      projectType: Number(values.projectType),
      themeId: Number(values.themeId),
      hasTranslations: isThemedProject || values.hasTranslations,
      includeOriginalImageVariant: true,
    };

    let result;

    if (projectId) {
      result = await updateProject(data);
    } else {
      result = await addProject(data);
    }

    // @ts-ignore
    if (result.ok) {
      showToast('success', 'Successfully saved');
      if (organizationId) {
        navigate(`/organizations/${organizationId}`);
      } else {
        navigate(`/projects/${projectId}`);
      }
    } else {
      showToast('error', 'There was an error processing your action');
    }
  };

  useEffect(() => {
    async function loadOrganization() {
      const orgId = organizationId || project?.organizationId;

      if (!orgId) return;

      const result = await fetchOrganizationDetails(orgId!);

      if (result) {
        setOrganization(result);
      } else {
        showToast('error', 'Organization not found', 3000);
        navigate('/dashboard');
      }
    }
    loadOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, project]);

  useEffect(() => {
    async function loadProject() {
      if (projectId) {
        const result = await fetchProjectDetails(organizationId!, projectId);

        if (result) {
          setProject(result);
        } else {
          showToast('error', 'Project not found', 3000);
          navigate('/dashboard');
        }
      }
    }
    loadProject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return {
    organization,
    project,
    save,
    onDelete,
    themes,
    allLanguages,
  };
}
