import { sortBy } from 'lodash';
import { parseISO } from 'src/common';
import { CollectionItemWithRSelect } from 'src/modules/nodes/types';
import { Content } from 'src/types';
import { PageNode } from '../pages';
import { transformSectionField, transformSectionFieldForApi } from '../sections/transformations';
import {
  CollectionItem,
  CollectionItemApiRequest,
  Media,
  Node,
  NodeApiRequest,
  NodeBlock,
  NodeContent,
  NodeEvent,
  NodeProduct,
  NodeProductPrice,
  NodePublishable,
} from './types';

const stringOrNull = (value: any): string | null => {
  return value ? String(value) : null;
};

const numberOrUndefined = (value: any): number | undefined => {
  return value != null ? Number(value) : undefined;
};

const transformNodeImage = (response: any): Media => ({
  id: response.id,
  isMain: response.isMain,
  name: response.name,
  url: response.url,
  smallUrl: response.smallUrl,
  mediumUrl: response.mediumUrl,
  originalFileId: response.originalFileId,
  mediumFileId: response.mediumFileId,
  smallFileId: response.smallFileId,
});

const transformNodeContent = (response: any): NodeContent => ({
  id: response.id,
  languageId: response.languageId,
  slug: response.slug,
  data: response.data,
});

const transformNodeEvent = (response: any): NodeEvent => ({
  dateFrom: response.dateFrom ? parseISO(response.dateFrom) : new Date(),
  dateTo: response.dateTo ? parseISO(response.dateTo) : new Date(),
  recurringDay: response.recurringDay,
  recurringWeekDay: response.recurringWeekDay,
  weekdayOccurrence: response.weekdayOccurrence,
  occurences: response.occurences,
});

const transformProductPriceRequest = (response: any): NodeProductPrice => ({
  dateFrom: response.dateFrom ? response.dateFrom.toISOString() : null,
  dateTo: response.dateTo ? response.dateTo.toISOString() : null,
  price: response.price || 0,
  priceName: response.priceName || '',
  stripeLiveId: response.stripeLiveId || '',
  stripeTestId: response.stripeTestId || '',
});

const transformProductPriceResponse = (response: any): NodeProductPrice => ({
  dateFrom: response.dateFrom ? new Date(response.dateFrom) : null,
  dateTo: response.dateTo ? new Date(response.dateTo) : null,
  price: response.price || 0,
  priceName: response.priceName || '',
  stripeLiveId: response.stripeLiveId || '',
  stripeTestId: response.stripeTestId || '',
});

const transformNodeProduct = (response: any): NodeProduct => ({
  price: response.price || 0,
  currency: response.currency || '',
  quantity: response.quantity || 0,
  sold: response.sold || 0,
  stripeLiveId: response.stripeLiveId || '',
  stripeTestId: response.stripeTestId || '',
  prices: (response.prices || []).map(transformProductPriceResponse),
});

const transformNodePublishable = (response: any): NodePublishable => ({
  isPublished: response.isPublished,
  publishedFrom: response.publishedFrom ? parseISO(response.publishedFrom) : null,
  publishedTo: response.publishedTo ? parseISO(response.publishedTo) : null,
});

const transformNodeCollectionItem = (response: any): CollectionItem => ({
  id: response.id,
  position: response.position,
  nodeId: response.nodeId,
  nodeSlug: response.nodeSlug,
  nodeName: response.nodeName,
});

export const transformNode = (response: any): Node | PageNode => ({
  id: response.id,
  category: response.category,
  slug: response.slug || response.data?.slug,
  sectionId: response.sectionId,
  languageId: response.languageId,
  createdAt: new Date(response.createdAt),

  publishable: response.publishable ? transformNodePublishable(response.publishable) : null,

  data: response.data || {},
  image: response.image ? transformNodeImage(response.image) : null,
  images: response.images?.map(transformNodeImage) || [],
  contents: response.contents?.map(transformNodeContent) || [],

  event: response.event ? transformNodeEvent(response.event) : null,
  product: response.product
    ? transformNodeProduct(response.product)
    : {
        currency: '',
        price: 0,
        quantity: 0,
        sold: 0,
        stripeLiveId: '',
        stripeTestId: '',
        prices: [],
      },

  collectionItems: response.collectionItems?.map(transformNodeCollectionItem) || [],
  subsections: response.subsections
    ? Object.entries<any[]>(response.subsections).reduce<Record<string, CollectionItem[]>>(
        (subsections, [section, items]) => {
          subsections[section] = items.map(transformNodeCollectionItem) || [];
          return subsections;
        },
        {},
      )
    : undefined,

  blocks: sortBy(
    (response.blocks || []).map((block: NodeBlock) => ({
      ...block,
      description: block.description || '',
      fields: sortBy(block.fields.map(transformSectionField), 'position'),
    })),
    'position',
  ),

  seoMetadata: typeof response.seoMetadata === 'string' ? JSON.parse(response.seoMetadata) : response.seoMetadata,

  /* TODO: remove after refactor */
  name: response.data?.name,
});

const transformNodeContentForApi = (content: NodeContent): Content => ({
  id: content.id,
  languageId: content.languageId,
  slug: content.slug,
  title: stringOrNull(content.data.title) || '',
  description: stringOrNull(content.data.description),
  text: stringOrNull(content.data.text) || '',
  data: JSON.stringify(content.data || {}),
});

const transformNodeCollectionItemForApi = (
  collectionItem: CollectionItem,
  index: number,
): CollectionItemApiRequest => ({
  id: collectionItem.id,
  nodeId: collectionItem.nodeId,
  position: index + 1,
});

const transformNodeBlockForApi = (block: NodeBlock, index: number) => ({
  ...block,
  position: index + 1,
  description: stringOrNull(block.description),
  fields: (block.fields || []).map((f, fIndex) => transformSectionFieldForApi(f, fIndex + 1)),
});

export const transformNodeForApi = (node: Node): NodeApiRequest => {
  const data = node.data || {};

  // TODO: remove after data refactor is done
  delete data.price;
  delete data.currency;
  delete data.quantity;
  delete data.sold;
  delete data.stripe_test_id;
  delete data.stripe_live_id;
  delete data.date_from;
  delete data.date_until;
  delete data.recurring_day;
  delete data.recurring_week_day;
  delete data.weekday_occurence;

  return {
    categoryId: node.category?.id,
    data: JSON.stringify(data),
    seoMetadata: JSON.stringify(node.seoMetadata || []),
    contents: (node.contents || []).map(transformNodeContentForApi),
    blocks: (node.blocks || []).map(transformNodeBlockForApi),
    images: node.images || [],

    author: stringOrNull(node.data?.author) || '',

    publishable: node.publishable
      ? {
          isPublished: Boolean(node.publishable.isPublished),
          publishedFrom: node.publishable.publishedFrom ? (node.publishable.publishedFrom as Date) : new Date(),
          publishedTo: node.publishable.publishedTo ? (node.publishable.publishedTo as Date) : undefined,
        }
      : undefined,

    /* EVENTS + PRODUCTS */
    name: node.name,
    slug: node.slug,

    /* EVENTS */
    event: node.event
      ? {
          dateFrom: node.event?.dateFrom,
          dateTo: node.event?.dateTo,
          recurringDay: numberOrUndefined(node.event?.recurringDay),
          recurringWeekDay: numberOrUndefined(node.event?.recurringWeekDay),
          weekdayOccurrence: numberOrUndefined(node.event?.weekdayOccurrence),
        }
      : undefined,

    /* PRODUCTS */
    product: node.product
      ? {
          price: Number(node.product?.price || 0),
          currency: node.product?.currency,
          quantity: Number(node.product?.quantity || 0),
          sold: Number(node.product?.sold || 0),
          stripeLiveId: node.product?.stripeLiveId || undefined,
          stripeTestId: node.product?.stripeTestId || undefined,
          prices: (node.product?.prices || []).map(transformProductPriceRequest),
        }
      : undefined,

    /* COLLECTIONS */
    collectionItems: (node.collectionItems || []).map(transformNodeCollectionItemForApi),

    /* SUBSECTIONS */
    subsections: node.subsections
      ? Object.entries(node.subsections as Record<string, CollectionItemWithRSelect[]>).reduce<
          Record<string, CollectionItemApiRequest[]>
        >((result, [slug, items]) => {
          result[slug] = items.map(transformNodeCollectionItemForApi);
          return result;
        }, {})
      : undefined,
    // @ts-ignore
    parentSubsectionNodeId: node.parentSubsectionNodeId,
  };
};

export const duplicateNode = (sourceNode: Node): Node => {
  const node: Node = JSON.parse(JSON.stringify(sourceNode));

  node.id = undefined;
  if (node.name) {
    node.name += '-Copy';
  }
  if (node.data?.title) {
    node.data.title += '-Copy';
  }
  if (node.slug) {
    node.slug += '-copy';
  }
  if (node.data?.name) {
    node.data.name += '-Copy';
  }
  if (node.data?.slug) {
    node.data.slug += '-copy';
  }
  node.contents?.forEach((c) => {
    c.id = undefined;
    if (c.data?.title) {
      c.data.title += '-Copy';
    }
    if (c.data?.name) {
      c.data.name += '-Copy';
    }
    if (c.slug) {
      c.slug += '-copy';
    } else if (node.slug) {
      c.slug = `${node.slug}-copy`;
    }
    if (node.data?.slug) {
      node.data.slug += '-copy';
    } else if (node.slug) {
      c.data.slug = `${node.slug}-copy`;
    }
  });
  node.collectionItems?.forEach((ci) => {
    ci.id = undefined;
  });
  node.images?.forEach((i: any) => {
    i.id = null;
    i.fileId = i.originalFileId;
  });

  return node;
};
