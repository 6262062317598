import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_TABLE_ROWS } from 'src/common/const';
import {
  getCollections,
  fetchCollections,
  addCollection,
  updateCollection as updateCollectionAction,
  deleteCollection as deleteCollectionAction,
} from '../redux';
import { Collection } from '../types';

export function useCollections(slug: string, collectionSlug: string) {
  const dispatch = useDispatch();
  const collections = useSelector(getCollections);

  const loadCollections = useCallback(
    (page = 1, search?: string, sortBy?: string, sortDirection?: string) => {
      return dispatch(
        fetchCollections(page, MAX_TABLE_ROWS, {
          slug,
          collectionSlug,
          search,
          sortBy: sortBy || 'id',
          sortDirection: sortDirection || 'desc',
        }),
      );
    },
    [collectionSlug, dispatch, slug],
  );

  function createCollection(data: Collection) {
    return dispatch(addCollection(data));
  }

  function deleteCollection(id: number) {
    return dispatch(deleteCollectionAction(id, { collectionSlug }));
  }

  function updateCollection(data: Collection) {
    return dispatch(updateCollectionAction(data, { collectionSlug }));
  }

  return {
    collections,
    deleteCollection,
    loadCollections,
    createCollection,
    updateCollection,
  };
}
